import { Accordion, AccordionTab } from "primereact/accordion";
import { useContext, useEffect, useState } from "react";
import SubmittalGeneralInformation from "./SubmittalGeneralInformation";
import SubmittalWorkflow from "./NewSubmittalWorkflow";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import {
  createSubmittal,
  createSubmittalCoversheet,
} from "../../api/submittalsAPI";
import { queryClient } from "../../api/requestProcessor";
import { uploadFiles } from "../../api/filesAPI";
import SubmittalLoader from "../Loaders/SubmittalLoader";
import { AppContext } from "../../context/AppProvider";
import { getErrorMessage } from "../../util/helper";

export default function DraftAiSubmittal() {
  const [newSubmittal, setNewSubmittal] = useState({
    title: "",
    specification_section_id: undefined,
    number: 0,
    revision: 0,
    responsible_contractor_id: undefined,
    received_from_id: undefined,
    submittal_manager_id: undefined,
    status: {},
    received_date: undefined,
    issue_date: undefined,
    distribution_member_ids: [],
    private: false,
    first_name: "",
  });
  const [files, setFiles] = useState([]);
  const [uploadReset, setUploadReset] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [workflow, setWorkflow] = useState([
    { user_ids: [], approver_type: "Submitter", due_date: undefined },
  ]);
  const [label, setLabel] = useState("Save");

  const { toast, user } = useContext(AppContext);

  const navigate = useNavigate();

  const { mutate: createSubmittalMutation, isPending: isPendingCreation } =
    useMutation({
      mutationFn: createSubmittal,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["submittals"] });
        navigate("/submittals");
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Submittal Created",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const {
    mutateAsync: createSubmittalCoversheetMutation,
    isPending: isPendingCoversheet,
    error: error,
  } = useMutation({
    mutationFn: createSubmittalCoversheet,
    onSuccess: () => {
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Coversheet Generated",
        life: 3000,
      });
      setUploadReset(true);
    },
    onError: (error) => {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: getErrorMessage(error),
        life: 3000,
      });
    },
  });

  const { mutateAsync: uploadFilesMutation, isPending: isPendingUpload } =
    useMutation({
      mutationFn: uploadFiles,
      onSuccess: () => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "File Uploaded",
          life: 3000,
        });
        setUploadReset(true);
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  useEffect(() => {
    if (isPendingUpload) {
      setLabel("Uploading Files");
    } else if (isPendingCreation) {
      setLabel("Saving Changes");
    } else {
      setLabel("Save");
    }
  }, [isPendingUpload, isPendingCreation]);

  useEffect(() => {
    const draftSubmittal = JSON.parse(localStorage.getItem("draft_submittal"));

    const transformedWorkflow = draftSubmittal.workflow_data.map(
      (innerArray) => ({
        user_ids: innerArray.map((obj) => obj.user_id),
        ...innerArray[0],
      })
    );

    setNewSubmittal(draftSubmittal);
    setWorkflow(transformedWorkflow);
  }, []);

  const cancelSubmittalCreation = () => {
    navigate("/submittals");
  };

  const handleUpdateSubmittal = async () => {
    let updatedAttachments = [...attachments];

    if (files.length > 0) {
      const attachmentNames = files.map((file) => file.name);
      const submittalData = { ...newSubmittal };
      let coversheetId;

      let uploadPromises;
      if (user.settings.should_create_cover_sheet) {
        coversheetId = await createSubmittalCoversheetMutation({
          submittalData: submittalData,
          attachments: attachmentNames,
          workflow: workflow,
        });

        uploadPromises = files.map(async (file) => {
          return await uploadFilesMutation({
            files: [file],
            coversheet_id: coversheetId,
          });
        });
      } else {
        uploadPromises = files.map(async (file) => {
          return await uploadFilesMutation({
            files: [file],
          });
        });
      }

      const responses = await Promise.all(uploadPromises);
      updatedAttachments = [...responses, ...attachments];
    }
    createSubmittalMutation({
      submittalData: newSubmittal,
      attachments: updatedAttachments,
      workflow: workflow,
    });
  };

  if (!newSubmittal) {
    return <SubmittalLoader />;
  }

  if (newSubmittal) {
    return (
      <>
        <p className="text-3xl w-10 font-medium line-height-1">
          Create Submittal
        </p>
        <Accordion multiple activeIndex={[0, 1]}>
          <AccordionTab header="GENERAL INFORMATION">
            <SubmittalGeneralInformation
              newSubmittal={newSubmittal}
              setNewSubmittal={setNewSubmittal}
              setFiles={setFiles}
              uploadReset={uploadReset}
              setUploadReset={setUploadReset}
              attachments={attachments}
              setAttachments={setAttachments}
              editMode={false}
            />
          </AccordionTab>
          <AccordionTab header="SUBMITTAL WORKFLOW">
            <SubmittalWorkflow
              workflow={workflow}
              setWorkflow={setWorkflow}
              editMode={false}
            />
          </AccordionTab>
        </Accordion>
        <div className="flex justify-content-end gap-2 mt-2">
          <Button
            label="Cancel"
            icon="pi pi-times"
            outlined
            onClick={cancelSubmittalCreation}
          />
          <Button
            label={label}
            icon="pi pi-check"
            onClick={handleUpdateSubmittal}
            loading={
              isPendingCreation || isPendingUpload || isPendingCoversheet
            }
            disabled={
              isPendingCreation || isPendingUpload || isPendingCoversheet
            }
            className="right-0"
          />
        </div>
      </>
    );
  }
}
