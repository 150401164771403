import { InputText } from "primereact/inputtext";
import { fetchVendors } from "../../api/vendorsAPI";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { fetchSpecs } from "../../api/specsAPI";
import {
  getNextSubmittalNumber,
  getSubmittalStatusOptions,
} from "../../api/submittalsAPI";
import { fetchEntities } from "../../api/entitiesAPI";
import { getUserData } from "../../util/storage";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { Checkbox } from "primereact/checkbox";
import CustomFileUpload from "../FileUpload/FileUpload";
import Attachments from "../Attachments";
import { createNewDate } from "../../util/helper";

export default function SubmittalGeneralInformation({
  newSubmittal,
  setNewSubmittal,
  setFiles,
  uploadReset,
  setUploadReset,
  attachments,
  setAttachments,
  editMode,
}) {
  const isProcoreUser = getUserData()["is_procore_user"];
  const { data: specs, isFetching: isFetchingSpecs } = useQuery({
    queryKey: ["specs"],
    queryFn: fetchSpecs,
    staleTime: 5 * 60 * 1000,
  });

  const { data: vendors, isFetching: isFetchingVendors } = useQuery({
    queryKey: ["vendors"],
    queryFn: fetchVendors,
    staleTime: 5 * 60 * 1000,
  });

  const { data: users, isFetching: isFetchingUsers } = useQuery({
    queryKey: ["entities"],
    queryFn: fetchEntities,
    staleTime: 5 * 60 * 1000,
  });

  const { data: statusOptions, isFetching: isFetchingStatusOptions } = useQuery(
    {
      queryKey: ["submittalStatusOptions"],
      queryFn: getSubmittalStatusOptions,
      staleTime: 30 * 60 * 1000,
    }
  );

  const {
    data: nextSubmittalNumber,
    refetch: fetchNextNumber,
    isFetching: isFetchingNextSubmittalNumber,
  } = useQuery({
    queryKey: ["nextNumber", newSubmittal.specification_section_id],
    queryFn: ({ signal }) =>
      getNextSubmittalNumber({
        signal,
        specSectionId: newSubmittal.specification_section_id,
      }),
    enabled: false,
  });

  useEffect(() => {
    let _newSubmittal = { ...newSubmittal };

    if (!newSubmittal.submittal_manager_id) {
      const user = getUserData();

      _newSubmittal.submittal_manager_id = isProcoreUser
        ? user.procore_user.id
        : user.id;
    }
    setNewSubmittal(_newSubmittal);
  }, []);

  useEffect(() => {
    fetchNextNumber();
  }, [newSubmittal.specification_section_id, fetchNextNumber]);

  useEffect(() => {
    if (nextSubmittalNumber && !editMode) {
      let _newSubmittal = { ...newSubmittal };
      _newSubmittal.number = nextSubmittalNumber;

      setNewSubmittal(_newSubmittal);
    }
  }, [nextSubmittalNumber]);

  useEffect(() => {
    if (statusOptions && !editMode && isProcoreUser) {
      let _newSubmittal = { ...newSubmittal };

      statusOptions.forEach((option) => {
        if (option.status === "Open") {
          _newSubmittal.status = option;
        }
      });

      setNewSubmittal(_newSubmittal);
    }
  }, [statusOptions]);

  useEffect(() => {
    if (newSubmittal.received_from_id && users) {
      users.forEach((element) => {
        if (element.id === newSubmittal.received_from_id && element.vendor.id) {
          let _newSubmittal = { ...newSubmittal };
          _newSubmittal.responsible_contractor_id = element.vendor.id;
          setNewSubmittal(_newSubmittal);
        }
      });
    }
  }, [newSubmittal.received_from_id]);

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _newSubmittal = { ...newSubmittal };

    _newSubmittal[`${name}`] = val;

    setNewSubmittal(_newSubmittal);
  };

  const onCheckBoxChanged = (e) => {
    const val = e.checked;
    let _newSubmittal = { ...newSubmittal };

    _newSubmittal["private"] = val;

    setNewSubmittal(_newSubmittal);
  };

  const onFileChange = (files) => {
    setFiles(files);
  };

  return (
    <>
      <div>
        <div className="grid">
          <div className="col-12">
            <div className="flex flex-column gap-2">
              <label htmlFor="title">Title</label>
              <InputText
                id="title"
                value={newSubmittal.title}
                onChange={(e) => onInputChange(e, "title")}
              />
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label htmlFor="specification-section">Spec Section</label>
              <Dropdown
                id="specification-section"
                value={newSubmittal.specification_section_id}
                onChange={(e) => onInputChange(e, "specification_section_id")}
                options={specs}
                optionLabel="label"
                optionValue="id"
                placeholder="Select a Spec Section"
                className="w-full"
                disabled={isFetchingSpecs}
                filter
              />
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="grid">
              <div className="col-6">
                <div className="flex flex-column gap-2">
                  <label htmlFor="number">Number</label>
                  <InputText
                    id="number"
                    type="number"
                    value={newSubmittal.number}
                    onChange={(e) => onInputChange(e, "number")}
                    disabled={isFetchingNextSubmittalNumber}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="flex flex-column gap-2">
                  <label htmlFor="revision">Revision</label>
                  <InputText
                    id="revision"
                    value={newSubmittal.revision}
                    onChange={(e) => onInputChange(e, "revision")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label htmlFor="submittal-type">Submittal Type</label>
              <div
                id="submittal-type"
                className="flex h-3rem align-items-center"
              >
                --
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label htmlFor="submittal-package">Submittal Package</label>
              <div
                id="submittal-package"
                className="flex h-3rem align-items-center"
              >
                --
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label htmlFor="responsible-contractor">
                Responsible Contractor
              </label>
              <Dropdown
                id="responsible-contractor"
                value={newSubmittal.responsible_contractor_id}
                onChange={(e) => onInputChange(e, "responsible_contractor_id")}
                options={vendors}
                optionLabel="name"
                optionValue={isProcoreUser ? "id" : "_id"}
                placeholder="Select a Vendor"
                className="w-full"
                disabled={true}
                filter
              />
              <small id="responsible-contractor-help">
                Will be automatically selected after selecting received from
              </small>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label htmlFor="received-from">Received From</label>
              <Dropdown
                id="received-from"
                value={newSubmittal.received_from_id}
                onChange={(e) => onInputChange(e, "received_from_id")}
                options={users}
                optionLabel="name"
                optionValue="id"
                placeholder="Select a Person"
                className="w-full"
                disabled={isFetchingUsers}
                filter
              />
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label htmlFor="submittal-manager">Submittal Manager</label>
              <Dropdown
                id="submittal-manager"
                value={newSubmittal.submittal_manager_id}
                onChange={(e) => onInputChange(e, "submittal_manager_id")}
                options={users}
                optionLabel="name"
                optionValue="id"
                placeholder="Select a Person"
                className="w-full"
                disabled={isFetchingUsers}
                filter
              />
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label htmlFor="status">Status</label>
              <Dropdown
                id="status"
                value={newSubmittal.status}
                onChange={(e) => onInputChange(e, "status")}
                options={statusOptions}
                optionLabel={isProcoreUser ? "name" : "value"}
                placeholder="Select a Status"
                className="w-full"
                disabled={isFetchingStatusOptions}
                filter
              />
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label htmlFor="submit-by">Submit By</label>
              <div id="submit-by" className="flex h-3rem align-items-center">
                --
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label htmlFor="received-date">Received Date</label>
              <Calendar
                id="received-date"
                value={
                  newSubmittal?.received_date &&
                  createNewDate(newSubmittal?.received_date)
                }
                onChange={(e) => onInputChange(e, "received_date")}
                showIcon
                placeholder="Select a Date"
              />
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label htmlFor="issue-date">Issue Date</label>
              <Calendar
                id="issue-date"
                value={
                  newSubmittal?.issue_date &&
                  new Date(
                    new Date(newSubmittal?.issue_date).getTime() +
                      Math.abs(
                        new Date(newSubmittal?.issue_date).getTimezoneOffset() *
                          60000
                      )
                  )
                }
                onChange={(e) => onInputChange(e, "issue_date")}
                showIcon
                placeholder="Select a Date"
              />
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label htmlFor="final-due-date">Final Due Date</label>
              <div
                id="final-due-date"
                className="flex h-3rem align-items-center"
              >
                --
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label htmlFor="cost-code">Cost Code</label>
              <div id="cost-code" className="flex h-3rem align-items-center">
                --
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label htmlFor="location">Location</label>
              <div id="location" className="flex h-3rem align-items-center">
                --
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label htmlFor="linked-drawings">Linked Drawings</label>
              <div
                id="linked-drawings"
                className="flex h-3rem align-items-center"
              >
                --
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label htmlFor="distribution-member-ids">Distribution List</label>
              <MultiSelect
                id="distribution-member-ids"
                value={newSubmittal.distribution_member_ids}
                onChange={(e) => onInputChange(e, "distribution_member_ids")}
                options={users}
                optionLabel="name"
                optionValue="id"
                placeholder="Select People"
                filter
              />
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label htmlFor="ball-in-court">Ball In Court</label>
              <div
                id="ball-in-court"
                className="flex h-3rem align-items-center"
              >
                --
              </div>
              <small id="responsible-contractor-help">
                Will be automatically assigned after creating the submittal
              </small>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label htmlFor="lead-time">Lead Time</label>
              <div id="lead-time" className="flex h-3rem align-items-center">
                --
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label htmlFor="required-on-site-date">
                Required On-Site Date
              </label>
              <div
                id="required-on-site-date"
                className="flex h-3rem align-items-center"
              >
                --
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label htmlFor="first_name">Private</label>
              <div className="flex align-items-center">
                <Checkbox
                  inputId="private"
                  onChange={(e) => onCheckBoxChanged(e)}
                  checked={newSubmittal.private}
                />
                <label htmlFor="private" className="ml-2">
                  Visible only to admins, workflow, and distribution list
                  members.
                </label>
              </div>
            </div>
          </div>
          <div className="col-12 md:col-6">
            <div className="flex flex-column gap-2">
              <label htmlFor="description">Description</label>
              <InputTextarea
                id="description"
                value={newSubmittal.description ? newSubmittal.description : ""}
                onChange={(e) => onInputChange(e, "description")}
                autoResize
                rows={8}
                cols={30}
              />
            </div>
          </div>
          <div className="col-12 md:col-6">
            <div className="flex flex-column gap-2">
              <label htmlFor="attachments">Attachments</label>
              <CustomFileUpload
                id="attachments"
                accept=""
                onFileChange={onFileChange}
                reset={uploadReset}
                setReset={setUploadReset}
              />
            </div>
            {attachments && attachments.length ? (
              <div className="field mt-2">
                <Attachments
                  id={"submittal-attachments"}
                  attachments={attachments}
                  setAttachments={setAttachments}
                  isDisabled={false}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
