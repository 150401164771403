import { useQuery } from "@tanstack/react-query";
import { fetchAllCompanies } from "../../../api/adminAPI";
import AdminCompaniesTable from "./CompaniesTable";

export default function AdminCompanies() {
  const { data: companies, isFetching } = useQuery({
    queryKey: ["admin-companies"],
    queryFn: ({ signal }) => fetchAllCompanies(signal),
    staleTime: 5 * 60 * 1000,
  });
  return <AdminCompaniesTable companies={companies} isLoading={isFetching} />;
}
