import axios from "axios";
import { getAuthToken } from "../util/auth";
import API_BASE_URL from "../util/apiConfig";
import { getCompanyId, getProjectId, getUserData } from "../util/storage";
import { generateBaseUrl } from "../util/helper";

const token = getAuthToken();

const vendorsClient = axios.create({
  baseURL: `${API_BASE_URL}`,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export async function fetchVendors({ signal }) {
  const user = getUserData();
  const isProcoreUser = user["is_procore_user"];
  const projectId = getProjectId();
  const companyId = getCompanyId();

  if (isProcoreUser) {
    const baseUrl = generateBaseUrl(isProcoreUser, projectId, `vendors`);

    const response = await vendorsClient.get(
      baseUrl,
      {
        params: {
          company_id: companyId,
        },
      },
      signal
    );

    return response.data;
  } else {
    const response = await vendorsClient.get(
      `/projects/${projectId}/vendors`,
      {
        params: {
          company_id: companyId,
        },
      },
      signal
    );
    return response.data;
  }
}

export async function fetchCompanyVendors({ signal }) {
  const companyId = getCompanyId();

  const response = await vendorsClient.get(
    `/nicky/companies/${companyId}/vendors`,
    { signal }
  );

  return response.data;
}

export async function createVendor({ newVendor }) {
  const projectId = getProjectId();
  const companyId = getCompanyId();

  const response = await vendorsClient.post(
    `/nicky/companies/${companyId}/vendors?project_id=${projectId}`,
    newVendor
  );

  return response.data;
}
