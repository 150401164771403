import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { fetchEntities } from "../api/entitiesAPI";
import { fetchCommitments } from "../api/commitmentsAPI";
import CommitmentsTable from "../components/Commitments/CommitmentsTable";
import DeleteCommitmentDialog from "../components/Commitments/DeleteCommitmentDialog";
import { useState } from "react";

const initialFilters = [
  {
    key: "contract_company_ids",
    value: "Contract Company",
    selected_options: [],
    options: [],
  },
  {
    key: "commitment_types",
    value: "Type",
    selected_options: [],
    options: [],
  },
  {
    key: "statuses",
    value: "Status",
    selected_options: [],
    options: [],
  },
  {
    key: "ssov_statuses",
    value: "SSOV Status",
    selected_options: [],
    options: [],
  },
  {
    key: "executed",
    value: "Executed",
    selected_options: [],
    options: [],
  },
];

const Commitments = () => {
  const [queryParams, setQueryParams] = useState({
    page: 1,
    per_page: 10,
  });
  const [isVisibleDelete, setIsVisibleDelete] = useState(false);
  const [commitment, setCommitment] = useState(null);
  const navigate = useNavigate();

  const { data: users } = useQuery({
    queryKey: ["entities"],
    queryFn: fetchEntities,
    staleTime: 5 * 60 * 1000,
  });

  const {
    data: commitments,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["commitments", queryParams],
    queryFn: ({ signal }) =>
      fetchCommitments({ signal, queryParams: queryParams }),
    staleTime: 5 * 60 * 1000,
  });

  const openCreateDialog = () => {
    navigate("/commitments/new");
  };

  const openUpdateDialog = (rowData) => {
    navigate(`/commitments/${rowData._id}/edit`);
  };

  const openDeleteDialog = (rowData) => {
    setCommitment(rowData);
    setIsVisibleDelete(true);
  };

  const hideDeleteDialog = () => {
    setIsVisibleDelete(false);
  };

  return (
    <>
      <CommitmentsTable
        isFetching={isFetching}
        commitments={commitments?.commitments}
        total={commitments && commitments.total}
        setQueryParams={setQueryParams}
        openCreateDialog={openCreateDialog}
        openUpdateDialog={openUpdateDialog}
        openDeleteDialog={openDeleteDialog}
        users={users}
        initialFilters={initialFilters}
        grandTotal={commitments?.grand_total}
      />
      <DeleteCommitmentDialog
        visible={isVisibleDelete}
        commitment={commitment}
        onHide={hideDeleteDialog}
      />
    </>
  );
};

export default Commitments;
