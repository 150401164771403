import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useMutation } from "@tanstack/react-query";

import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";

import { postPrompt } from "../../api/inputAPI";
import { updateInput } from "../../reducers/inputSlice";
import { useNavigate } from "react-router-dom";

import "./Input.css";
import { AppContext } from "../../context/AppProvider";
import { getErrorMessage } from "../../util/helper";

export default function PromptForm() {
  const [prompt, setPrompt] = useState("");

  const { toast } = useContext(AppContext);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: postPrompt,
    onSuccess: () => {
      navigate("../handler");
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Input Processed",
        life: 3000,
      });
    },
    onError: (error) => {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: getErrorMessage(error),
        life: 3000,
      });
    },
  });

  const submitPrompt = async () => {
    try {
      const response = await mutateAsync({ prompt: prompt });
      if (response) {
        dispatch(updateInput(response));
      }
    } catch (error) {
      console.error("Failed to submit prompt:", error);
    }
  };

  return (
    <>
      <div className="flex justify-content-center">
        <div className="w-20rem max-w-20rem">
          <div className="field flex flex-column">
            <label htmlFor="prompt" className="font-bold">
              Prompt
            </label>
            <InputTextarea
              id="prompt"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              required
              rows={8}
              cols={30}
              autoFocus
              className="max-w-full"
            />
          </div>
          <div className="field">
            <Button
              label="Submit"
              icon="pi pi-check"
              onClick={submitPrompt}
              loading={isPending}
              disabled={isPending}
            />
          </div>
        </div>
      </div>
    </>
  );
}
