import axios from "axios";
import { getAuthToken } from "../util/auth";
import { getCompanyId, getProjectId, getUserData } from "../util/storage";
import API_BASE_URL from "../util/apiConfig";

const token = getAuthToken();

const projectsClient = axios.create({
  baseURL: `${API_BASE_URL}/projects`,
  headers: {
    Authorization: "Bearer " + token,
  },
});

export async function fetchProjects() {
  const isProcoreUser = getUserData()["is_procore_user"];
  const companyId = getCompanyId();

  if (isProcoreUser) {
    const response = await projectsClient.get("/procore", {
      params: {
        company_id: companyId,
      },
    });
    return response.data;
  } else {
    const response = await projectsClient.get("", {
      params: {
        company_id: companyId,
      },
    });
    return response.data;
  }
}

export async function fetchIdentifiers() {
  const isProcoreUser = getUserData()["is_procore_user"];
  const companyId = getCompanyId();

  const projectId = getProjectId();
  if (isProcoreUser) {
    const response = await projectsClient.get(
      `/${projectId}/identifiers/procore`,
      {
        params: {
          company_id: companyId,
        },
      }
    );
    return response.data;
  } else {
    const response = await projectsClient.get(`/${projectId}/identifiers`);
    return response.data;
  }
}

export async function createProject(project) {
  const response = await projectsClient.post("", project);

  return response.data;
}
