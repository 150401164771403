import { Avatar } from "primereact/avatar";
import { ProgressBar } from "primereact/progressbar";
import {
  formatProcoreRfiUrl,
  formatProcoreSubmittalUrl,
} from "../../util/helper";
import { getCompanyId } from "../../util/storage";
import { Button } from "primereact/button";
import { useMutation } from "@tanstack/react-query";
import { createRfiByAi } from "../../api/rfisAPI";
import { useContext } from "react";
import { AppContext } from "../../context/AppProvider";
import { useNavigate } from "react-router-dom";

export default function ChatbotMessages({ messages, chatbot }) {
  const { toast, user } = useContext(AppContext);
  const navigate = useNavigate();
  const { mutate: createRfiMutation, isPending: isPendingCreateRfi } =
    useMutation({
      mutationFn: ({ rfiData }) => createRfiByAi({ rfiData }),
      onSuccess: (response) => {
        toast.current.show({
          severity: "success",
          summary: "RFI Created",
          detail: "Rfi created successfully",
          life: 3000,
        });
        if (user.is_procore_user) {
          navigate(`/rfis/${response.id}/view`);
        } else {
          navigate(`/rfis/${response.data.id}/view`);
        }
      },
      onError: () => {
        toast.current.show({
          severity: "error",
          summary: "RFI Creation Failed",
          detail: "Rfi creation failed",
          life: 3000,
        });
      },
    });

  const handleCreateRfi = (rfiData) => {
    createRfiMutation({ rfiData });
  };

  const handleSendEmail = (emailData) => {
    window.open(emailData.mailto_url, "_blank");
  };

  const companyId = getCompanyId();
  if (
    chatbot &&
    chatbot.is_initialized &&
    chatbot.status === "Created" &&
    !!messages.length
  ) {
    return (
      <div className="flex align-items-center w-screen flex-column">
        {messages.map((message, index) => (
          <div
            className="flex bg-bluegray-50 border-round m-2 p-2 gap-2 chatbot-message-card"
            key={index}
          >
            <div className="flex m-1">
              <Avatar
                label={message.label}
                size="medium"
                style={{ backgroundColor: "#2196F3", color: "#ffffff" }}
                shape="circle"
              />
            </div>
            <div className="flex-1">
              <div className="flex flex-column">
                <div className="font-bold mb-2">{message.from}</div>
                <div>
                  {message.status === "pending" && (
                    <ProgressBar
                      mode="indeterminate"
                      style={{ height: "6px" }}
                    ></ProgressBar>
                  )}
                  {message.status === "sent" && message.text}
                </div>
                {message.sources?.map((doc, index) => {
                  if (message.type === "document") {
                    return (
                      <div className="my-1">
                        Source #{index + 1}:{" "}
                        <a href={doc.source}>
                          {doc.filename} (Page {doc.page})
                        </a>
                      </div>
                    );
                  } else if (message.type === "submittal") {
                    return (
                      <div className="my-1">
                        Submittal #{doc.specification_section}-{doc.number}-
                        {doc.revision}:{" "}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={formatProcoreSubmittalUrl(
                            doc.project_id,
                            doc.id
                          )}
                        >
                          {doc.title}
                        </a>
                      </div>
                    );
                  } else if (message.type === "rfi") {
                    return (
                      <div className="my-1">
                        RFI #{doc.number}:{" "}
                        <a
                          target="_blank"
                          href={formatProcoreRfiUrl(
                            companyId,
                            doc.project_id,
                            doc.id
                          )}
                          rel="noreferrer"
                        >
                          {doc.subject}
                        </a>
                      </div>
                    );
                  } else if (message.type === "create_rfi") {
                    return (
                      <>
                        <div className="my-1">RFI Details</div>
                        <div className="my-1">Subject: {doc.subject}</div>
                        <div className="my-1">
                          Question:{" "}
                          {user.is_procore_user
                            ? doc.question.body
                            : doc.rfi_body}
                        </div>
                        <div className="my-1">
                          Assignees: {doc.assignee_names.join(", ")}
                        </div>
                        <Button
                          className="my-1"
                          onClick={() => handleCreateRfi(doc)}
                          loading={isPendingCreateRfi}
                        >
                          Create RFI
                        </Button>
                      </>
                    );
                  } else if (message.type === "create_email") {
                    return (
                      <>
                        <div className="my-1">Email Details</div>
                        <div className="my-1">Subject: {doc.subject}</div>
                        <div className="my-1">Body: {doc.body}</div>
                        <div className="my-1">
                          Recipient:{" "}
                          {doc.recipients.map((recipient) => recipient.name)}
                        </div>
                        <Button
                          className="my-1"
                          onClick={() => handleSendEmail(doc)}
                        >
                          Go to Email
                        </Button>
                      </>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
