import { fetchPunchLists } from "../api/punchListsAPI";
import { useQuery } from "@tanstack/react-query";
import PunchListTable from "../components/PunchList/PunchListTable";

const PunchList = () => {
  const { data: punchLists, isPending } = useQuery({
    queryKey: ["punch-lists"],
    queryFn: ({ signal }) => fetchPunchLists(signal),
    staleTime: 5 * 60 * 1000,
  });

  return <PunchListTable punchLists={punchLists} />;
};

export default PunchList;
