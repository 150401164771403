import React, { useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";
import { MultiSelect } from "primereact/multiselect";
import { useQuery } from "@tanstack/react-query";
import { fetchVendors } from "../../api/vendorsAPI";
import { fetchEntities } from "../../api/entitiesAPI";
import CustomFileUpload from "../FileUpload/FileUpload";
import { fetchNextCommitmentNumber } from "../../api/commitmentsAPI";

const NewCommitmentForm = ({
  commitment,
  setCommitment,
  files,
  setFiles,
  uploadReset,
  setUploadReset,
}) => {
  const { data: vendors, isFetching: isFetchingVendors } = useQuery({
    queryKey: ["vendors"],
    queryFn: fetchVendors,
  });

  const { data: users, isFetching: isFetchingUsers } = useQuery({
    queryKey: ["entities"],
    queryFn: fetchEntities,
  });

  const { data: nextNumber, isFetching: isFetchingNumber } = useQuery({
    queryKey: ["nextCommitmentNumber"],
    queryFn: fetchNextCommitmentNumber,
    staleTime: 0,
  });

  useEffect(() => {
    if (nextNumber) {
      setCommitment((prev) => ({
        ...prev,
        number: nextNumber.number,
      }));
    }
  }, [nextNumber]);

  const commitmentTypes = [
    { label: "Subcontract", value: "subcontract" },
    { label: "Purchase Order", value: "purchase_order" },
  ];

  const statuses = [
    { label: "Draft", value: "draft" },
    { label: "Out For Bid", value: "out_for_bid" },
    { label: "Out For Signature", value: "out_for_signature" },
    { label: "Approved", value: "approved" },
    { label: "Complete", value: "complete" },
    { label: "Terminated", value: "terminated" },
    { label: "Void", value: "void" },
  ];

  const ssovTypes = [
    { label: "Draft", value: "draft" },
    { label: "None", value: "none" },
  ];

  const onFileChange = (files) => {
    setFiles(files);
  };

  return (
    <div className="grid p-fluid">
      <div className="col-12 md:col-6">
        <div className="p-inputgroup mb-3">
          <span className="p-inputgroup-addon">
            <i className="pi pi-tag"></i>
          </span>
          <Dropdown
            value={commitment.commitment_type}
            options={commitmentTypes}
            onChange={(e) =>
              setCommitment({ ...commitment, commitment_type: e.value })
            }
            placeholder="Select Type*"
            className={`${!commitment.commitment_type ? "p-invalid" : ""}`}
          />
        </div>

        <div className="p-inputgroup mb-3">
          <span className="p-inputgroup-addon">
            <i className="pi pi-building"></i>
          </span>
          <Dropdown
            value={commitment.contract_company_id}
            options={vendors}
            onChange={(e) =>
              setCommitment({ ...commitment, contract_company_id: e.value })
            }
            optionLabel="name"
            optionValue="_id"
            placeholder="Select Contract Company*"
            className={`${!commitment.contract_company_id ? "p-invalid" : ""}`}
            filter
            filterBy="name"
            emptyFilterMessage="No companies found"
          />
        </div>

        <div className="p-inputgroup mb-3">
          <span className="p-inputgroup-addon">
            <i className="pi pi-file"></i>
          </span>
          <InputText
            value={commitment.title}
            onChange={(e) =>
              setCommitment({ ...commitment, title: e.target.value })
            }
            placeholder="Title*"
            className={`${!commitment.title ? "p-invalid" : ""}`}
          />
        </div>

        <div className="p-inputgroup mb-3">
          <span className="p-inputgroup-addon">
            <i className="pi pi-hashtag"></i>
          </span>
          <InputText
            value={commitment.number}
            onChange={(e) =>
              setCommitment({ ...commitment, number: e.target.value })
            }
            placeholder="Number*"
            className={`${!commitment.number ? "p-invalid" : ""}`}
            disabled={isFetchingNumber}
          />
        </div>

        <div className="mb-3">
          <InputTextarea
            value={commitment.description}
            onChange={(e) =>
              setCommitment({ ...commitment, description: e.target.value })
            }
            rows={3}
            placeholder="Description"
          />
        </div>

        <div className="p-inputgroup mb-3">
          <span className="p-inputgroup-addon">$</span>
          <InputNumber
            value={commitment.original_contract_amount}
            onValueChange={(e) =>
              setCommitment({
                ...commitment,
                original_contract_amount: e.value,
              })
            }
            mode="decimal"
            minFractionDigits={2}
            placeholder="Original Contract Amount"
          />
        </div>
      </div>

      <div className="col-12 md:col-6">
        <div className="mb-3">
          <Dropdown
            value={commitment.status}
            options={statuses}
            onChange={(e) => setCommitment({ ...commitment, status: e.value })}
            placeholder="Select Status"
          />
        </div>

        <div className="mb-3">
          <Dropdown
            value={commitment.ssov_status}
            options={ssovTypes}
            onChange={(e) =>
              setCommitment({ ...commitment, ssov_status: e.value })
            }
            placeholder="Select SSOV Status"
          />
        </div>

        <div className="mb-3">
          <Calendar
            value={
              commitment.contract_date
                ? new Date(commitment.contract_date)
                : null
            }
            onChange={(e) =>
              setCommitment({
                ...commitment,
                contract_date: e.value?.toISOString().split("T")[0],
              })
            }
            placeholder="Contract Date"
            showIcon
          />
        </div>

        <div className="mb-3">
          <Calendar
            value={
              commitment.contract_start_date
                ? new Date(commitment.contract_start_date)
                : null
            }
            onChange={(e) =>
              setCommitment({
                ...commitment,
                contract_start_date: e.value?.toISOString().split("T")[0],
              })
            }
            placeholder="Contract Start Date"
            showIcon
          />
        </div>

        <div className="mb-3">
          <Calendar
            value={
              commitment.contract_estimated_completion_date
                ? new Date(commitment.contract_estimated_completion_date)
                : null
            }
            onChange={(e) =>
              setCommitment({
                ...commitment,
                contract_estimated_completion_date: e.value
                  ?.toISOString()
                  .split("T")[0],
              })
            }
            placeholder="Estimated Completion Date"
            showIcon
          />
        </div>
      </div>

      <div className="col-12">
        <div className="mb-3">
          <InputTextarea
            value={commitment.inclusions}
            onChange={(e) =>
              setCommitment({ ...commitment, inclusions: e.target.value })
            }
            rows={2}
            placeholder="Inclusions"
          />
        </div>

        <div className="mb-3">
          <InputTextarea
            value={commitment.exclusions}
            onChange={(e) =>
              setCommitment({ ...commitment, exclusions: e.target.value })
            }
            rows={2}
            placeholder="Exclusions"
          />
        </div>

        <div className="mb-3">
          <MultiSelect
            value={commitment.visibility}
            options={users}
            onChange={(e) =>
              setCommitment({ ...commitment, visibility: e.value })
            }
            optionLabel="name"
            optionValue="id"
            placeholder="Select Users for Visibility"
            filter
            filterBy="name"
            emptyFilterMessage="No users found"
          />
        </div>

        <div className="mb-3 flex align-items-center">
          <Checkbox
            checked={commitment.executed}
            onChange={(e) =>
              setCommitment({ ...commitment, executed: e.checked })
            }
            className="mr-2"
          />
          <label>Executed</label>
        </div>

        <div className="mb-3">
          <CustomFileUpload
            id="attachments"
            accept=""
            onFileChange={onFileChange}
            reset={uploadReset}
            setReset={setUploadReset}
          />
        </div>
      </div>
    </div>
  );
};

export default NewCommitmentForm;
