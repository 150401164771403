import React, { useState, useRef } from "react";

import DeleteSubmittal from "./DeleteSubmittal";
import SubmittalsTable from "./SubmittalsTable";
import MobileDataTable from "../MobileDataTable/MobileDataTable";
import SubmittalAttachments from "./SubmittalAttachments";
import CreateAiSubmittal from "./CreateAiSubmittal";
import { useNavigate } from "react-router-dom";

export default function Submittals({
  submittals,
  isPending,
  refetch,
  setQueryParams,
  total,
}) {
  let emptySubmittal = {
    number: "",
    attachments: [],
    received_from: {},
    workflow_data: [],
  };

  const initialFilters = [
    {
      key: "approver_id",
      value: "Approver",
      selected_options: [],
      options: [],
    },
    {
      key: "ball_in_court_id",
      value: "Ball In Court",
      selected_options: [],
      options: [],
    },
    {
      key: "received_from_id",
      value: "Received From",
      selected_options: [],
      options: [],
    },
    {
      key: "responsible_contractor_id",
      value: "Responsible Contractor",
      selected_options: [],
      options: [],
    },
    {
      key: "specification_section_id",
      value: "Spec Section",
      selected_options: [],
      options: [],
    },
    {
      key: "submittal_manager_id",
      value: "Submittal Manager",
      selected_options: [],
      options: [],
    },
    { key: "status_id", value: "Status", selected_options: [], options: [] },
  ];

  const [selectedSubmittals, setSelectedSubmittals] = useState([]);
  const [deleteSubmittalDialog, setDeleteSubmittalDialog] = useState(false);
  const [submittal, setSubmittal] = useState(emptySubmittal);
  const [submittalId, setSubmittalId] = useState();
  const [attachmentsDialog, setAttachmentsDialog] = useState(false);
  const [submittalAiDialog, setSubmittalAiDialog] = useState(false);

  const dt = useRef(null);

  const navigate = useNavigate();

  const openNew = () => {
    navigate("/submittals/new");
  };

  const openNewAi = () => {
    setSubmittalAiDialog(true);
  };

  const openSubmittalAttachments = (submittal) => {
    navigate(`/submittals/${submittal.id}/view`);
    /* if (SubmittalData) {
      setSubmittal(SubmittalData);
    } else {
      setSubmittal({ ...submittal });
    }

    setSubmittalId(submittal.id);
    setAttachmentsDialog(true); */
  };

  const hideAttachmentsDialog = () => {
    setAttachmentsDialog(false);
  };

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const hideAiDialog = () => {
    setSubmittalAiDialog(false);
  };

  const hideDeleteSubmittalDialog = () => {
    setDeleteSubmittalDialog(false);
  };

  const confirmDeleteSubmittal = (submittal) => {
    setSubmittal(submittal);
    setDeleteSubmittalDialog(true);
  };

  const editSubmittal = (submittal) => {
    navigate(`/submittals/${submittal.id}/edit`);
  };

  return (
    <>
      <SubmittalsTable
        dt={dt}
        selectedSubmittals={selectedSubmittals}
        setSelectedSubmittals={setSelectedSubmittals}
        submittals={submittals}
        isPending={isPending}
        exportCSV={exportCSV}
        editSubmittal={editSubmittal}
        refetch={refetch}
        setQueryParams={setQueryParams}
        total={total}
        openSubmittalAttachments={openSubmittalAttachments}
        openNew={openNew}
        confirmDeleteSubmittal={confirmDeleteSubmittal}
        openNewAi={openNewAi}
        initialFilters={initialFilters}
      />

      <MobileDataTable
        items={submittals}
        isPending={isPending}
        title="title"
        body="specification_section.description"
        number="formatted_number"
        status="status.name"
        date="due_date"
        assignees="ball_in_court"
        editMode={true}
        editHandler={editSubmittal}
        total={total}
        setQueryParams={setQueryParams}
        createHandler={openNew}
        openSubmittalAttachments={openSubmittalAttachments}
        deleteHandler={confirmDeleteSubmittal}
        initialFilters={initialFilters}
        pageType="submittal"
      />

      <SubmittalAttachments
        attachmentsDialog={attachmentsDialog}
        hideAttachmentsDialog={hideAttachmentsDialog}
        submittalId={submittalId}
      />

      <CreateAiSubmittal
        submittalDialog={submittalAiDialog}
        hideDialog={hideAiDialog}
      />

      <DeleteSubmittal
        deleteSubmittalDialog={deleteSubmittalDialog}
        hideDeleteSubmittalDialog={hideDeleteSubmittalDialog}
        submittal={submittal}
      />
    </>
  );
}
