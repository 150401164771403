import axios from "axios";
import { getAuthToken } from "../util/auth";
import API_BASE_URL from "../util/apiConfig";
import { getCompanyId, getProjectId } from "../util/storage";

const token = getAuthToken();

const chatbotsClient = axios.create({
  baseURL: `${API_BASE_URL}/chatbots`,
  headers: {
    Authorization: "Bearer " + token,
  },
});

export async function getChatbot({ signal }) {
  const projectId = getProjectId();
  const companyId = getCompanyId();

  const response = await chatbotsClient.get("", {
    params: {
      project_id: projectId,
      company_id: companyId,
    },
    signal,
  });
  return response.data;
}

export async function askChatbot({ query, history }) {
  const projectId = getProjectId();
  const companyId = getCompanyId();

  const response = await chatbotsClient.post(`/ask`, query, {
    params: {
      project_id: projectId,
      company_id: companyId,
    },
  });
  return response.data;
}

export async function initializeChatbot() {
  const projectId = getProjectId();
  const companyId = getCompanyId();

  const response = await chatbotsClient.post(
    "",
    {},
    {
      params: {
        project_id: projectId,
        company_id: companyId,
      },
    }
  );
  return response.data;
}
