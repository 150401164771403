import React from "react";
import { Card } from "primereact/card";
import { InviteCodesTable } from "../components/Admin/InviteCodes/InviteCodesTable";
import { CreateInviteCode } from "../components/Admin/InviteCodes/CreateInviteCode";

const InviteCodesPage = () => {
  return (
    <div className="grid">
      <div className="col-12 mb-4">
        <Card title="Create Invite Code">
          <CreateInviteCode />
        </Card>
      </div>
      <div className="col-12">
        <Card title="Invite Codes">
          <InviteCodesTable />
        </Card>
      </div>
    </div>
  );
};

export default InviteCodesPage;
