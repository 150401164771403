import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useContext, useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { AppContext } from "../../context/AppProvider";
import FilterSidebar from "../FilterSidebar";
import { Dropdown } from "primereact/dropdown";
import { exportPDF } from "../../store/utils";
import { Tag } from "primereact/tag";

const exportOptions = [
  { label: "CSV", value: "csv", icon: "pi pi-file" },
  { label: "PDF", value: "pdf", icon: "pi pi-file-pdf" },
];

const CommitmentsTable = ({
  isFetching,
  commitments,
  total,
  setQueryParams,
  openCreateDialog,
  openUpdateDialog,
  openDeleteDialog,
  users,
  initialFilters,
  grandTotal,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [filterSidebarVisible, setFilterSidebarVisible] = useState(false);
  const [filterQuery, setFilterQuery] = useState({});
  const [lazyState, setLazyState] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: {},
  });
  const [numOfSelectedFilters, setNumOfSelectedFilters] = useState(0);
  const dt = useRef(null);
  const { user } = useContext(AppContext);

  useEffect(() => {
    setQueryParams(mergeAllQueries());
  }, [lazyState, setQueryParams]);

  useEffect(() => {
    setLazyState({ ...lazyState, first: 0, page: 0 });
  }, [filterQuery, setQueryParams]);

  const mergeAllQueries = () => {
    const pageQuery = { page: lazyState.page + 1, per_page: lazyState.rows };
    const searchQuery = { search: searchInput };
    return {
      ...pageQuery,
      ...searchQuery,
      ...filterQuery,
    };
  };

  const onSearchInputChange = (e) => {
    const val = (e.target && e.target.value) || "";
    setSearchInput(val);
  };

  const handleSearch = (e, isFromButton) => {
    if (e.key === "Enter" || isFromButton) {
      setLazyState({ ...lazyState, page: 0, first: 0 });
    }
  };

  const onResetSearch = (e) => {
    const allQueries = mergeAllQueries();
    allQueries.search = "";
    setQueryParams(allQueries);
    setSearchInput("");
  };

  const onPage = (e) => {
    setLazyState(e);
  };

  const handleExport = (type) => {
    if (type === "csv") {
      exportCSV(false);
    } else if (type === "pdf") {
      // Format data for PDF
      const formattedData = commitments.map((commitment) => ({
        Number: commitment.number,
        "Contract Company": commitment.contract_company?.name,
        Title: commitment.title,
        Status: commitment.status || "N/A",
        Executed: commitment.executed ? "Yes" : "No",
        "Original Contract Amount": formatCurrency(
          commitment.original_contract_amount
        ),
        "Approved Change Orders": formatCurrency(
          commitment.approved_change_orders
        ),
        "Revised Contract Amount": formatCurrency(
          commitment.revised_contract_amount
        ),
      }));

      const title = "Commitments Report";
      const fileName = "commitments-report.pdf";

      exportPDF(formattedData, title, fileName, grandTotal);
    }
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value || 0);
  };

  const getStatusSeverity = (status) => {
    switch (status) {
      case "draft":
        return "warning";
      case "out_for_bid":
        return "info";
      case "out_for_signature":
        return "info";
      case "approved":
        return "success";
      case "complete":
        return "success";
      case "terminated":
        return "danger";
      case "void":
        return "danger";
      default:
        return null;
    }
  };

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const header = () => {
    return (
      <div className="flex align-items-center justify-content-between">
        <div className="flex gap-2 align-items-center">
          <Button
            label="New"
            icon="pi pi-plus"
            severity="success"
            onClick={openCreateDialog}
            disabled={user.role < 2}
          />
          <Dropdown
            options={exportOptions}
            onChange={(e) => handleExport(e.value)}
            value={null}
            placeholder="Export"
            className="p-button-outlined"
            optionLabel="label"
            icon="pi pi-file-export"
          />
          <Button
            label={
              numOfSelectedFilters === 0
                ? "Filter"
                : `Filter (${numOfSelectedFilters})`
            }
            icon="pi pi-filter"
            onClick={() => setFilterSidebarVisible(true)}
          />
        </div>

        <div className="flex align-items-center gap-2">
          <span className="p-input-icon-right">
            {searchInput && (
              <i
                className="pi pi-times mr-1 cursor-pointer"
                onClick={onResetSearch}
              />
            )}
            <InputText
              value={searchInput}
              onChange={onSearchInputChange}
              placeholder="Keyword Search"
              className="mr-1"
              onKeyDown={(e) => handleSearch(e, false)}
            />
          </span>
          <Button icon="pi pi-search" onClick={(e) => handleSearch(e, true)} />
        </div>
      </div>
    );
  };

  const footer = (
    <div className="flex justify-content-between">
      <div className="flex flex-column mr-4">
        <span className="font-normal mb-2">Original Contract Amount</span>
        <span className="font-bold mb-2">TOTAL</span>

        <span className="text-primary text-xl">
          {formatCurrency(grandTotal?.original_contract_amount)}
        </span>
      </div>
      <div className="flex flex-column mr-4">
        <span className="font-normal mb-2">Approved Change Orders</span>
        <span className="font-bold mb-2">TOTAL</span>
        <span className="text-primary text-xl">
          {formatCurrency(grandTotal?.approved_change_orders)}
        </span>
      </div>
      <div className="flex flex-column mr-4">
        <span className="font-normal mb-2">Pending Change Orders</span>
        <span className="font-bold mb-2">TOTAL</span>

        <span className="text-primary text-xl">
          {formatCurrency(grandTotal?.pending_change_orders)}
        </span>
      </div>
      <div className="flex flex-column">
        <span className="font-normal mb-2">Revised Contract Amount</span>
        <span className="font-bold mb-2">TOTAL</span>

        <span className="text-primary text-xl">
          {formatCurrency(
            Number(grandTotal?.original_contract_amount) +
              Number(grandTotal?.approved_change_orders)
          )}
        </span>
      </div>
    </div>
  );

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex">
        <Button
          icon="pi pi-pencil"
          rounded
          onClick={() => openUpdateDialog(rowData)}
          text
        />
        {user.role > 1 && (
          <Button
            type="button"
            icon="pi pi-times"
            rounded
            onClick={() => openDeleteDialog(rowData)}
            text
            severity="danger"
          />
        )}
      </div>
    );
  };

  const contractCompanyTemplate = (rowData) => {
    return <span>{rowData?.contract_company.name}</span>;
  };

  const statusTemplate = (rowData) => {
    if (!rowData.status) return <span>-</span>;

    const formattedStatus = rowData.status
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return (
      <Tag
        value={formattedStatus}
        severity={getStatusSeverity(rowData.status)}
      />
    );
  };

  const numberTemplate = (rowData) => {
    return (
      <span>
        {rowData.commitment_type === "purchase_order" && (
          <Tag
            value="PO"
            severity="info"
            className="mr-1"
            style={{ fontSize: "0.75rem" }}
          />
        )}
        {rowData.commitment_type === "subcontract" && (
          <Tag
            value="SC"
            severity="warning"
            className="mr-1"
            style={{ fontSize: "0.75rem" }}
          />
        )}
        {rowData.number}
      </span>
    );
  };

  const currencyTemplate = (rowData, field) => {
    return <span>{formatCurrency(rowData[field])}</span>;
  };

  const executedTemplate = (rowData) => {
    if (rowData.executed === null) return <span>-</span>;

    return (
      <Tag
        value={rowData.executed ? "Yes" : "No"}
        severity={rowData.executed ? "success" : "danger"}
      />
    );
  };

  return (
    <>
      <DataTable
        ref={dt}
        value={commitments}
        tableStyle={{ minWidth: "50rem" }}
        header={header}
        footer={footer}
        dataKey="_id"
        emptyMessage="No commitments found."
        paginator
        className="hidden md:block"
        loading={isFetching}
        lazy
        onPage={onPage}
        first={lazyState.first}
        rows={10}
        totalRecords={total}
      >
        <Column
          key="number"
          field="number"
          header="Number"
          body={numberTemplate}
          headerStyle={{ minWidth: "10rem" }}
        />
        <Column
          key="contract_company_id"
          field="contract_company_id"
          header="Contract Company"
          body={contractCompanyTemplate}
          headerStyle={{ minWidth: "12rem" }}
        />
        <Column
          key="title"
          field="title"
          header="Title"
          headerStyle={{ minWidth: "12rem" }}
        />
        <Column
          key="status"
          field="status"
          header="Status"
          body={statusTemplate}
          headerStyle={{ minWidth: "10rem" }}
        />
        <Column
          key="executed"
          field="executed"
          header="Executed"
          body={executedTemplate}
          headerStyle={{ minWidth: "8rem" }}
          bodyStyle={{ textAlign: "left" }}
        />
        <Column
          key="original_contract_amount"
          field="original_contract_amount"
          header="Original Contract Amount"
          body={(rowData) =>
            currencyTemplate(rowData, "original_contract_amount")
          }
          headerStyle={{ minWidth: "12rem" }}
          bodyStyle={{ textAlign: "left" }}
        />
        <Column
          key="pending_change_orders"
          field="pending_change_orders"
          header="Pending Change Orders"
          body={(rowData) => currencyTemplate(rowData, "pending_change_orders")}
          headerStyle={{ minWidth: "10rem" }}
          bodyStyle={{ textAlign: "left" }}
        />
        <Column
          key="approved_change_orders"
          field="approved_change_orders"
          header="Approved Change Orders"
          body={(rowData) =>
            currencyTemplate(rowData, "approved_change_orders")
          }
          headerStyle={{ minWidth: "10rem" }}
          bodyStyle={{ textAlign: "left" }}
        />
        <Column
          headerStyle={{ width: "5rem", textAlign: "center" }}
          bodyStyle={{ textAlign: "center", overflow: "visible" }}
          body={actionBodyTemplate}
        />
      </DataTable>
      <FilterSidebar
        isVisible={filterSidebarVisible}
        setIsVisible={setFilterSidebarVisible}
        setFilterQuery={setFilterQuery}
        initialFilters={initialFilters}
        pageType="commitments"
        setNumOfSelectedFilters={setNumOfSelectedFilters}
      />
    </>
  );
};

export default CommitmentsTable;
