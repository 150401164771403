import axios from "axios";
import { getAuthToken } from "../util/auth";
import { getProjectId, getUserData } from "../util/storage";
import API_BASE_URL from "../util/apiConfig";

const token = getAuthToken();

const filterClient = axios.create({
  baseURL: `${API_BASE_URL}/sticky-filters`,
  headers: {
    Authorization: "Bearer " + token,
  },
});

export async function fetchFilters({ signal, type }) {
  const projectId = getProjectId();

  const queryParams = { project_id: projectId, type: type };

  const response = await filterClient.get("", {
    params: queryParams,
    signal: signal,
  });

  return response.data;
}

export async function createFilter({ filterData }) {
  const isProcoreUser = getUserData()["is_procore_user"];
  const projectId = getProjectId();

  filterData["project_id"] = projectId;

  if (isProcoreUser) {
    const response = await filterClient.post("/", filterData);
    return response.data;
  } else {
    const response = await filterClient.post("/", filterData);
    return response.data;
  }
}
