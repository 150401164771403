import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { getProjects } from "../util/storage";

export const AppContext = React.createContext();

export const AppProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [projects, setProjects] = useState([]);
  const [selectedProjectName, setSelectedProjectName] = useState(null);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const toast = useRef(null);

  useEffect(() => {
    const storedUser = localStorage.getItem("userData");
    const storedProjects = getProjects();
    const storedSelectedProjectName = localStorage.getItem("savedProjectName");
    const storedSelectedProjectId = localStorage.getItem("savedProjectId");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    if (storedProjects) {
      setProjects(storedProjects);
    }
    if (storedSelectedProjectName) {
      setSelectedProjectName(storedSelectedProjectName);
    }
    if (storedSelectedProjectId) {
      setSelectedProjectId(storedSelectedProjectId);
    }
  }, []);

  return (
    <AppContext.Provider
      value={{
        toast,
        user,
        setUser,
        projects,
        setProjects,
        selectedProjectName,
        setSelectedProjectName,
        selectedProjectId,
        setSelectedProjectId,
      }}
    >
      {children}
      <Toast ref={toast} />
    </AppContext.Provider>
  );
};
