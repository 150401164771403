import axios from "axios";
import { getAuthToken } from "../util/auth";
import { getProjectId, getUserData } from "../util/storage";
import API_BASE_URL from "../util/apiConfig";
import { formatAssignees } from "../util/helper";
import qs from "qs";

const token = getAuthToken();

const tasksClient = axios.create({
  baseURL: `${API_BASE_URL}/tasks`,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

tasksClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response.data);
  }
);

export async function fetchTasks({ queryParams, signal }) {
  const response = await tasksClient.get("", {
    params: queryParams,
    signal: signal,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  });
  return response.data;
}

export async function fetchTask({ taskId, signal }) {
  const response = await tasksClient.get(`/${taskId}`, {
    signal,
  });
  return response.data;
}

export async function createTask({ taskData, attachments }) {
  const user = getUserData();
  const isProcoreUser = user.is_procore_user;
  console.log(taskData.assignees);

  taskData.assignees = formatAssignees(taskData.assignees);
  taskData.attachments = attachments;

  if (isProcoreUser) {
    taskData.task_manager_id = user.procore_user.id.toString();
  } else {
    taskData.task_manager_id = user._id;
  }

  const response = await tasksClient.post("", taskData);
  return response.data;
}

export async function updateTask({ taskId, taskData, attachments }) {
  taskData.attachments = attachments;

  const response = await tasksClient.patch(`/${taskId}`, taskData);
  return response.data;
}

export async function updateTaskStatus({ taskId, status }) {
  const taskData = { status: status };

  const response = await tasksClient.put(`/${taskId}`, taskData);
  return response.data;
}

export async function deleteTask({ taskId }) {
  const response = await tasksClient.delete(`/${taskId}`);
  return response.data;
}

export async function createReminder({ taskId, taskData }) {
  const response = await tasksClient.post(`/${taskId}/reminder`, taskData);
  return response.data;
}

export async function assignTask({ taskId, taskData }) {
  const response = await tasksClient.patch(`/${taskId}/assign`, taskData);
  return response.data;
}
