import jsPDF from "jspdf";
import "jspdf-autotable";

export function preventDefault(callback, selector) {
  selector = selector || ((x) => x);
  return (event) => {
    event.preventDefault();
    callback(selector(event));
  };
}

export function eventTargetSelector(event) {
  return event.target;
}

export const formatTime = (date) => {
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // The hour '0' should be '12'
  const minutesStr = minutes < 10 ? "0" + minutes : minutes;
  return hours + ":" + minutesStr + " " + ampm;
};

export const exportPDF = (data, title, fileName, grandTotal) => {
  const doc = new jsPDF("l", "pt"); // landscape orientation

  // Add title
  doc.setFontSize(16);
  doc.text(title, 40, 40);

  // Add timestamp
  doc.setFontSize(10);
  doc.text(`Generated on: ${new Date().toLocaleString()}`, 40, 60);

  // Convert data to format expected by autoTable
  const columns = Object.keys(data[0]).map((key) => ({
    header: key,
    dataKey: key,
  }));

  // Add main table
  const mainTable = doc.autoTable({
    columns,
    body: data,
    margin: { top: 80 },
    headStyles: { fillColor: [71, 71, 71] },
    alternateRowStyles: { fillColor: [245, 245, 245] },
    styles: {
      fontSize: 8,
      cellPadding: 5,
    },
  });

  // Calculate position for totals table
  const finalY = mainTable.lastAutoTable.finalY + 20;

  // Format currency for totals
  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value || 0);
  };

  // Create totals data
  const totalsData = [
    {
      Label: "Original Contract Amount",
      Amount: formatCurrency(grandTotal.original_contract_amount),
    },
    {
      Label: "Approved Change Orders",
      Amount: formatCurrency(grandTotal.approved_change_orders),
    },
    {
      Label: "Pending Change Orders",
      Amount: formatCurrency(grandTotal.pending_change_orders),
    },
    {
      Label: "Revised Contract Amount",
      Amount: formatCurrency(
        Number(grandTotal.original_contract_amount) +
          Number(grandTotal.approved_change_orders)
      ),
    },
  ];

  // Add totals table
  doc.autoTable({
    startY: finalY,
    head: [["Summary", "Amount"]],
    body: totalsData.map((item) => [item.Label, item.Amount]),
    headStyles: {
      fillColor: [71, 71, 71],
      fontSize: 10,
      fontStyle: "bold",
    },
    styles: {
      fontSize: 9,
      cellPadding: 5,
    },
    theme: "grid",
    margin: { left: 40 },
    tableWidth: 300,
  });

  // Save PDF
  doc.save(fileName);
};
