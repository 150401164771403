import axios from "axios";
import { getAuthToken } from "../util/auth";
import { getProjectId, getUserData } from "../util/storage";
import API_BASE_URL from "../util/apiConfig";

const token = getAuthToken();

const dashboardClient = axios.create({
  baseURL: `${API_BASE_URL}/nicky/projects`,
  headers: {
    Authorization: "Bearer " + token,
  },
});

dashboardClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response.data);
  }
);

export async function fetchOpenItems({ signal }) {
  const projectId = getProjectId();

  const response = await dashboardClient.get(
    `/${projectId}/dashboard/open-items`,
    signal
  );

  return response.data;
}
