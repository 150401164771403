import { useQuery } from "@tanstack/react-query";
import { DataTable } from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
import { fetchFolders, fetchRootFolders } from "../../api/foldersAPI";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import FolderDialog from "./FolderDialog";
import FileDialog from "./FileDialog";
import { BreadCrumb } from "primereact/breadcrumb";
import DeleteFolderDialog from "./DeleteFolderDialog";
import RenameDialog from "./RenameDialog";
import { Menu } from "primereact/menu";

export default function FolderTable() {
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [folderId, setFolderId] = useState(null);
  const [folders, setFolders] = useState(null);
  const [folderDialog, setFolderDialog] = useState(null);
  const [fileDialog, setFileDialog] = useState(null);
  const [deleteFolderDialog, setDeleteFolderDialog] = useState(null);
  const [renameDialog, setRenameDialog] = useState(null);
  const [directoryList, setDirectoryList] = useState([]);
  const [selectedFileOrFolder, setSelectedFileOrFolder] = useState(null);

  const dt = useRef(null);
  const menuRight = useRef(null);

  const items = [
    {
      label: "Create Folder",
      icon: "pi pi-plus",
      command: () => {
        openFolderDialog();
      },
    },
    {
      label: "Upload File",
      icon: "pi pi-upload",
      command: () => {
        openFileDialog();
      },
    },
  ];

  const home = {
    icon: "pi pi-home",
    command: () => {
      setFolderId(rootFolder.id);
      setDirectoryList([]);
    },
  };

  const { data: rootFolder, isFetching: isFetchingRootFolder } = useQuery({
    queryKey: ["root-folder"],
    queryFn: fetchRootFolders,
    options: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    },
  });

  const { data: folderData, isFetching: isPendingFolderData } = useQuery({
    queryKey: ["folders", folderId],
    queryFn: ({ signal }) => fetchFolders({ signal, folderId: folderId }),
    enabled: !!folderId,
    staleTime: 5 * 60 * 1000,
  });

  useEffect(() => {
    if (rootFolder) {
      setFolderId(rootFolder.id);
    }
  }, [rootFolder]);

  useEffect(() => {
    if (folderData) {
      setFolders([...folderData.folders, ...folderData.files]);
      const exists = directoryList.some((item) => item.id === folderData.id);
      if (!exists && rootFolder.id !== folderData.id) {
        setDirectoryList([
          ...directoryList,
          {
            label: folderData.name,
            id: folderData.id,
            command: () => {
              setFolderId(folderData.id);
              let _directoryList = [...directoryList];
              const index = _directoryList.findIndex(
                (item) => item.id === folderData.id
              );
              if (index !== -1 && index < _directoryList.length - 1) {
                _directoryList.length = index + 1;
              }
              setDirectoryList(_directoryList);
            },
          },
        ]);
      }
    }
  }, [folderData]);

  const openFolderDialog = () => {
    setFolderDialog(true);
  };

  const hideFolderDialog = () => {
    setFolderDialog(false);
  };

  const openFileDialog = () => {
    setFileDialog(true);
  };

  const hideFileDialog = () => {
    setFileDialog(false);
  };

  const openDeleteFolderDialog = () => {
    setDeleteFolderDialog(true);
  };

  const hideDeleteFolderDialog = () => {
    setDeleteFolderDialog(false);
  };

  const openRenameDialog = (rowData) => {
    setSelectedFileOrFolder(rowData);
    setRenameDialog(true);
  };

  const hideRenameDialog = () => {
    setRenameDialog(false);
  };

  const onFolderChange = (rowData) => {
    setSelectedFolders([]);
    if (rowData.file_type) {
      const newWindow = window.open(
        rowData.url,
        "_blank",
        "noopener,noreferrer"
      );
      if (newWindow) newWindow.opener = null;
    } else {
      setFolderId(rowData.id);
    }
  };

  const nameTemplate = (rowData) => {
    let icon = "";
    if (rowData.file_type) {
      if (rowData.file_type === "application/pdf") {
        icon = "pi pi-file-pdf";
      } else if (rowData.file_type.includes("image")) {
        icon = "pi pi-image";
      } else {
        icon = "pi pi-file";
      }
    } else {
      icon = "pi pi-folder-open";
    }
    return (
      <>
        <Button
          key={`button-${rowData.name}`}
          label={rowData.name}
          severity="secondary"
          icon={icon}
          className="border-none"
          onClick={() => {
            onFolderChange(rowData);
          }}
          outlined
        />
      </>
    );
  };

  const updatedOnTemplate = (rowData) => {
    const createdOn = new Date(rowData.updated_at);

    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    const formattedDateTime = createdOn
      .toLocaleString("en-US", options)
      .replace(",", " at");

    return <>{formattedDateTime}</>;
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-pencil"
          rounded
          text
          className="mr-2"
          onClick={() => openRenameDialog(rowData)}
        />
      </>
    );
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">Documents</h4>
      <div>
        {selectedFolders && !!selectedFolders.length && (
          <Button
            label="Delete"
            severity="danger"
            onClick={openDeleteFolderDialog}
            raised
            size="small"
            className="mr-2"
          />
        )}
        <Menu
          model={items}
          popup
          ref={menuRight}
          id="popup_menu_right"
          popupAlignment="right"
        />
        <Button
          label="New"
          icon="pi pi-plus"
          className="mr-2"
          onClick={(event) => menuRight.current.toggle(event)}
          aria-controls="popup_menu_right"
          aria-haspopup
        />
      </div>
    </div>
  );

  return (
    <>
      <BreadCrumb model={directoryList} home={home} />
      <DataTable
        ref={dt}
        selection={selectedFolders}
        onSelectionChange={(e) => setSelectedFolders(e.value)}
        dataKey="id"
        value={folders}
        header={header}
        paginator
        showGridlines
        lazy
        rows={10}
        loading={isPendingFolderData | isFetchingRootFolder}
        size="small"
        emptyMessage="No Documents found."
        exportFilename="Nicky AI Documents Log"
      >
        <Column
          selectionMode="multiple"
          exportable={false}
          style={{ width: "2rem" }}
        ></Column>
        <Column
          header="Name"
          body={nameTemplate}
          style={{ minWidth: "12rem" }}
        />
        <Column
          header="Updated On"
          body={updatedOnTemplate}
          style={{ minWidth: "12rem" }}
        />
        <Column
          body={actionBodyTemplate}
          exportable={false}
          style={{ width: "4rem" }}
        ></Column>
      </DataTable>

      {folderDialog && (
        <FolderDialog
          isVisible={folderDialog}
          hideDialog={hideFolderDialog}
          folderId={folderId}
        />
      )}

      {fileDialog && (
        <FileDialog
          isVisible={fileDialog}
          hideDialog={hideFileDialog}
          folderId={folderId}
        />
      )}
      {deleteFolderDialog && (
        <DeleteFolderDialog
          isVisible={deleteFolderDialog}
          hideDialog={hideDeleteFolderDialog}
          selectedFolders={selectedFolders}
          setSelectedFolders={setSelectedFolders}
          folderId={folderId}
        />
      )}
      {renameDialog && (
        <RenameDialog
          isVisible={renameDialog}
          hideDialog={hideRenameDialog}
          fileOrFolder={selectedFileOrFolder}
          folderId={folderId}
        />
      )}
    </>
  );
}
