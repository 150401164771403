import { useState, useEffect, useContext } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

import { useQuery } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";

import { fetchIdentifiers } from "../../api/projectsAPI";
import { queryClient } from "../../api/requestProcessor";

import { createConsultant, updateConsultant } from "../../api/consultantsAPI";
import { MultiSelect } from "primereact/multiselect";
import { AppContext } from "../../context/AppProvider";
import { getErrorMessage } from "../../util/helper";

export default function CreateConsultant({
  dialog,
  hideDialog,
  consultant,
  onInputChange,
  editMode,
}) {
  const [label, setLabel] = useState("Save");

  const { toast } = useContext(AppContext);

  const { data: identifiers } = useQuery({
    queryKey: ["identifiers"],
    queryFn: fetchIdentifiers,
  });

  const { mutate: createConsultantMutation, isPending: isPendingCreation } =
    useMutation({
      mutationFn: createConsultant,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["consultants"] });
        hideDialog();
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Consultant Created",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const { mutate: updateConsultantMutation, isPending: isPendingUpdate } =
    useMutation({
      mutationFn: updateConsultant,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["consultants"] });
        hideDialog();
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Consultant Updated",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  useEffect(() => {
    if (isPendingCreation || isPendingUpdate) {
      setLabel("Saving Changes");
    } else {
      setLabel("Save");
    }
  }, [isPendingCreation, isPendingUpdate]);

  const saveConsultant = async () => {
    if (editMode) {
      updateConsultantMutation({
        consultantData: consultant,
      });
    } else createConsultantMutation({ consultantData: consultant });
  };

  const consultantDialogFooter = (
    <>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button
        label={label}
        icon="pi pi-check"
        onClick={saveConsultant}
        loading={isPendingCreation || isPendingUpdate}
        disabled={isPendingCreation || isPendingUpdate}
      />
    </>
  );

  return (
    <Dialog
      visible={dialog}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Consultant Details"
      modal
      className="p-fluid mx-2"
      footer={consultantDialogFooter}
      onHide={hideDialog}
    >
      <div className="field">
        <label htmlFor="role" className="font-bold">
          Role
        </label>
        <InputText
          id="role"
          value={consultant.role}
          onChange={(e) => onInputChange(e, "role")}
          required
        />
      </div>
      <div className="field">
        <label htmlFor="project_managers" className="font-bold">
          Project Managers
        </label>
        <MultiSelect
          id="project_managers"
          value={consultant.project_manager_ids}
          onChange={(e) => onInputChange(e, "project_manager_ids")}
          options={identifiers}
          filter
          optionLabel="name"
          optionValue="id"
          placeholder="Select a Person"
        />
      </div>
      <div className="field">
        <label htmlFor="distribution_list" className="font-bold">
          Distribution List
        </label>
        <MultiSelect
          id="distribution_list"
          value={consultant.distribution_ids}
          onChange={(e) => onInputChange(e, "distribution_ids")}
          options={identifiers}
          filter
          optionLabel="name"
          optionValue="id"
          placeholder="Select a Person"
        />
      </div>
    </Dialog>
  );
}
