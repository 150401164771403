import axios from "axios";
import { getAuthToken } from "../util/auth";
import { getCompanyId, getProjectId, getUserData } from "../util/storage";
import API_BASE_URL from "../util/apiConfig";

const token = getAuthToken();

const entitiesClient = axios.create({
  baseURL: `${API_BASE_URL}/projects`,
  headers: {
    Authorization: "Bearer " + token,
  },
});

export async function fetchEntities() {
  const isProcoreUser = getUserData()["is_procore_user"];
  const projectId = getProjectId();
  const companyId = getCompanyId();

  if (isProcoreUser) {
    const response = await entitiesClient.get(`/${projectId}/users/procore`, {
      params: {
        company_id: companyId,
      },
    });
    return response.data;
  } else {
    const response = await entitiesClient.get(`/${projectId}/users`, {
      params: {
        company_id: companyId,
      },
    });
    return response.data;
  }
}
