import { Skeleton } from "primereact/skeleton";

export default function SubmittalLoader() {
  return (
    <>
      <div>
        <Skeleton width="30rem" height="3rem" className="mb-2"></Skeleton>
        <Skeleton width="30rem" height="2rem"></Skeleton>
        <div className="grid mt-2">
          <div className="col-12">
            <div className="flex flex-column gap-2">
              <Skeleton width="5rem" className="mb-2"></Skeleton>
              <Skeleton height="2rem" className="mb-2"></Skeleton>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <Skeleton width="5rem" className="mb-2"></Skeleton>
              <Skeleton height="2rem" className="mb-2"></Skeleton>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="grid">
              <div className="col-6">
                <div className="flex flex-column gap-2">
                  <Skeleton width="5rem" className="mb-2"></Skeleton>
                  <Skeleton height="2rem" className="mb-2"></Skeleton>
                </div>
              </div>
              <div className="col-6">
                <div className="flex flex-column gap-2">
                  <Skeleton width="5rem" className="mb-2"></Skeleton>
                  <Skeleton height="2rem" className="mb-2"></Skeleton>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <Skeleton width="5rem" className="mb-2"></Skeleton>
              <Skeleton height="2rem" className="mb-2"></Skeleton>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <Skeleton width="5rem" className="mb-2"></Skeleton>
              <Skeleton height="2rem" className="mb-2"></Skeleton>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <Skeleton width="5rem" className="mb-2"></Skeleton>
              <Skeleton height="2rem" className="mb-2"></Skeleton>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <Skeleton width="5rem" className="mb-2"></Skeleton>
              <Skeleton height="2rem" className="mb-2"></Skeleton>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <Skeleton width="5rem" className="mb-2"></Skeleton>
              <Skeleton height="2rem" className="mb-2"></Skeleton>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <Skeleton width="5rem" className="mb-2"></Skeleton>
              <Skeleton height="2rem" className="mb-2"></Skeleton>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <Skeleton width="5rem" className="mb-2"></Skeleton>
              <Skeleton height="2rem" className="mb-2"></Skeleton>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <Skeleton width="5rem" className="mb-2"></Skeleton>
              <Skeleton height="2rem" className="mb-2"></Skeleton>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <Skeleton width="5rem" className="mb-2"></Skeleton>
              <Skeleton height="2rem" className="mb-2"></Skeleton>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <Skeleton width="5rem" className="mb-2"></Skeleton>
              <Skeleton height="2rem" className="mb-2"></Skeleton>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <Skeleton width="5rem" className="mb-2"></Skeleton>
              <Skeleton height="2rem" className="mb-2"></Skeleton>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <Skeleton width="5rem" className="mb-2"></Skeleton>
              <Skeleton height="2rem" className="mb-2"></Skeleton>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <Skeleton width="5rem" className="mb-2"></Skeleton>
              <Skeleton height="2rem" className="mb-2"></Skeleton>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <Skeleton width="5rem" className="mb-2"></Skeleton>
              <Skeleton height="2rem" className="mb-2"></Skeleton>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <Skeleton width="5rem" className="mb-2"></Skeleton>
              <Skeleton height="2rem" className="mb-2"></Skeleton>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <Skeleton width="5rem" className="mb-2"></Skeleton>
              <Skeleton height="2rem" className="mb-2"></Skeleton>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <Skeleton width="5rem" className="mb-2"></Skeleton>
              <Skeleton height="2rem" className="mb-2"></Skeleton>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <Skeleton width="5rem" className="mb-2"></Skeleton>
              <Skeleton height="2rem" className="mb-2"></Skeleton>
            </div>
          </div>
          <div className="col-12 md:col-6">
            <div className="flex flex-column gap-2">
              <Skeleton width="5rem" className="mb-2"></Skeleton>
              <Skeleton height="2rem" className="mb-2"></Skeleton>
            </div>
          </div>
          <div className="col-12 md:col-6">
            <div className="flex flex-column gap-2">
              <Skeleton width="5rem" className="mb-2"></Skeleton>
              <Skeleton height="2rem" className="mb-2"></Skeleton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
