import React, { useContext, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { useMutation } from "@tanstack/react-query";
import { createReminder } from "../../api/tasksAPI";
import { AppContext } from "../../context/AppProvider";
import { getErrorMessage } from "../../util/helper";

const CreateTaskReminderDialog = ({ visible, onHide, task }) => {
  const [dateTime, setDateTime] = useState(null);
  const { toast } = useContext(AppContext);

  const { mutate: createTaskReminderMutation, isPending } = useMutation({
    mutationFn: createReminder,
    onSuccess: () => {
      onHide();
      setDateTime(null);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Reminder Added",
        life: 3000,
      });
    },
    onError: (error) => {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: getErrorMessage(error),
        life: 3000,
      });
    },
  });

  const handleSave = () => {
    createTaskReminderMutation({
      taskId: task._id,
      taskData: { send_at: dateTime },
    });
  };

  const handleHide = () => {
    setDateTime(null);
    onHide();
  };

  const footerContent = (
    <div className="mt-8">
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={handleHide}
        className="p-button-text"
      />
      <Button
        label={isPending ? "Saving" : "Save"}
        icon="pi pi-check"
        onClick={handleSave}
        disabled={!dateTime}
        loading={isPending}
      />
    </div>
  );

  return (
    <Dialog
      header="Create Task Reminder"
      visible={visible}
      style={{ width: "50vw" }}
      onHide={handleHide}
      footer={footerContent}
    >
      <div className="flex flex-column gap-2">
        <label htmlFor="assignees" className="font-bold">
          Reminder Date
        </label>
        <Calendar
          value={dateTime}
          onChange={(e) => setDateTime(e.value)}
          showTime
          showSeconds
          dateFormat="dd/mm/yy"
          className="w-full"
          placeholder="Select Date and Time"
        />
      </div>
    </Dialog>
  );
};

export default CreateTaskReminderDialog;
