import { useState, useRef, useContext } from "react";
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";

import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";

import { fetchIdentifiers } from "../../api/projectsAPI";
import { postInput } from "../../api/inputAPI";
import CustomFileUpload from "../FileUpload/FileUpload";
import { uploadFiles } from "../../api/filesAPI";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../../api/requestProcessor";
import { AppContext } from "../../context/AppProvider";
import { getErrorMessage } from "../../util/helper";

export default function Handler() {
  const [files, setFiles] = useState([]);
  const [uploadReset, setUploadReset] = useState(false);

  const { toast } = useContext(AppContext);
  const filterRef = useRef();

  const navigate = useNavigate();

  const inputData = useSelector((state) => state.input);

  const [handlerData, setHandlerData] = useState(inputData);

  const { data: identifiers } = useQuery({
    queryKey: ["identifiers"],
    queryFn: fetchIdentifiers,
  });

  const { mutateAsync, isPending } = useMutation({
    mutationFn: postInput,
    onSuccess: (response) => {
      if (response.email) {
        if (window.innerWidth < 768) {
          window.location.href = response.email;
        } else {
          window.open(response.email, "_blank");
        }
        navigate("../tasks");
      }
      if (response.rfi) {
        queryClient.invalidateQueries({ queryKey: ["tasks"] });
        queryClient.invalidateQueries({ queryKey: ["rfis"] });
        navigate("../rfis");
      } else if (response.task) {
        queryClient.invalidateQueries({ queryKey: ["tasks"] });
        navigate("../tasks");
      }
    },
    onError: (error) => {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: getErrorMessage(error),
        life: 3000,
      });
    },
  });

  const {
    mutateAsync: uploadFilesMutation,
    isPending: isPendingUpload,
    isError: isErrorUploading,
    error: uploadError,
  } = useMutation({
    mutationFn: uploadFiles,
    onSuccess: () => {
      setUploadReset(true);
    },
    onError: () => {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to Upload File",
        life: 3000,
      });
    },
  });

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _handlerData = { ...handlerData };

    _handlerData[`${name}`] = val;

    setHandlerData(_handlerData);
  };

  const onSwitchChange = (e, name) => {
    const val = e.target && e.target.value;
    let _handlerData = { ...handlerData };

    _handlerData[`${name}`] = val;

    setHandlerData(_handlerData);
  };

  const submitInput = async () => {
    let updatedAttachments = [];

    if (files.length > 0) {
      const uploadPromises = files.map(async (file) => {
        return await uploadFilesMutation({
          files: [file],
        });
      });

      const responses = await Promise.all(uploadPromises);
      updatedAttachments = [...responses];
    }
    await mutateAsync({
      inputData: handlerData,
      attachments: updatedAttachments,
    });
  };

  const onFileChange = (files) => {
    setFiles(files);
  };

  return (
    <>
      <div className="flex justify-content-center">
        <div className="w-30rem max-w-30rem">
          <div className="field flex flex-column">
            <label htmlFor="input_to" className="font-bold">
              To
            </label>
            <MultiSelect
              value={handlerData.to}
              id="input_to"
              options={identifiers}
              optionLabel="name"
              onChange={(e) => onInputChange(e, "to")}
              placeholder={inputData.to.length ? "" : "Assignees"}
              className="w-full mb-5"
              filter
              display="chip"
              autoFocus
              ref={filterRef}
              panelClassName="max-w-16rem"
            />
          </div>
          <div className="field flex flex-column">
            <label htmlFor="input_cc" className="font-bold">
              Cc
            </label>
            <MultiSelect
              value={handlerData.cc}
              id="input_cc"
              options={identifiers}
              optionLabel="name"
              onChange={(e) => onInputChange(e, "cc")}
              placeholder={inputData.to.length ? "" : "Assignees"}
              className="w-full mb-5"
              filter
              display="chip"
              autoFocus
              ref={filterRef}
              panelClassName="max-w-16rem"
            />
          </div>
          <div className="field flex flex-column">
            <label htmlFor="input_subject" className="font-bold">
              Subject
            </label>
            <InputText
              id="input_subject"
              value={handlerData.subject}
              onChange={(e) => onInputChange(e, "subject")}
              required
            />
          </div>
          <div className="field flex flex-column">
            <label htmlFor="input_body" className="font-bold">
              Body
            </label>
            <InputTextarea
              id="input_body"
              value={handlerData.body}
              onChange={(e) => onInputChange(e, "body")}
              required
              rows={8}
              cols={30}
              autoFocus
            />
          </div>
          <div className="mb-3">
            <CustomFileUpload
              accept=""
              onFileChange={onFileChange}
              reset={uploadReset}
              setReset={setUploadReset}
            />
          </div>
          <div className="field flex align-items-center gap-2">
            <label htmlFor="input_email" className="font-bold mb-0">
              Email
            </label>
            <InputSwitch
              id="input_email"
              checked={handlerData.send_email}
              onChange={(e) => onSwitchChange(e, "send_email")}
            />
          </div>
          <div className="field flex align-items-center gap-2">
            <label htmlFor="input_task" className="font-bold mb-0">
              Task
            </label>
            <InputSwitch
              id="input_task"
              checked={handlerData.assign_task}
              onChange={(e) => onSwitchChange(e, "assign_task")}
            />
          </div>
          <div className="field flex align-items-center gap-2">
            <label htmlFor="input_rfi" className="font-bold mb-0">
              RFI
            </label>
            <InputSwitch
              id="input_rfi"
              checked={handlerData.create_rfi}
              onChange={(e) => onSwitchChange(e, "create_rfi")}
            />
          </div>
          <div className="field">
            <Button
              label="Submit"
              icon="pi pi-check"
              onClick={submitInput}
              loading={isPending || isPendingUpload}
              disabled={isPending || isPendingUpload}
            />
          </div>
        </div>
      </div>
    </>
  );
}
