import { Button } from "primereact/button";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { queryClient } from "../api/requestProcessor";
import { AppContext } from "../context/AppProvider";
import { getErrorMessage } from "../util/helper";
import { getCompanyInfo, setCompanyInfo } from "../api/companiesAPI";
import { InputText } from "primereact/inputtext";

const CompanyInfo = () => {
  const [localCompanyInfo, setLocalCompanyInfo] = useState({
    name: "",
    address: "",
    phone: "",
  });
  const { toast } = useContext(AppContext);

  const { data: companyInfo, isFetching } = useQuery({
    queryKey: ["company-info"],
    queryFn: getCompanyInfo,
    staleTime: 5 * 60 * 1000,
  });

  const { mutate: setCompanyInfoMutation, isPending } = useMutation({
    mutationFn: setCompanyInfo,
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ["company-info"] });
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: response.message,
        life: 3000,
      });
    },
    onError: (error) => {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: getErrorMessage(error),
        life: 3000,
      });
    },
  });

  useEffect(() => {
    if (companyInfo) {
      setLocalCompanyInfo(companyInfo);
    }
  }, [companyInfo]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setLocalCompanyInfo((prevLocalCompanyInfo) => ({
      ...prevLocalCompanyInfo,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSave = () => {
    setCompanyInfoMutation({ companyInfo: localCompanyInfo });
  };

  return (
    <div className="flex flex-column gap-2 max-w-25rem">
      <label htmlFor="company_info_name" className="font-bold">
        Company Name
      </label>
      <InputText
        id="company_info_name"
        name="name"
        value={localCompanyInfo.name}
        placeholder="Company Name"
        onChange={handleChange}
      />
      <label htmlFor="company_info_address" className="font-bold">
        Address
      </label>
      <InputText
        id="company_info_address"
        name="address"
        value={localCompanyInfo.address}
        placeholder="Company Address"
        onChange={handleChange}
      />
      <label htmlFor="company_info_phone" className="font-bold">
        Phone
      </label>
      <InputText
        id="company_info_phone"
        name="phone"
        value={localCompanyInfo.phone}
        placeholder="Company Phone"
        onChange={handleChange}
      />
      <Button
        label={
          isPending
            ? "Savings"
            : isFetching
            ? "Fetching Info"
            : "Save Company Info"
        }
        onClick={handleSave}
        loading={isPending && isFetching}
      />
    </div>
  );
};

export default CompanyInfo;
