import React, { useEffect, useRef, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Menubar } from "primereact/menubar";
import { Dropdown } from "primereact/dropdown";
import { ProgressBar } from "primereact/progressbar";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { AppContext } from "../context/AppProvider";
import { fetchProjects } from "../api/projectsAPI";
import { queryClient } from "../api/requestProcessor";

const PROJECTS_CACHE_DURATION = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

const NavigationItems = {
  PREMIUM: [
    { label: "Tasks", icon: "pi pi-check", path: "/tasks" },
    { label: "RFIs", icon: "pi pi-list", path: "/rfis" },
    { label: "Submittals", icon: "pi pi-file", path: "/submittals" },
    { label: "Daily Reports", icon: "pi pi-calendar", path: "/daily-reports" },
    { label: "Punch List", icon: "pi pi-check-circle", path: "/punch-lists" },
    { label: "Commitments", icon: "pi pi-dollar", path: "/commitments" },
    { label: "Documents", icon: "pi pi-folder-open", path: "/folders" },
    { label: "Directory", icon: "pi pi-users", path: "/directory" },
    { label: "Chatbot", icon: "pi pi-comments", path: "/chatbots" },
  ],
  BASIC: [
    { label: "Tasks", icon: "pi pi-check", path: "/tasks" },
    { label: "RFIs", icon: "pi pi-list", path: "/rfis" },
    { label: "Submittals", icon: "pi pi-file", path: "/submittals" },
    { label: "Punch List", icon: "pi pi-check-circle", path: "/punch-lists" },
  ],
};

const Navbar = () => {
  const menuRight = useRef(null);
  const navigate = useNavigate();
  const {
    user,
    projects,
    setProjects,
    selectedProjectName,
    setSelectedProjectName,
    selectedProjectId,
    setSelectedProjectId,
  } = useContext(AppContext);

  const userName = `${user.first_name} ${user.last_name}`;
  const isPremiumUser = user.role >= 2;

  const {
    data: projectsData,
    isPending,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["projects"],
    queryFn: fetchProjects,
    enabled: false,
  });

  useEffect(() => {
    if (projects.length < 1) {
      refetch();
    }
  }, [projects.length, refetch]);

  useEffect(() => {
    if (projectsData) {
      const expiresAt = Date.now() + PROJECTS_CACHE_DURATION;

      localStorage.setItem("projects", JSON.stringify(projectsData));
      localStorage.setItem("projectsExpiration", JSON.stringify(expiresAt));
      setProjects(projectsData);

      if (projectsData.length > 0) {
        const firstProject = projectsData[0];
        setSelectedProjectName(firstProject.name);
        setSelectedProjectId(firstProject.id);
        localStorage.setItem("savedProjectId", firstProject.id);
        localStorage.setItem("savedProjectName", firstProject.name);
        queryClient.invalidateQueries({ queryKey: ["openItems"] });
      }
    }
  }, [projectsData, setProjects, setSelectedProjectId, setSelectedProjectName]);

  const handleTabChange = (path) => {
    navigate(path);
  };

  const handleProjectChange = (e) => {
    const selectedValue = e.value;
    const selectedProject = projects.find(
      (project) => project.id === selectedValue
    );

    if (selectedProject) {
      setSelectedProjectId(selectedValue);
      setSelectedProjectName(selectedProject.name);
      localStorage.setItem("savedProjectId", selectedValue);
      localStorage.setItem("savedProjectName", selectedProject.name);

      queryClient.invalidateQueries(["tasks"]);
      queryClient.invalidateQueries(["identifiers"]);
      queryClient.invalidateQueries(["rfis"]);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload(true);
  };

  // Convert navigation items to menubar format
  const menuItems = [
    {
      icon: "pi pi-home text-xl",
      command: () => handleTabChange("/company-selection"),
    },
    {
      template: () => (
        <>
          {isPending && !projects.length ? (
            <>
              <p>Loading...</p>
              <ProgressBar mode="indeterminate" style={{ height: "0.5rem" }} />
            </>
          ) : isError ? (
            <div>Error loading projects.</div>
          ) : (
            <Dropdown
              value={selectedProjectId}
              options={projects.map((project) => ({
                label: project.name,
                value: project.id,
              }))}
              onChange={handleProjectChange}
              placeholder={selectedProjectName || "Select Project"}
              className="custom-dropdown mr-1 no-border mx-2 max-w-12rem hidden md:flex"
            />
          )}
        </>
      ),
    },
    {
      label: "Project Tools",
      icon: "pi pi-folder",
      items: (isPremiumUser
        ? NavigationItems.PREMIUM
        : NavigationItems.BASIC
      ).map((item) => ({
        label: item.label,
        icon: item.icon,
        command: () => handleTabChange(item.path),
      })),
    },
  ];

  const userMenuItems = [
    {
      label: userName,
      icon: "pi pi-fw pi-user",
      items: isPremiumUser
        ? [
            {
              label: "Settings",
              icon: "pi pi-fw pi-cog",
              command: () => handleTabChange("/settings"),
              className: "hidden md:block",
            },
            {
              label: "Logout",
              icon: "pi pi-fw pi-sign-out",
              command: handleLogout,
            },
          ]
        : [
            {
              label: "Logout",
              icon: "pi pi-fw pi-sign-out",
              command: handleLogout,
            },
          ],
    },
  ];

  const start = () => (
    <div className="hidden md:block header-title mx-4">
      <Link style={{ textDecoration: "none", color: "inherit" }} to="/">
        Nicky AI
      </Link>
    </div>
  );

  const end = () => (
    <div className="flex">
      <Dropdown
        value={selectedProjectId}
        options={projects.map((project) => ({
          label: project.name,
          value: project.id,
        }))}
        onChange={handleProjectChange}
        placeholder={selectedProjectName || "Select Project"}
        className="custom-dropdown mr-1 no-border mx-2 max-w-12rem md:hidden"
      />
      <>
        <Menu
          model={userMenuItems}
          popup
          ref={menuRight}
          id="popup_menu_right"
          popupAlignment="right"
          className="mt-2"
        />
        <Button
          icon="pi pi-fw pi-user"
          label={userName}
          severity="secondary"
          text
          className="hidden sm:block mr-2"
          onClick={(event) => menuRight.current.toggle(event)}
          aria-controls="popup_menu_right"
          aria-haspopup
        />
        <Button
          icon="pi pi-fw pi-user"
          severity="secondary"
          text
          className="sm:hidden"
          onClick={(event) => menuRight.current.toggle(event)}
          aria-controls="popup_menu_right"
          aria-haspopup
        />
      </>
    </div>
  );

  return (
    <div className="card fixed w-full z-5 top-0">
      <Menubar model={menuItems} start={start} end={end} />
    </div>
  );
};

export default Navbar;
