import React, { useContext, useEffect, useState } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { useQuery } from "@tanstack/react-query";
import { fetchCompanies } from "../api/companiesAPI";
import { ListBox } from "primereact/listbox";
import { getUserData } from "../util/storage";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../api/requestProcessor";
import { AppContext } from "../context/AppProvider";
import { getOnboardInfo } from "../api/authAPI";

const CompanySelectionPage = () => {
  const isProcoreUser = getUserData()["is_procore_user"];

  const [selectedCompany, setSelectedCompany] = useState("");

  const navigate = useNavigate();
  const { setProjects, setSelectedProjectName, user } = useContext(AppContext);

  const { data: companies, isPending: isPendingCompanies } = useQuery({
    queryKey: ["companies"],
    queryFn: fetchCompanies,
    staleTime: 5 * 60 * 1000,
  });

  const { data: onboardInfo, isPending: isPendingOnboardInfo } = useQuery({
    queryKey: ["onboardInfo"],
    queryFn: getOnboardInfo,
    staleTime: 5 * 60 * 1000,
  });

  useEffect(() => {
    if (user.role < 2) {
      console.log("Stable");
    } else if (
      onboardInfo &&
      (!onboardInfo.is_procore_integrated || !onboardInfo.is_email_integrated)
    ) {
      navigate("/user-onboard");
    }
  }, [onboardInfo, navigate, user]);

  const handleCompanySelection = () => {
    if (isProcoreUser) {
      localStorage.setItem("company_id", selectedCompany.id);
      localStorage.removeItem("projects");
      localStorage.removeItem("savedProjectId");
      localStorage.removeItem("savedProjectName");
      queryClient.invalidateQueries({ queryKey: ["tasks"] });
      queryClient.invalidateQueries({ queryKey: ["rfis"] });
      queryClient.invalidateQueries({ queryKey: ["submittals"] });
      setProjects([]);
      setSelectedProjectName(null);
    } else {
      localStorage.setItem("company_id", selectedCompany._id);
      localStorage.removeItem("projects");
      localStorage.removeItem("savedProjectId");
      localStorage.removeItem("savedProjectName");
      queryClient.invalidateQueries({ queryKey: ["tasks"] });
      queryClient.invalidateQueries({ queryKey: ["rfis"] });
      queryClient.invalidateQueries({ queryKey: ["submittals"] });
      setProjects([]);
      setSelectedProjectName(null);
    }
    navigate("/");
  };

  const logout = () => {
    localStorage.clear();
    window.location.reload(true);
  };

  return (
    <div className="centered-content">
      <Card className="nicky-card" title="Select a Company">
        <div className="field mr-1">
          <ListBox
            value={selectedCompany}
            onChange={(e) => setSelectedCompany(e.value)}
            options={companies}
            optionLabel="name"
            className="w-full"
          />
        </div>

        <div>
          <Button
            className="w-full mb-2"
            label={isPendingCompanies ? "Fetching Companies" : "Save"}
            loading={isPendingCompanies}
            onClick={handleCompanySelection}
            disabled={!selectedCompany}
          />
          <Button
            className="w-full"
            severity="danger"
            label="Logout"
            onClick={logout}
          />
        </div>
      </Card>
    </div>
  );
};

export default CompanySelectionPage;
