import { useMutation, useQuery } from "@tanstack/react-query";
import { Sidebar } from "primereact/sidebar";
import React, { useContext, useEffect, useRef, useState } from "react";
import { fetchFilterOptions } from "../api/filterOptionsAPI";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import { createFilter, fetchFilters } from "../api/filtersAPI";
import { queryClient } from "../api/requestProcessor";
import { AppContext } from "../context/AppProvider";

export default function FilterSidebar({
  isVisible,
  setIsVisible,
  setFilterQuery,
  pageType,
  initialFilters,
  setNumOfSelectedFilters,
  additionalData,
}) {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filterType, setFilterType] = useState();
  const [filters, setFilters] = useState(initialFilters);
  const [multiSelectLoadingState, setMultiSelectLoadingState] = useState(
    filters.map(() => false)
  );

  const filterRef = useRef();

  const { user } = useContext(AppContext);

  const { data, refetch: refetchFilterOptions } = useQuery({
    queryKey: ["filterOptions", filterType],
    queryFn: ({ signal }) =>
      fetchFilterOptions({
        signal,
        filterType: filterType,
        pageType: pageType,
        additionalData: additionalData,
      }),
    enabled: false,
  });

  const { data: stickyFilters } = useQuery({
    queryKey: ["filters", pageType],
    queryFn: ({ signal }) =>
      fetchFilters({
        signal,
        type: pageType,
      }),
    staleTime: 5 * 60 * 1000,
    enabled: pageType !== "punch_list_items",
  });

  const {
    mutate: createFilterMutation,
    isPending: isPendingCreation,
    isError: isErrorCreating,
    error: createError,
  } = useMutation({
    mutationFn: createFilter,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["filters", pageType] });
    },
    onError: () => {},
  });

  useEffect(() => {
    if (stickyFilters && !stickyFilters.message) {
      setFilters(stickyFilters.filter_options);
      setSelectedFilters(stickyFilters.selected_filters);
    }
  }, [stickyFilters, setFilterQuery]);

  useEffect(() => {
    filters.forEach((filter, index) => {
      if (
        selectedFilters.includes(filter.value) &&
        filter.options.length === 0
      ) {
        setFilterType(filter.key);

        setMultiSelectLoadingState((prevLoadingState) => {
          const tempLoadingState = [...prevLoadingState];
          tempLoadingState[index] = true;
          return tempLoadingState;
        });
      }
    });

    if (selectedFilters.length === 0) {
      setFilterQuery({});
    } else if (stickyFilters && !stickyFilters.message) {
      const queryParams = {};
      stickyFilters.filter_options.forEach((filter) => {
        if (filter.selected_options.length > 0) {
          queryParams[filter.key] = filter.selected_options;
        }
      });
      setFilterQuery(queryParams);
      setNumOfSelectedFilters(selectedFilters.length);
    }
  }, [selectedFilters]);

  useEffect(() => {
    refetchFilterOptions();
  }, [filterType, refetchFilterOptions]);

  useEffect(() => {
    if (data && filterType) {
      const updatedFilters = filters.map((filter, index) => {
        if (filter.key === filterType) {
          setMultiSelectLoadingState((prevLoadingState) => {
            const tempLoadingState = [...prevLoadingState];
            tempLoadingState[index] = false;
            return tempLoadingState;
          });

          return { ...filter, options: data };
        }
        return filter;
      });
      setFilters(updatedFilters);
    }
  }, [data]);

  const handleFilter = (e) => {
    e.preventDefault();

    const queryParams = {};
    filters.forEach((filter) => {
      if (filter.selected_options.length > 0) {
        queryParams[filter.key] = filter.selected_options;
      }
    });
    setFilterQuery(queryParams);

    const filterData = {
      filter_options: filters,
      selected_filters: selectedFilters,
      type: pageType,
    };
    if (pageType !== "punch_list_items") {
      createFilterMutation({ filterData: filterData });
    }

    setIsVisible(false);
  };

  const onSelectSubFilters = (e, selectedFilter) => {
    const updatedFilters = filters.map((filter) => {
      if (filter.value === selectedFilter) {
        return { ...filter, selected_options: e.target.value };
      }
      return filter;
    });

    setFilters(updatedFilters);
  };

  const resetFilters = (e) => {
    e.preventDefault();

    const filterData = {
      filter_options: initialFilters,
      selected_filters: [],
      type: pageType,
    };

    if (pageType === "punch_list_items") {
      setFilters(initialFilters);
      setSelectedFilters([]);
    }

    if (pageType !== "punch_list_items") {
      createFilterMutation({ filterData: filterData });
    }

    setNumOfSelectedFilters(0);

    setIsVisible(false);
  };

  const handleMultiselect = (e) => {
    if (typeof e.selectedOption === "string") {
      const updatedFilters = filters.map((filter) => {
        if (filter.value === e.selectedOption) {
          return { ...filter, selected_options: [] };
        }
        return filter;
      });
      setFilters(updatedFilters);
    }

    setSelectedFilters(e.value);
    filterRef.current.hide();
  };

  return (
    <Sidebar
      visible={isVisible}
      position="right"
      onHide={() => setIsVisible(false)}
      className="w-30rem"
    >
      <h2>Filter</h2>
      <MultiSelect
        value={selectedFilters}
        onChange={(e) => handleMultiselect(e)}
        options={
          user.role > 1
            ? filters
            : filters.filter(
                (filter) =>
                  filter.key !== "assigned_id" && filter.key !== "approver_id"
              )
        }
        optionLabel="value"
        display="chip"
        placeholder="Select Filters"
        className="w-full mb-5"
        filter
        ref={filterRef}
        panelClassName="max-w-16rem"
      />
      {selectedFilters.map((selectedFilter, index) => {
        if (multiSelectLoadingState[index]) {
          return (
            <React.Fragment key={`loading-${selectedFilter}`}>
              <span>{selectedFilter}</span>
              <Skeleton height="2rem" className="mb-2"></Skeleton>
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={`filter-${selectedFilter}`}>
              <div className="my-3">
                <span>{selectedFilter}</span>
                <MultiSelect
                  value={
                    filters.find(
                      (filterObj) => filterObj.value === selectedFilter
                    ).selected_options
                  }
                  onChange={(e) => onSelectSubFilters(e, selectedFilter)}
                  options={
                    filters.find(
                      (filterObj) => filterObj.value === selectedFilter
                    ).options
                  }
                  optionLabel="value"
                  optionValue="key"
                  display="chip"
                  placeholder="Select Filters"
                  className="w-full"
                  panelClassName="max-w-16rem"
                  filter
                />
              </div>
            </React.Fragment>
          );
        }
      })}
      <Button
        label="Filter"
        className="mt-2 w-full"
        onClick={(e) => {
          handleFilter(e, true);
        }}
      />

      <Button
        label="Reset Filters"
        outlined
        className="mt-2 w-full"
        onClick={resetFilters}
      />
    </Sidebar>
  );
}
