import { configureStore } from "@reduxjs/toolkit";
import { inputSlice } from "../reducers/inputSlice";
import { setupListeners } from "@reduxjs/toolkit/query";
import { projectSlice } from "../reducers/projectSlice";
import checkTokenExpirationMiddleware from "./tokenMiddleware";

export const store = configureStore({
  reducer: {
    [projectSlice.name]: projectSlice.reducer,
    [inputSlice.name]: inputSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(checkTokenExpirationMiddleware);
  },
});

setupListeners(store.dispatch);
