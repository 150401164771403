import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import { useMutation } from "@tanstack/react-query";

import { queryClient } from "../../api/requestProcessor";
import { deleteUser } from "../../api/usersAPI";

export default function DeleteUser({ isVisible, hideDialog, user, toast }) {
  const { mutate, isPending } = useMutation({
    mutationFn: deleteUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["entities"] });
      hideDialog();
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "User Removed",
        life: 3000,
      });
    },
    onError: (er) => {
      hideDialog();
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to remove user",
        life: 3000,
      });
    },
  });

  const handleDeleteUser = () => {
    mutate({ userId: user.id });
  };

  const deleteUserDialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={handleDeleteUser}
        loading={isPending}
      />
    </>
  );

  return (
    <Dialog
      visible={isVisible}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Confirm"
      modal
      footer={deleteUserDialogFooter}
      onHide={hideDialog}
      className="mx-2"
    >
      <div className="confirmation-content">
        <div className="flex">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {user && (
            <span>
              Are you sure you want to remove{" "}
              <b>{`${user.first_name} ${user.last_name}`}</b> from the project?
            </span>
          )}
        </div>
      </div>
    </Dialog>
  );
}
