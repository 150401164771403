import React, { useState, useRef, useEffect } from "react";

import { Button } from "primereact/button";
import { Panel } from "primereact/panel";

import "./FileUpload.css";

export default function CustomFileUpload({
  id,
  accept,
  onFileChange,
  reset,
  setReset,
}) {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (reset) {
      setSelectedFiles([]);
      onFileChange([]);
      setReset(false);
    }
  }, [reset, setReset, onFileChange]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles([...selectedFiles, ...files]);

    if (onFileChange) {
      onFileChange([...selectedFiles, ...files]);
    }
  };

  const handleRemoveFile = (file) => {
    const updatedFiles = selectedFiles.filter((f) => f !== file);
    setSelectedFiles(updatedFiles);

    if (onFileChange) {
      onFileChange(updatedFiles);
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();

    const files = Array.from(e.dataTransfer.files);
    setSelectedFiles([...selectedFiles, ...files]);

    if (onFileChange) {
      onFileChange([...selectedFiles, ...files]);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const fileUploadHeader = () => {
    return (
      <div>
        <Button
          icon="pi pi-plus"
          label="Choose"
          onClick={handleButtonClick}
          size="small"
        />
        <input
          type="file"
          id="file-input-button"
          accept={accept}
          multiple
          ref={fileInputRef}
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
      </div>
    );
  };

  return (
    <>
      <Panel header={fileUploadHeader}>
        <div
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          draggable={true}
          className="min-h-5rem file-upload-draggable"
        >
          {selectedFiles.length ? (
            <div>
              {selectedFiles.map((file, index) => (
                <div
                  className="flex align-items-center justify-content-between mb-2"
                  key={index}
                >
                  <div className="w-full max-w-full">{file.name}</div>
                  {file.type.startsWith("image/") && (
                    <img
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                      style={{ maxWidth: "100px", maxHeight: "100px" }}
                    />
                  )}
                  <Button
                    icon="pi pi-times"
                    rounded
                    text
                    severity="danger"
                    aria-label="Cancel"
                    onClick={() => handleRemoveFile(file)}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="h-5rem">Drag and drop files to here to upload.</div>
          )}
        </div>
      </Panel>
    </>
  );
}
