import React, { useState } from "react";
import { fetchTasks } from "../api/tasksAPI";
import Tasks from "../components/Tasks/Tasks";
import { useQuery } from "@tanstack/react-query";

const TaskPage = () => {
  const [queryParams, setQueryParams] = useState({
    page: 1,
    per_page: 10,
    search: "",
  });

  const {
    data: tasks,
    isFetching,
    isError,
    error,
    refetch,
  } = useQuery({
    queryKey: ["tasks", queryParams],
    queryFn: ({ signal }) => fetchTasks({ signal, queryParams: queryParams }),
    staleTime: 5 * 60 * 1000,
  });

  return (
    <div>
      <Tasks
        tasks={tasks && tasks.tasks}
        total={tasks && tasks.total}
        isPending={isFetching}
        refetch={refetch}
        setQueryParams={setQueryParams}
      />
    </div>
  );
};

export default TaskPage;
