import React, { useState, useEffect, useContext } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import FilterSidebar from "../FilterSidebar";
import { getUserData } from "../../util/storage";
import { AppContext } from "../../context/AppProvider";

export default function SubmittalsTable({
  dt,
  selectedSubmittals,
  setSelectedSubmittals,
  submittals,
  isPending,
  editSubmittal,
  openSubmittalAttachments,
  exportCSV,
  setQueryParams,
  total,
  openNew,
  confirmDeleteSubmittal,
  openNewAi,
  initialFilters,
}) {
  const isProcoreUser = getUserData()["is_procore_user"];

  const [searchInput, setSearchInput] = useState("");
  const [localSubmittals, setLocalSubmittals] = useState([]);
  const [filterSidebarVisible, setFilterSidebarVisible] = useState(false);
  const [filterQuery, setFilterQuery] = useState({});
  const [lazyState, setLazyState] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });
  const [numOfSelectedFilters, setNumOfSelectedFilters] = useState(0);

  const { user } = useContext(AppContext);

  useEffect(() => {
    if (submittals) {
      const updatedData = submittals.map((submittal) => ({
        ...submittal,
        created_by_name: submittal.created_by
          ? submittal.created_by.name
          : null,
        status: submittal.status
          ? isProcoreUser
            ? submittal.status.status
            : submittal.status
          : null,
        spec_section_description: submittal.specification_section
          ? submittal.specification_section.description
          : null,
        ball_in_courts: submittal.ball_in_court
          ? submittal.ball_in_court.map((assignee) => assignee.name).join(", ")
          : null,
        responsible_contractor: submittal.responsible_contractor
          ? submittal.responsible_contractor.name
          : null,
      }));
      setLocalSubmittals(updatedData);
    }
  }, [submittals]);

  useEffect(() => {
    setQueryParams(mergeAllQueries());
  }, [lazyState, setQueryParams]);

  useEffect(() => {
    setLazyState({ ...lazyState, first: 0, page: 0 });
  }, [filterQuery, setQueryParams]);

  const mergeAllQueries = () => {
    const pageQuery = { page: lazyState.page + 1, per_page: lazyState.rows };
    const searchQuery = { search: searchInput };
    return { ...pageQuery, ...searchQuery, ...filterQuery };
  };

  const getSeverity = (status) => {
    switch (status.toLowerCase()) {
      case "open":
        return "danger";

      case "closed":
        return "success";

      case "draft":
        return "info";

      default:
        return "info";
    }
  };

  const onSearchInputChange = (e) => {
    const val = (e.target && e.target.value) || "";
    setSearchInput(val);
  };

  const handleSearch = (e, isFromButton) => {
    if (e.key === "Enter" || isFromButton) {
      setLazyState({ ...lazyState, page: 0, first: 0 });
    }
  };

  const onResetSearch = (e) => {
    const allQueries = mergeAllQueries();
    allQueries.search = "";
    setQueryParams(allQueries);
    setSearchInput("");
  };

  const onPage = (e) => {
    setLazyState(e);
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag value={rowData.status} severity={getSeverity(rowData.status)} />
    );
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <div>
          {user.role > 1 && (
            <Button
              label="New"
              icon="pi pi-plus"
              severity="success"
              onClick={openNew}
              className="mr-2"
            />
          )}
          {user.role > 1 && (
            <Button
              label="New (using AI)"
              icon="pi pi-plus"
              severity="success"
              outlined
              onClick={openNewAi}
              className="mr-2"
            />
          )}
          <Button
            label="Export"
            icon="pi pi-upload"
            className="p-button-help mr-2"
            onClick={() =>
              exportCSV(selectedSubmittals.length > 0 ? true : false)
            }
          />
          {user.role > 1 && (
            <Button
              label={
                numOfSelectedFilters === 0
                  ? "Filter"
                  : `Filter (${numOfSelectedFilters})`
              }
              icon="pi pi-filter"
              onClick={() => setFilterSidebarVisible(true)}
            />
          )}
        </div>
        <div>
          <span className="p-input-icon-right">
            {searchInput && (
              <i
                className="pi pi-times mr-1 cursor-pointer"
                onClick={onResetSearch}
              />
            )}
            <InputText
              value={searchInput}
              onChange={onSearchInputChange}
              placeholder="Keyword Search"
              className="mr-1"
              onKeyDown={(e) => handleSearch(e, false)}
            />
          </span>
          <Button icon="pi pi-search" onClick={(e) => handleSearch(e, true)} />
        </div>
      </div>
    );
  };

  const header = renderHeader();

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-eye"
          rounded
          text
          className="m-1"
          onClick={() => openSubmittalAttachments(rowData)}
        />
        {user.role > 1 && (
          <Button
            icon="pi pi-pencil"
            rounded
            text
            className="m-1"
            onClick={() => editSubmittal(rowData)}
          />
        )}
        {user.role > 1 && (
          <Button
            icon="pi pi-trash"
            rounded
            text
            severity="danger"
            className="m-1"
            onClick={() => confirmDeleteSubmittal(rowData)}
          />
        )}
      </>
    );
  };

  return (
    <>
      <DataTable
        ref={dt}
        selection={selectedSubmittals}
        onSelectionChange={(e) => setSelectedSubmittals(e.value)}
        dataKey="id"
        value={localSubmittals}
        paginator
        showGridlines
        lazy
        onPage={onPage}
        first={lazyState.first}
        rows={10}
        totalRecords={total}
        loading={isPending}
        header={header}
        emptyMessage="No submittal found."
        exportFilename="Nicky AI Submittals"
        className="hidden sm:block"
      >
        <Column
          selectionMode="multiple"
          exportable={false}
          style={{ width: "0.2rem" }}
        ></Column>
        <Column
          header="Spec Section"
          field="spec_section_description"
          style={{ maxWidth: "10rem" }}
        />
        <Column
          header="#"
          field={"formatted_number"}
          style={{ maxWidth: "6rem" }}
        />
        <Column header="Rev" field="revision" style={{ maxWidth: "2rem" }} />
        <Column header="Title" field="title" style={{ maxWidth: "12rem" }} />
        <Column
          header="Responsible Contractor"
          field="responsible_contractor"
          style={{ maxWidth: "12rem" }}
        />
        <Column
          header="Ball in Court"
          field="ball_in_courts"
          style={{ maxWidth: "12rem" }}
        />
        <Column
          field="status"
          header="Status"
          filterMenuStyle={{ width: "14rem" }}
          style={{ minWidth: "4rem" }}
          body={statusBodyTemplate}
        />
        <Column
          body={actionBodyTemplate}
          exportable={false}
          style={{ minWidth: "6rem" }}
        ></Column>
      </DataTable>
      {filterSidebarVisible && (
        <FilterSidebar
          isVisible={filterSidebarVisible}
          setIsVisible={setFilterSidebarVisible}
          setFilterQuery={setFilterQuery}
          initialFilters={initialFilters}
          pageType="submittal"
          setNumOfSelectedFilters={setNumOfSelectedFilters}
        />
      )}
    </>
  );
}
