import React from "react";
import { RFIGeneralInformationLoader } from "../Loaders/RFILoader";

export default function ViewRFIGeneralInformation({
  rfi,
  isProcoreUser,
  isPending,
}) {
  if (isPending) {
    return <RFIGeneralInformationLoader />;
  }

  if (!isPending) {
    return (
      <>
        <div>
          <div className="grid">
            <div className="col-12 md:col-3">
              <div className="flex flex-column gap-2">
                <div className="flex flex-column gap-2">
                  <label className="font-semibold" htmlFor="number">
                    Number
                  </label>
                  <div id="number" className="flex h-3rem align-items-center">
                    {rfi.number}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 md:col-3">
              <div className="flex flex-column gap-2">
                <label className="font-semibold" htmlFor="due-date">
                  Due Date
                </label>
                <div id="due-date" className="flex h-3rem align-items-center">
                  {rfi.due_date}
                </div>
              </div>
            </div>
            <div className="col-12 md:col-3">
              <div className="flex flex-column gap-2">
                <label className="font-semibold" htmlFor="rfi-manager">
                  RFI Manager
                </label>
                <div
                  id="rfi-manager"
                  className="flex h-3rem align-items-center"
                >
                  {rfi.rfi_manager?.name}
                </div>
              </div>
            </div>
            <div className="col-12 md:col-3">
              <div className="flex flex-column gap-2">
                <label className="font-semibold" htmlFor="status">
                  Status
                </label>
                <div id="status" className="flex h-3rem align-items-center">
                  --
                </div>
              </div>
            </div>
            <div className="col-12 md:col-3">
              <div className="flex flex-column gap-2">
                <label className="font-semibold" htmlFor="received-from">
                  Received From
                </label>
                <div
                  id="received-from"
                  className="flex h-3rem align-items-center"
                >
                  {rfi.received_from?.name}
                </div>
              </div>
            </div>
            <div className="col-12 md:col-3">
              <div className="flex flex-column gap-2">
                <label className="font-semibold" htmlFor="assignee-ids">
                  Assignees
                </label>
                <div id="assignee-ids">
                  {rfi.assignees?.map((user, index) => (
                    <React.Fragment key={user.id}>
                      <span>{user.name}</span>
                      {index !== rfi.assignees.length - 1 && <br />}{" "}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-12 md:col-3">
              <div className="flex flex-column gap-2">
                <label className="font-semibold" htmlFor="distribution-ids">
                  Distribution List
                </label>
                <div id="distribution-ids">
                  {rfi.distribution_list?.map((user, index) => (
                    <React.Fragment key={user.id}>
                      <span>{user.name}</span>
                      {index !== rfi.distribution_list.length - 1 && (
                        <br />
                      )}{" "}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-12 md:col-3">
              <div className="flex flex-column gap-2">
                <label className="font-semibold" htmlFor="ball-in-court">
                  Ball In Court
                </label>
                <div id="ball-in-court">
                  {isProcoreUser
                    ? rfi.ball_in_courts?.map((user, index) => (
                        <React.Fragment key={user.id}>
                          <span>{user.name}</span>
                          {index !== rfi.ball_in_courts.length - 1 && (
                            <br />
                          )}{" "}
                        </React.Fragment>
                      ))
                    : rfi.ball_in_court?.map((user, index) => (
                        <React.Fragment key={user.id}>
                          <span>{user.name}</span>
                          {index !== rfi.ball_in_court.length - 1 && (
                            <br />
                          )}{" "}
                        </React.Fragment>
                      ))}
                </div>
              </div>
            </div>
            <div className="col-12 md:col-3">
              <div className="flex flex-column gap-2">
                <label
                  className="font-semibold"
                  htmlFor="responsible-contractor"
                >
                  Responsible Contractor
                </label>
                <div
                  id="responsible-contractor"
                  className="flex h-3rem align-items-center"
                >
                  {rfi.responsible_contractor?.name}
                </div>
              </div>
            </div>
            <div className="col-12 md:col-3">
              <div className="flex flex-column gap-2">
                <label
                  className="font-semibold"
                  htmlFor="specification-section"
                >
                  Spec Section
                </label>
                <div
                  id="specification-section"
                  className="flex h-3rem align-items-center"
                >
                  {rfi.specification_section?.label}
                </div>
              </div>
            </div>
            <div className="col-12 md:col-3">
              <div className="flex flex-column gap-2">
                <label className="font-semibold" htmlFor="location">
                  Location
                </label>
                <div id="location" className="flex h-3rem align-items-center">
                  --
                </div>
              </div>
            </div>
            <div className="col-12 md:col-3">
              <div className="flex flex-column gap-2">
                <label className="font-semibold" htmlFor="created-by">
                  Created By
                </label>
                <div id="created-by" className="flex h-3rem align-items-center">
                  --
                </div>
              </div>
            </div>
            <div className="col-12 md:col-3">
              <div className="flex flex-column gap-2">
                <label className="font-semibold" htmlFor="rfi-stage">
                  RFI Stage
                </label>
                <div id="rfi-stage" className="flex h-3rem align-items-center">
                  --
                </div>
              </div>
            </div>
            <div className="col-12 md:col-3">
              <div className="flex flex-column gap-2">
                <label className="font-semibold" htmlFor="drawing-number">
                  Drawing Number
                </label>
                <div
                  id="drawing-number"
                  className="flex h-3rem align-items-center"
                >
                  --
                </div>
              </div>
            </div>
            <div className="col-12 md:col-3">
              <div className="flex flex-column gap-2">
                <label className="font-semibold" htmlFor="cost-code">
                  Cost Code
                </label>
                <div id="cost-code" className="flex h-3rem align-items-center">
                  --
                </div>
              </div>
            </div>
            <div className="col-12 md:col-3">
              <div className="flex flex-column gap-2">
                <label className="font-semibold" htmlFor="cost-code">
                  Date Initiated
                </label>
                <div id="cost-code" className="flex h-3rem align-items-center">
                  --
                </div>
              </div>
            </div>
            <div className="col-12 md:col-3">
              <div className="flex flex-column gap-2">
                <label className="font-semibold" htmlFor="schedule-impact">
                  Schedule Impact
                </label>
                <div
                  id="schedule-impact"
                  className="flex h-3rem align-items-center"
                >
                  --
                </div>
              </div>
            </div>
            <div className="col-12 md:col-3">
              <div className="flex flex-column gap-2">
                <label className="font-semibold" htmlFor="cost-impact">
                  Cost Impact
                </label>
                <div
                  id="cost-impact"
                  className="flex h-3rem align-items-center"
                >
                  --
                </div>
              </div>
            </div>
            <div className="col-12 md:col-3">
              <div className="flex flex-column gap-2">
                <label className="font-semibold" htmlFor="cost-impact">
                  Reference
                </label>
                <div id="reference" className="flex h-3rem align-items-center">
                  --
                </div>
              </div>
            </div>
            <div className="col-12 md:col-3">
              <div className="flex flex-column gap-2">
                <label className="font-semibold" htmlFor="private">
                  Private
                </label>
                <div className="flex align-items-center">
                  <div id="private" className="flex h-3rem align-items-center">
                    {rfi?.private}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
