import React, { useState, useEffect, useContext } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getUserData } from "../../util/storage";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppProvider";

export default function EntityTable({
  dt,
  selectedEntities,
  setSelectedEntities,
  entities,
  isPending,
  exportCSV,
  openDialog,
}) {
  const isProcoreUser = getUserData().is_procore_user;

  const [filters, setFilters] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [updatedEntities, setUpdatedEntities] = useState([]);

  const { user } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    initFilters();
  }, []);

  useEffect(() => {
    if (entities) {
      const updatedData = entities.map((entity) => ({
        ...entity,
        vendor_name: entity.vendor ? entity.vendor.name : null,
      }));
      setUpdatedEntities(updatedData);
    }
  }, [entities]);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      vendor_name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      mobile_phone: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      email_address: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
    setGlobalFilterValue("");
  };

  const clearFilter = () => {
    initFilters();
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const navigateNewUser = () => {
    navigate("new");
  };

  const navigateDetailedUser = (rowData) => {
    navigate(`${rowData.id}`);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        {user.role > rowData.role && (
          <>
            <Button
              icon="pi pi-pencil"
              rounded
              text
              className="mr-2"
              onClick={() => navigateDetailedUser(rowData)}
            />
            <Button
              icon="pi pi-trash"
              rounded
              text
              severity="danger"
              onClick={() => openDialog(rowData)}
            />
          </>
        )}
      </>
    );
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <div>
          {!isProcoreUser && (
            <Button
              label="Create User"
              icon="pi pi-plus"
              severity="success"
              onClick={navigateNewUser}
              className="mr-2"
            />
          )}
          <Button
            label="Export"
            icon="pi pi-upload"
            className="p-button-help"
            onClick={() =>
              exportCSV(selectedEntities.length > 0 ? true : false)
            }
          />
        </div>
        <div>
          <Button
            type="button"
            icon="pi pi-filter-slash"
            label="Clear Filters"
            outlined
            onClick={clearFilter}
            className="mr-2"
          />
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <DataTable
      ref={dt}
      selection={selectedEntities}
      onSelectionChange={(e) => setSelectedEntities(e.value)}
      dataKey="id"
      value={updatedEntities}
      paginator
      showGridlines
      rows={8}
      loading={isPending}
      filters={filters}
      globalFilterFields={[
        "name",
        "vendor_name",
        "mobile_phone",
        isProcoreUser ? "email_address" : "email",
      ]}
      header={header}
      emptyMessage="No directory found."
      exportFilename="Nicky AI Directory"
      className="hidden sm:block"
    >
      <Column
        selectionMode="multiple"
        exportable={false}
        style={{ width: "0.2rem" }}
      ></Column>
      <Column
        header="Name"
        field="name"
        style={{ maxWidth: "12rem" }}
        filter
        filterPlaceholder="Search by name"
      />
      <Column
        header="Vendor"
        field="vendor_name"
        style={{ maxWidth: "12rem" }}
        filter
        filterPlaceholder="Search by vendor"
      />
      <Column
        header="Mobile Phone"
        field="mobile_phone"
        style={{ maxWidth: "12rem" }}
        filter
        filterPlaceholder="Search by mobile number"
      />
      <Column
        header="Email"
        field={isProcoreUser ? "email_address" : "email"}
        style={{ maxWidth: "16rem" }}
        filter
        filterPlaceholder="Search by email"
      />
      {!isProcoreUser && (
        <Column
          body={actionBodyTemplate}
          exportable={false}
          style={{ maxWidth: "12rem" }}
        ></Column>
      )}
    </DataTable>
  );
}
