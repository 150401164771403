import { jwtDecode } from "jwt-decode";

const checkTokenExpirationMiddleware = (store) => (next) => (action) => {
  const token = localStorage.getItem("access_token")
  if (token && jwtDecode(token).exp < Date.now() / 1000) {
    localStorage.clear();
    window.location.reload(true);
  }

  return next(action);
};

export default checkTokenExpirationMiddleware;
