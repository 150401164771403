import { useMutation, useQuery } from "@tanstack/react-query";
import {
  fetchDailyReportByDate,
  updateDailyReport,
  generateDailyReportPdf,
} from "../api/dailyReportsAPI";
import { Calendar } from "primereact/calendar";
import { useContext, useEffect, useState } from "react";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import CrewTable from "../components/DailyReports/CrewTable";
import VisitorsTable from "../components/DailyReports/VisitorsTable";
import DeliveriesTable from "../components/DailyReports/DeliveriesTable";
import MeetingsTable from "../components/DailyReports/MeetingsTable";
import NotesTable from "../components/DailyReports/NotesTable";
import { queryClient } from "../api/requestProcessor";
import { AppContext } from "../context/AppProvider";
import { getErrorMessage } from "../util/helper";
import Photos from "../components/DailyReports/Photos";
import CopyFromDialog from "../components/DailyReports/CopyFromDialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import DailyReportLoader from "../components/Loaders/DailyReportLoader";

const initalDailyReport = {
  site_hours_from: "7:00 AM",
  site_hours_to: "3:30 PM",
  temperature: null,
};

const conditions = [
  { label: "Sunny", value: "Sunny" },
  { label: "Cloudy", value: "Cloudy" },
  { label: "Windy", value: "Windy" },
  { label: "Rainy", value: "Rainy" },
  { label: "Snowy", value: "Snowy" },
  { label: "Stormy", value: "Stormy" },
];

const DailyReports = () => {
  const [date, setDate] = useState(new Date());
  const [localDailyReport, setLocalDailyReport] = useState(initalDailyReport);
  const [isVisible, setIsVisible] = useState(false);
  const { toast } = useContext(AppContext);

  const { data: dailyReport, isLoading: isLoadingDailyReport } = useQuery({
    queryKey: ["daily-report", date],
    queryFn: ({ signal }) => fetchDailyReportByDate(signal, date),
    staleTime: 5 * 60 * 1000,
  });

  const { mutate: updateDailyReportMutation, isLoading: isPendingUpdate } =
    useMutation({
      mutationFn: updateDailyReport,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["daily-report"] });
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Daily Report Updated",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const { mutate: generatePdfMutation, isPending: isGeneratingPdf } =
    useMutation({
      mutationFn: generateDailyReportPdf,
      onSuccess: (response) => {
        window.open(response, "_blank");
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  useEffect(() => {
    if (dailyReport) {
      setLocalDailyReport(dailyReport);
    }
  }, [dailyReport]);

  const hideDialog = () => {
    setIsVisible(false);
  };

  const onDateChange = (e) => {
    setDate((prevDate) => {
      const newDate = e.value;
      newDate.setHours(prevDate.getHours());
      newDate.setSeconds(prevDate.getSeconds());
      return newDate;
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setLocalDailyReport((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
    handleOnFinish(name, value);
  };

  const handleIntegerChange = (e, name) => {
    const { value } = e;
    setLocalDailyReport((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    handleOnFinish(name, value);
  };

  const handleOnFinish = (name, value) => {
    let updatedData;
    let shouldUpdated = true;

    updatedData = {
      [name]: value,
    };
    shouldUpdated = updatedData[name] !== dailyReport[name];

    if (shouldUpdated) {
      updateDailyReportMutation({
        dailyReport: updatedData,
        dailyReportId: localDailyReport._id,
      });
    }
  };

  const handleGeneratePdf = () => {
    if (dailyReport) {
      generatePdfMutation({ dailyReportId: dailyReport?._id });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Daily Report is not set",
        life: 3000,
      });
    }
  };

  if (isLoadingDailyReport) {
    return <DailyReportLoader />;
  }

  if (!isLoadingDailyReport) {
    return (
      <div className="flex flex-column gap-2 max-w-screen">
        <div className="grid">
          <div className="flex gap-2">
            <Calendar value={date} onChange={onDateChange} showIcon />
            <Button
              className="w-9rem"
              label="Copy"
              icon="pi pi-copy"
              onClick={() => {
                setIsVisible(true);
              }}
            />
          </div>
          <Button
            className="w-full md:w-12rem md:ml-auto mt-2 md:mt-0"
            label={isGeneratingPdf ? "Generating..." : "Generate PDF"}
            icon="pi pi-file-pdf"
            onClick={handleGeneratePdf}
            loading={isGeneratingPdf}
          />
        </div>
        <CopyFromDialog
          isVisible={isVisible}
          hideDialog={hideDialog}
          copyToDate={date}
        />

        <div className="grid">
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <div className="flex flex-column gap-2">
                <label htmlFor="site_hours_from">Site Hours From</label>
                <InputText
                  id="site_hours_from"
                  name="site_hours_from"
                  value={localDailyReport.site_hours_from}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <div className="flex flex-column gap-2">
                <label htmlFor="site_hours_to">Site Hours To</label>
                <InputText
                  id="site_hours_to"
                  name="site_hours_to"
                  value={localDailyReport.site_hours_to}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <div className="flex flex-column gap-2">
                <label htmlFor="temperature">Temperature</label>
                <InputNumber
                  id="temperature"
                  name="temperature"
                  value={localDailyReport.temperature}
                  onChange={(e) => handleIntegerChange(e, "temperature")}
                  onValueChange={(e) => handleIntegerChange(e, "temperature")}
                />
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <div className="flex flex-column gap-2">
                <label htmlFor="conditions">Condition</label>
                <Dropdown
                  id="conditions"
                  name="conditions"
                  value={localDailyReport.conditions}
                  options={conditions}
                  onChange={handleChange}
                  placeholder="Select a Condition"
                />
              </div>
            </div>
          </div>
        </div>

        {dailyReport?.crew && (
          <>
            <CrewTable
              dailyReport={localDailyReport}
              setDailyReport={setLocalDailyReport}
              updateDailyReportMutation={updateDailyReportMutation}
            />
            <Photos
              dailyReport={localDailyReport}
              setDailyReport={setLocalDailyReport}
              updateDailyReportMutation={updateDailyReportMutation}
            />
            <VisitorsTable
              dailyReport={localDailyReport}
              setDailyReport={setLocalDailyReport}
              updateDailyReportMutation={updateDailyReportMutation}
            />
            <DeliveriesTable
              dailyReport={localDailyReport}
              setDailyReport={setLocalDailyReport}
              updateDailyReportMutation={updateDailyReportMutation}
            />
            <MeetingsTable
              dailyReport={localDailyReport}
              setDailyReport={setLocalDailyReport}
              updateDailyReportMutation={updateDailyReportMutation}
            />
            <NotesTable
              dailyReport={localDailyReport}
              setDailyReport={setLocalDailyReport}
              updateDailyReportMutation={updateDailyReportMutation}
            />
          </>
        )}
      </div>
    );
  }
};
export default DailyReports;
