import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useContext, useEffect, useState } from "react";
import { queryClient } from "../../api/requestProcessor";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AppContext } from "../../context/AppProvider";
import { getErrorMessage } from "../../util/helper";
import { updatePunchListItem } from "../../api/punchListsAPI";
import { InputText } from "primereact/inputtext";
import { fetchEntities } from "../../api/entitiesAPI";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Carousel } from "primereact/carousel";
import { Image } from "primereact/image";
import CustomFileUpload from "../FileUpload/FileUpload";
import { uploadFiles } from "../../api/filesAPI";
import { InputNumber } from "primereact/inputnumber";

export default function UpdatePunchListItemDialog({
  isVisible,
  hideDialog,
  punchListItem,
  punchListId,
}) {
  const [files, setFiles] = useState([]);
  const [uploadReset, setUploadReset] = useState(false);
  const { toast, user } = useContext(AppContext);

  const [localPunchListItem, setLocalPuchListItem] = useState({
    approver_id: "",
    assignee_ids: [],
    status: "Incomplete",
    location: "",
    room: "",
    reference: "",
    notes: "",
  });

  const { data: users, isFetching: isFetchingUsers } = useQuery({
    queryKey: ["entities"],
    queryFn: fetchEntities,
    staleTime: 5 * 60 * 1000,
  });

  const { mutate: updatePunchListItemMutation, isPending: isPendingUpdate } =
    useMutation({
      mutationFn: updatePunchListItem,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["punch-lists"],
        });
        queryClient.invalidateQueries({
          queryKey: ["punch-list-number"],
        });
        hideDialog();
        setFiles([]);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Punch List Item Updated",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "Error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const { mutateAsync: uploadFilesMutation, isPending: isPendingUpload } =
    useMutation({
      mutationFn: uploadFiles,
      onSuccess: () => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "File Uploaded",
          life: 3000,
        });
        setUploadReset(true);
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  useEffect(() => {
    if (punchListItem) {
      setLocalPuchListItem(punchListItem);
    }
  }, [punchListItem]);

  const onFileChange = (files) => {
    setFiles(files);
  };

  const handleDeleteFile = (file) => {
    let _localPunchListItem = { ...localPunchListItem };
    _localPunchListItem.files = _localPunchListItem.files.filter(
      (elem) => elem.name !== file.name
    );

    setLocalPuchListItem(_localPunchListItem);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setLocalPuchListItem((prevLocalPunchListItem) => ({
      ...prevLocalPunchListItem,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleIntegerChange = (e, name) => {
    const { value } = e;
    setLocalPuchListItem((prevLocalPunchListItem) => ({
      ...prevLocalPunchListItem,
      [name]: value,
    }));
  };

  const printErrorMessage = (errorMessage) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: errorMessage,
      life: 3000,
    });
  };

  const checkValidation = () => {
    if (!localPunchListItem.title) {
      printErrorMessage("The title area is required!");
      return false;
    }
    return true;
  };

  const handleUpdatePunchListItem = async () => {
    if (checkValidation()) {
      let updatedAttachments = [...localPunchListItem.files];

      if (files.length > 0) {
        const uploadPromises = files.map(async (file) => {
          return await uploadFilesMutation({
            files: [file],
          });
        });

        const responses = await Promise.all(uploadPromises);
        updatedAttachments = [...responses, ...localPunchListItem.files];
      }

      localPunchListItem.files = updatedAttachments;

      updatePunchListItemMutation({
        punchListItem: localPunchListItem,
        punchListId: punchListId,
      });
    }
  };

  const filesTemplate = (file) => {
    return (
      <div className="border-1 surface-border border-round text-center py-2 px-2">
        <div className="mb-3 max-w-7rem md:max-w-max">
          <Image
            src={file.url}
            alt={file.name}
            className="shadow-2 md:hidden"
            width="250"
          />
          <Image
            src={file.url}
            alt={file.name}
            className="shadow-2 hidden md:block"
            width="333"
          />
        </div>
        <div>
          <div className="flex flex-wrap gap-2 justify-content-center">
            {user.role > 1 && (
              <Button
                icon="pi pi-times"
                className="p-button-danger"
                text
                rounded
                onClick={() => handleDeleteFile(file)}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  const dialogFooter = (
    <>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button
        label={isPendingUpdate || isPendingUpload ? "Updating" : "Update"}
        icon="pi pi-check"
        onClick={handleUpdatePunchListItem}
        loading={isPendingUpdate || isPendingUpload}
      />
    </>
  );

  return (
    <Dialog
      visible={isVisible}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="New Punch List"
      modal
      className="p-fluid mx-2"
      footer={dialogFooter}
      onHide={hideDialog}
    >
      {localPunchListItem?.files?.length > 0 && (
        <div className="field">
          <label htmlFor="approver_id" className="font-bold">
            Files
          </label>
          <Carousel
            value={localPunchListItem.files}
            numScroll={1}
            numVisible={1}
            itemTemplate={filesTemplate}
          />
        </div>
      )}
      <div className="field">
        <label htmlFor="title" className="font-bold">
          Number
        </label>
        <InputNumber
          id="number"
          name="number"
          value={localPunchListItem.number}
          onChange={handleIntegerChange}
          disabled={user.role < 2}
        />
      </div>
      <div className="field">
        <label htmlFor="title" className="font-bold">
          Title
        </label>
        <InputText
          id="title"
          name="title"
          value={localPunchListItem.title}
          options={users}
          onChange={handleChange}
          disabled={user.role < 2}
        />
      </div>
      <div className="field">
        <label htmlFor="approver_id" className="font-bold">
          Approver
        </label>
        <Dropdown
          id="approver_id"
          name="approver_id"
          value={localPunchListItem.approver_id}
          options={users}
          optionValue="id"
          optionLabel="name"
          placeholder="Select a Person"
          onChange={handleChange}
          disabled={isFetchingUsers || user.role < 2}
          filter
        />
      </div>
      <div className="field">
        <label htmlFor="assignee_ids" className="font-bold">
          Assignees
        </label>
        <MultiSelect
          id="assignee_ids"
          name="assignee_ids"
          value={localPunchListItem.assignee_ids}
          options={users}
          optionValue="id"
          optionLabel="name"
          placeholder="Select Assignees"
          onChange={handleChange}
          disabled={isFetchingUsers || user.role < 2}
          filter
        />
      </div>
      <div className="field">
        <label htmlFor="status" className="font-bold">
          Status
        </label>
        <Dropdown
          id="status"
          name="status"
          value={localPunchListItem.status}
          options={["Incomplete", "Complete"]}
          placeholder="Select a Status"
          onChange={handleChange}
          disabled={user.role < 2}
        />
      </div>
      <div className="field">
        <label htmlFor="location" className="font-bold">
          Location
        </label>
        <InputText
          id="location"
          name="location"
          value={localPunchListItem.location}
          onChange={handleChange}
          required
          disabled={user.role < 2}
        />
      </div>
      <div className="field">
        <label htmlFor="room" className="font-bold">
          Room
        </label>
        <InputText
          id="room"
          name="room"
          value={localPunchListItem.room}
          onChange={handleChange}
          required
          disabled={user.role < 2}
        />
      </div>
      <div className="field">
        <label htmlFor="reference" className="font-bold">
          Reference
        </label>
        <InputText
          id="reference"
          name="reference"
          value={localPunchListItem.reference}
          onChange={handleChange}
          required
          disabled={user.role < 2}
        />
      </div>
      <div className="field">
        <label htmlFor="notes" className="font-bold">
          Notes
        </label>
        <InputTextarea
          id="notes"
          name="notes"
          value={localPunchListItem.notes}
          onChange={handleChange}
          required
          disabled={user.role < 2}
        />
      </div>
      {user.role > 1 && (
        <CustomFileUpload
          accept=""
          onFileChange={onFileChange}
          reset={uploadReset}
          setReset={setUploadReset}
        />
      )}
    </Dialog>
  );
}
