import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useContext, useState } from "react";
import { queryClient } from "../../api/requestProcessor";
import { useMutation } from "@tanstack/react-query";
import { AppContext } from "../../context/AppProvider";
import { createFolder } from "../../api/foldersAPI";
import { getErrorMessage } from "../../util/helper";

export default function FolderDialog({ isVisible, hideDialog, folderId }) {
  const [newFolder, setNewFolder] = useState({
    name: "",
  });

  const { toast } = useContext(AppContext);

  const { mutate: createFolderMutation, isPending: isPendingCreation } =
    useMutation({
      mutationFn: createFolder,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["folders", folderId] });
        hideDialog();
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Folder Created",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _newFolder = { ...newFolder };

    _newFolder[`${name}`] = val;

    setNewFolder(_newFolder);
  };

  const createCompany = () => {
    const folderData = { name: newFolder.name, parent_id: folderId };
    createFolderMutation({ folderData: folderData });
  };

  const dialogFooter = (
    <>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button
        label={isPendingCreation ? "Creating" : "Create"}
        icon="pi pi-check"
        onClick={createCompany}
        loading={isPendingCreation}
      />
    </>
  );

  return (
    <Dialog
      visible={isVisible}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Folder Details"
      modal
      className="p-fluid mx-2"
      footer={dialogFooter}
      onHide={hideDialog}
    >
      <div className="field">
        <label htmlFor="folder_name" className="font-bold">
          Folder Name
        </label>
        <InputText
          id="folder_name"
          value={newFolder.name}
          onChange={(e) => onInputChange(e, "name")}
          required
        />
      </div>
    </Dialog>
  );
}
