import axios from "axios";
import { getAuthToken } from "../util/auth";
import { getProjectId, getUserData, getCompanyId } from "../util/storage";
import API_BASE_URL from "../util/apiConfig";
import { generateBaseUrl } from "../util/helper";

const token = getAuthToken();

const specsClient = axios.create({
  baseURL: `${API_BASE_URL}`,
  headers: {
    Authorization: "Bearer " + token,
  },
});

specsClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response.data);
  }
);

export async function fetchSpecs() {
  const isProcoreUser = getUserData()["is_procore_user"];
  const projectId = getProjectId();
  const companyId = getCompanyId();

  const baseUrl = generateBaseUrl(isProcoreUser, projectId, `specs`);

  if (isProcoreUser) {
    const response = await specsClient.get(baseUrl, {
      params: {
        company_id: companyId,
      },
    });
    return response.data;
  } else {
    const response = await specsClient.get(baseUrl);
    return response.data;
  }
}

export async function createSpec({ specData }) {
  const isProcoreUser = getUserData()["is_procore_user"];
  const projectId = getProjectId();
  const baseUrl = generateBaseUrl(isProcoreUser, projectId, "specs");

  const response = await specsClient.post(baseUrl, specData);
  return response.data;
}

export async function updateSpec({ specData, specId }) {
  const isProcoreUser = getUserData()["is_procore_user"];
  const projectId = getProjectId();
  const baseUrl = generateBaseUrl(isProcoreUser, projectId, `specs/${specId}`);

  const response = await specsClient.patch(baseUrl, specData);
  return response.data;
}

export async function deleteSpec({ specId }) {
  const isProcoreUser = getUserData()["is_procore_user"];
  const projectId = getProjectId();
  const baseUrl = generateBaseUrl(isProcoreUser, projectId, `specs/${specId}`);

  const response = await specsClient.delete(baseUrl);
  return response.data;
}
