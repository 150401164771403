import React, { useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";
import { MultiSelect } from "primereact/multiselect";
import { useQuery } from "@tanstack/react-query";
import { fetchVendors } from "../../api/vendorsAPI";
import { fetchEntities } from "../../api/entitiesAPI";
import CustomFileUpload from "../FileUpload/FileUpload";
import Attachments from "../Attachments";
import ChangeOrdersTable from "./ChangeOrdersTable";

const UpdateCommitmentForm = ({
  commitment,
  setCommitment,
  files,
  setFiles,
  uploadReset,
  setUploadReset,
  attachments,
  setAttachments,
  isPending,
}) => {
  const { data: vendors, isFetching: isFetchingVendors } = useQuery({
    queryKey: ["vendors"],
    queryFn: fetchVendors,
    staleTime: 5 * 60 * 1000,
  });

  const { data: users, isFetching: isFetchingUsers } = useQuery({
    queryKey: ["entities"],
    queryFn: fetchEntities,
    staleTime: 5 * 60 * 1000,
  });

  const commitmentTypes = [
    { label: "Subcontract", value: "subcontract" },
    { label: "Purchase Order", value: "purchase_order" },
  ];

  const statuses = [
    { label: "Draft", value: "draft" },
    { label: "Out For Bid", value: "out_for_bid" },
    { label: "Out For Signature", value: "out_for_signature" },
    { label: "Approved", value: "approved" },
    { label: "Complete", value: "complete" },
    { label: "Terminated", value: "terminated" },
    { label: "Void", value: "void" },
  ];

  const ssovTypes = [
    { label: "Draft", value: "draft" },
    { label: "None", value: "none" },
  ];

  const onFileChange = (files) => {
    setFiles(files);
  };

  if (isPending) {
    return <div>Loading...</div>;
  }

  return (
    <div className="grid p-fluid">
      <div className="col-12 md:col-6">
        <div className="p-inputgroup mb-3">
          <span className="p-inputgroup-addon">
            <i className="pi pi-tag"></i>
          </span>
          <Dropdown
            value={commitment.commitment_type}
            options={commitmentTypes}
            onChange={(e) =>
              setCommitment({ ...commitment, commitment_type: e.value })
            }
            placeholder="Select Type"
          />
        </div>

        <div className="p-inputgroup mb-3">
          <span className="p-inputgroup-addon">
            <i className="pi pi-building"></i>
          </span>
          <Dropdown
            value={commitment?.contract_company_id}
            options={vendors}
            onChange={(e) =>
              setCommitment({ ...commitment, contract_company_id: e.value })
            }
            optionLabel="name"
            optionValue="_id"
            placeholder="Select Contract Company"
            loading={isFetchingVendors}
            filter
            filterBy="name"
            emptyMessage="No companies found"
            emptyFilterMessage="No companies found"
          />
        </div>

        <div className="p-inputgroup mb-3">
          <span className="p-inputgroup-addon">
            <i className="pi pi-file"></i>
          </span>
          <InputText
            value={commitment.title}
            onChange={(e) =>
              setCommitment({ ...commitment, title: e.target.value })
            }
            placeholder="Title"
          />
        </div>

        <div className="p-inputgroup mb-3">
          <span className="p-inputgroup-addon">
            <i className="pi pi-hashtag"></i>
          </span>
          <InputText
            value={commitment.number}
            onChange={(e) =>
              setCommitment({ ...commitment, number: e.target.value })
            }
            placeholder="Number"
          />
        </div>

        <div className="mb-3">
          <InputTextarea
            value={commitment.description}
            onChange={(e) =>
              setCommitment({ ...commitment, description: e.target.value })
            }
            rows={3}
            placeholder="Description"
          />
        </div>

        <div className="mb-3">
          <Calendar
            value={
              commitment.contract_date
                ? new Date(commitment.contract_date)
                : null
            }
            onChange={(e) =>
              setCommitment({
                ...commitment,
                contract_date: e.value?.toISOString().split("T")[0],
              })
            }
            placeholder="Contract Date"
            showIcon
          />
        </div>

        <div className="mb-3">
          <Calendar
            value={
              commitment.contract_start_date
                ? new Date(commitment.contract_start_date)
                : null
            }
            onChange={(e) =>
              setCommitment({
                ...commitment,
                contract_start_date: e.value?.toISOString().split("T")[0],
              })
            }
            placeholder="Contract Start Date"
            showIcon
          />
        </div>

        <div className="mb-3">
          <Calendar
            value={
              commitment.contract_estimated_completion_date
                ? new Date(commitment.contract_estimated_completion_date)
                : null
            }
            onChange={(e) =>
              setCommitment({
                ...commitment,
                contract_estimated_completion_date: e.value
                  ?.toISOString()
                  .split("T")[0],
              })
            }
            placeholder="Estimated Completion Date"
            showIcon
          />
        </div>
      </div>

      <div className="col-12 md:col-6">
        <div className="mb-3">
          <Dropdown
            value={commitment.status}
            options={statuses}
            onChange={(e) => setCommitment({ ...commitment, status: e.value })}
            placeholder="Select Status"
          />
        </div>

        <div className="mb-3">
          <Dropdown
            value={commitment.ssov_status}
            options={ssovTypes}
            onChange={(e) =>
              setCommitment({ ...commitment, ssov_status: e.value })
            }
            placeholder="Select SSOV Status"
          />
        </div>

        <div>
          <label htmlFor="original-contract-amount" className="block mb-2">
            Original Contract Amount
          </label>
          <div className="p-inputgroup mb-3">
            <span className="p-inputgroup-addon">$</span>
            <InputNumber
              id="original-contract-amount"
              value={commitment.original_contract_amount}
              onValueChange={(e) =>
                setCommitment({
                  ...commitment,
                  original_contract_amount: e.value,
                })
              }
              mode="decimal"
              minFractionDigits={2}
              placeholder="Original Contract Amount"
            />
          </div>
        </div>

        <div>
          <label htmlFor="approved-change-orders" className="block mb-2">
            Approved Change Order Amount
          </label>
          <div className="p-inputgroup mb-3">
            <span className="p-inputgroup-addon">$</span>
            <InputNumber
              id="approved-change-orders"
              value={commitment.approved_change_orders}
              mode="decimal"
              minFractionDigits={2}
              placeholder="Approved Change Order Amount"
              disabled
            />
          </div>
        </div>

        <div>
          <label htmlFor="pending-change-orders" className="block mb-2">
            Pending Change Order Amount
          </label>
          <div className="p-inputgroup mb-3">
            <span className="p-inputgroup-addon">$</span>
            <InputNumber
              id="pending-change-orders"
              value={commitment.pending_change_orders}
              mode="decimal"
              minFractionDigits={2}
              placeholder="Pending Change Order Amount"
              disabled
            />
          </div>
        </div>

        <div>
          <label htmlFor="revised-contract-amount" className="block mb-2">
            Revised Contract Amount
          </label>
          <div className="p-inputgroup mb-3">
            <span className="p-inputgroup-addon">$</span>
            <InputNumber
              id="revised-contract-amount"
              value={commitment.revised_contract_amount}
              mode="decimal"
              minFractionDigits={2}
              placeholder="Revised Contract Amount"
              disabled
            />
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className="flex flex-column gap-2">
          <label htmlFor="attachments">Attachments</label>
        </div>
        {attachments && attachments.length > 0 && (
          <div className="field mt-2">
            <Attachments
              id="commitment-attachments"
              attachments={attachments}
              setAttachments={setAttachments}
              isDisabled={false}
            />
          </div>
        )}
      </div>

      <div className="col-12">
        <div className="flex flex-column gap-2">
          <CustomFileUpload
            id="attachments"
            accept=""
            onFileChange={onFileChange}
            reset={uploadReset}
            setReset={setUploadReset}
          />
        </div>
      </div>

      <div className="col-12">
        {commitment.id && (
          <div className="card mt-4">
            <ChangeOrdersTable commitmentId={commitment.id} />
          </div>
        )}
      </div>

      <div className="col-12">
        <div className="mb-3">
          <InputTextarea
            value={commitment.inclusions}
            onChange={(e) =>
              setCommitment({ ...commitment, inclusions: e.target.value })
            }
            rows={2}
            placeholder="Inclusions"
          />
        </div>

        <div className="mb-3">
          <InputTextarea
            value={commitment.exclusions}
            onChange={(e) =>
              setCommitment({ ...commitment, exclusions: e.target.value })
            }
            rows={2}
            placeholder="Exclusions"
          />
        </div>

        <div className="mb-3">
          <MultiSelect
            value={commitment.visibility}
            options={users}
            onChange={(e) =>
              setCommitment({ ...commitment, visibility: e.value })
            }
            optionLabel="name"
            optionValue="id"
            placeholder="Select Users for Visibility"
            loading={isFetchingUsers}
            filter
            filterBy="name"
            emptyMessage="No users found"
            emptyFilterMessage="No users found"
          />
        </div>

        <div className="mb-3 flex align-items-center">
          <Checkbox
            checked={commitment.executed}
            onChange={(e) =>
              setCommitment({ ...commitment, executed: e.checked })
            }
            className="mr-2"
          />
          <label>Executed</label>
        </div>
      </div>
    </div>
  );
};

export default UpdateCommitmentForm;
