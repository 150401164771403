import axios from "axios";
import { getAuthToken } from "../util/auth";
import {
  getCompanyId,
  getProjectId,
  getUserData,
  getProjects,
} from "../util/storage";
import API_BASE_URL from "../util/apiConfig";
import { convertToDate, generateBaseUrl, mapIds } from "../util/helper";

const token = getAuthToken();

const submittalsClient = axios.create({
  baseURL: `${API_BASE_URL}`,
  headers: {
    Authorization: "Bearer " + token,
  },
});

submittalsClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response?.data);
  }
);

export async function fetchSubmittals({ queryParams, signal }) {
  const isProcoreUser = getUserData()["is_procore_user"];
  const projectId = getProjectId();
  const companyId = getCompanyId();

  const baseUrl = generateBaseUrl(isProcoreUser, projectId, `submittals`);

  const queryString = new URLSearchParams(queryParams).toString();

  const response = await submittalsClient.get(
    `${baseUrl}?${queryString}`,
    {
      params: {
        company_id: companyId,
      },
    },
    signal
  );

  if (isProcoreUser) {
    const formattedSubmittals = response.data.submittals.map((submittal) => {
      const groupedByWorkflowGroupNumber = {};
      submittal.approvers.forEach((item) => {
        const groupNumber = item.workflow_group_number;
        if (!groupedByWorkflowGroupNumber[groupNumber]) {
          groupedByWorkflowGroupNumber[groupNumber] = [];
        }
        groupedByWorkflowGroupNumber[groupNumber].push(item);
      });

      return {
        ...submittal,
        workflow_data: Object.values(groupedByWorkflowGroupNumber),
      };
    });
    return { ...response.data, submittals: formattedSubmittals };
  }

  return response.data;
}

export async function getSubmittalAttachments({ signal, submittalId }) {
  const isProcoreUser = getUserData()["is_procore_user"];
  const projectId = getProjectId();
  const companyId = getCompanyId();

  const baseUrl = generateBaseUrl(
    isProcoreUser,
    projectId,
    `submittals/${submittalId}/attachments`
  );

  const response = await submittalsClient.get(
    baseUrl,
    {
      params: {
        company_id: companyId,
      },
    },
    signal
  );
  return response.data;
}

export async function getSubmittal({ signal, submittalId }) {
  const isProcoreUser = getUserData()["is_procore_user"];
  const projectId = getProjectId();
  const companyId = getCompanyId();

  const baseUrl = generateBaseUrl(
    isProcoreUser,
    projectId,
    `submittals/${submittalId}`
  );

  const response = await submittalsClient.get(
    baseUrl,
    {
      params: {
        company_id: companyId,
      },
    },
    signal
  );

  if (isProcoreUser) {
    mapIds(response.data);
    const groupedByWorkflowGroupNumber = {};
    response.data.approvers.forEach((item) => {
      const groupNumber = item.workflow_group_number;
      if (!groupedByWorkflowGroupNumber[groupNumber]) {
        groupedByWorkflowGroupNumber[groupNumber] = [];
      }
      groupedByWorkflowGroupNumber[groupNumber].push(item);
    });

    return {
      ...response.data,
      workflow_data: Object.values(groupedByWorkflowGroupNumber),
    };
  } else {
    mapIds(response.data);

    return response.data;
  }
}

export async function createSubmittal({
  submittalData,
  attachments,
  workflow,
}) {
  const user = getUserData();
  const isProcoreUser = user["is_procore_user"];
  const projectId = getProjectId();
  const companyId = getCompanyId();

  const baseUrl = generateBaseUrl(isProcoreUser, projectId, `submittals`);

  let submittalCreateData;

  if (isProcoreUser) {
    const transformedWorkflow = workflow.map((item) =>
      item.user_ids.map((user_id) => ({
        login_information_id: user_id,
        due_date: item.due_date && convertToDate(item.due_date),
        ...item,
      }))
    );

    submittalData.received_date =
      submittalData.received_date && convertToDate(submittalData.received_date);
    submittalData.issue_date =
      submittalData.issue_date && convertToDate(submittalData.issue_date);

    submittalData.revision = submittalData.revision.toString();
    submittalData.status_id = submittalData?.status.id;

    submittalCreateData = submittalData;
    submittalCreateData.prostore_file_ids = attachments.map((obj) => obj.id);

    submittalCreateData.workflow_data = transformedWorkflow;
  } else {
    submittalData.received_date =
      submittalData.received_date && convertToDate(submittalData.received_date);
    submittalData.issue_date =
      submittalData.issue_date && convertToDate(submittalData.issue_date);
    const transformedWorkflow = workflow.map((item) =>
      item.user_ids.map((user_id) => {
        return {
          user_id,
          approver_type: item.approver_type,
          due_date: item.due_date && convertToDate(item.due_date),
        };
      })
    );

    submittalCreateData = submittalData;
    submittalCreateData.workflow_data = transformedWorkflow;
    submittalCreateData.attachments = attachments;
  }
  const response = await submittalsClient.post(baseUrl, submittalCreateData, {
    params: {
      company_id: companyId,
    },
  });
  return response.data;
}

export async function updateSubmittal({
  submittalId,
  submittalData,
  attachments,
  workflow,
}) {
  const user = getUserData();
  const isProcoreUser = user["is_procore_user"];
  const projectId = getProjectId();
  const companyId = getCompanyId();

  const baseUrl = generateBaseUrl(
    isProcoreUser,
    projectId,
    `submittals/${submittalId}`
  );

  let submittalUpdateData = {};

  if (isProcoreUser) {
    submittalUpdateData = submittalData;
    submittalData.received_date =
      submittalUpdateData.received_date &&
      convertToDate(submittalData.received_date);
    submittalData.issue_date =
      submittalUpdateData.issue_date && convertToDate(submittalData.issue_date);

    submittalUpdateData.prostore_file_ids = attachments.map((obj) => obj.id);
  } else {
    submittalUpdateData = {};
    submittalData.received_date =
      submittalData.received_date && convertToDate(submittalData.received_date);
    submittalData.issue_date =
      submittalData.issue_date && convertToDate(submittalData.issue_date);

    const transformedWorkflow = workflow.map((item) =>
      item.user_ids.map((user_id) => ({
        user_id,
        due_date: item.due_date && convertToDate(item.due_date),
        ...item,
      }))
    );

    submittalUpdateData = submittalData;
    submittalUpdateData.workflow_data = transformedWorkflow;
    submittalUpdateData.attachments = attachments;
  }

  const response = await submittalsClient.patch(baseUrl, submittalUpdateData, {
    params: {
      company_id: companyId,
    },
  });

  return response.data;
}

export async function deleteSubmittal({ submittalId }) {
  const user = getUserData();
  const isProcoreUser = user["is_procore_user"];
  const projectId = getProjectId();
  const companyId = getCompanyId();

  const baseUrl = generateBaseUrl(
    isProcoreUser,
    projectId,
    `submittals/${submittalId}`
  );

  const response = await submittalsClient.delete(baseUrl, {
    params: {
      company_id: companyId,
    },
  });
  return response.data;
}

export async function createSubmittalViaAi({ submittalInput }) {
  const isProcoreUser = getUserData()["is_procore_user"];
  const projectId = getProjectId();
  const companyId = getCompanyId();

  const baseUrl = generateBaseUrl(isProcoreUser, projectId, `submittals/ai`);

  const response = await submittalsClient.post(baseUrl, submittalInput, {
    params: {
      company_id: companyId,
    },
  });
  return response.data;
}

export async function createSubmittalFromDraft({
  draftSubmittal,
  attachments,
}) {
  const user = getUserData();
  const isProcoreUser = user["is_procore_user"];
  const projectId = getProjectId();
  const companyId = getCompanyId();

  const baseUrl = generateBaseUrl(
    isProcoreUser,
    projectId,
    `submittals/create`
  );

  if (isProcoreUser) {
    draftSubmittal.prostore_file_ids = attachments.map((obj) => obj.id);
    draftSubmittal.revision = draftSubmittal.revision.toString();
  } else {
    draftSubmittal.attachments = attachments;
  }

  const response = await submittalsClient.post(baseUrl, draftSubmittal, {
    params: {
      company_id: companyId,
    },
  });
  return response.data;
}

export async function getNextSubmittalNumber({ signal, specSectionId }) {
  const user = getUserData();
  const isProcoreUser = user["is_procore_user"];
  const projectId = getProjectId();
  const companyId = getCompanyId();

  let baseUrl = generateBaseUrl(
    isProcoreUser,
    projectId,
    `submittals/next-number`
  );

  if (specSectionId) {
    baseUrl += `?spec_section_id=${specSectionId}`;
  }

  const response = await submittalsClient.get(
    baseUrl,
    {
      params: {
        company_id: companyId,
      },
    },
    signal
  );
  return response.data.next_number;
}

export async function getSubmittalStatusOptions({ signal }) {
  const user = getUserData();
  const isProcoreUser = user["is_procore_user"];
  const projectId = getProjectId();
  const companyId = getCompanyId();

  let baseUrl = generateBaseUrl(
    isProcoreUser,
    projectId,
    `submittals/status-options`
  );

  const response = await submittalsClient.get(
    baseUrl,
    {
      params: {
        company_id: companyId,
      },
    },
    signal
  );
  return response.data;
}

export async function updateSubmittalWorkflow({ submittalId, workflow }) {
  const user = getUserData();
  const isProcoreUser = user["is_procore_user"];
  const projectId = getProjectId();
  const companyId = getCompanyId();

  const baseUrl = generateBaseUrl(
    isProcoreUser,
    projectId,
    `submittals/${submittalId}/workflow`
  );

  if (!isProcoreUser) {
    const groupedByWorkflowStep = Object.values(
      workflow.reduce((acc, obj) => {
        const { step } = obj;
        if (!acc[step]) {
          acc[step] = [];
        }
        acc[step].push(obj);
        return acc;
      }, {})
    );

    const response = await submittalsClient.patch(
      baseUrl,
      groupedByWorkflowStep,
      {
        params: {
          company_id: companyId,
        },
      }
    );
    return response.data;
  } else {
    const response = await submittalsClient.patch(baseUrl, workflow, {
      params: {
        company_id: companyId,
      },
    });
    return response.data;
  }
}

export async function createSubmittalCoversheet({
  submittalData,
  attachments,
  workflow,
}) {
  const user = getUserData();
  const isProcoreUser = user["is_procore_user"];
  const projectId = getProjectId();
  const companyId = getCompanyId();
  const projects = getProjects();

  const baseUrl = generateBaseUrl(
    isProcoreUser,
    projectId,
    `submittals/coversheet`
  );
  let submittalCreateData;
  const matchedProject = projects.find(
    (project) => project.id.toString() === projectId.toString()
  );
  if (isProcoreUser) {
    const transformedWorkflow = workflow.map((item) =>
      item.user_ids.map((user_id) => ({
        login_information_id: user_id,
        due_date: item.due_date && convertToDate(item.due_date),
        ...item,
      }))
    );

    submittalCreateData = {
      number: submittalData["number"],
      revision: submittalData.revision?.toString(),
      title: submittalData["title"],
      received_from_id: submittalData["received_from_id"],
      specification_section_id: submittalData["specification_section_id"],
      workflow_data: transformedWorkflow,
      attachments: attachments,
      project_name: matchedProject?.name,
      submittal_manager_id: submittalData["submittal_manager_id"],
    };
  } else {
    const transformedWorkflow = workflow.map((item) =>
      item.user_ids.map((user_id) => {
        return {
          user_id,
          approver_type: item.approver_type,
          due_date: item.due_date && convertToDate(item.due_date),
        };
      })
    );
    submittalCreateData = {
      number: submittalData["number"],
      revision: parseInt(submittalData?.revision),
      title: submittalData["title"],
      submittal_manager_id: submittalData["submittal_manager_id"],
      received_from_id: submittalData["received_from_id"],
      specification_section_id: submittalData["specification_section_id"],
      workflow_data: transformedWorkflow,
      attachments: attachments,
      project_name: matchedProject?.name,
    };
  }
  const response = await submittalsClient.post(baseUrl, submittalCreateData, {
    params: {
      company_id: companyId,
    },
  });
  return response.data;
}

export async function setBallInCourt({ submittalId, ballInCourt, step }) {
  const user = getUserData();
  const isProcoreUser = user["is_procore_user"];
  const projectId = getProjectId();
  const companyId = getCompanyId();

  const baseUrl = generateBaseUrl(
    isProcoreUser,
    projectId,
    `submittals/${submittalId}/ball-in-court`
  );

  const payload = { ball_in_court: ballInCourt, step: step - 1 };

  const response = await submittalsClient.patch(baseUrl, payload, {
    params: {
      company_id: companyId,
    },
  });
  return response.data;
}
