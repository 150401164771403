import axios from "axios";
import { getAuthToken } from "../util/auth";
import { getProjectId, getUserData } from "../util/storage";
import API_BASE_URL from "../util/apiConfig";

const token = getAuthToken();

const filterOptionClient = axios.create({
  baseURL: `${API_BASE_URL}/filter-options`,
  headers: {
    Authorization: "Bearer " + token,
  },
});

export async function fetchFilterOptions({
  signal,
  filterType,
  pageType,
  additionalData,
}) {
  if (pageType === "task") {
    return await fetchTasksFilterOptions({ signal, filterType });
  } else if (pageType === "rfi") {
    return await fetchRFIsFilterOptions({ signal, filterType });
  } else if (pageType === "punch_list_items") {
    return await fetchPunchListsFilterOptions({
      signal,
      filterType,
      additionalData,
    });
  } else {
    return await fetchSubmittalsFilterOptions({ signal, filterType });
  }
}

export async function fetchRFIsFilterOptions({ signal, filterType }) {
  const isProcoreUser = getUserData()["is_procore_user"];
  const projectId = getProjectId();

  if (isProcoreUser) {
    let url = `/procore/rfis?project_id=${projectId}`;

    if (filterType) {
      url += `&filter_type=${filterType}`;
    }
    const response = await filterOptionClient.get(url, signal);
    return response.data;
  } else {
    let url = `/nicky/rfis?project_id=${projectId}`;

    if (filterType) {
      url += `&filter_type=${filterType}`;
    }
    const response = await filterOptionClient.get(url, signal);
    return response.data;
  }
}

export async function fetchSubmittalsFilterOptions({ signal, filterType }) {
  const isProcoreUser = getUserData()["is_procore_user"];
  const projectId = getProjectId();

  if (isProcoreUser) {
    let url = `/procore/submittals?project_id=${projectId}`;

    if (filterType) {
      url += `&filter_type=${filterType}`;
    }
    const response = await filterOptionClient.get(url, signal);
    return response.data;
  } else {
    let url = `/nicky/submittals?project_id=${projectId}`;
    if (filterType) {
      url += `&filter_type=${filterType}`;
    }
    const response = await filterOptionClient.get(url, signal);
    return response.data;
  }
}

export async function fetchTasksFilterOptions({ signal, filterType }) {
  let url = `/nicky/tasks`;

  if (filterType) {
    url += `?filter_type=${filterType}`;
  }
  const response = await filterOptionClient.get(url, signal);
  return response.data;
}

export async function fetchPunchListsFilterOptions({
  signal,
  filterType,
  additionalData,
}) {
  let url = `/punch-list-items?punch_list_id=${additionalData}`;

  if (filterType) {
    url += `&filter_type=${filterType}`;
  }
  const response = await filterOptionClient.get(url, signal);
  return response.data;
}
