import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import { useMutation } from "@tanstack/react-query";

import { deleteRfi } from "../../api/rfisAPI";
import { queryClient } from "../../api/requestProcessor";
import { useContext } from "react";
import { AppContext } from "../../context/AppProvider";
import { getErrorMessage } from "../../util/helper";

export default function DeleteRFI({
  deleteRFIDialog,
  hideDeleteRFIDialog,
  rfi,
}) {
  const { toast } = useContext(AppContext);

  const { mutate, isPending } = useMutation({
    mutationFn: deleteRfi,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["rfis"] });
      hideDeleteRFIDialog();
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "RFI Deleted",
        life: 3000,
      });
    },
    onError: (error) => {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: getErrorMessage(error),
        life: 3000,
      });
    },
  });

  const handleDeleteRFI = () => {
    mutate({ rfiId: rfi.id });
  };

  const deleteRFIDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteRFIDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={handleDeleteRFI}
        loading={isPending}
      />
    </>
  );

  return (
    <Dialog
      visible={deleteRFIDialog}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Confirm"
      modal
      footer={deleteRFIDialogFooter}
      onHide={hideDeleteRFIDialog}
    >
      <div className="confirmation-content">
        <i
          className="pi pi-exclamation-triangle mr-3"
          style={{ fontSize: "2rem" }}
        />
        {rfi && (
          <span>
            Are you sure you want to delete <b>{rfi.subject}</b>?
          </span>
        )}
      </div>
    </Dialog>
  );
}
