import { useMutation } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { sendOtpCode, verifyOtpCode } from "../api/authAPI";
import { AppContext } from "../context/AppProvider";
import { getErrorMessage } from "../util/helper";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

const initalCredentials = { email: "", code: "" };

const OtpLogin = () => {
  const [credentials, setCredentials] = useState(initalCredentials);
  const [step, setStep] = useState(1);
  const { toast } = useContext(AppContext);

  const { mutate: sendOtpMutation, isPending: isPendingOtpCode } = useMutation({
    mutationFn: sendOtpCode,
    onSuccess: (response) => {
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: response.message,
        life: 3000,
      });
      setStep(2);
    },
    onError: (error) => {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: getErrorMessage(error),
        life: 3000,
      });
    },
  });

  const { mutate: verifyOtpMutation, isPending: isPendingOtpVerification } =
    useMutation({
      mutationFn: verifyOtpCode,
      onSuccess: async (response) => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: response.message,
          life: 3000,
        });
        localStorage.setItem("access_token", response.token);
        localStorage.setItem("userData", JSON.stringify(response.user));
        window.location.reload(true);
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };

  const handleGoBack = (e) => {
    e.preventDefault();
    setStep(1);
  };

  const handleResendCode = (e) => {
    e.preventDefault();
    sendOtpMutation(credentials);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (step === 1) {
      sendOtpMutation(credentials);
    } else if (step === 2) {
      verifyOtpMutation(credentials);
    }
  };

  return (
    <div className="centered-content">
      <Card
        className="nicky-card"
        title="Welcome back!"
        subTitle="Login to your account"
      >
        <form onSubmit={handleSubmit}>
          <div className="flex flex-column gap-4">
            {step === 1 && (
              <span className="p-float-label">
                <InputText
                  id="email"
                  name="email"
                  type="email"
                  value={credentials.email}
                  onChange={handleChange}
                  className="w-full"
                  required
                />
                <label htmlFor="email">Email</label>
              </span>
            )}
            {step === 2 && (
              <>
                <span className="w-20rem">
                  We have emailed you a single-use login code. Your login code
                  expires in 5 minutes.
                </span>
                <span className="p-float-label">
                  <InputText
                    id="code"
                    name="code"
                    type="text"
                    value={credentials.code}
                    onChange={handleChange}
                    className="w-20rem"
                    required
                  />
                  <label htmlFor="password">Code</label>
                </span>
              </>
            )}
            <div>
              <Button
                className="w-full"
                icon="pi pi-sign-in"
                label={
                  step === 1
                    ? isPendingOtpCode
                      ? "Sending Code..."
                      : "Continue"
                    : isPendingOtpVerification
                    ? "Signing In..."
                    : "Sign In"
                }
                type="submit"
                loading={isPendingOtpCode || isPendingOtpVerification}
              />
            </div>
            {step === 2 && (
              <div>
                <Button
                  className="w-full"
                  icon="pi pi-replay"
                  type="button"
                  label={isPendingOtpCode ? "Sending..." : "Resend Code"}
                  onClick={handleResendCode}
                  loading={isPendingOtpCode}
                  outlined
                />
                <Button
                  type="button"
                  icon="pi pi-arrow-left"
                  label="Go Back"
                  className="w-full mt-4"
                  onClick={handleGoBack}
                  outlined
                />
              </div>
            )}
            {step === 1 && (
              <Button
                label="Not registered yet? Schedule a demo"
                link
                onClick={() =>
                  window.open(
                    "https://calendly.com/alper-olo/nicky-demo",
                    "_blank"
                  )
                }
              />
            )}
          </div>
        </form>
      </Card>
    </div>
  );
};

export default OtpLogin;
