import axios from "axios";
import { getAuthToken } from "../util/auth";
import API_BASE_URL from "../util/apiConfig";
import { getProjectId } from "../util/storage";
import qs from "qs";

const token = getAuthToken();

const punchListsClient = axios.create({
  baseURL: `${API_BASE_URL}/punch-lists`,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

punchListsClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response.data);
  }
);

export async function fetchPunchLists(signal) {
  const projectId = getProjectId();
  const response = await punchListsClient.get("", {
    signal,
    params: {
      project_id: projectId,
    },
  });
  return response.data;
}

export async function createPunchList(punchList) {
  const projectId = getProjectId();
  punchList.project_id = projectId;

  const response = await punchListsClient.post("", punchList);
  return response.data;
}

export async function updatePunchList(punchList) {
  const response = await punchListsClient.patch(`/${punchList._id}`, punchList);
  return response.data;
}

export async function deletePunchList(punchListId) {
  const response = await punchListsClient.delete(`/${punchListId}`);
  return response.data;
}

export async function fetchPunchListItems({ signal, queryParams }) {
  const response = await punchListsClient.get(
    `/${queryParams.punch_list_id}/items`,
    {
      params: queryParams,
      signal: signal,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    }
  );
  return response.data;
}

export async function createPunchListItem({ newPunchListItem, punchListId }) {
  const projectId = getProjectId();
  newPunchListItem.project_id = projectId;

  const response = await punchListsClient.post(
    `/${punchListId}/items`,
    newPunchListItem
  );
  return response.data;
}

export async function updatePunchListItem({ punchListItem, punchListId }) {
  const response = await punchListsClient.patch(
    `/${punchListId}/items/${punchListItem?._id}`,
    punchListItem
  );
  return response.data;
}

export async function deletePunchListItem({ punchListId, punchListItemId }) {
  const response = await punchListsClient.delete(
    `/${punchListId}/items/${punchListItemId}`
  );
  return response.data;
}

export async function fetchPunchListNextNumber({ signal, punchListId }) {
  const response = await punchListsClient.get(`/${punchListId}/next-number`, {
    signal,
  });
  return response.data;
}
