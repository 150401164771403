import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useContext, useState } from "react";
import { queryClient } from "../../api/requestProcessor";
import { useMutation } from "@tanstack/react-query";
import { AppContext } from "../../context/AppProvider";
import { getErrorMessage } from "../../util/helper";
import { createPunchList } from "../../api/punchListsAPI";
import { InputText } from "primereact/inputtext";

const initialPunchList = {
  title: "",
};

export default function NewPunchListDialog({ isVisible, hideDialog }) {
  const { toast } = useContext(AppContext);

  const [newPunchList, setNewPuchList] = useState(initialPunchList);

  const { mutate: createPunchListMutation, isPending: isPendingCreate } =
    useMutation({
      mutationFn: createPunchList,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["punch-lists"] });
        hideDialog();
        setNewPuchList(initialPunchList);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Punch List Created",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "Error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewPuchList((prevNewPunchList) => ({
      ...prevNewPunchList,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleCreateNewPunchList = () => {
    createPunchListMutation(newPunchList);
  };

  const dialogFooter = (
    <>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button
        label={isPendingCreate ? "Creating" : "Create"}
        icon="pi pi-plus"
        onClick={handleCreateNewPunchList}
        loading={isPendingCreate}
      />
    </>
  );

  return (
    <Dialog
      visible={isVisible}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="New Punch List"
      modal
      className="p-fluid mx-2"
      footer={dialogFooter}
      onHide={hideDialog}
    >
      <div className="field">
        <label htmlFor="title" className="font-bold">
          Title
        </label>
        <InputText
          id="title"
          name="title"
          value={newPunchList.title}
          onChange={handleChange}
          required
        />
      </div>
    </Dialog>
  );
}
