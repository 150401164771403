import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useContext, useEffect, useState } from "react";
import { queryClient } from "../../api/requestProcessor";
import { useMutation } from "@tanstack/react-query";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import CustomFileUpload from "../FileUpload/FileUpload";
import { uploadFiles } from "../../api/filesAPI";
import { updateSubmittalWorkflow } from "../../api/submittalsAPI";
import { AppContext } from "../../context/AppProvider";
import { getErrorMessage } from "../../util/helper";

export default function EditResponseDialog({
  isVisible,
  hideDialog,
  submittalId,
  workflow,
  selectedApprover,
}) {
  const { toast } = useContext(AppContext);

  const submittalApproverStatuses = [
    { label: "Approved", value: "Approved" },
    { label: "Approved as Noted", value: "Approved as Noted" },
    { label: "Reviewed", value: "Reviewed" },
    { label: "Revise and Resubmit", value: "Revise and Resubmit" },
    { label: "Rejected", value: "Rejected" },
    { label: "Void", value: "Void" },
    { label: "Pending", value: "Pending" },
    { label: "For Record Only", value: "For Record Only" },
  ];

  const submittalSubmitterStatuses = [
    { label: "Pending", value: "Pending" },
    { label: "Submitted", value: "Submitted" },
  ];

  const [response, setResponse] = useState({
    response: "",
    comment: "",
  });
  const [files, setFiles] = useState([]);
  const [uploadReset, setUploadReset] = useState(false);
  const [label, setLabel] = useState("Save");

  const {
    mutate: updateSubmittalWorkflowMutation,
    isPending: isPendingUpdate,
  } = useMutation({
    mutationFn: updateSubmittalWorkflow,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["submittals"] });
      queryClient.invalidateQueries({
        queryKey: ["submittal", submittalId],
      });
      queryClient.invalidateQueries({
        queryKey: ["submittal_attachments", submittalId],
      });
      hideDialog();
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Submittal Updated",
        life: 3000,
      });
    },
    onError: (error) => {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: getErrorMessage(error),
        life: 3000,
      });
    },
  });

  const { mutateAsync: uploadFilesMutation, isPending: isPendingUpload } =
    useMutation({
      mutationFn: uploadFiles,
      onSuccess: () => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "File Uploaded",
          life: 3000,
        });
        setUploadReset(true);
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  useEffect(() => {
    if (isPendingUpload) {
      setLabel("Uploading Files");
    } else if (isPendingUpdate) {
      setLabel("Saving Changes");
    } else {
      setLabel("Save");
    }
  }, [isPendingUpload, isPendingUpdate]);

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _response = { ...response };

    _response[`${name}`] = val;

    setResponse(_response);
  };

  const onFileChange = (files) => {
    setFiles(files);
  };

  const editResponse = async () => {
    let updatedAttachments = [];

    if (files.length > 0) {
      const uploadPromises = files.map(async (file) => {
        return await uploadFilesMutation({
          files: [file],
        });
      });

      const responses = await Promise.all(uploadPromises);
      updatedAttachments = [...responses];
    }

    workflow.forEach((element) => {
      if (
        element.step === selectedApprover.step &&
        element.user.id === selectedApprover.user.id
      ) {
        element.response = { status: response.response };
        element.comment = response.comment;
        element.attachments = updatedAttachments;
      }
    });

    updateSubmittalWorkflowMutation({
      submittalId: submittalId,
      workflow: workflow,
    });
  };

  const dialogFooter = (
    <>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button
        label={label}
        onClick={editResponse}
        loading={isPendingUpdate || isPendingUpload}
        disabled={isPendingUpdate || isPendingUpload}
      />
    </>
  );

  return (
    <Dialog
      visible={isVisible}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={`Edit ${selectedApprover.user.name}'s Response`}
      modal
      className="p-fluid mx-2"
      footer={dialogFooter}
      onHide={hideDialog}
    >
      <div className="field">
        <label htmlFor="workflow-status" className="font-bold">
          Status
        </label>
        <Dropdown
          id="workflow-status"
          value={response.response}
          onChange={(e) => onInputChange(e, "response")}
          options={
            selectedApprover === "approver_type"
              ? submittalSubmitterStatuses
              : submittalApproverStatuses
          }
          placeholder="Select Status"
        />
      </div>
      <div className="field">
        <label htmlFor="comments" className="font-bold">
          Comments
        </label>
        <InputTextarea
          id="comments"
          value={response.comment}
          onChange={(e) => onInputChange(e, "comment")}
          required
        />
      </div>
      <div className="field">
        <label htmlFor="attachments">Attachments</label>
        <CustomFileUpload
          id="attachments"
          accept=""
          onFileChange={onFileChange}
          reset={uploadReset}
          setReset={setUploadReset}
        />
      </div>
    </Dialog>
  );
}
