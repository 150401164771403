import axios from "axios";
import { getAuthToken } from "../util/auth";
import API_BASE_URL from "../util/apiConfig";
import { getCompanyId, getProjectId } from "../util/storage";

const token = getAuthToken();

const usersClient = axios.create({
  baseURL: `${API_BASE_URL}/nicky/projects`,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

usersClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response.data);
  }
);

export async function createUser({ newUser }) {
  const projectId = getProjectId();
  const company_id = getCompanyId();

  const response = await usersClient.post(
    `/${projectId}/users?company_id=${company_id}`,
    newUser
  );

  return response.data;
}

export async function getUser({ signal, userId }) {
  const projectId = getProjectId();
  const company_id = getCompanyId();

  const response = await usersClient.get(
    `/${projectId}/users/${userId}?company_id=${company_id}`,
    signal
  );

  return response.data;
}

export async function updateUser({ userId, userData }) {
  const projectId = getProjectId();
  const company_id = getCompanyId();

  const response = await usersClient.patch(
    `/${projectId}/users/${userId}?company_id=${company_id}`,
    userData
  );

  return response.data;
}

export async function deleteUser({ userId }) {
  const projectId = getProjectId();
  const company_id = getCompanyId();

  const response = await usersClient.delete(
    `/${projectId}/users/${userId}?company_id=${company_id}`
  );

  return response.data;
}
