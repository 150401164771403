import React, { useState, useRef } from "react";

import CreateTask from "./CreateTask";
import TaskTable from "./TaskTable";
import DeleteTask from "./DeleteTask";
import MobileDataTable from "../MobileDataTable/MobileDataTable";
import CreateTaskReminderDialog from "./CreateTaskReminderDialog";

export default function Tasks({
  tasks,
  isPending,
  refetch,
  setQueryParams,
  total,
}) {
  let emptyTask = {
    subject: "",
    task_body: "",
    assignees: [],
    attachments: [],
  };

  const initialFilters = [
    {
      key: "statuses",
      value: "Status",
      selected_options: [],
      options: [],
    },
    {
      key: "assignees",
      value: "Assignee",
      selected_options: [],
      options: [],
    },
  ];

  const [taskDialog, setTaskDialog] = useState(false);
  const [deleteTaskDialog, setDeleteTaskDialog] = useState(false);
  const [taskReminderDialog, setTaskReminderDialog] = useState(false);
  const [task, setTask] = useState(emptyTask);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [editTaskMode, setEditTaskMode] = useState(null);

  const dt = useRef(null);

  const openNew = () => {
    setTask(emptyTask);
    setEditTaskMode(false);
    setTaskDialog(true);
  };

  const openTaskReminderDialog = (task) => {
    setTask(task);
    setTaskReminderDialog(true);
  };

  const hideDialog = () => {
    setTaskDialog(false);
  };

  const hideDeleteTaskDialog = () => {
    setDeleteTaskDialog(false);
  };

  const hideTaskReminderDialog = () => {
    setTaskReminderDialog(false);
  };

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const editTask = (task) => {
    setTask({ ...task });
    setEditTaskMode(true);
    setTaskDialog(true);
  };

  const confirmDeleteTask = (task) => {
    setTask(task);
    setDeleteTaskDialog(true);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _task = { ...task };

    _task[`${name}`] = val;

    setTask(_task);
  };

  return (
    <>
      <TaskTable
        dt={dt}
        selectedTasks={selectedTasks}
        setSelectedTasks={setSelectedTasks}
        tasks={tasks}
        isPending={isPending}
        editTask={editTask}
        confirmDeleteTask={confirmDeleteTask}
        exportCSV={exportCSV}
        openNew={openNew}
        refetch={refetch}
        setQueryParams={setQueryParams}
        total={total}
        initialFilters={initialFilters}
        openTaskReminderDialog={openTaskReminderDialog}
      />

      <MobileDataTable
        items={tasks}
        createHandler={openNew}
        editHandler={editTask}
        deleteHandler={confirmDeleteTask}
        isPending={isPending}
        title="subject"
        body="task_body"
        number={null}
        status="status"
        date="due_date"
        assignees="assignee"
        editMode={true}
        total={total}
        setQueryParams={setQueryParams}
        initialFilters={initialFilters}
        pageType="task"
      />

      {taskDialog && (
        <CreateTask
          taskDialog={taskDialog}
          hideDialog={hideDialog}
          task={task}
          onInputChange={onInputChange}
          editTaskMode={editTaskMode}
          initialAttachments={task.attachments}
        />
      )}

      {deleteTaskDialog && (
        <DeleteTask
          deleteTaskDialog={deleteTaskDialog}
          hideDeleteTaskDialog={hideDeleteTaskDialog}
          task={task}
        />
      )}

      {taskReminderDialog && (
        <CreateTaskReminderDialog
          visible={taskReminderDialog}
          onHide={hideTaskReminderDialog}
          task={task}
        />
      )}
    </>
  );
}
