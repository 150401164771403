import { redirect } from "react-router-dom";
import { getUserData } from "./storage";

export function getTokenDuration() {
  const storedExpirationDate = localStorage.getItem("expiration");
  const expirationDate = new Date(storedExpirationDate);
  const now = new Date();
  const duration = expirationDate.getTime() - now.getTime();
  return duration;
}

export function getAuthToken() {
  const token = localStorage.getItem("access_token");

  if (!token) {
    return null;
  }

  return token;
}

export function getCompany() {
  const company = localStorage.getItem("company_id");

  if (!company) {
    return null;
  }

  return company;
}

export function tokenLoader() {
  const token = getAuthToken();
  const company = getCompany();
  const user = getUserData();

  if (token && user.role === 3) {
    return redirect("/admin");
  } else if (token && !company) {
    return redirect("/user-onboard");
  } else if (token && company) {
    return redirect("/");
  }

  return true;
}

export function checkAuthLoader() {
  const token = getAuthToken();

  if (!token) {
    return redirect("/login");
  }

  const user = getUserData();

  if (user.role === 3) {
    return true;
  }

  const company = getCompany();

  if (!company) {
    return redirect("/user-onboard");
  }

  return true;
}

export function checkOnlyAuthLoader() {
  const token = getAuthToken();

  if (!token) {
    return redirect("/login");
  }

  return true;
}

export function checkAdminAuthLoader() {
  const token = getAuthToken();

  if (!token) {
    return redirect("/login");
  }

  const user = getUserData();

  if (user.role === 3) {
    return true;
  }

  return redirect("/login");
}

export function checkAuthLoaderWithUserType() {
  const token = getAuthToken();

  if (!token) {
    return redirect("/login");
  }

  const company = getCompany();

  if (!company) {
    return redirect("/company-selection");
  }

  const user = getUserData();

  if (!user) {
    return redirect("/");
  }

  if (user.is_procore_user) {
    return redirect("/");
  }

  return true;
}

export function indexLoader() {
  return redirect("/chatbots");
}
