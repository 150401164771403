import { useMutation, useQuery } from "@tanstack/react-query";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { getRfi } from "../../api/rfisAPI";
import { uploadFiles } from "../../api/filesAPI";
import { queryClient } from "../../api/requestProcessor";
import { createReply } from "../../api/responsesAPI";
import CustomFileUpload from "../FileUpload/FileUpload";
import { useContext, useState } from "react";
import CustomLoader from "../Loaders/CustomLoader";
import { InputTextarea } from "primereact/inputtextarea";
import Answers from "./Answers";
import { getUserData } from "../../util/storage";
import { AppContext } from "../../context/AppProvider";
import { getErrorMessage } from "../../util/helper";

export default function Reply({ replyDialog, hideReplyDialog, rfiId }) {
  const isProcoreUser = getUserData()["is_procore_user"];

  const [files, setFiles] = useState([]);
  const [uploadReset, setUploadReset] = useState(false);
  const [responseBody, setResponseBody] = useState("");

  const { toast } = useContext(AppContext);

  const { data: RFIData, isFetching: isPendingRFI } = useQuery({
    queryKey: ["rfi", rfiId],
    queryFn: ({ signal }) => getRfi({ signal, rfiId: rfiId }),
    enabled: !!rfiId,
    staleTime: 5 * 60 * 1000,
  });

  const { mutate: createReplyMutation, isPending: isPendingCreation } =
    useMutation({
      mutationFn: createReply,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["rfi", rfiId] });
        setResponseBody("");
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Reply Created",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const { mutateAsync: uploadFilesMutation, isPending: isPendingUpload } =
    useMutation({
      mutationFn: uploadFiles,
      onSuccess: () => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "File Uploaded",
          life: 3000,
        });
        setUploadReset(true);
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const saveReply = async () => {
    let updatedAttachments = [];

    if (files.length > 0) {
      const uploadPromises = files.map(async (file) => {
        return await uploadFilesMutation({
          files: [file],
        });
      });

      const responses = await Promise.all(uploadPromises);
      updatedAttachments = [...responses];
    }

    const replyData = {
      body: responseBody,
    };

    if (isProcoreUser) {
      replyData.question_id = RFIData.questions[0].id;
    }

    createReplyMutation({
      replyData: replyData,
      attachments: updatedAttachments,
      rfiId: RFIData.id,
    });
  };

  const onFileChange = (files) => {
    setFiles(files);
  };

  const rfiDialogFooter = (
    <>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={hideReplyDialog}
      />
      <Button
        label="Add"
        icon="pi pi-check"
        onClick={saveReply}
        loading={isPendingCreation || isPendingUpload}
        disabled={isPendingCreation || isPendingUpload}
      />
    </>
  );

  return (
    <Dialog
      visible={replyDialog}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Responses"
      modal
      className="p-fluid"
      footer={rfiDialogFooter}
      onHide={hideReplyDialog}
    >
      {isPendingRFI ? (
        <CustomLoader />
      ) : (
        <>
          <div className="field">
            <label htmlFor="assignee_ids" className="font-bold">
              Question
            </label>
            <div>
              {RFIData
                ? isProcoreUser
                  ? RFIData.questions[0].plain_text_body
                  : RFIData.rfi_body
                : ""}
            </div>
          </div>
          <div className="field">
            <label htmlFor="assignee_ids" className="font-bold">
              Answers
            </label>
            {RFIData && isProcoreUser ? (
              RFIData.questions[0].answers.length ? (
                <Answers
                  replies={RFIData ? RFIData.questions[0].answers : []}
                  toast={toast}
                  rfiId={rfiId}
                />
              ) : (
                <div>There is no answer</div>
              )
            ) : RFIData.answers?.length ? (
              <Answers
                replies={RFIData ? RFIData.answers : []}
                toast={toast}
                rfiId={rfiId}
              />
            ) : (
              <div>There is no answer</div>
            )}
          </div>
          <div className="field">
            <label htmlFor="body" className="font-bold">
              Body
            </label>
            <InputTextarea
              id="body"
              value={responseBody}
              onChange={(e) => {
                setResponseBody(e.target.value);
              }}
              required
              rows={5}
            />
          </div>
          <CustomFileUpload
            accept=""
            onFileChange={onFileChange}
            reset={uploadReset}
            setReset={setUploadReset}
          />
        </>
      )}
    </Dialog>
  );
}
