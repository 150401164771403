import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useContext } from "react";
import { queryClient } from "../../api/requestProcessor";
import { useMutation } from "@tanstack/react-query";
import { AppContext } from "../../context/AppProvider";
import { getErrorMessage } from "../../util/helper";
import { deleteFolders } from "../../api/foldersAPI";

export default function DeleteFolderDialog({
  isVisible,
  hideDialog,
  selectedFolders,
  setSelectedFolders,
  folderId,
}) {
  const { toast } = useContext(AppContext);

  const { mutate: deleteFoldersMutation, isPending: isPendingDeletion } =
    useMutation({
      mutationFn: deleteFolders,
      onSuccess: () => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Successfully Deleted",
          life: 3000,
        });
        setSelectedFolders([]);
        queryClient.invalidateQueries({ queryKey: ["folders", folderId] });
        hideDialog();
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const handleDeleteFolders = () => {
    const foldersData = selectedFolders.map((item) => {
      return { id: item.id, is_folder: item.file_type ? false : true };
    });
    deleteFoldersMutation({ folders: foldersData });
  };

  const dialogFooter = (
    <>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button
        severity="danger"
        label={isPendingDeletion ? "Deleting" : "Delete"}
        icon="pi pi-check"
        onClick={handleDeleteFolders}
        loading={isPendingDeletion}
      />
    </>
  );

  return (
    <Dialog
      visible={isVisible}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Are you sure you want to delete?"
      modal
      className="p-fluid mx-2"
      footer={dialogFooter}
      onHide={hideDialog}
    >
      <div className="field">
        {selectedFolders.map((folder) => {
          return <div>{folder.name}</div>;
        })}
      </div>
      <label htmlFor="folder_name" className="font-bold">
        You can’t undo this action.
      </label>
    </Dialog>
  );
}
