import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useContext } from "react";
import { queryClient } from "../../api/requestProcessor";
import { useMutation } from "@tanstack/react-query";
import { AppContext } from "../../context/AppProvider";
import { getErrorMessage } from "../../util/helper";
import { deletePunchListItem } from "../../api/punchListsAPI";

export default function DeletePunchListItemDialog({
  isVisible,
  hideDialog,
  punchListId,
  punchListItemId,
}) {
  const { toast } = useContext(AppContext);

  const { mutate: deletePunchListItemMutation, isPending: isPendingDeletion } =
    useMutation({
      mutationFn: deletePunchListItem,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["punch-lists"] });
        hideDialog();
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Punch List Item Deleted",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "Error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const handleUpdatePunchList = () => {
    deletePunchListItemMutation({ punchListId, punchListItemId });
  };

  const dialogFooter = (
    <>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button
        label={isPendingDeletion ? "Deleting" : "Delete"}
        icon="pi pi-trash"
        onClick={handleUpdatePunchList}
        loading={isPendingDeletion}
        severity="danger"
      />
    </>
  );

  return (
    <Dialog
      visible={isVisible}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Delete Punch List"
      modal
      className="p-fluid mx-2"
      footer={dialogFooter}
      onHide={hideDialog}
    >
      <div className="field">
        <label htmlFor="title" className="font-bold">
          Are you sure to delete the punch list item?
        </label>
      </div>
    </Dialog>
  );
}
