import { useState, useContext } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

import { useMutation } from "@tanstack/react-query";

import { queryClient } from "../../api/requestProcessor";

import { AppContext } from "../../context/AppProvider";
import { getErrorMessage } from "../../util/helper";
import { createContact } from "../../api/contactsAPI";

export default function CreateContact({
  dialog,
  hideDialog,
  contact,
  onInputChange,
}) {
  const [validationMessages, setValidationMessages] = useState([]);

  const { toast } = useContext(AppContext);

  const { mutate: createContactMutation, isPending: isPendingCreation } =
    useMutation({
      mutationFn: createContact,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["contacts"] });
        hideDialog();
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Contact Created",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const checkValidation = () => {
    let _validationMessages = [];
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!contact.email) {
      _validationMessages = [..._validationMessages, "Email field is required"];
    } else if (!regex.test(contact.email)) {
      _validationMessages = [
        ..._validationMessages,
        "Please enter a valid email address. The email should include '@' and a domain (e.g., user@example.com).",
      ];
    }
    setValidationMessages(_validationMessages);

    return !_validationMessages.length;
  };

  const saveContact = async () => {
    setValidationMessages([]);
    if (checkValidation()) {
      createContactMutation({ contact: contact });
    }
  };

  const contactDialogFooter = (
    <>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button
        label={isPendingCreation ? "Saving..." : "Save"}
        icon="pi pi-check"
        onClick={saveContact}
        loading={isPendingCreation}
        disabled={isPendingCreation}
      />
    </>
  );

  return (
    <Dialog
      visible={dialog}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Contact Details"
      modal
      className="p-fluid mx-2"
      footer={contactDialogFooter}
      onHide={hideDialog}
    >
      <div className="field">
        <label htmlFor="email" className="font-bold">
          Email
        </label>
        <InputText
          id="email"
          value={contact.name}
          onChange={(e) => onInputChange(e, "email")}
          required
        />
        {validationMessages && !!validationMessages.length && (
          <div className="text-red-600">
            {validationMessages.map((message) => {
              return <small key={message}>{message}</small>;
            })}
          </div>
        )}
      </div>
    </Dialog>
  );
}
