import axios from "axios";
import { getAuthToken } from "../util/auth";
import API_BASE_URL from "../util/apiConfig";
import { getProjectId, getCompanyId } from "../util/storage";
import qs from "qs";

const token = getAuthToken();

const commitmentsClient = axios.create({
  baseURL: `${API_BASE_URL}`,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

commitmentsClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response.data);
  }
);

export async function fetchCommitments({ signal, queryParams }) {
  const projectId = getProjectId();
  const companyId = getCompanyId();
  queryParams.company_id = companyId;
  const response = await commitmentsClient.get(
    `/projects/${projectId}/commitments`,
    {
      params: queryParams,
      signal: signal,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    }
  );
  return response.data;
}

export async function fetchCommitment({ signal, commitmentId }) {
  const projectId = getProjectId();
  const response = await commitmentsClient.get(
    `/projects/${projectId}/commitments/${commitmentId}`,
    {
      signal: signal,
    }
  );
  return response.data;
}

export async function createCommitment(commitment) {
  const projectId = getProjectId();
  const companyId = getCompanyId();

  const response = await commitmentsClient.post(
    `/projects/${projectId}/commitments?company_id=${companyId}`,
    commitment
  );
  return response.data;
}

export async function updateCommitment({ commitment, commitmentId }) {
  const projectId = getProjectId();

  const response = await commitmentsClient.patch(
    `/projects/${projectId}/commitments/${commitmentId}`,
    commitment
  );
  return response.data;
}

export async function deleteCommitment(commitmentId) {
  const projectId = getProjectId();

  const response = await commitmentsClient.delete(
    `/projects/${projectId}/commitments/${commitmentId}`
  );
  return response.data;
}

export async function fetchChangeOrders({ signal, queryParams, commitmentId }) {
  const projectId = getProjectId();
  const response = await commitmentsClient.get(
    `/projects/${projectId}/commitments/${commitmentId}/change-orders`,
    {
      params: queryParams,
      signal: signal,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    }
  );
  return response.data;
}

export async function fetchChangeOrder({
  signal,
  commitmentId,
  changeOrderId,
}) {
  const projectId = getProjectId();
  const response = await commitmentsClient.get(
    `/projects/${projectId}/commitments/${commitmentId}/change-orders/${changeOrderId}`,
    {
      signal: signal,
    }
  );
  return response.data;
}

export async function createChangeOrder({ commitmentId, changeOrder }) {
  const projectId = getProjectId();

  const response = await commitmentsClient.post(
    `/projects/${projectId}/commitments/${commitmentId}/change-orders`,
    changeOrder
  );
  return response.data;
}

export async function updateChangeOrder({
  changeOrder,
  commitmentId,
  changeOrderId,
}) {
  const projectId = getProjectId();

  const response = await commitmentsClient.patch(
    `/projects/${projectId}/commitments/${commitmentId}/change-orders/${changeOrderId}`,
    changeOrder
  );
  return response.data;
}

export async function deleteChangeOrder({ commitmentId, changeOrderId }) {
  const projectId = getProjectId();

  const response = await commitmentsClient.delete(
    `/projects/${projectId}/commitments/${commitmentId}/change-orders/${changeOrderId}`
  );
  return response.data;
}

export async function fetchNextCommitmentNumber({ signal }) {
  const projectId = getProjectId();
  const companyId = getCompanyId();
  const response = await commitmentsClient.get(
    `/projects/${projectId}/commitments/number`,
    {
      params: { company_id: companyId },
      signal: signal,
    }
  );
  return response.data;
}

export async function fetchNextChangeOrderNumber({ signal }) {
  const projectId = getProjectId();
  const response = await commitmentsClient.get(
    `/projects/${projectId}/commitments/change-orders/number`,
    {
      signal: signal,
    }
  );
  return response.data;
}
