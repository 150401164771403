import React, { useState, useRef } from "react";

import { useQuery } from "@tanstack/react-query";

import SpecTable from "./SpecTable";
import { fetchSpecs } from "../../api/specsAPI";
import CreateSpec from "./CreateSpec";
import DeleteSpec from "./DeleteSpec";
import { getUserData } from "../../util/storage";

export default function Specs() {
  let emptySpec = {
    description: "",
    number: "",
  };

  const isProcoreUser = getUserData()["is_procore_user"];

  const [dialog, setDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [spec, setSpec] = useState(emptySpec);
  const [selectedSpecs, setSelectedSpecs] = useState([]);
  const [editMode, setEditMode] = useState(null);

  const { data: specs, isFetching } = useQuery({
    queryKey: ["specs"],
    queryFn: fetchSpecs,
    staleTime: 5 * 60 * 1000,
  });

  const dt = useRef(null);

  const openNew = () => {
    setSpec(emptySpec);
    setEditMode(false);
    setDialog(true);
  };

  const hideDialog = () => {
    setDialog(false);
  };

  const hideDeleteDialog = () => {
    setDeleteDialog(false);
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const edit = (spec) => {
    setSpec({ ...spec });
    setEditMode(true);
    setDialog(true);
  };

  const confirmDelete = (spec) => {
    setSpec(spec);
    setDeleteDialog(true);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _spec = { ...spec };

    _spec[`${name}`] = val;

    setSpec(_spec);
  };

  return (
    <>
      <SpecTable
        dt={dt}
        selectedSpecs={selectedSpecs}
        setSelectedSpecs={setSelectedSpecs}
        specs={specs}
        isPending={isFetching}
        edit={edit}
        confirmDelete={confirmDelete}
        exportCSV={exportCSV}
        openNew={openNew}
      />

      {/* TODO: Add MobileDataTable */}

      {!isProcoreUser && (
        <CreateSpec
          dialog={dialog}
          hideDialog={hideDialog}
          spec={spec}
          onInputChange={onInputChange}
          editMode={editMode}
        />
      )}

      {!isProcoreUser && (
        <DeleteSpec
          deleteDialog={deleteDialog}
          hideDeleteDialog={hideDeleteDialog}
          spec={spec}
        />
      )}
    </>
  );
}
