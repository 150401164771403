import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useContext, useState } from "react";
import { queryClient } from "../../api/requestProcessor";
import { useMutation } from "@tanstack/react-query";
import { AppContext } from "../../context/AppProvider";
import { updateDailyReport } from "../../api/dailyReportsAPI";
import { getErrorMessage } from "../../util/helper";
import { InputTextarea } from "primereact/inputtextarea";

export default function NewMeetingDialog({
  isVisible,
  hideDialog,
  dailyReport,
  setDailyReport,
}) {
  const { toast } = useContext(AppContext);

  const [newMeeting, setNewMeeting] = useState({
    meeting: "",
  });

  const { mutate: updateDailyReportMutation, isPending: isPendingUpdate } =
    useMutation({
      mutationFn: updateDailyReport,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["daily-report"] });
        hideDialog();
        setNewMeeting({
          meeting: "",
        });
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Meeting added to the daily report",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "Error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewMeeting((prevNewMeeting) => ({
      ...prevNewMeeting,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleUpdateDailyReport = () => {
    let _dailyReport = { ...dailyReport };
    _dailyReport.meetings = [..._dailyReport.meetings, newMeeting];

    setDailyReport(_dailyReport);
    updateDailyReportMutation({
      dailyReport: { meetings: _dailyReport.meetings },
      dailyReportId: _dailyReport._id,
    });
  };

  const dialogFooter = (
    <>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button
        label={isPendingUpdate ? "Creating" : "Create"}
        icon="pi pi-check"
        onClick={handleUpdateDailyReport}
        loading={isPendingUpdate}
      />
    </>
  );

  return (
    <Dialog
      visible={isVisible}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Meeting Details"
      modal
      className="p-fluid mx-2"
      footer={dialogFooter}
      onHide={hideDialog}
    >
      <div className="field">
        <label htmlFor="meeting" className="font-bold">
          Note
        </label>
        <InputTextarea
          id="meeting"
          name="meeting"
          value={newMeeting.meeting}
          onChange={handleChange}
          required
        />
      </div>
    </Dialog>
  );
}
