import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import CreateCompanyDialog from "./CreateCompanyDialog";
import UpdateCompanyDialog from "./UpdateCompanyDialog";

const CompaniesTable = ({ companies, isLoading, onCreateCompany }) => {
  const [displayDialog, setDisplayDialog] = useState(false);
  const [isUpdateVisible, setIsUpdateVisible] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const openNew = () => {
    setDisplayDialog(true);
  };

  const openUpdateDialog = (rowData) => {
    setSelectedCompany(rowData);
    setIsUpdateVisible(true);
  };

  const hideDialog = () => {
    setDisplayDialog(false);
  };

  const hideUpdateDialog = () => {
    setIsUpdateVisible(false);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex">
        <Button
          type="button"
          icon="pi pi-pencil"
          rounded
          onClick={() => openUpdateDialog(rowData)}
          text
        />
      </div>
    );
  };

  return (
    <div>
      <div className="flex w-full justify-content-between align-items-center">
        <h2>Companies</h2>
        <Button label="New Company" icon="pi pi-plus" onClick={openNew} />
      </div>
      <DataTable value={companies} paginator rows={10} loading={isLoading}>
        <Column
          field="_id"
          header="ID"
          sortable
          filter
          filterPlaceholder="Search by ID"
        ></Column>
        <Column
          field="name"
          header="Name"
          sortable
          filter
          filterPlaceholder="Search by Name"
        ></Column>
        <Column
          field="time_zone"
          header="Time Zone"
          filter
          filterPlaceholder="Search by Time Zone"
        ></Column>
        <Column
          field="is_active"
          header="Active"
          body={(rowData) => (rowData.is_active ? "Yes" : "No")}
          filter
          filterElement={(options) => (
            <Dropdown
              value={options.value}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
              onChange={(e) => options.filterCallback(e.value)}
              placeholder="Filter by Active"
            />
          )}
        ></Column>
        <Column
          field="phone"
          header="Phone"
          filter
          filterPlaceholder="Search by Phone"
        ></Column>
        <Column
          field="created_at"
          header="Created At"
          sortable
          filter
          filterPlaceholder="Search by Created At"
        ></Column>
        <Column
          field="updated_at"
          header="Updated At"
          sortable
          filter
          filterPlaceholder="Search by Updated At"
        ></Column>
        <Column
          field="point_of_contact"
          header="Point of Contact"
          filter
          filterPlaceholder="Search by Point of Contact"
        ></Column>
        <Column
          field="company_type"
          header="Company Type"
          filter
          filterPlaceholder="Search by Company Type"
        ></Column>
        <Column
          headerStyle={{ width: "5rem", textAlign: "center" }}
          bodyStyle={{ textAlign: "center", overflow: "visible" }}
          body={actionBodyTemplate}
        />
      </DataTable>
      <CreateCompanyDialog
        visible={displayDialog}
        onHide={hideDialog}
        onSave={onCreateCompany}
      />
      {selectedCompany && isUpdateVisible && (
        <UpdateCompanyDialog
          visible={isUpdateVisible}
          onHide={hideUpdateDialog}
          company={selectedCompany}
        />
      )}
    </div>
  );
};

export default CompaniesTable;
