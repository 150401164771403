import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  to: [],
  cc: [],
  body: "",
  subject: "",
  send_email: false,
  assign_task: false,
  create_rfi: false,
};

export const inputSlice = createSlice({
  name: "input",
  initialState,
  reducers: {
    updateInput: (state, action) => {
      state[action.payload.field] = action.payload.value;
      Object.assign(state, action.payload);
    },
  },
});

export const { updateInput } = inputSlice.actions;

export default inputSlice.reducer;
