import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function MobileMenu() {
  const [currentURL, setCurrentURL] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setCurrentURL(location.pathname);
  }, [location.pathname]);

  const handleClick = (e, toNavigate) => {
    e.preventDefault();
    navigate(toNavigate);
  };

  return (
    <div className={`sm:hidden ${currentURL === "/chatbots" ? "hidden" : ""}`}>
      <div className={`mobile-menu w-full`}>
        <div className="flex gap-5 justify-content-center pb-2">
          <div>
            <Button
              icon="pi pi-check"
              text={!(currentURL === "/tasks")}
              severity="primary"
              aria-label="User"
              size="large"
              onClick={(e) => handleClick(e, "tasks")}
            />
          </div>
          <div>
            <Button
              icon="pi pi-list"
              text={!(currentURL === "/rfis")}
              severity="primary"
              aria-label="User"
              size="large"
              onClick={(e) => handleClick(e, "rfis")}
            />
          </div>
          <div>
            <Button
              icon="pi pi-file"
              text={!(currentURL === "/submittals")}
              severity="primary"
              aria-label="User"
              size="large"
              onClick={(e) => handleClick(e, "submittals")}
            />
          </div>
          <div>
            <Button
              label="AI"
              text={!(currentURL === "/prompt")}
              severity="info"
              aria-label="User"
              size="large"
              onClick={(e) => handleClick(e, "prompt")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
