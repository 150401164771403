import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useContext, useState } from "react";
import { queryClient } from "../../api/requestProcessor";
import { useMutation } from "@tanstack/react-query";
import { AppContext } from "../../context/AppProvider";
import { updateDailyReport } from "../../api/dailyReportsAPI";
import { getErrorMessage } from "../../util/helper";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";

const initialVisitor = {
  visitor_name: "",
  visitor_note: "",
};

export default function NewVisitorDialog({
  isVisible,
  hideDialog,
  dailyReport,
  setDailyReport,
}) {
  const { toast } = useContext(AppContext);

  const [newVisitor, setNewVisitor] = useState(initialVisitor);

  const { mutate: updateDailyReportMutation, isPending: isPendingUpdate } =
    useMutation({
      mutationFn: updateDailyReport,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["daily-report"] });
        hideDialog();
        setNewVisitor(initialVisitor);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Visitor added to the daily report",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "Error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewVisitor((prevNewVisitor) => ({
      ...prevNewVisitor,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleUpdateDailyReport = () => {
    let _dailyReport = { ...dailyReport };
    _dailyReport.visitors = [..._dailyReport.visitors, newVisitor];

    setDailyReport(_dailyReport);
    updateDailyReportMutation({
      dailyReport: { visitors: _dailyReport.visitors },
      dailyReportId: _dailyReport._id,
    });
  };

  const dialogFooter = (
    <>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button
        label={isPendingUpdate ? "Creating" : "Create"}
        icon="pi pi-check"
        onClick={handleUpdateDailyReport}
        loading={isPendingUpdate}
      />
    </>
  );

  return (
    <Dialog
      visible={isVisible}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Visitor Details"
      modal
      className="p-fluid mx-2"
      footer={dialogFooter}
      onHide={hideDialog}
    >
      <div className="field">
        <label htmlFor="visitor_name" className="font-bold">
          Visitor
        </label>
        <InputText
          id="visitor_name"
          name="visitor_name"
          value={newVisitor.visitor_name}
          onChange={handleChange}
          required
        />
      </div>
      <div className="field">
        <label htmlFor="visitor_note" className="font-bold">
          Note
        </label>
        <InputTextarea
          id="visitor_note"
          name="visitor_note"
          value={newVisitor.visitor_note}
          onChange={handleChange}
          required
        />
      </div>
    </Dialog>
  );
}
