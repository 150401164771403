import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useQuery } from "@tanstack/react-query";
import { fetchAllCompanies } from "../../../api/adminAPI";
import { Checkbox } from "primereact/checkbox";

const initialFormData = {
  email: "",
  password: "",
  first_name: "",
  last_name: "",
  role: null,
  company_id: null,
  is_procore_user: false,
};

const roles = [
  { label: "None", value: 0 },
  { label: "Basic", value: 1 },
  { label: "Premium", value: 2 },
];

const CreateUserDialog = ({ visible, onHide, onSave, isPendingRegister }) => {
  const [formData, setFormData] = useState(initialFormData);

  const { data: companies, isFetching } = useQuery({
    queryKey: ["admin-companies"],
    queryFn: ({ signal }) => fetchAllCompanies(signal),
    staleTime: 5 * 60 * 1000,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = () => {
    onSave(formData);
    onHide();
    setFormData(initialFormData);
  };

  const dialogFooter = (
    <div className="flex justify-content-end">
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={onHide}
        className="button-text"
      />
      <Button
        label="Save"
        icon="pi pi-check"
        onClick={handleSubmit}
        loading={isPendingRegister}
      />
    </div>
  );

  return (
    <Dialog
      header="Create New User"
      visible={visible}
      style={{ width: "50vw" }}
      footer={dialogFooter}
      onHide={onHide}
    >
      <form className="p-fluid">
        <div className="field">
          <label htmlFor="email">Email</label>
          <InputText
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="password">Password</label>
          <InputText
            id="password"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="first_name">First Name</label>
          <InputText
            id="first_name"
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="last_name">Last Name</label>
          <InputText
            id="last_name"
            name="last_name"
            value={formData.last_name}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="role">Role</label>
          <Dropdown
            id="role"
            name="role"
            value={formData.role}
            options={roles}
            onChange={handleChange}
            placeholder="Select a Role"
          />
        </div>
        <div className="field">
          <label htmlFor="company_id">Company</label>
          <Dropdown
            id="company_id"
            name="company_id"
            value={formData.company_id}
            options={companies?.map((company) => ({
              label: company.name,
              value: company._id,
            }))}
            onChange={handleChange}
            placeholder="Select a Company"
            loading={isFetching}
          />
        </div>
        <div className="field-checkbox">
          <Checkbox
            id="is_procore_user"
            name="is_procore_user"
            checked={formData.is_procore_user}
            onChange={handleChange}
          />
          <label htmlFor="is_active">Is Procore User</label>
        </div>
      </form>
    </Dialog>
  );
};

export default CreateUserDialog;
