import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import CustomFileUpload from "../FileUpload/FileUpload";
import { useContext, useState } from "react";
import { AppContext } from "../../context/AppProvider";
import { useMutation } from "@tanstack/react-query";
import { uploadFiles } from "../../api/filesAPI";
import { getErrorMessage } from "../../util/helper";

const UploadPhotosDialog = ({
  isVisible,
  hideDialog,
  dailyReport,
  setDailyReport,
  updateDailyReportMutation,
}) => {
  const [files, setFiles] = useState([]);
  const [uploadReset, setUploadReset] = useState(false);
  const { toast } = useContext(AppContext);

  const { mutateAsync: uploadFilesMutation, isPending: isPendingUpload } =
    useMutation({
      mutationFn: uploadFiles,
      onSuccess: () => {
        setUploadReset(true);
        hideDialog();
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const handleUploadFiles = async () => {
    let updatedAttachments = [...dailyReport?.pictures];

    if (files.length > 0) {
      try {
        const uploadPromises = files.map(async (file) => {
          return await uploadFilesMutation({
            files: [file],
          });
        });

        const responses = await Promise.all(uploadPromises);
        updatedAttachments = [...responses, ...dailyReport?.pictures];
      } catch {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error uploading photos, try renaming the file",
          life: 3000,
        });
        return;
      }
    }

    let _dailyReport = { ...dailyReport };
    _dailyReport.pictures = updatedAttachments;
    setDailyReport(_dailyReport);

    let updatedData = { pictures: _dailyReport["pictures"] };
    updateDailyReportMutation({
      dailyReport: updatedData,
      dailyReportId: dailyReport._id,
    });
  };

  const dialogFooter = (
    <>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button
        label={isPendingUpload ? "Uploading" : "Upload"}
        icon="pi pi-check"
        onClick={handleUploadFiles}
        loading={isPendingUpload}
      />
    </>
  );

  return (
    <Dialog
      visible={isVisible}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Upload Photos"
      modal
      className="p-fluid mx-2"
      footer={dialogFooter}
      onHide={hideDialog}
    >
      <div className="field">
        <div className="mb-3">
          <CustomFileUpload
            accept=""
            onFileChange={setFiles}
            reset={uploadReset}
            setReset={setUploadReset}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default UploadPhotosDialog;
