import axios from "axios";
import { getAuthToken } from "../util/auth";
import API_BASE_URL from "../util/apiConfig";

const token = getAuthToken();

const contactsClient = axios.create({
  baseURL: `${API_BASE_URL}/contacts`,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

contactsClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response.data);
  }
);

export async function fetchContacts({ signal }) {
  const response = await contactsClient.get("", {
    signal,
  });
  return response.data;
}

export async function createContact({ contact }) {
  const response = await contactsClient.post("", contact);
  return response.data;
}

export async function deleteContact({ email }) {
  const response = await contactsClient.delete(`/${email}`);
  return response.data;
}
