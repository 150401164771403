import React, { useEffect, useRef, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Menubar } from "primereact/menubar";
import { Dropdown } from "primereact/dropdown";
import { ProgressBar } from "primereact/progressbar";
import { useQuery } from "@tanstack/react-query";
import { fetchProjects } from "../api/projectsAPI";
import { queryClient } from "../api/requestProcessor";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { AppContext } from "../context/AppProvider";

const Navbar = () => {
  const menuRight = useRef(null);
  const navigate = useNavigate();
  const {
    user,
    projects,
    setProjects,
    selectedProjectName,
    setSelectedProjectName,
    selectedProjectId,
    setSelectedProjectId,
  } = useContext(AppContext);
  const userName = `${user.first_name} ${user.last_name}`;

  const premiumMenuItems = [
    {
      label: "Tasks",
      icon: "pi pi-check",
      command: () => handleTabChange("/tasks"),
    },
    {
      label: "RFIs",
      icon: "pi pi-list",
      command: () => handleTabChange("/rfis"),
    },
    {
      label: "Submittals",
      icon: "pi pi-file",
      command: () => handleTabChange("/submittals"),
    },
    {
      label: "Daily Reports",
      icon: "pi pi-calendar",
      command: () => handleTabChange("/daily-reports"),
    },
    {
      label: "Punch List",
      icon: "pi pi-check-circle",
      command: () => handleTabChange("/punch-lists"),
    },
    {
      label: "Documents",
      icon: "pi pi-folder-open",
      command: () => handleTabChange("/folders"),
    },
    {
      label: "Directory",
      icon: "pi pi-users",
      command: () => handleTabChange("/directory"),
    },
    {
      label: "AI Assistant",
      icon: "pi pi-send",
      command: () => handleTabChange("/prompt"),
    },
    {
      label: "Chatbot",
      icon: "pi pi-comments",
      command: () => handleTabChange("/chatbots"),
    },
  ];

  const basicMenuItems = [
    {
      label: "Tasks",
      icon: "pi pi-check",
      command: () => handleTabChange("/tasks"),
    },
    {
      label: "RFIs",
      icon: "pi pi-list",
      command: () => handleTabChange("/rfis"),
    },
    {
      label: "Submittals",
      icon: "pi pi-file",
      command: () => handleTabChange("/submittals"),
    },
    {
      label: "Punch List",
      icon: "pi pi-check-circle",
      command: () => handleTabChange("/punch-lists"),
    },
  ];

  const {
    data: projectsData,
    isPending,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["projects"],
    queryFn: fetchProjects,
    enabled: false,
  });

  useEffect(() => {
    if (projects.length < 1) {
      refetch();
    }
  });

  useEffect(() => {
    if (projectsData) {
      localStorage.setItem("projects", JSON.stringify(projectsData));
      setProjects(projectsData);
      const now = Date.now();
      const expiresIn = now + 30 * 24 * 60 * 60 * 1000;
      localStorage.setItem("projectsExpiration", JSON.stringify(expiresIn));

      const firstProject = projectsData[0];
      const firstProjectName = firstProject?.name;
      const firstProjectId = firstProject?.id;

      setSelectedProjectName(firstProjectName);
      setSelectedProjectId(firstProjectId);
      localStorage.setItem("savedProjectId", firstProjectId);
      localStorage.setItem("savedProjectName", firstProjectName);
      queryClient.invalidateQueries({ queryKey: ["openItems"] });
    }
  }, [projectsData]);

  const items = [
    {
      icon: "pi pi-home text-xl",
      command: () => handleTabChange("/company-selection"),
    },
    {
      template: (item, options) => {
        return (
          <>
            {isPending && !projects.length ? (
              <>
                <p>Loading...</p>
                <ProgressBar
                  mode="indeterminate"
                  style={{ height: "0.5rem" }}
                />
              </>
            ) : isError ? (
              <div>Error loading projects.</div>
            ) : (
              <Dropdown
                value={selectedProjectId}
                options={
                  projects.map((project) => ({
                    label: project.name,
                    value: project.id,
                  })) || []
                }
                onChange={handleProjectChange}
                placeholder={selectedProjectName || "Select Project"}
                className="custom-dropdown mr-1 no-border mx-2 max-w-12rem"
              />
            )}
          </>
        );
      },
    },
    {
      label: "Project Tools",
      icon: "pi pi-folder",
      items: user.role < 2 ? basicMenuItems : premiumMenuItems,
    },
  ];

  const handleTabChange = (tab) => {
    navigate(tab);
  };

  const handleProjectChange = (e) => {
    const selectedValue = e.value;
    const selectedName = projects.find(
      (project) => project.id === selectedValue
    )?.name;
    setSelectedProjectId(selectedValue);
    localStorage.setItem("savedProjectId", selectedValue);
    localStorage.setItem("savedProjectName", selectedName);
    queryClient.invalidateQueries(["tasks"]);
    queryClient.invalidateQueries(["identifiers"]);
    queryClient.invalidateQueries(["rfis"]);
  };

  const start = () => {
    return (
      <div className="hidden sm:block header-title mx-4">
        <Link style={{ textDecoration: "none", color: "inherit" }} to="/">
          Nicky AI
        </Link>
      </div>
    );
  };

  const handleEndDropdownChange = (e) => {
    const selectedValue = e.value;
    if (selectedValue === "settings") {
      console.log("Settings clicked");
    } else if (selectedValue === "logout") {
      localStorage.clear();
      window.location.reload(true);
    }
  };

  const endMenuItems = [
    {
      label: userName || "Settings",
      icon: "pi pi-fw pi-user",
      items:
        user.role > 1
          ? [
              {
                label: "Settings",
                icon: "pi pi-fw pi-cog",
                command: () => handleTabChange("settings"),
              },
              {
                label: "Logout",
                icon: "pi pi-fw pi-sign-out",
                command: () => handleEndDropdownChange({ value: "logout" }),
              },
            ]
          : [
              {
                label: "Logout",
                icon: "pi pi-fw pi-sign-out",
                command: () => handleEndDropdownChange({ value: "logout" }),
              },
            ],
    },
  ];

  const end = () => {
    return (
      <>
        <Menu
          model={endMenuItems}
          popup
          ref={menuRight}
          id="popup_menu_right"
          popupAlignment="right"
          className="mt-2"
        />
        <Button
          icon="pi pi-fw pi-user"
          label={userName || "Settings"}
          severity="secondary"
          text
          className="hidden sm:block mr-2"
          onClick={(event) => menuRight.current.toggle(event)}
          aria-controls="popup_menu_right"
          aria-haspopup
        />
        <Button
          icon="pi pi-fw pi-user"
          severity="secondary"
          text
          className="sm:hidden"
          onClick={(event) => menuRight.current.toggle(event)}
          aria-controls="popup_menu_right"
          aria-haspopup
        />
      </>
    );
  };

  return (
    <div className="card fixed w-full z-5 top-0">
      <Menubar model={items} start={start} end={end} />
    </div>
  );
};

export default Navbar;
