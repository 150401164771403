import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  project_id: 0,
};

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    updateProject: (state, action) => {
      state[action.payload.field] = action.payload.value;
      Object.assign(state, action.payload);
    },
  },
});

export const { updateProject } = projectSlice.actions;

export default projectSlice.reducer;
