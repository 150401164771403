import React from "react";
import { Menubar } from "primereact/menubar";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";

export default function AdminNavbar() {
  const navigate = useNavigate();

  const handleTabChange = (tab) => {
    navigate(`/admin${tab}`);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload(true);
  };

  const items = [
    {
      label: "Companies",
      icon: "pi pi-home",
      command: () => handleTabChange("/companies"),
    },
    {
      label: "Onboarding",
      icon: "pi pi-users",
      command: () => handleTabChange("/onboarding"),
    },
    {
      label: "Scheduler",
      icon: "pi pi-calendar-plus",
      command: () => handleTabChange("/scheduler"),
    },
    {
      label: "Waitlist",
      icon: "pi pi-list-check",
      command: () => handleTabChange("/waitlist"),
    },
    {
      label: "Invite Codes",
      icon: "pi pi-list-check",
      command: () => handleTabChange("/invite-codes"),
    },
  ];

  const end = (
    <div className="flex align-items-center gap-2">
      <Button
        label="Sign Out"
        icon="pi pi-fw pi-sign-out"
        text
        onClick={handleLogout}
      />
    </div>
  );

  return (
    <div className="card">
      <Menubar model={items} end={end} />
    </div>
  );
}
