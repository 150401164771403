import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Message } from "primereact/message";

import { login, getCurrentUser } from "../api/authAPI";
import { getErrorMessage } from "../util/helper";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const {
    mutate: loginMutation,
    isPending,
    isError,
    error,
  } = useMutation({
    mutationFn: login,
    onSuccess: async (result) => {
      localStorage.setItem("access_token", result.access_token);
      const currentUserData = await getCurrentUserQuery.refetch();
      if (currentUserData.status === "success") {
        localStorage.setItem("userData", JSON.stringify(currentUserData.data));
        window.location.reload(true);
      }
    },
  });

  const getCurrentUserQuery = useQuery({
    queryKey: ["getCurrentUser"],
    queryFn: getCurrentUser,
    enabled: false,
  });

  const handleLogin = (e) => {
    e.preventDefault();

    const loginInfo = {
      username: email,
      password: password,
    };

    loginMutation({ credentials: loginInfo });
  };

  return (
    <div className="centered-content">
      <Card className="nicky-card" title="Login">
        {isError && (
          <Message
            severity="error"
            text={
              error
                ? getErrorMessage(error)
                : "Login failed. Please check your email and password."
            }
            className="mb-5 w-full"
          />
        )}
        <form onSubmit={handleLogin}>
          <div className="flex flex-column gap-4">
            <span className="p-float-label">
              <InputText
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-20rem"
                required
              />
              <label htmlFor="email">Email</label>
            </span>
            <span className="p-float-label">
              <InputText
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-20rem"
                required
              />
              <label htmlFor="password">Password</label>
            </span>
            <div>
              <Button
                className="w-full"
                label={isPending ? "Logging in..." : "Log In"}
                type="submit"
                loading={isPending}
              />
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default Login;
