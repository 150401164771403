import axios from "axios";
import { getAuthToken } from "../util/auth";
import { getProjectId, getUserData } from "../util/storage";
import API_BASE_URL from "../util/apiConfig";
import { generateBaseUrl } from "../util/helper";

const token = getAuthToken();

const filesClient = axios.create({
  baseURL: `${API_BASE_URL}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + token,
  },
});

filesClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response.data);
  }
);

export async function uploadFiles({ files, coversheet_id }) {
  const user = getUserData();
  const isProcoreUser = user["is_procore_user"];
  const projectId = getProjectId();

  const baseUrl = generateBaseUrl(isProcoreUser, projectId, "files");
  const coversheetParam = `?coversheet_id=${
    coversheet_id !== undefined ? coversheet_id : "None"
  }`;
  const fullUrl = baseUrl + coversheetParam;

  const formData = new FormData();
  files.forEach((file, index) => {
    formData.append("files", file, file.name);
  });

  const response = await filesClient.post(fullUrl, formData);
  if (isProcoreUser) {
    return response.data.file_versions[0].prostore_file;
  } else {
    return response.data[0];
  }
}
