import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { useState } from "react";

export default function ChatbotInputArea({ writeMessage, isPending, chatbot }) {
  const [message, setMessage] = useState("");

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      writeMessage(message);
      setMessage("");
    }
  };

  const handleButton = () => {
    writeMessage(message);
    setMessage("");
  };

  if (chatbot && chatbot.is_initialized && chatbot.status === "Created") {
    return (
      <div className="flex justify-content-center align-items-center w-screen gap-2 p-2">
        <InputTextarea
          autoResize
          value={message}
          rows={1}
          cols={80}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          disabled={isPending}
        />
        <div className="flex-none w-3rem h-3rem">
          <Button
            icon="pi pi-arrow-up"
            onClick={handleButton}
            disabled={isPending}
            loading={isPending}
          />
        </div>
      </div>
    );
  }
}
