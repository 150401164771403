import axios from "axios";
import API_BASE_URL from "../util/apiConfig";
import { getCompanyId, getUserData } from "../util/storage";
import { getAuthToken } from "../util/auth";

const token = getAuthToken();

const companiesClient = axios.create({
  baseURL: `${API_BASE_URL}`,
  headers: {
    Authorization: "Bearer " + token,
  },
});

export async function fetchCompanies() {
  const isProcoreUser = getUserData()["is_procore_user"];

  let baseURL;

  if (isProcoreUser) {
    baseURL = "/procore/companies";
  } else {
    baseURL = "/nicky/companies";
  }

  const response = await companiesClient.get(baseURL);
  return response.data;
}

export async function createCompany(company) {
  const response = await companiesClient.post("/nicky/companies", company);
  return response.data;
}

export async function updateCompany({ companyId, company }) {
  const response = await companiesClient.patch(
    `/nicky/companies/${companyId}`,
    company
  );
  return response.data;
}

export async function getCompanyInfo({ signal }) {
  const isProcoreUser = getUserData()["is_procore_user"];
  const companyId = getCompanyId();

  let baseURL;

  if (isProcoreUser) {
    baseURL = `/procore/companies/${companyId}/company-info`;
  } else {
    baseURL = `/nicky/companies/${companyId}/company-info`;
  }

  const response = await companiesClient.get(baseURL, { signal });
  return response.data;
}

export async function setCompanyInfo({ companyInfo }) {
  const isProcoreUser = getUserData()["is_procore_user"];
  const companyId = getCompanyId();

  let baseURL;

  if (isProcoreUser) {
    baseURL = `/procore/companies/${companyId}/company-info`;
  } else {
    baseURL = `/nicky/companies/${companyId}/company-info`;
  }

  const response = await companiesClient.post(baseURL, companyInfo);
  return response.data;
}
