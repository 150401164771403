import { Accordion, AccordionTab } from "primereact/accordion";
import { useContext, useEffect, useState } from "react";
import SubmittalGeneralInformation from "./SubmittalGeneralInformation";
import SubmittalWorkflow from "./NewSubmittalWorkflow";
import { Button } from "primereact/button";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getSubmittal, updateSubmittal } from "../../api/submittalsAPI";
import { queryClient } from "../../api/requestProcessor";
import { uploadFiles } from "../../api/filesAPI";
import SubmittalLoader from "../Loaders/SubmittalLoader";
import MobileSubmittalWorkflow from "./MobileSubmittalWorkflow";
import { AppContext } from "../../context/AppProvider";
import { getErrorMessage } from "../../util/helper";

export default function EditSubmittal() {
  const [newSubmittal, setNewSubmittal] = useState({
    title: "",
    specification_section_id: undefined,
    number: 0,
    revision: 0,
    responsible_contractor_id: undefined,
    received_from_id: undefined,
    submittal_manager_id: undefined,
    status: {},
    received_date: undefined,
    issue_date: undefined,
    distribution_member_ids: [],
    private: false,
    first_name: "",
  });
  const [files, setFiles] = useState([]);
  const [uploadReset, setUploadReset] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [workflow, setWorkflow] = useState([
    { user_ids: [], approver_type: "Submitter", due_date: undefined },
  ]);
  const [label, setLabel] = useState("Save");

  const { toast } = useContext(AppContext);

  const params = useParams();
  const navigate = useNavigate();

  const { data: submittal, isFetching: isFetchingSubmittal } = useQuery({
    queryKey: ["submittals", params.submittalId],
    queryFn: ({ signal }) =>
      getSubmittal({ signal, submittalId: params.submittalId }),
    staleTime: 5 * 60 * 1000,
  });

  const { mutate: updateSubmittalMutation, isPending: isPendingUpdate } =
    useMutation({
      mutationFn: updateSubmittal,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["submittals"] });
        queryClient.invalidateQueries({
          queryKey: ["submittal", newSubmittal.id],
        });
        queryClient.invalidateQueries({
          queryKey: ["submittal_attachments", newSubmittal.id],
        });
        queryClient.invalidateQueries({ queryKey: ["openItems"] });
        navigate("/submittals");
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Submittal Updated",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const { mutateAsync: uploadFilesMutation, isPending: isPendingUpload } =
    useMutation({
      mutationFn: uploadFiles,
      onSuccess: () => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "File Uploaded",
          life: 3000,
        });
        setUploadReset(true);
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  useEffect(() => {
    if (isPendingUpload) {
      setLabel("Uploading Files");
    } else if (isPendingUpdate) {
      setLabel("Saving Changes");
    } else {
      setLabel("Save");
    }
  }, [isPendingUpload, isPendingUpdate]);

  useEffect(() => {
    if (submittal) {
      setNewSubmittal(submittal);
      const transformedWorkflow = submittal.workflow_data.map((innerArray) => ({
        user_ids: innerArray.map((obj) => obj.user.id),
        ...innerArray[0],
      }));
      setWorkflow(transformedWorkflow);
      setAttachments(submittal.attachments);
    }
  }, [submittal]);

  const cancelSubmittalCreation = () => {
    navigate("/submittals");
  };

  const handleUpdateSubmittal = async () => {
    let updatedAttachments = [...attachments];

    if (files.length > 0) {
      const uploadPromises = files.map(async (file) => {
        return await uploadFilesMutation({
          files: [file],
        });
      });

      const responses = await Promise.all(uploadPromises);
      updatedAttachments = [...responses, ...attachments];
    }
    updateSubmittalMutation({
      submittalId: newSubmittal.id,
      submittalData: newSubmittal,
      attachments: updatedAttachments,
      workflow: workflow,
    });
  };

  if (isFetchingSubmittal) {
    return <SubmittalLoader />;
  }

  if (!isFetchingSubmittal) {
    return (
      <>
        <p className="text-3xl w-10 font-medium line-height-1">
          Edit Submittal
        </p>
        <Accordion multiple activeIndex={[0, 1, 2]}>
          <AccordionTab header="GENERAL INFORMATION">
            <SubmittalGeneralInformation
              newSubmittal={newSubmittal}
              setNewSubmittal={setNewSubmittal}
              setFiles={setFiles}
              uploadReset={uploadReset}
              setUploadReset={setUploadReset}
              attachments={attachments}
              setAttachments={setAttachments}
              editMode={true}
            />
          </AccordionTab>
          <AccordionTab className="hidden sm:block" header="SUBMITTAL WORKFLOW">
            <SubmittalWorkflow
              workflow={workflow}
              setWorkflow={setWorkflow}
              editMode={true}
            />
          </AccordionTab>
          <AccordionTab className="sm:hidden" header="SUBMITTAL WORKFLOW">
            <MobileSubmittalWorkflow
              workflow={workflow}
              setWorkflow={setWorkflow}
              editMode={false}
            />
          </AccordionTab>
        </Accordion>
        <div className="flex justify-content-end gap-2 mt-2">
          <Button
            label="Cancel"
            icon="pi pi-times"
            outlined
            onClick={cancelSubmittalCreation}
          />
          <Button
            label={label}
            icon="pi pi-check"
            onClick={handleUpdateSubmittal}
            loading={isPendingUpdate || isPendingUpload}
            disabled={isPendingUpdate || isPendingUpload}
            className="right-0"
          />
        </div>
      </>
    );
  }
}
