// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-panel .p-panel-header {
  padding: 0.75rem !important;
}

.p-panel .p-panel-content {
  padding: 0 !important;
}

.file-upload-draggable {
  padding: 0.75rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/FileUpload/FileUpload.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;AAC7B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".p-panel .p-panel-header {\n  padding: 0.75rem !important;\n}\n\n.p-panel .p-panel-content {\n  padding: 0 !important;\n}\n\n.file-upload-draggable {\n  padding: 0.75rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
