import { useState, useEffect, useRef, useContext } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Accordion, AccordionTab } from "primereact/accordion";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";

import { useQuery } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";

import { fetchIdentifiers } from "../../api/projectsAPI";
import {
  createRfi,
  updateRfi,
  getRfi,
  getNextRfiNumber,
} from "../../api/rfisAPI";
import { uploadFiles } from "../../api/filesAPI";
import { queryClient } from "../../api/requestProcessor";
import RFIAttachments from "../Attachments";
import CustomFileUpload from "../FileUpload/FileUpload";
import CustomLoader from "../Loaders/CustomLoader";
import { fetchSpecs } from "../../api/specsAPI";
import { getUserData } from "../../util/storage";
import { Message } from "primereact/message";
import { AppContext } from "../../context/AppProvider";
import { getErrorMessage } from "../../util/helper";

export default function CreateRFI({
  rfiDialog,
  hideDialog,
  rfi,
  onInputChange,
  editRFIMode,
  rfiId,
  setRFI,
}) {
  const isProcoreUser = getUserData()["is_procore_user"];

  const [files, setFiles] = useState([]);
  const [uploadReset, setUploadReset] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [label, setLabel] = useState("Save");
  const [validationErrorMessage, setValidationErrorMessage] = useState("");
  const [isClosed, setIsClosed] = useState(false);

  const filterRef = useRef();

  const { toast } = useContext(AppContext);

  const { data: identifiers } = useQuery({
    queryKey: ["identifiers"],
    queryFn: fetchIdentifiers,
  });

  /* TODO: It will be activated after implementing specs for nicky
  const { data: specs } = useQuery({
    queryKey: ["specs"],
    queryFn: fetchSpecs,
  }); */

  const { data: nextRfiNumber, isFetching: isFetchingRfiNextNumber } = useQuery(
    {
      queryKey: ["nextRfiNumber"],
      queryFn: ({ signal }) =>
        getNextRfiNumber({
          signal,
        }),
      enabled: !editRFIMode,
    }
  );

  const { data: RFIData, isPending: isPendingRFI } = useQuery({
    queryKey: ["rfi", rfiId],
    queryFn: ({ signal }) => getRfi({ signal, rfiId: rfiId }),
    enabled: !!rfiId,
    staleTime: 5 * 60 * 1000,
  });

  useEffect(() => {
    if (isProcoreUser) {
      if (rfi.questions && editRFIMode && rfi.questions[0].attachments) {
        setAttachments(rfi.questions[0].attachments);
      } else {
        setAttachments([]);
      }
    } else {
      if (rfi.attachments && editRFIMode) {
        setAttachments(rfi.attachments);
      } else {
        setAttachments([]);
      }
    }
  }, [setAttachments, rfi.questions, rfi, editRFIMode]);

  useEffect(() => {
    if (RFIData) {
      setRFI(RFIData);
    }
  }, [RFIData, setRFI]);

  useEffect(() => {
    if (nextRfiNumber && !editRFIMode) {
      setRFI({ ...rfi, number: nextRfiNumber.next_number.toString() });
    }
  }, [nextRfiNumber]);

  useEffect(() => {
    if (editRFIMode && rfi.status === "closed") {
      setIsClosed(true);
    } else {
      setIsClosed(false);
    }
  }, [rfi, editRFIMode]);

  const { mutate: createRfiMutation, isPending: isPendingCreation } =
    useMutation({
      mutationFn: createRfi,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["rfis"] });
        queryClient.invalidateQueries({ queryKey: ["openItems"] });
        hideDialog();
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "RFI Created",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const { mutate: updateRfiMutation, isPending: isPendingUpdate } = useMutation(
    {
      mutationFn: updateRfi,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["rfis"] });
        queryClient.invalidateQueries({ queryKey: ["rfi", rfiId] });
        queryClient.invalidateQueries({ queryKey: ["openItems"] });
        hideDialog();
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "RFI Updated",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    }
  );

  const { mutateAsync: uploadFilesMutation, isPending: isPendingUpload } =
    useMutation({
      mutationFn: uploadFiles,
      onSuccess: () => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "File Uploaded",
          life: 3000,
        });
        setUploadReset(true);
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  useEffect(() => {
    if (isPendingUpload) {
      setLabel("Uploading Files");
    } else if (isPendingCreation || isPendingUpdate) {
      setLabel("Saving Changes");
    } else {
      setLabel("Save");
    }
  }, [isPendingUpload, isPendingCreation, isPendingUpdate]);

  const checkValidation = () => {
    if (!rfi.subject) {
      setValidationErrorMessage("RFI Subject area is required!");
      return false;
    } else if (
      isProcoreUser &&
      editRFIMode &&
      !rfi.questions[0].plain_text_body
    ) {
      setValidationErrorMessage("RFI Body area is required!");
      return false;
    } else if (isProcoreUser && !editRFIMode && !rfi.questions) {
      setValidationErrorMessage("RFI Body area is required!");
      return false;
    } else if (!isProcoreUser && !rfi.rfi_body) {
      setValidationErrorMessage("RFI Body area is required!");
      return false;
    }
    return true;
  };

  const saveRFI = async () => {
    if (checkValidation()) {
      let updatedAttachments = [...attachments];

      if (files.length > 0) {
        const uploadPromises = files.map(async (file) => {
          return await uploadFilesMutation({
            files: [file],
          });
        });

        const responses = await Promise.all(uploadPromises);
        updatedAttachments = [...responses, ...attachments];
      }

      const rfiData = { ...rfi };
      if (editRFIMode) {
        updateRfiMutation({
          rfiId: rfiData.id,
          rfiData: rfiData,
          attachments: updatedAttachments,
        });
      } else {
        createRfiMutation({
          rfiData: rfiData,
          attachments: updatedAttachments,
        });
      }

      setValidationErrorMessage("");
    }
  };

  const onFileChange = (files) => {
    setFiles(files);
  };

  const rfiDialogFooter = (
    <>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button
        label={label}
        icon="pi pi-check"
        onClick={saveRFI}
        loading={isPendingCreation || isPendingUpdate || isPendingUpload}
        disabled={isPendingCreation || isPendingUpdate || isPendingUpload}
      />
    </>
  );
  return (
    <Dialog
      visible={rfiDialog}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="RFI Details"
      modal
      className="p-fluid"
      footer={rfiDialogFooter}
      onHide={hideDialog}
    >
      {validationErrorMessage && (
        <Message
          severity="error"
          text={validationErrorMessage}
          className="mb-1"
        />
      )}
      {isPendingRFI && editRFIMode ? (
        <CustomLoader />
      ) : (
        <>
          <div className="field mr-1">
            <label htmlFor="number" className="font-bold">
              Number
            </label>
            <span className="p-input-icon-right">
              {isFetchingRfiNextNumber && (
                <i className="pi pi-spin pi-spinner" />
              )}
              <InputText
                id="number"
                value={rfi.number}
                onChange={(e) => onInputChange(e, "number")}
                required
                disabled={isFetchingRfiNextNumber || isClosed}
              />
            </span>
          </div>
          <div className="field">
            <label htmlFor="assignee_ids" className="font-bold">
              Assignees
            </label>
            <MultiSelect
              value={rfi.assignees}
              id="assignees"
              options={identifiers}
              optionLabel="name"
              onChange={(e) => onInputChange(e, "assignees")}
              placeholder="Assignees"
              className="w-full mb-5"
              filter
              display="chip"
              panelClassName="max-w-16rem"
              disabled={isClosed}
            />
          </div>
          <div className="field">
            <label htmlFor="subject" className="font-bold">
              Subject
            </label>
            <InputText
              id="subject"
              value={rfi.subject}
              onChange={(e) => onInputChange(e, "subject")}
              required
              autoFocus
              disabled={isClosed}
            />
          </div>
          <div className="field">
            <label htmlFor="body" className="font-bold">
              Body
            </label>
            <InputTextarea
              id="body"
              value={
                editRFIMode
                  ? isProcoreUser
                    ? rfi["questions"][0]["plain_text_body"]
                    : rfi["rfi_body"]
                  : rfi.question
              }
              onChange={(e) =>
                onInputChange(e, isProcoreUser ? "questions" : "rfi_body")
              }
              required
              rows={5}
              disabled={isClosed}
            />
          </div>
          <Accordion className="mb-2">
            <AccordionTab header="Additional Settings">
              <div className="field">
                <label htmlFor="received_from" className="font-bold">
                  Received From
                </label>
                <Dropdown
                  id="received_from"
                  value={rfi.received_from}
                  onChange={(e) => onInputChange(e, "received_from")}
                  options={identifiers}
                  optionLabel="name"
                  placeholder="Select a Person"
                  filter
                  disabled={isClosed}
                />
              </div>
              {/* TODO: It will be activated after implementing specs for nicky
              <div className="field">
                <label htmlFor="specification_section_id" className="font-bold">
                  Spec Section
                </label>
                <Dropdown
                  id="specification_section_id"
                  value={rfi.specification_section_id}
                  onChange={(e) => onInputChange(e, "specification_section_id")}
                  options={specs}
                  optionLabel="label"
                  placeholder="Select a Spec Section"
                  filter
                  optionValue="id"
                />
              </div> */}
              <div className="field">
                <label htmlFor="distribution_list" className="font-bold">
                  Distribution List
                </label>
                <MultiSelect
                  value={rfi.distribution_list}
                  id="distribution_list"
                  options={identifiers}
                  optionLabel="name"
                  onChange={(e) => onInputChange(e, "distribution_list")}
                  placeholder="Distribution List"
                  className="w-full mb-5"
                  filter
                  display="chip"
                  ref={filterRef}
                  panelClassName="max-w-16rem"
                  disabled={isClosed}
                />
              </div>
            </AccordionTab>
          </Accordion>
          {editRFIMode && (
            <>
              <div className="field">
                {isProcoreUser ? (
                  <>
                    <label htmlFor="status" className="font-bold">
                      Status
                    </label>
                    <Dropdown
                      id="status"
                      value={rfi.accepted}
                      onChange={(e) => onInputChange(e, "accepted")}
                      options={[
                        { label: "Open", value: false },
                        { label: "Closed", value: true },
                      ]}
                      placeholder="Select Status"
                    />
                  </>
                ) : (
                  <>
                    <label htmlFor="status" className="font-bold">
                      Status
                    </label>
                    <Dropdown
                      value={rfi.status}
                      onChange={(e) => onInputChange(e, "status")}
                      options={[
                        { label: "Open", value: "open" },
                        { label: "Closed", value: "closed" },
                      ]}
                      placeholder="Select Status"
                      disabled={isClosed}
                    />
                  </>
                )}
              </div>
            </>
          )}

          {attachments && attachments.length ? (
            <div className="field">
              <label htmlFor="rfi-attachments" className="font-bold">
                Attachments
              </label>
              <RFIAttachments
                id={"rfi-attachments"}
                attachments={attachments}
                setAttachments={setAttachments}
                isDisabled={isClosed}
              />
            </div>
          ) : (
            <></>
          )}
          {!isClosed && (
            <CustomFileUpload
              accept=""
              onFileChange={onFileChange}
              reset={uploadReset}
              setReset={setUploadReset}
            />
          )}
        </>
      )}
    </Dialog>
  );
}
