import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import CreateSchedulerJobDialog from "./CreateSchedulerJobDialog";
import DeleteSchedulerJobDialog from "./DeleteSchedulerJobDialog";

const SchedulerTable = ({ schedulerJobs, isLoading, users }) => {
  const [displayDialog, setDisplayDialog] = useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [selectedSchedulerJob, setSelectedSchedulerJob] = useState(null);

  const openNew = () => {
    setDisplayDialog(true);
  };

  const openUpdateDialog = (rowData) => {
    setSelectedSchedulerJob(rowData);
    setIsDeleteVisible(true);
  };

  const hideDialog = () => {
    setDisplayDialog(false);
  };

  const hideDeleteDialog = () => {
    setIsDeleteVisible(false);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex">
        <Button
          type="button"
          severity="danger"
          icon="pi pi-trash"
          rounded
          onClick={() => openUpdateDialog(rowData)}
          text
        />
      </div>
    );
  };

  return (
    <div>
      <div className="flex w-full justify-content-between align-items-center">
        <h2>Schedulers</h2>
        <Button label="New Scheduler Job" icon="pi pi-plus" onClick={openNew} />
      </div>
      <DataTable value={schedulerJobs} paginator rows={10} loading={isLoading}>
        <Column
          field="frequency"
          header="Frequency"
          sortable
          filter
          filterPlaceholder="Search by Frequency"
        ></Column>
        <Column
          field="frequency_unit"
          header="Unit"
          sortable
          filter
          filterPlaceholder="Search by Unit"
        ></Column>
        <Column
          field="job_id"
          header="Job ID"
          filter
          filterPlaceholder="Search by Job ID"
        ></Column>
        <Column
          field="start_date"
          header="Start Date"
          filter
          filterPlaceholder="Search by Start Date"
        ></Column>
        <Column
          field="type"
          header="Job Type"
          filter
          filterPlaceholder="Search by Job Type"
        ></Column>
        <Column
          field="is_active"
          header="Is Active"
          filter
          filterPlaceholder="Search by Activity"
        ></Column>
        <Column
          headerStyle={{ width: "5rem", textAlign: "center" }}
          bodyStyle={{ textAlign: "center", overflow: "visible" }}
          body={actionBodyTemplate}
        />
      </DataTable>
      <CreateSchedulerJobDialog
        visible={displayDialog}
        onHide={hideDialog}
        users={users}
      />
      {selectedSchedulerJob && isDeleteVisible && (
        <DeleteSchedulerJobDialog
          visible={isDeleteVisible}
          onHide={hideDeleteDialog}
          schedulerJob={selectedSchedulerJob}
        />
      )}
    </div>
  );
};

export default SchedulerTable;
