import React, { useContext, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createInviteCode } from "../../../api/adminAPI";
import { AppContext } from "../../../context/AppProvider";

export const CreateInviteCode = () => {
  const [email, setEmail] = useState("");
  const { toast } = useContext(AppContext);
  const queryClient = useQueryClient();

  const createMutation = useMutation({
    mutationFn: (data) => createInviteCode(data),
    onSuccess: (response) => {
      queryClient.invalidateQueries(["invite-codes"]);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: response.message,
        life: 3000,
      });
      setEmail("");
    },
    onError: (error) => {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail:
          error.message || "An error occurred while creating the invite code",
        life: 3000,
      });
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) return;

    createMutation.mutate({ email });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-column align-items-start"
    >
      <div className="field w-full">
        <span className="p-float-label w-full">
          <InputText
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full"
            type="email"
            required
          />
          <label htmlFor="email">Email</label>
        </span>
      </div>
      <Button
        type="submit"
        label="Create Invite Code"
        icon="pi pi-plus"
        loading={createMutation.isLoading}
        className="mt-3"
      />
    </form>
  );
};
