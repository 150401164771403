import axios from "axios";
import { getAuthToken } from "../util/auth";
import { getCompanyId, getProjectId } from "../util/storage";
import API_BASE_URL from "../util/apiConfig";

const token = getAuthToken();

const inputClient = axios.create({
  baseURL: `${API_BASE_URL}/input`,
  headers: {
    Authorization: "Bearer " + token,
  },
});

inputClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response.data);
  }
);

export async function postPrompt({ prompt }) {
  const projectId = getProjectId();
  const companyId = getCompanyId();

  const promptData = {
    prompt: prompt,
    project_id: projectId,
  };

  const response = await inputClient.post("/parser", promptData, {
    params: {
      company_id: companyId,
    },
  });

  return response.data;
}

export async function postInput({ inputData, attachments }) {
  const projectId = getProjectId();
  const companyId = getCompanyId();

  inputData["attachments"] = attachments;

  const payload = { user_input: inputData, project_id: projectId };

  const response = await inputClient.post("/handler", payload, {
    params: {
      company_id: companyId,
    },
  });
  return response.data;
}
