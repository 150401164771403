import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { fetchCompanyVendors } from "../../api/vendorsAPI";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import NewVendorDialog from "../Vendor/NewVendorDialog";
import { createUser } from "../../api/usersAPI";
import { queryClient } from "../../api/requestProcessor";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppProvider";
import { getErrorMessage } from "../../util/helper";

export default function NewUser() {
  const [newUser, setNewUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    vendor_id: "",
  });
  const [isVisible, setIsVisible] = useState(false);

  const { toast } = useContext(AppContext);

  const navigate = useNavigate();

  const {
    data: vendors,
    isFetching: isFetchingVendors,
    error,
  } = useQuery({
    queryKey: ["companyVendors"],
    queryFn: fetchCompanyVendors,
    staleTime: 5 * 60 * 1000,
  });

  const { mutate: createUserMutation, isPending: isPendingCreation } =
    useMutation({
      mutationFn: createUser,
      onSuccess: (response) => {
        queryClient.invalidateQueries({ queryKey: ["users"] });
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: response.message,
          life: 3000,
        });
        navigate("/directory");
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _newUser = { ...newUser };

    _newUser[`${name}`] = val;

    setNewUser(_newUser);
  };

  const openCompanyDialog = () => {
    setIsVisible(true);
  };

  const hideDialog = () => {
    setIsVisible(false);
  };

  const handleCreateUser = () => {
    createUserMutation({ newUser: newUser });
  };

  const cancelUserCreation = () => {
    navigate("/directory");
  };

  const panelFooterTemplate = () => {
    return (
      <div className="py-2 px-3">
        <Button
          label="Create New"
          loading={isFetchingVendors}
          onClick={openCompanyDialog}
          className="w-full"
          size="small"
        />
      </div>
    );
  };

  return (
    <>
      <div>
        <p className="text-3xl w-10 font-medium line-height-1">
          Create New User
        </p>
        <div className="grid nested-grid">
          <div className="col-12 md:col-8">
            <Divider align="left">
              <div className="inline-flex align-items-center">
                <span className="p-tag">
                  <i className="pi pi-user mr-2"></i>
                  <b>Personal Information</b>
                </span>
              </div>
            </Divider>
            <div className="grid">
              <div className="col-12 md:col-6">
                <div className="flex flex-column gap-2">
                  <label htmlFor="first_name">First Name</label>
                  <InputText
                    id="first_name"
                    value={newUser.first_name}
                    onChange={(e) => onInputChange(e, "first_name")}
                  />
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className="flex flex-column gap-2">
                  <label htmlFor="last_name">Last Name</label>
                  <InputText
                    id="last_name"
                    value={newUser.last_name}
                    onChange={(e) => onInputChange(e, "last_name")}
                  />
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className="flex flex-column gap-2">
                  <label htmlFor="email">Email</label>
                  <InputText
                    id="email"
                    value={newUser.email}
                    onChange={(e) => onInputChange(e, "email")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 md:col-4">
            <Divider align="left">
              <div className="inline-flex align-items-center">
                <span className="p-tag">
                  <i className="pi pi-user mr-2"></i>
                  <b>Company Information</b>
                </span>
              </div>
            </Divider>
            <div className="grid">
              <div className="col-12">
                <div className="flex flex-column gap-2">
                  <label htmlFor="company">Company</label>
                  <Dropdown
                    value={newUser.vendor_id}
                    onChange={(e) => onInputChange(e, "vendor_id")}
                    options={vendors}
                    optionLabel="name"
                    optionValue="_id"
                    placeholder="Select a Company"
                    className="w-full"
                    disabled={isFetchingVendors}
                    panelFooterTemplate={panelFooterTemplate}
                    filter
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col-12">
            <div className="flex justify-content-end gap-2">
              <Button
                label="Cancel"
                icon="pi pi-times"
                outlined
                onClick={cancelUserCreation}
              />
              <Button
                label={isPendingCreation ? "Creating" : "Create"}
                icon="pi pi-check"
                onClick={handleCreateUser}
                loading={isPendingCreation}
                className="right-0"
              />
            </div>
          </div>
        </div>
        {isVisible && (
          <NewVendorDialog
            isVisible={isVisible}
            hideDialog={hideDialog}
            toast={toast}
          />
        )}
      </div>
    </>
  );
}
