import axios from "axios";
import { getAuthToken } from "../util/auth";
import API_BASE_URL from "../util/apiConfig";
import { getCompanyId, getProjectId } from "../util/storage";

const token = getAuthToken();

const foldersClient = axios.create({
  baseURL: `${API_BASE_URL}/folders`,
  headers: {
    Authorization: "Bearer " + token,
  },
});

export async function fetchRootFolders({ signal }) {
  const projectId = getProjectId();
  const companyId = getCompanyId();

  const response = await foldersClient.get("", {
    params: {
      project_id: projectId,
      company_id: companyId,
    },
    signal,
  });

  return response.data;
}

export async function fetchFolders({ signal, folderId }) {
  const projectId = getProjectId();
  const companyId = getCompanyId();

  const response = await foldersClient.get(`/${folderId}`, {
    params: {
      project_id: projectId,
      company_id: companyId,
    },
    signal,
  });

  return response.data;
}

export async function createFolder({ folderData }) {
  const projectId = getProjectId();
  const companyId = getCompanyId();
  folderData.project_id = projectId;

  const response = await foldersClient.post("", folderData, {
    params: {
      company_id: companyId,
    },
  });
  return response.data;
}

export async function uploadFilesToFolder({ files, folderId }) {
  const projectId = getProjectId();
  const companyId = getCompanyId();

  const formData = new FormData();
  files.forEach((file, index) => {
    formData.append("files", file, file.name);
  });

  const response = await foldersClient.post(`/${folderId}/files`, formData, {
    params: {
      project_id: projectId,
      company_id: companyId,
    },
  });

  return response.data;
}

export async function deleteFolders({ folders }) {
  const projectId = getProjectId();
  const companyId = getCompanyId();

  const response = await foldersClient.patch("", folders, {
    params: {
      project_id: projectId,
      company_id: companyId,
    },
  });

  return response.data;
}

export async function renameFileOrFolder({ fileOrFolderId, fileOrFolder }) {
  const projectId = getProjectId();
  const companyId = getCompanyId();

  const response = await foldersClient.patch(
    `/${fileOrFolderId}/rename`,
    fileOrFolder,
    {
      params: {
        project_id: projectId,
        company_id: companyId,
      },
    }
  );

  return response.data;
}
