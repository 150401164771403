import { Skeleton } from "primereact/skeleton";
import React from "react";

function DailyReportLoader() {
  return (
    <div>
      <div className="grid">
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <Skeleton height="3rem" className="mb-2"></Skeleton>
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <Skeleton height="3rem" className="mb-2"></Skeleton>
          </div>
        </div>
        <div className="col-offset-3 col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <Skeleton height="3rem" className="mb-2"></Skeleton>
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
          </div>
        </div>
      </div>
      <div className="flex flex-column gap-2">
        <Skeleton width="100%" height="10rem"></Skeleton>
        <Skeleton width="100%" height="10rem"></Skeleton>
        <Skeleton width="100%" height="10rem"></Skeleton>
        <Skeleton width="100%" height="10rem"></Skeleton>
      </div>
    </div>
  );
}

export default DailyReportLoader;
