import React, { useState, useEffect } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useQuery } from "@tanstack/react-query";
import { fetchIdentifiers } from "../../api/projectsAPI";

export default function ConsultantTable({
  dt,
  selectedConsultants,
  setSelectedConsultants,
  consultants,
  isPending,
  edit,
  confirmDelete,
  exportCSV,
  openNew,
}) {
  const [filters, setFilters] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [localConsultants, setLocalConsultants] = useState([]);

  useEffect(() => {
    initFilters();
  }, []);

  const { data: identifiers } = useQuery({
    queryKey: ["identifiers"],
    queryFn: fetchIdentifiers,
    staleTime: 60 * 60 * 1000,
  });

  useEffect(() => {
    if (consultants && identifiers) {
      const namesMap = new Map();
      identifiers.forEach((item) => {
        namesMap.set(item.id, item.name);
      });
      let _consultants = [...consultants];
      _consultants.forEach((element) => {
        const project_managers = element.project_manager_ids
          ? element.project_manager_ids
              .map((id) => namesMap.get(id))
              .join(",\n")
          : "";

        const distribution_list = element.distribution_ids
          ? element.distribution_ids.map((id) => namesMap.get(id)).join(",\n")
          : "";

        element.project_managers = project_managers;
        element.distribution_list = distribution_list;
      });
      setLocalConsultants(_consultants);
    }
  }, [consultants, identifiers]);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      role: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      project_managers: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      distribution_list: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
    setGlobalFilterValue("");
  };

  const clearFilter = () => {
    initFilters();
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <div>
          <Button
            label="New"
            icon="pi pi-plus"
            severity="success"
            onClick={openNew}
            className="mr-2"
          />
          <Button
            label="Export"
            icon="pi pi-upload"
            className="p-button-help"
            onClick={() =>
              exportCSV(selectedConsultants.length > 0 ? true : false)
            }
          />
        </div>
        <div>
          <Button
            type="button"
            icon="pi pi-filter-slash"
            label="Clear Filters"
            outlined
            onClick={clearFilter}
            className="mr-2"
          />
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
      </div>
    );
  };

  const header = renderHeader();

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-pencil"
          rounded
          text
          className="mr-2"
          onClick={() => edit(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          text
          severity="danger"
          onClick={() => confirmDelete(rowData)}
        />
      </>
    );
  };

  return (
    <DataTable
      ref={dt}
      selection={selectedConsultants}
      onSelectionChange={(e) => setSelectedConsultants(e.value)}
      dataKey="_id"
      value={localConsultants}
      paginator
      showGridlines
      rows={10}
      loading={isPending}
      filters={filters}
      globalFilterFields={["role", "project_managers", "distribution_list"]}
      header={header}
      emptyMessage="No consultant found."
      className="hidden sm:block"
    >
      <Column selectionMode="multiple" exportable={false}></Column>
      <Column
        header="Role"
        field="role"
        filter
        filterPlaceholder="Search by role"
        style={{ minWidth: "12rem" }}
      />
      <Column
        header="Project Managers"
        field="project_managers"
        style={{ minWidth: "12rem" }}
        filter
        filterPlaceholder="Search by project manager"
      />
      <Column
        header="Distribution List"
        field="distribution_list"
        style={{ minWidth: "12rem" }}
        filter
        filterPlaceholder="Search by distribution name"
      />
      <Column
        body={actionBodyTemplate}
        exportable={false}
        style={{ minWidth: "8rem", maxWidth: "8rem" }}
      ></Column>
    </DataTable>
  );
}
