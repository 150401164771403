import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppProvider";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../api/requestProcessor";
import { getErrorMessage } from "../util/helper";
import { uploadFiles } from "../api/filesAPI";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { createCommitment } from "../api/commitmentsAPI";
import NewCommitmentForm from "../components/Commitments/NewCommitmentForm";

export default function NewCommitmentPage() {
  const [commitment, setCommitment] = useState({
    commitment_type: "",
    contract_company_id: "",
    title: "",
    status: null,
    description: undefined,
    executed: false,
    ssov_status: null,
    original_contract_amount: null,
    attachments: [],
    contract_date: null,
    inclusions: null,
    exclusions: null,
    visibility: null,
    contract_start_date: null,
    contract_estimated_completion_date: null,
  });
  const [files, setFiles] = useState([]);
  const [uploadReset, setUploadReset] = useState(false);
  const [label, setLabel] = useState("Save");

  const { toast } = useContext(AppContext);
  const navigate = useNavigate();

  const { mutate: createCommitmentMutation, isPending: isPendingCreation } =
    useMutation({
      mutationFn: createCommitment,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["commitments"] });
        navigate("/commitments");
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Commitment Created",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const { mutateAsync: uploadFilesMutation, isPending: isPendingUpload } =
    useMutation({
      mutationFn: uploadFiles,
      onSuccess: () => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "File Uploaded",
          life: 3000,
        });
        setUploadReset(true);
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  useEffect(() => {
    if (isPendingUpload) {
      setLabel("Uploading Files");
    } else if (isPendingCreation) {
      setLabel("Saving Changes");
    } else {
      setLabel("Create");
    }
  }, [isPendingUpload, isPendingCreation]);

  const cancelCommitmentCreation = () => {
    navigate("/commitments");
  };

  const printErrorMessage = (errorMessage) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: errorMessage,
      life: 3000,
    });
  };

  const checkValidation = () => {
    if (!commitment.commitment_type) {
      printErrorMessage("Type area is required!");
      return false;
    } else if (!commitment.contract_company_id) {
      printErrorMessage("Contract company area is required!");
      return false;
    } else if (!commitment.title) {
      printErrorMessage("Title area is required!");
      return false;
    }
    return true;
  };

  const handleCreateCommitment = async () => {
    if (checkValidation()) {
      let updatedAttachments = [];

      if (files.length > 0) {
        const uploadPromises = files.map(async (file) => {
          return await uploadFilesMutation({
            files: [file],
          });
        });

        const responses = await Promise.all(uploadPromises);
        updatedAttachments = [...responses];
      }

      const commitmentData = { ...commitment, attachments: updatedAttachments };

      createCommitmentMutation(commitmentData);
    }
  };

  return (
    <>
      <div className="flex justify-content-between align-items-center mb-3">
        <p className="text-3xl font-medium line-height-1 m-0">
          Create New Commitment
        </p>
        <div className="flex gap-2">
          <Button
            label="Cancel"
            icon="pi pi-times"
            outlined
            onClick={cancelCommitmentCreation}
          />
          <Button
            label={label}
            Create
            New
            Commitment
            icon="pi pi-check"
            onClick={handleCreateCommitment}
            loading={isPendingCreation || isPendingUpload}
            disabled={isPendingCreation || isPendingUpload}
          />
        </div>
      </div>

      <NewCommitmentForm
        commitment={commitment}
        setCommitment={setCommitment}
        files={files}
        setFiles={setFiles}
        setUploadReset={uploadReset}
      />

      <div className="flex justify-content-end gap-2 mt-2">
        <Button
          label="Cancel"
          icon="pi pi-times"
          outlined
          onClick={cancelCommitmentCreation}
        />
        <Button
          label={label}
          icon="pi pi-check"
          onClick={handleCreateCommitment}
          loading={isPendingCreation || isPendingUpload}
          disabled={isPendingCreation || isPendingUpload}
          className="right-0"
        />
      </div>
    </>
  );
}
