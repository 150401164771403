import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { useQuery } from "@tanstack/react-query";
import { fetchAllCompanies } from "../../../api/adminAPI";
import { InputNumber } from "primereact/inputnumber";
import { fetchTimeZones } from "../../../api/timeZonesAPI";

const initialFormData = {
  name: "",
  display_name: "",
  company_id: null,
  project_number: null,
  address: "",
  city: "",
  state_code: "",
  country_code: "",
  zip: "",
  time_zone: "",
  county: "",
  is_active: true,
  phone: "",
  start_date: null,
  completion_date: null,
  total_value: null,
  is_demo: false,
  abbreviation: "",
};

const CreateProjectDialog = ({ visible, onHide, onSave }) => {
  const [formData, setFormData] = useState(initialFormData);

  const { data: companies, isFetching: isFetchingCompanies } = useQuery({
    queryKey: ["admin-companies"],
    queryFn: ({ signal }) => fetchAllCompanies(signal),
    staleTime: 5 * 60 * 1000,
  });

  const { data: timeZones, isFetching: isFetchingTimeZones } = useQuery({
    queryKey: ["time-zones"],
    queryFn: ({ signal }) => fetchTimeZones(signal),
    staleTime: 5 * 60 * 1000,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleIntegerChange = (e) => {
    const { name, value } = e;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleDateChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    onSave(formData);
    onHide();
    setFormData(initialFormData);
  };

  const dialogFooter = (
    <div className="flex justify-content-end">
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={onHide}
        className="button-text"
      />
      <Button label="Save" icon="pi pi-check" onClick={handleSubmit} />
    </div>
  );

  return (
    <Dialog
      header="Create New Project"
      visible={visible}
      style={{ width: "50vw" }}
      footer={dialogFooter}
      onHide={onHide}
    >
      <form className="p-fluid">
        <div className="field">
          <label htmlFor="name">Name</label>
          <InputText
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="display_name">Display Name</label>
          <InputText
            id="display_name"
            name="display_name"
            value={formData.display_name}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="company_id">Company</label>
          <Dropdown
            id="company_id"
            name="company_id"
            value={formData.company_id}
            options={companies?.map((company) => ({
              label: company.name,
              value: company._id,
            }))}
            onChange={handleChange}
            placeholder="Select a Company"
            loading={isFetchingCompanies}
            filter
          />
        </div>
        <div className="field">
          <label htmlFor="time_zone">Time Zone</label>
          <Dropdown
            id="time_zone"
            name="time_zone"
            value={formData.time_zone}
            options={timeZones?.map((tz) => ({
              label: tz,
              value: tz,
            }))}
            onChange={handleChange}
            placeholder="Select a Time Zone"
            loading={isFetchingTimeZones}
            filter
          />
        </div>
        <div className="field">
          <label htmlFor="project_number">Project Number</label>
          <InputNumber
            id="project_number"
            name="project_number"
            value={formData.project_number}
            onChange={handleIntegerChange}
          />
        </div>
        <div className="field">
          <label htmlFor="address">Address</label>
          <InputText
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="city">City</label>
          <InputText
            id="city"
            name="city"
            value={formData.city}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="state_code">State</label>
          <InputText
            id="state_code"
            name="state_code"
            value={formData.state_code}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="country_code">Country</label>
          <InputText
            id="country_code"
            name="country_code"
            value={formData.country_code}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="zip">Zip</label>
          <InputText
            id="zip"
            name="zip"
            value={formData.zip}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="county">County</label>
          <InputText
            id="county"
            name="county"
            value={formData.county}
            onChange={handleChange}
          />
        </div>
        <div className="field-checkbox">
          <Checkbox
            id="is_active"
            name="is_active"
            checked={formData.is_active}
            onChange={handleChange}
          />
          <label htmlFor="is_active">Active</label>
        </div>
        <div className="field">
          <label htmlFor="phone">Phone</label>
          <InputText
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="start_date">Start Date</label>
          <Calendar
            id="start_date"
            name="start_date"
            value={formData.start_date}
            onChange={(e) => handleDateChange("start_date", e.value)}
            showIcon
          />
        </div>
        <div className="field">
          <label htmlFor="completion_date">Completion Date</label>
          <Calendar
            id="completion_date"
            name="completion_date"
            value={formData.completion_date}
            onChange={(e) => handleDateChange("completion_date", e.value)}
            showIcon
          />
        </div>
        <div className="field">
          <label htmlFor="total_value">Total Value</label>
          <InputNumber
            id="total_value"
            name="total_value"
            value={formData.total_value}
            onChange={handleIntegerChange}
          />
        </div>
        <div className="field-checkbox">
          <Checkbox
            id="is_demo"
            name="is_demo"
            checked={formData.is_demo}
            onChange={handleChange}
          />
          <label htmlFor="is_demo">Is Demo</label>
        </div>
        <div className="field">
          <label htmlFor="abbreviation">Abbreviation</label>
          <InputText
            id="abbreviation"
            name="abbreviation"
            value={formData.abbreviation}
            onChange={handleChange}
          />
        </div>
      </form>
    </Dialog>
  );
};

export default CreateProjectDialog;
