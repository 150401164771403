import { DataView } from "primereact/dataview";
import { useContext, useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { AppContext } from "../../context/AppProvider";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

export default function MobileDashboardTable({ items, isPending }) {
  const [filterValue, setFilterValue] = useState("");
  const [filteredItems, setFilteredItems] = useState(items);

  const { toast, user } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (items) {
      setFilteredItems(items);
    }
  }, [items]);

  const navigateItem = (item) => {
    if (item.type === "Submittal") {
      navigate(`/submittals/${item.id}/view`);
    } else if (item.type === "RFI") {
      navigate("/rfis");
    } else {
      navigate("tasks");
    }
  };

  const itemTemplate = (item) => {
    return (
      <div className="surface-card p-4 shadow-2 border-round w-full my-1">
        <div className="grid">
          <div className="col-5">
            <label htmlFor="email" className="block text-900 font-medium mb-1">
              Type
            </label>
            <div className="mb-1">{item.type}</div>
            <label htmlFor="email" className="block text-900 font-medium mb-1">
              Title
            </label>
            <div className="mb-1">{item.title}</div>
          </div>
          <div className="col-5">
            <label htmlFor="email" className="block text-900 font-medium mb-1">
              Status
            </label>
            <div className="mb-1">{item.status}</div>
            <label htmlFor="email" className="block text-900 font-medium mb-1">
              Due Date
            </label>
            <div className="mb-1">{item.due_date}</div>
          </div>
          <div className="col-2 my-auto">
            <Button icon="pi pi-eye" onClick={() => navigateItem(item)} />
          </div>
        </div>
      </div>
    );
  };

  const onFilterChange = (e) => {
    const value = (e.target && e.target.value) || "";
    setFilterValue(value);

    if (items) {
      const lowerCaseFilterValue = value.toLowerCase();

      const filtered = items.filter((item) => {
        const itemType = item?.type.toLowerCase();
        const itemTitle = item?.title.toLowerCase();
        const itemStatus = item?.status.toLowerCase();

        return (
          itemType.includes(lowerCaseFilterValue) ||
          itemTitle.includes(lowerCaseFilterValue) ||
          itemStatus.includes(lowerCaseFilterValue)
        );
      });
      setFilteredItems(filtered);
    }
  };

  const renderHeader = () => {
    return (
      <div className="flex flex-column gap-2">
        <h1>Open Items</h1>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="w-full"
            value={filterValue}
            onChange={onFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <DataView
      header={header}
      value={filteredItems}
      itemTemplate={itemTemplate}
      paginator
      rows={10}
      className="sm:hidden"
      loading={isPending}
    />
  );
}
