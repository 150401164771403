import { useState, useEffect, useContext } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

import { useQuery } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";

import { fetchIdentifiers } from "../../api/projectsAPI";
import { createSubmittalViaAi } from "../../api/submittalsAPI";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppProvider";
import { getErrorMessage } from "../../util/helper";
import { fetchSpecs } from "../../api/specsAPI";

export default function CreateAiSubmittal({ submittalDialog, hideDialog }) {
  const [label, setLabel] = useState("Save");
  const [submittalInput, setSubmittalInput] = useState({
    received_from_id: null,
    submittal_name: "",
  });

  const { toast } = useContext(AppContext);

  const { data: identifiers, isFetching: isFetchingIdentifiers } = useQuery({
    queryKey: ["identifiers"],
    queryFn: fetchIdentifiers,
  });

  const { data: specs, isFetching: isFetchingSpecs } = useQuery({
    queryKey: ["specs"],
    queryFn: fetchSpecs,
  });

  const navigate = useNavigate();

  const { mutate: createSubmittalAiMutation, isPending: isPendingCreation } =
    useMutation({
      mutationFn: createSubmittalViaAi,
      onSuccess: (response) => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Submittal Created",
          life: 3000,
        });
        localStorage.setItem("draft_submittal", JSON.stringify(response));
        hideDialog();
        navigate("/submittals/draft");
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  useEffect(() => {
    if (isPendingCreation) {
      setLabel("Saving Changes");
    } else if (isFetchingIdentifiers || isFetchingSpecs) {
      setLabel("Fetching Data");
    } else {
      setLabel("Save");
    }
  }, [isPendingCreation, isFetchingIdentifiers, isFetchingSpecs]);

  const onCreateSubmittalViaAi = async () => {
    createSubmittalAiMutation({
      submittalInput: submittalInput,
    });
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _submittalInput = { ...submittalInput };

    _submittalInput[`${name}`] = val;

    setSubmittalInput(_submittalInput);
  };

  const submittalDialogFooter = (
    <>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button
        label={label}
        icon="pi pi-check"
        onClick={onCreateSubmittalViaAi}
        loading={isPendingCreation}
        disabled={isPendingCreation}
      />
    </>
  );

  return (
    <Dialog
      visible={submittalDialog}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Create Submittal Via AI"
      modal
      className="p-fluid"
      footer={submittalDialogFooter}
      onHide={hideDialog}
    >
      <div className="field">
        <label htmlFor="title" className="font-bold">
          Title
        </label>
        <InputText
          id="title"
          value={submittalInput.submittal_name}
          onChange={(e) => onInputChange(e, "submittal_name")}
          required
          autoFocus
        />
      </div>
      <div className="field">
        <label htmlFor="received_from" className="font-bold">
          Received From
        </label>
        <Dropdown
          value={submittalInput.received_from_id}
          onChange={(e) => onInputChange(e, "received_from_id")}
          options={identifiers}
          optionLabel="name"
          optionValue="id"
          placeholder="Select a Person"
          filter
        />
      </div>
      <div className="field">
        <label htmlFor="spec_section" className="font-bold">
          Specification Section
        </label>
        <Dropdown
          value={submittalInput.spec_section}
          onChange={(e) => onInputChange(e, "spec_section")}
          options={specs}
          optionLabel="label"
          placeholder="Select a Spec"
          filter
        />
      </div>
    </Dialog>
  );
}
