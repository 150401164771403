import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppProvider";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createSchedulerJob } from "../../../api/schedulerAPI";
import { queryClient } from "../../../api/requestProcessor";
import { getErrorMessage } from "../../../util/helper";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { ProgressBar } from "primereact/progressbar";
import { fetchUserCompanies, fetchUserProjects } from "../../../api/adminAPI";

const initialFormData = {
  type: "",
  user_id: "",
  start_date: null,
  frequency: 0,
  frequency_unit: "",
};
const jobTypes = [
  { value: "email_scanner", label: "Email Scanner" },
  {
    value: "procore_document_vectorization",
    label: "Procore Document Embedding",
  },
  {
    value: "ai_search_upload",
    label: "AI Search Data Upload",
  },
];
const frequencyUnits = [
  { value: "second", label: "Second" },
  { value: "minute", label: "Minute" },
  { value: "hour", label: "Hour" },
  { value: "day", label: "Day" },
  { value: "week", label: "Week" },
];

function CreateSchedulerJobDialog({ visible, onHide, users }) {
  const [formData, setFormData] = useState(initialFormData);
  const [previousUserId, setPreviousUserId] = useState(null);
  const [previousCompanyId, setPreviousCompanyId] = useState(null);
  const { toast } = useContext(AppContext);

  const {
    data: userCompanies,
    isFetching: isFetchingCompanies,
    refetch: refetchUserCompanies,
  } = useQuery({
    queryKey: ["userCompanies"],
    queryFn: ({ signal }) => fetchUserCompanies(signal, formData.user_id),
    staleTime: 5 * 60 * 1000,
    enabled: false,
  });

  const {
    data: userProjects,
    isFetching: isFetchingProjects,
    refetch: refetchUserProjects,
  } = useQuery({
    queryKey: ["userProjects"],
    queryFn: ({ signal }) =>
      fetchUserProjects(signal, formData.user_id, formData.company_id),
    staleTime: 5 * 60 * 1000,
    enabled: false,
  });

  const { mutate: createSchedulerJobMutation, isPending: isPendingCreation } =
    useMutation({
      mutationFn: createSchedulerJob,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["schedulerJobs"] });
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Scheduler Job Created",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  useEffect(() => {
    // Eğer tip ve kullanıcı seçilmişse ve bu kullanıcı için companies henüz fetch edilmediyse veya kullanıcı değişmişse companies'i fetch et.
    if (
      (formData.type === "procore_document_vectorization" ||
        formData.type === "ai_search_upload") &&
      formData.user_id &&
      formData.user_id !== previousUserId
    ) {
      refetchUserCompanies();
      setPreviousUserId(formData.user_id); // Geçerli user_id'yi sakla
      setPreviousCompanyId(null); // Yeni kullanıcı seçildiği için company'i sıfırla
    }
  }, [formData.type, formData.user_id]);

  useEffect(() => {
    // Eğer kullanıcı ve company_id seçilmişse ve bu company_id için projects fetch edilmemişse veya company değişmişse projects'i fetch et.
    if (
      (formData.type === "procore_document_vectorization" ||
        formData.type === "ai_search_upload") &&
      formData.user_id &&
      formData.company_id &&
      formData.company_id !== previousCompanyId
    ) {
      refetchUserProjects();
      setPreviousCompanyId(formData.company_id); // Geçerli company_id'yi sakla
    }
  }, [formData.type, formData.user_id, formData.company_id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleIntegerChange = (e, name) => {
    const { value } = e;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleDateChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    createSchedulerJobMutation(formData);
    onHide();
    setFormData(initialFormData);
  };

  const dialogFooter = (
    <div className="flex justify-content-end">
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={onHide}
        className="button-text"
      />
      <Button
        label="Save"
        icon="pi pi-check"
        loading={isPendingCreation}
        onClick={handleSubmit}
      />
    </div>
  );

  return (
    <Dialog
      header="Create New Scheduler Job"
      visible={visible}
      style={{ width: "50vw" }}
      footer={dialogFooter}
      onHide={onHide}
    >
      <form className="p-fluid">
        <div className="field">
          <label htmlFor="type">Job Type</label>
          <Dropdown
            id="type"
            name="type"
            value={formData.type}
            options={jobTypes?.map((jobType) => ({
              label: jobType.label,
              value: jobType.value,
            }))}
            onChange={handleChange}
            placeholder="Select a Job Type"
            filter
          />
        </div>
        <div className="field">
          <label htmlFor="user_id">User</label>
          <Dropdown
            id="user_id"
            name="user_id"
            value={formData.user_id}
            options={users?.map((user) => ({
              label: `${user.first_name} ${user.last_name}`,
              value: user.id,
            }))}
            onChange={handleChange}
            placeholder="Select a User"
            filter
          />
        </div>
        <div className="field">
          <label htmlFor="start_date">Start Date</label>
          <Calendar
            id="start_date"
            name="start_date"
            value={formData.start_date}
            onChange={(e) => handleDateChange("start_date", e.value)}
            showIcon
            showTime
            hourFormat="12"
          />
        </div>
        <div className="field">
          <label htmlFor="frequency">Frequency</label>
          <InputNumber
            id="frequency"
            name="frequency"
            value={formData.frequency}
            onChange={(e) => handleIntegerChange(e, "frequency")}
          />
        </div>
        <div className="field">
          <label htmlFor="type">Frequency Unit</label>
          <Dropdown
            id="frequency_unit"
            name="frequency_unit"
            value={formData.frequency_unit}
            options={frequencyUnits?.map((frequencyUnit) => ({
              label: frequencyUnit.label,
              value: frequencyUnit.value,
            }))}
            onChange={handleChange}
            placeholder="Select a Unit"
            filter
          />
        </div>
        {(formData.type === "procore_document_vectorization" ||
          formData.type === "ai_search_upload") &&
          formData.user_id && (
            <div className="field">
              <label htmlFor="company_id">Company</label>
              {isFetchingCompanies ? (
                <ProgressBar
                  mode="indeterminate"
                  style={{ height: "6px" }}
                ></ProgressBar>
              ) : (
                <Dropdown
                  id="company_id"
                  name="company_id"
                  value={formData?.company_id}
                  options={userCompanies?.map((company) => ({
                    label: company.name,
                    value: company.id.toString(),
                  }))}
                  onChange={handleChange}
                  placeholder="Select a Company"
                  filter
                />
              )}
            </div>
          )}
        {(formData.type === "procore_document_vectorization" ||
          formData.type === "ai_search_upload") &&
          formData.user_id &&
          formData?.company_id && (
            <div className="field">
              <label htmlFor="company_id">Project</label>
              {isFetchingProjects ? (
                <ProgressBar
                  mode="indeterminate"
                  style={{ height: "6px" }}
                ></ProgressBar>
              ) : (
                <Dropdown
                  id="project_id"
                  name="project_id"
                  value={formData?.project_id}
                  options={userProjects?.map((project) => ({
                    label: project.name,
                    value: project.id.toString(),
                  }))}
                  onChange={handleChange}
                  placeholder="Select a Project"
                  filter
                />
              )}
            </div>
          )}
      </form>
    </Dialog>
  );
}

export default CreateSchedulerJobDialog;
