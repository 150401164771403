import { Button } from "primereact/button";
import { fetchTeamMembers, setTeamMembers } from "../api/teamAPI";
import { useMutation, useQuery } from "@tanstack/react-query";
import { MultiSelect } from "primereact/multiselect";
import { fetchIdentifiers } from "../api/projectsAPI";
import { useContext, useEffect, useState } from "react";
import { queryClient } from "../api/requestProcessor";
import { AppContext } from "../context/AppProvider";
import { getErrorMessage } from "../util/helper";

export default function TeamMembers() {
  const [selectedMembers, setSelectedMembers] = useState([]);

  const { toast } = useContext(AppContext);

  const { data: teamMembers, isFetching } = useQuery({
    queryKey: ["teamMembers"],
    queryFn: fetchTeamMembers,
    staleTime: 5 * 60 * 1000,
  });

  const { data: identifiers } = useQuery({
    queryKey: ["identifiers"],
    queryFn: fetchIdentifiers,
  });

  const { mutate: setTeamMembersMutation, isPending: isPendingSet } =
    useMutation({
      mutationFn: setTeamMembers,
      onSuccess: (response) => {
        queryClient.invalidateQueries({ queryKey: ["teamMembers"] });
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: response.message,
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  useEffect(() => {
    setSelectedMembers(teamMembers);
  }, [teamMembers]);

  const onTeamMemberSelected = (event) => {
    setSelectedMembers(event.target.value);
  };

  const handleSave = () => {
    setTeamMembersMutation({ teamData: { team_member_ids: selectedMembers } });
  };

  return (
    <div className="flex flex-column gap-2 max-w-25rem">
      <label htmlFor="team-members" className="font-bold">
        Team Members
      </label>
      <MultiSelect
        id="team-members"
        value={selectedMembers}
        options={identifiers}
        filter
        optionLabel="name"
        optionValue="id"
        placeholder="Select team members"
        onChange={onTeamMemberSelected}
        disabled={isFetching}
      />
      <Button
        label={isPendingSet ? "Savings" : "Save Members"}
        onClick={handleSave}
        loading={isPendingSet}
      />
    </div>
  );
}
