import { Outlet, useLocation, useNavigate } from "react-router-dom";

import Navbar from "../components/Navbar";
import MobileMenu from "../components/MobileMenu";
import { getOnboardInfo } from "../api/authAPI";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { AppContext } from "../context/AppProvider";

export default function Root() {
  const navigate = useNavigate();
  const { user } = useContext(AppContext);
  const location = useLocation();
  const { data: onboardInfo, isPending: isPendingOnboardInfo } = useQuery({
    queryKey: ["onboardInfo"],
    queryFn: getOnboardInfo,
  });

  useEffect(() => {
    if (user.role < 2) {
      navigate("/company-selection");
    }
    if (onboardInfo) {
      if (
        !onboardInfo.is_procore_integrated ||
        !onboardInfo.is_email_integrated
      ) {
        navigate("/user-onboard");
      }
    }
  }, [onboardInfo, navigate, user]);
  return (
    <>
      <Navbar />
      {location.pathname === "/chatbots" ? (
        <Outlet />
      ) : (
        <div className="sm:mx-5 mt-4 mb-7 md:mb-0 layout-main-container top-0">
          <Outlet />
        </div>
      )}

      <MobileMenu />
    </>
  );
}
