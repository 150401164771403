import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useContext, useEffect, useState } from "react";
import { queryClient } from "../../api/requestProcessor";
import { useMutation } from "@tanstack/react-query";
import { AppContext } from "../../context/AppProvider";
import { getErrorMessage } from "../../util/helper";
import { updatePunchList } from "../../api/punchListsAPI";
import { InputText } from "primereact/inputtext";

export default function UpdatePunchListDialog({
  isVisible,
  hideDialog,
  punchList,
}) {
  const { toast } = useContext(AppContext);

  const [localPunchList, setLocalPuchList] = useState({
    title: "",
  });

  const { mutate: updatePunchListMutation, isPending: isPendingUpdate } =
    useMutation({
      mutationFn: updatePunchList,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["punch-lists"] });
        hideDialog();
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Punch List Updated",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "Error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  useEffect(() => {
    if (punchList) {
      setLocalPuchList(punchList);
    }
  }, [punchList]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setLocalPuchList((prevLocalPunchList) => ({
      ...prevLocalPunchList,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleUpdatePunchList = () => {
    updatePunchListMutation(localPunchList);
  };

  const dialogFooter = (
    <>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button
        label={isPendingUpdate ? "Updating" : "Update"}
        icon="pi pi-check"
        onClick={handleUpdatePunchList}
        loading={isPendingUpdate}
      />
    </>
  );

  return (
    <Dialog
      visible={isVisible}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="New Punch List"
      modal
      className="p-fluid mx-2"
      footer={dialogFooter}
      onHide={hideDialog}
    >
      <div className="field">
        <label htmlFor="title" className="font-bold">
          Title
        </label>
        <InputText
          id="title"
          name="title"
          value={localPunchList.title}
          onChange={handleChange}
          required
        />
      </div>
    </Dialog>
  );
}
