import React, { useEffect, useState } from "react";
import { fetchRfis } from "../api/rfisAPI";
import RFIs from "../components/RFIs/RFIs";
import { useQuery } from "@tanstack/react-query";

const RFIPage = () => {
  const [queryParams, setQueryParams] = useState({
    page: 1,
    per_page: 10,
    search: "",
  });

  const {
    data: rfis,
    isFetching,
    isError,
    error,
    refetch,
  } = useQuery({
    queryKey: ["rfis", queryParams],
    queryFn: ({ signal }) => fetchRfis({ signal, queryParams: queryParams }),
    staleTime: 5 * 60 * 1000,
  });

  return (
    <div>
      <RFIs
        rfis={rfis && rfis.rfis}
        total={rfis && rfis.total}
        isPending={isFetching}
        refetch={refetch}
        setQueryParams={setQueryParams}
      />
    </div>
  );
};

export default RFIPage;
