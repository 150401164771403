import { Button } from "primereact/button";
import { getUserData } from "../../util/storage";
import { truncateFileName } from "../../util/helper";
import { useContext } from "react";
import { AppContext } from "../../context/AppProvider";

export default function MobileSubmittalDetailsWorkflow({
  workflow,
  setSelectedApprover,
  setIsDialogVisible,
  ballInCourtIds,
}) {
  const isProcoreUser = getUserData()["is_procore_user"];

  const { user } = useContext(AppContext);

  const editResponse = (rowData) => {
    if (setSelectedApprover) {
      setSelectedApprover(rowData);
    }
    if (setIsDialogVisible) {
      setIsDialogVisible(true);
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        {!isProcoreUser &&
          rowData.response.status === "Pending" &&
          ((ballInCourtIds &&
            ballInCourtIds.includes(user.id) &&
            user.id === rowData.user.id) ||
            user.role > 1) && (
            <Button
              label="Edit Response"
              size="small"
              onClick={() => editResponse(rowData)}
            />
          )}
      </>
    );
  };

  const attachmentsTemplate = (rowData) => {
    return rowData.attachments.map((attachment) => (
      <Button
        key={attachment.id}
        label={truncateFileName(attachment.filename)}
        severity="secondary"
        icon="pi pi-external-link"
        className="mb-2"
        onClick={() => window.open(attachment.url, "_blank")}
        outlined
      />
    ));
  };

  const workflowItemTemplate = (item, index) => (
    <div
      key={index}
      className="workflow-item bg-gray-100 p-3 mb-3 border-round-md"
    >
      <div>
        Step Number:{" "}
        <span className="font-bold">
          {isProcoreUser ? item.workflow_group_number : item.step}
        </span>
      </div>
      <div>
        Name: <span className="font-bold">{item.user.name}</span>
      </div>
      <div>
        Sent Date: <span className="font-bold">{item.sent_date}</span>
      </div>
      <div>
        Due Date: <span className="font-bold">{item.due_date}</span>
      </div>
      <div>
        Returned Date: <span className="font-bold">{item.returned_date}</span>
      </div>
      <div>
        Response:{" "}
        <span className="font-bold">
          {isProcoreUser ? item.response.name : item.response.status}
        </span>
      </div>
      <div>
        Comments: <span className="font-bold">{item.comment}</span>
      </div>
      <div>Attachments:</div>
      {attachmentsTemplate(item)}
      {actionBodyTemplate(item)}
    </div>
  );

  return (
    <div className="field">
      <div>
        {workflow.map((item, index) => workflowItemTemplate(item, index))}
      </div>
    </div>
  );
}
