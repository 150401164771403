import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useContext, useEffect, useState } from "react";
import { queryClient } from "../../api/requestProcessor";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AppContext } from "../../context/AppProvider";
import { getErrorMessage } from "../../util/helper";
import {
  createPunchListItem,
  fetchPunchListNextNumber,
} from "../../api/punchListsAPI";
import { InputText } from "primereact/inputtext";
import { fetchEntities } from "../../api/entitiesAPI";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { InputTextarea } from "primereact/inputtextarea";
import CustomFileUpload from "../FileUpload/FileUpload";
import { uploadFiles } from "../../api/filesAPI";
import { InputNumber } from "primereact/inputnumber";

const initialPunchList = {
  title: "",
  approver_id: undefined,
  assignee_ids: [],
  status: "Incomplete",
  location: "",
  room: "",
  reference: "",
  notes: "",
  number: undefined,
};

export default function NewPunchListItemDialog({
  isVisible,
  hideDialog,
  punchListId,
}) {
  const [files, setFiles] = useState([]);
  const [uploadReset, setUploadReset] = useState(false);
  const { toast } = useContext(AppContext);

  const [newPunchListItem, setNewPuchListItem] = useState(initialPunchList);

  const { data: nextNumber } = useQuery({
    queryKey: ["punch-list-number"],
    queryFn: ({ signal }) => fetchPunchListNextNumber({ signal, punchListId }),
  });

  const { data: users, isFetching: isFetchingUsers } = useQuery({
    queryKey: ["entities"],
    queryFn: fetchEntities,
    staleTime: 5 * 60 * 1000,
  });

  const { mutate: createPunchListItemMutation, isPending: isPendingCreate } =
    useMutation({
      mutationFn: createPunchListItem,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["punch-lists"],
        });
        queryClient.invalidateQueries({
          queryKey: ["punch-list-number"],
        });
        hideDialog();
        setFiles([]);
        setNewPuchListItem(initialPunchList);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Punch List Item Created",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "Error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const { mutateAsync: uploadFilesMutation, isPending: isPendingUpload } =
    useMutation({
      mutationFn: uploadFiles,
      onSuccess: () => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "File Uploaded",
          life: 3000,
        });
        setUploadReset(true);
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  useEffect(() => {
    if (nextNumber) {
      let _newPunchListItem = { ...newPunchListItem };
      _newPunchListItem.number = nextNumber.next_number;
      setNewPuchListItem(_newPunchListItem);
    }
  }, [nextNumber]);

  const onFileChange = (files) => {
    setFiles(files);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewPuchListItem((prevNewPunchListItem) => ({
      ...prevNewPunchListItem,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleIntegerChange = (e, name) => {
    const { value } = e;
    setNewPuchListItem((prevNewPunchListItem) => ({
      ...prevNewPunchListItem,
      [name]: value,
    }));
  };

  const printErrorMessage = (errorMessage) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: errorMessage,
      life: 3000,
    });
  };

  const checkValidation = () => {
    if (!newPunchListItem.title) {
      printErrorMessage("The title area is required!");
      return false;
    } else if (!newPunchListItem.approver_id) {
      printErrorMessage("The approver area is required!");
      return false;
    } else if (!newPunchListItem.assignee_ids.length > 0) {
      printErrorMessage("The assignees area is required!");
      return false;
    }
    return true;
  };

  const handleCreateNewPunchListItem = async () => {
    if (checkValidation()) {
      let updatedAttachments = [];

      if (files.length > 0) {
        const uploadPromises = files.map(async (file) => {
          return await uploadFilesMutation({
            files: [file],
          });
        });

        const responses = await Promise.all(uploadPromises);
        updatedAttachments = [...responses];
      }

      newPunchListItem.files = updatedAttachments;

      createPunchListItemMutation({
        newPunchListItem,
        punchListId,
      });
    }
  };

  const dialogFooter = (
    <>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button
        label={isPendingCreate || isPendingUpload ? "Creating" : "Create"}
        icon="pi pi-plus"
        onClick={handleCreateNewPunchListItem}
        loading={isPendingCreate || isPendingUpload}
      />
    </>
  );

  return (
    <Dialog
      visible={isVisible}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="New Punch List Item"
      modal
      className="p-fluid mx-2"
      footer={dialogFooter}
      onHide={hideDialog}
    >
      <div className="field">
        <label htmlFor="title" className="font-bold">
          Number
        </label>
        <InputNumber
          id="number"
          name="number"
          value={newPunchListItem.number}
          onChange={handleIntegerChange}
        />
      </div>
      <div className="field">
        <label htmlFor="title" className="font-bold">
          Title
        </label>
        <InputText
          id="title"
          name="title"
          value={newPunchListItem.title}
          options={users}
          onChange={handleChange}
        />
      </div>
      <div className="field">
        <label htmlFor="approver_id" className="font-bold">
          Approver
        </label>
        <Dropdown
          id="approver_id"
          name="approver_id"
          value={newPunchListItem.approver_id}
          options={users}
          optionValue="id"
          optionLabel="name"
          placeholder="Select a Person"
          onChange={handleChange}
          disabled={isFetchingUsers}
          filter
        />
      </div>
      <div className="field">
        <label htmlFor="assignee_ids" className="font-bold">
          Assignees
        </label>
        <MultiSelect
          id="assignee_ids"
          name="assignee_ids"
          value={newPunchListItem.assignee_ids}
          options={users}
          optionValue="id"
          optionLabel="name"
          placeholder="Select Assignees"
          onChange={handleChange}
          disabled={isFetchingUsers}
          filter
        />
      </div>
      <div className="field">
        <label htmlFor="status" className="font-bold">
          Status
        </label>
        <Dropdown
          id="status"
          name="status"
          value={newPunchListItem.status}
          options={["Incomplete", "Complete"]}
          placeholder="Select a Status"
          onChange={handleChange}
        />
      </div>
      <div className="field">
        <label htmlFor="location" className="font-bold">
          Location
        </label>
        <InputText
          id="location"
          name="location"
          value={newPunchListItem.location}
          onChange={handleChange}
          required
        />
      </div>
      <div className="field">
        <label htmlFor="room" className="font-bold">
          Room
        </label>
        <InputText
          id="room"
          name="room"
          value={newPunchListItem.room}
          onChange={handleChange}
          required
        />
      </div>
      <div className="field">
        <label htmlFor="reference" className="font-bold">
          Reference
        </label>
        <InputText
          id="reference"
          name="reference"
          value={newPunchListItem.reference}
          onChange={handleChange}
          required
        />
      </div>
      <div className="field">
        <label htmlFor="notes" className="font-bold">
          Notes
        </label>
        <InputTextarea
          id="notes"
          name="notes"
          value={newPunchListItem.notes}
          onChange={handleChange}
          required
        />
      </div>
      <CustomFileUpload
        accept=""
        onFileChange={onFileChange}
        reset={uploadReset}
        setReset={setUploadReset}
      />
    </Dialog>
  );
}
