import { useQuery } from "@tanstack/react-query";
import React, { useContext, useEffect, useState } from "react";
import { fetchProcoreAuthUrl, getOnboardInfo } from "../api/authAPI";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import {
  fetchGoogleAuthUrl,
  fetchMicrosoftAuthUrl,
} from "../api/emailSettingsAPI";
import { AppContext } from "../context/AppProvider";
import OnboardNavbar from "../components/OnboardNavbar";
import { Skeleton } from "primereact/skeleton";

function UserOnboard() {
  const [step, setStep] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useContext(AppContext);
  const shouldChecked = user.role > 1;

  const { data: onboardInfo, isPending: isPendingOnboardInfo } = useQuery({
    queryKey: ["onboardInfo"],
    queryFn: getOnboardInfo,
    staleTime: 5 * 60 * 1000,
    enabled: shouldChecked,
  });

  const { data: procoreAuthUrl, isPending: isPendingProcoreAuthUrl } = useQuery(
    {
      queryKey: ["procoreAuthUrl"],
      queryFn: fetchProcoreAuthUrl,
      staleTime: 5 * 60 * 1000,
      enabled: shouldChecked,
    }
  );

  const { data: googleAuthUrl, isPending: isPendingGoogleAuthUrl } = useQuery({
    queryKey: ["googleAuthUrl"],
    queryFn: fetchGoogleAuthUrl,
    staleTime: 5 * 60 * 1000,
    shouldChecked: shouldChecked,
  });

  const { data: microsoftAuthUrl, isPending: isPendingMicrosoftAuthUrl } =
    useQuery({
      queryKey: ["microsoftAuthUrl"],
      queryFn: fetchMicrosoftAuthUrl,
      staleTime: 5 * 60 * 1000,
      enabled: shouldChecked,
    });

  useEffect(() => {
    setIsLoading(
      isPendingProcoreAuthUrl ||
        isPendingGoogleAuthUrl ||
        isPendingMicrosoftAuthUrl ||
        isPendingOnboardInfo
    );
  }, [
    isPendingGoogleAuthUrl,
    isPendingMicrosoftAuthUrl,
    isPendingOnboardInfo,
    isPendingProcoreAuthUrl,
  ]);

  useEffect(() => {
    if (user.role < 2) {
      navigate("/company-selection");
    }
    if (onboardInfo) {
      if (!onboardInfo.is_procore_integrated) {
        setStep(1);
      } else if (!onboardInfo.is_email_integrated) {
        setStep(2);
      } else {
        navigate("/company-selection");
      }
    }
  }, [onboardInfo, navigate, user]);

  return (
    <>
      <OnboardNavbar />
      <div className="centered-content">
        <Card
          className="nicky-card"
          title="Welcome back!"
          subTitle={`Sign in with your ${
            step === 1 ? "Procore" : "email"
          } account to continue`}
        >
          {step === 1 && !isLoading && (
            <div>
              <Button
                className="w-full mb-2"
                label="Authenticate Procore Account"
                loading={isPendingOnboardInfo}
                style={{ backgroundColor: "#F47D42", borderColor: "#F47D42" }}
                onClick={() => window.open(procoreAuthUrl?.url)}
              />
            </div>
          )}
          {step === 2 && !isLoading && (
            <div className="flex flex-column gap-2">
              <div>
                <Button
                  className="w-full mb-2"
                  label="Authenticate Google Account"
                  loading={isPendingOnboardInfo}
                  onClick={() => window.open(googleAuthUrl)}
                />
              </div>
              <Divider align="center">
                <div className="inline-flex align-items-center">
                  <b>OR</b>
                </div>
              </Divider>
              <div>
                <Button
                  className="w-full mb-2"
                  label="Authenticate Microsoft Account"
                  loading={isPendingOnboardInfo}
                  severity="info"
                  onClick={() => window.open(microsoftAuthUrl)}
                />
              </div>
            </div>
          )}
          {isLoading && (
            <div>
              <Skeleton height="2rem" className="mb-2"></Skeleton>
              <Skeleton className="mb-2"></Skeleton>
              <Divider align="center">
                <div className="inline-flex align-items-center">
                  <b>OR</b>
                </div>
              </Divider>
              <Skeleton className="mb-2"></Skeleton>
              <Skeleton height="2rem" className="mb-2"></Skeleton>
            </div>
          )}
        </Card>
      </div>
    </>
  );
}

export default UserOnboard;
