import { DataView } from "primereact/dataview";
import CustomCard from "./CustomCard";
import { useContext, useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { getProperty } from "../../util/property";
import FilterSidebar from "../FilterSidebar";
import { useMutation } from "@tanstack/react-query";
import { updateTaskStatus } from "../../api/tasksAPI";
import { queryClient } from "../../api/requestProcessor";
import { AppContext } from "../../context/AppProvider";
import { getErrorMessage } from "../../util/helper";

export default function MobileDataTable({
  items,
  editMode,
  title,
  body,
  assignees,
  number,
  status,
  date,
  createHandler,
  editHandler,
  deleteHandler,
  isPending,
  total,
  setQueryParams,
  createReply,
  openSubmittalAttachments,
  initialFilters,
  pageType,
}) {
  const [searchInput, setSearchInput] = useState("");
  const [lazyState, setLazyState] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });
  const [filterSidebarVisible, setFilterSidebarVisible] = useState(false);
  const [filterQuery, setFilterQuery] = useState({});
  const [numOfSelectedFilters, setNumOfSelectedFilters] = useState(0);

  const { toast, user } = useContext(AppContext);

  useEffect(() => {
    setQueryParams(mergeAllQueries());
  }, [lazyState, setQueryParams]);

  useEffect(() => {
    setLazyState({ ...lazyState, first: 0, page: 0 });
  }, [filterQuery, setQueryParams]);

  const { mutate: updateStatusMutation } = useMutation({
    mutationFn: updateTaskStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["tasks"] });
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Status Updated",
        life: 3000,
      });
    },
    onError: (error) => {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: getErrorMessage(error),
        life: 3000,
      });
    },
  });

  const mergeAllQueries = () => {
    const pageQuery = { page: lazyState.page + 1, per_page: lazyState.rows };
    const searchQuery = { search: searchInput };
    return { ...pageQuery, ...searchQuery, ...filterQuery };
  };

  const onStatusChanged = (task) => {
    let status = "";
    if (task.status === "Closed") {
      status = "Open";
    } else {
      status = "Closed";
    }
    updateStatusMutation({ taskId: task._id, status: status });
  };

  const itemTemplate = (item) => {
    return (
      <CustomCard
        editMode={editMode}
        title={item[title]}
        body={getProperty(item, body)}
        assignees={
          item[assignees]
            ? Array.isArray(item[assignees])
              ? item[assignees].map((assignee) => assignee.name).join(", ")
              : item[assignees]
            : ""
        }
        number={number ? item[number] : ""}
        status={getProperty(item, status)}
        date={item[date]}
        item={item}
        editHandler={editHandler}
        deleteHandler={deleteHandler}
        createReply={createReply}
        openSubmittalAttachments={openSubmittalAttachments}
        onStatusChanged={onStatusChanged}
      />
    );
  };

  const onSearchInputChange = (e) => {
    const val = (e.target && e.target.value) || "";
    setSearchInput(val);
  };

  const handleSearch = (e, isFromButton) => {
    if (e.key === "Enter" || isFromButton) {
      setLazyState({ ...lazyState, page: 0, first: 0 });
    }
  };

  const onResetSearch = (e) => {
    setSearchInput("");
    setLazyState({ ...lazyState, page: 0, first: 0 });
  };

  const onPage = (e) => {
    setLazyState(e);
  };

  const renderHeader = () => {
    return (
      <div className="flex flex-column gap-2">
        {editMode && user.role > 1 && (
          <Button
            label="New"
            icon="pi pi-plus"
            severity="success"
            onClick={createHandler}
          />
        )}
        {user.role > 1 && (
          <Button
            label={
              numOfSelectedFilters === 0
                ? "Filter"
                : `Filter (${numOfSelectedFilters})`
            }
            icon="pi pi-filter"
            onClick={() => setFilterSidebarVisible(true)}
          />
        )}
        <div className="flex w-full">
          <span className="p-input-icon-right mr-auto">
            {searchInput && (
              <i
                className="pi pi-times mr-1 cursor-pointer"
                onClick={onResetSearch}
              />
            )}
            <InputText
              value={searchInput}
              onChange={onSearchInputChange}
              placeholder="Keyword Search"
              className="mr-1"
              onKeyDown={(e) => handleSearch(e, false)}
            />
          </span>
          <Button icon="pi pi-search" onClick={(e) => handleSearch(e, true)} />
        </div>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <>
      <DataView
        header={header}
        value={items}
        itemTemplate={itemTemplate}
        paginator
        rows={10}
        className="sm:hidden"
        loading={isPending}
        lazy
        onPage={onPage}
        first={lazyState.first}
        totalRecords={total}
      />
      {filterSidebarVisible && (
        <FilterSidebar
          isVisible={filterSidebarVisible}
          setIsVisible={setFilterSidebarVisible}
          setFilterQuery={setFilterQuery}
          initialFilters={initialFilters}
          pageType={pageType}
          setNumOfSelectedFilters={setNumOfSelectedFilters}
        />
      )}
    </>
  );
}
