import { useQuery } from "@tanstack/react-query";
import { fetchAllUsers, fetchAllProjects } from "../../../api/adminAPI";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { useState } from "react";

const initialFormData = {
  project_id: "",
};

const RoleManagementDialog = ({ visible, onHide, onSave, isLoading }) => {
  const [selectedUser, setSelectedUser] = useState({ id: null });
  const [formData, setFormData] = useState(initialFormData);

  const { data: users, isFetching: isFetchingUsers } = useQuery({
    queryKey: ["admin-users"],
    queryFn: ({ signal }) => fetchAllUsers(signal),
    staleTime: 5 * 60 * 1000,
  });

  const { data: projects, isFetching: isFetchingProjects } = useQuery({
    queryKey: ["admin-projects"],
    queryFn: ({ signal }) => fetchAllProjects(signal),
    staleTime: 5 * 60 * 1000,
  });

  const handleUserChange = (e) => {
    const { value } = e.target;
    const user = users.find((elem) => elem.id === value);
    setSelectedUser(user);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    await onSave({
      user_id: selectedUser.id,
      project_id: formData.project_id,
    });
    onHide();
    setFormData(initialFormData);
    setSelectedUser({ id: null });
  };

  const dialogFooter = (
    <div className="flex justify-content-end">
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={onHide}
        className="button-text"
      />
      <Button
        loading={isLoading}
        label="Save"
        icon="pi pi-check"
        onClick={handleSubmit}
      />
    </div>
  );

  return (
    <Dialog
      header="Handle Project Authorization"
      visible={visible}
      style={{ width: "50vw" }}
      footer={dialogFooter}
      onHide={onHide}
    >
      <form className="p-fluid">
        <div className="field">
          <label htmlFor="user_id">User</label>
          <Dropdown
            id="user_id"
            name="user_id"
            value={selectedUser?.id}
            options={users?.map((user) => ({
              label: `${user.first_name} ${user.last_name}`,
              value: user.id,
            }))}
            onChange={handleUserChange}
            placeholder="Select a User"
            loading={isFetchingUsers}
            filter
          />
        </div>

        {selectedUser?.id && !selectedUser?.is_procore_user && (
          <div className="field">
            <label htmlFor="project_id">Project</label>
            <Dropdown
              id="project_id"
              name="project_id"
              value={formData.project_id}
              options={projects?.map((project) => ({
                label: project.name,
                value: project._id,
              }))}
              onChange={handleChange}
              placeholder="Select a Project"
              loading={isFetchingProjects}
              filter
            />
          </div>
        )}
      </form>
    </Dialog>
  );
};

export default RoleManagementDialog;
