import React, { useContext, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { useMutation } from "@tanstack/react-query";
import { updateCompany } from "../../../api/companiesAPI";
import { queryClient } from "../../../api/requestProcessor";
import { AppContext } from "../../../context/AppProvider";
import { getErrorMessage } from "../../../util/helper";

const UpdateCompanyDialog = ({ visible, onHide, company }) => {
  const [formData, setFormData] = useState(company);
  const { toast } = useContext(AppContext);

  const { mutate: updateCompanyMutation, isPending } = useMutation({
    mutationFn: updateCompany,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["admin-companies"] });
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Company Created",
        life: 3000,
      });
    },
    onError: (error) => {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: getErrorMessage(error),
        life: 3000,
      });
    },
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = () => {
    updateCompanyMutation({ companyId: company._id, company: formData });
    onHide();
  };

  const dialogFooter = (
    <div className="flex justify-content-end">
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={onHide}
        className="button-text"
      />
      <Button
        label="Save"
        icon="pi pi-check"
        loading={isPending}
        onClick={handleSubmit}
      />
    </div>
  );

  return (
    <Dialog
      header={`Update ${company.name}`}
      visible={visible}
      style={{ width: "50vw" }}
      footer={dialogFooter}
      onHide={onHide}
    >
      <form className="p-fluid">
        <div className="field">
          <label htmlFor="name">Name</label>
          <InputText
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="address">Address</label>
          <InputText
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="city">City</label>
          <InputText
            id="city"
            name="city"
            value={formData.city}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="state_code">State</label>
          <InputText
            id="state_code"
            name="state_code"
            value={formData.state_code}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="country_code">Country</label>
          <InputText
            id="country_code"
            name="country_code"
            value={formData.country_code}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="zip">Zip</label>
          <InputText
            id="zip"
            name="zip"
            value={formData.zip}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="county">County</label>
          <InputText
            id="county"
            name="county"
            value={formData.county}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="time_zone">Time Zone</label>
          <InputText
            id="time_zone"
            name="time_zone"
            value={formData.time_zone}
            onChange={handleChange}
          />
        </div>
        <div className="field-checkbox">
          <Checkbox
            id="is_active"
            name="is_active"
            checked={formData.is_active}
            onChange={handleChange}
          />
          <label htmlFor="is_active">Active</label>
        </div>
        <div className="field">
          <label htmlFor="phone">Phone</label>
          <InputText
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="point_of_contact">Point of Contact</label>
          <InputText
            id="point_of_contact"
            name="point_of_contact"
            value={formData.point_of_contact}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="company_type">Company Type</label>
          <InputText
            id="company_type"
            name="company_type"
            value={formData.company_type}
            onChange={handleChange}
          />
        </div>
      </form>
    </Dialog>
  );
};

export default UpdateCompanyDialog;
