import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useQuery } from "@tanstack/react-query";
import { getSubmittalAttachments } from "../../api/submittalsAPI";
import CustomLoader from "../Loaders/CustomLoader";

export default function SubmittalAttachments({
  hideAttachmentsDialog,
  submittalId,
  attachmentsDialog,
}) {
  const { data: SubmittalAttachments, isPending: isPendingSubmittal } =
    useQuery({
      queryKey: ["submittal_attachments", submittalId],
      queryFn: ({ signal }) =>
        getSubmittalAttachments({ signal, submittalId: submittalId }),
      enabled: !!submittalId,
      staleTime: 5 * 60 * 1000,
    });

  const submittalDialogFooter = (
    <>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={hideAttachmentsDialog}
      />
    </>
  );
  return (
    <Dialog
      visible={attachmentsDialog}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Submittal Attachments"
      modal
      className="p-fluid"
      footer={submittalDialogFooter}
      onHide={hideAttachmentsDialog}
    >
      {isPendingSubmittal ? (
        <CustomLoader />
      ) : (
        <>
          {SubmittalAttachments && SubmittalAttachments.length > 0 ? (
            <div>
              {SubmittalAttachments.map((attachment) => (
                <Button
                  key={attachment.id}
                  label={attachment.name}
                  severity="secondary"
                  icon="pi pi-external-link"
                  className="p-mr-2 p-mb-2 mt-3"
                  onClick={() => window.open(attachment.url, "_blank")}
                  outlined
                />
              ))}
            </div>
          ) : (
            <div>No attachments</div>
          )}
        </>
      )}
    </Dialog>
  );
}
