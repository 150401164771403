import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppProvider";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "../api/requestProcessor";
import { getErrorMessage } from "../util/helper";
import { uploadFiles } from "../api/filesAPI";
import { Button } from "primereact/button";
import { useNavigate, useParams } from "react-router-dom";
import { fetchCommitment, updateCommitment } from "../api/commitmentsAPI";
import UpdateCommitmentForm from "../components/Commitments/UpdateCommitmentForm";

export default function UpdateCommitmentPage() {
  const { id } = useParams();
  const [commitment, setCommitment] = useState(null);
  const [files, setFiles] = useState([]);
  const [uploadReset, setUploadReset] = useState(false);
  const [label, setLabel] = useState("Update");
  const [attachments, setAttachments] = useState([]);

  const { toast } = useContext(AppContext);
  const navigate = useNavigate();

  // Fetch commitment data
  const { data: commitmentData, isFetching: isPendingCommitment } = useQuery({
    queryKey: ["commitments", id],
    queryFn: ({ signal }) => fetchCommitment({ signal, commitmentId: id }),
    enabled: !!id,
  });

  // Set initial commitment data
  useEffect(() => {
    if (commitmentData) {
      setCommitment({
        id: commitmentData._id,
        commitment_type: commitmentData.commitment_type,
        contract_company_id: commitmentData.contract_company?.id,
        title: commitmentData.title,
        number: commitmentData.number,
        status: commitmentData.status,
        description: commitmentData.description,
        executed: commitmentData.executed,
        ssov_status: commitmentData.ssov_status,
        original_contract_amount: commitmentData.original_contract_amount,
        approved_change_orders: commitmentData.approved_change_orders,
        pending_change_orders: commitmentData.pending_change_orders,
        revised_contract_amount: commitmentData.revised_contract_amount,
        contract_date: commitmentData.contract_date,
        inclusions: commitmentData.inclusions,
        exclusions: commitmentData.exclusions,
        visibility: commitmentData.visibility,
        contract_start_date: commitmentData.contract_start_date,
        contract_estimated_completion_date:
          commitmentData.contract_estimated_completion_date,
      });
      setAttachments(commitmentData.attachments || []);
    }
  }, [commitmentData]);

  const { mutate: updateCommitmentMutation, isPending: isPendingUpdate } =
    useMutation({
      mutationFn: updateCommitment,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["commitments"] });
        queryClient.invalidateQueries({ queryKey: ["commitments", id] });
        navigate("/commitments");
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Commitment Updated",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const { mutateAsync: uploadFilesMutation, isPending: isPendingUpload } =
    useMutation({
      mutationFn: uploadFiles,
      onSuccess: () => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "File Uploaded",
          life: 3000,
        });
        setUploadReset(true);
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  useEffect(() => {
    if (isPendingUpload) {
      setLabel("Uploading Files");
    } else if (isPendingUpdate) {
      setLabel("Saving Changes");
    } else {
      setLabel("Update");
    }
  }, [isPendingUpload, isPendingUpdate]);

  const cancelCommitmentUpdate = () => {
    navigate("/commitments");
  };

  const handleUpdateCommitment = async () => {
    if (!commitment) return;

    let updatedAttachments = [...attachments];

    if (files.length > 0) {
      const uploadPromises = files.map(async (file) => {
        return await uploadFilesMutation({
          files: [file],
        });
      });

      const responses = await Promise.all(uploadPromises);
      updatedAttachments = [...responses, ...attachments];
    }

    const commitmentData = {
      ...commitment,
      attachments: updatedAttachments,
    };

    updateCommitmentMutation({
      commitmentId: id,
      commitment: commitmentData,
    });
  };

  if (!commitment) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="flex justify-content-between align-items-center mb-3">
        <p className="text-3xl font-medium line-height-1 m-0">
          Update Commitment
        </p>
        <div className="flex gap-2">
          <Button
            label="Cancel"
            icon="pi pi-times"
            outlined
            onClick={cancelCommitmentUpdate}
          />
          <Button
            label={label}
            icon="pi pi-check"
            onClick={handleUpdateCommitment}
            loading={isPendingUpdate || isPendingUpload}
            disabled={isPendingUpdate || isPendingUpload}
          />
        </div>
      </div>

      <UpdateCommitmentForm
        commitment={commitment}
        setCommitment={setCommitment}
        files={files}
        setFiles={setFiles}
        uploadReset={uploadReset}
        setUploadReset={setUploadReset}
        attachments={attachments}
        setAttachments={setAttachments}
        isPending={isPendingCommitment}
      />

      <div className="flex justify-content-end gap-2 mt-2">
        <Button
          label="Cancel"
          icon="pi pi-times"
          outlined
          onClick={cancelCommitmentUpdate}
        />
        <Button
          label={label}
          icon="pi pi-check"
          onClick={handleUpdateCommitment}
          loading={isPendingUpdate || isPendingUpload}
          disabled={isPendingUpdate || isPendingUpload}
          className="right-0"
        />
      </div>
    </>
  );
}
