import { Skeleton } from "primereact/skeleton";

export function RFIRequestLoader() {
  return (
    <div>
      <div className="grid">
        <div className="col-12 md:col-6">
          <div className="flex flex-column gap-2">
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="col-12 md:col-6">
          <div className="flex flex-column gap-2">
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
          </div>
        </div>
        <div className="col-12 md:col-6">
          <div className="flex flex-column gap-2">
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
          </div>
        </div>
      </div>
    </div>
  );
}

export function RFIGeneralInformationLoader() {
  return (
    <div>
      <div className="grid">
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
          </div>
        </div>
      </div>
    </div>
  );
}

export function RFIResponseLoader() {
  return (
    <div>
      <div className="grid">
        <div className="col-12 md:col-6">
          <div className="flex flex-column gap-2">
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
          </div>
        </div>
        <div className="col-12 md:col-6">
          <div className="flex flex-column gap-2">
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
          </div>
        </div>
      </div>
    </div>
  );
}
