import axios from "axios";
import { getAuthToken } from "../util/auth";
import API_BASE_URL from "../util/apiConfig";

const token = getAuthToken();

const schedulerClient = axios.create({
  baseURL: `${API_BASE_URL}/scheduler`,
  headers: {
    Authorization: "Bearer " + token,
  },
});

schedulerClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response.data);
  }
);

export async function fetchSchedulerJobs({ signal }) {
  const response = await schedulerClient.get("", {
    signal: signal,
    params: { mode: "inactive" },
  });
  return response.data;
}

export async function createSchedulerJob(schedulerJob) {
  const response = await schedulerClient.post("", schedulerJob);
  return response.data;
}

export async function deleteSchedulerJob({ schedulerJobId }) {
  const response = await schedulerClient.delete(`/${schedulerJobId}`);
  return response.data;
}
