import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { fetchVendors } from "../../api/vendorsAPI";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import NewVendorDialog from "../Vendor/NewVendorDialog";
import { Toast } from "primereact/toast";
import { getUser, updateUser } from "../../api/usersAPI";
import { queryClient } from "../../api/requestProcessor";
import { useNavigate, useParams } from "react-router-dom";
import UserDetailsLoader from "../Loaders/UserDetailsLoader";
import { InputTextarea } from "primereact/inputtextarea";

export default function UserDetails() {
  const [userData, setUserData] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  const toast = useRef(null);

  const params = useParams();
  const navigate = useNavigate();

  const {
    data: user,
    isPending: isPendingUser,
    error: userError,
  } = useQuery({
    queryKey: ["users", params.userId],
    queryFn: ({ signal }) => getUser({ signal, userId: params.userId }),
  });

  useEffect(() => {
    setUserData(user);
  }, [user]);

  const {
    data: vendors,
    isFetching: isFetchingVendors,
    error,
  } = useQuery({
    queryKey: ["vendors"],
    queryFn: fetchVendors,
    staleTime: 5 * 60 * 1000,
  });

  const {
    mutate: updateUserMutation,
    isPending: isPendingUpdate,
    error: updateError,
  } = useMutation({
    mutationFn: updateUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["entities"] });
      navigate("/directory");
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "User Updated",
        life: 3000,
      });
    },
    onError: () => {
      toast.current.show({
        severity: "Error",
        summary: "Error",
        detail: updateError,
        life: 3000,
      });
    },
  });

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _userData = { ...userData };

    _userData[`${name}`] = val;

    setUserData(_userData);
  };

  const openCompanyDialog = () => {
    setIsVisible(true);
  };

  const hideDialog = () => {
    setIsVisible(false);
  };

  const handleUpdateUser = () => {
    updateUserMutation({ userId: params.userId, userData: userData });
  };

  const cancelUserCreation = () => {
    navigate("/directory");
  };

  const panelFooterTemplate = () => {
    return (
      <div className="py-2 px-3">
        <Button
          label="Create New"
          loading={isFetchingVendors}
          onClick={openCompanyDialog}
          className="w-full"
          size="small"
        />
      </div>
    );
  };

  if (isPendingUser) {
    return <UserDetailsLoader />;
  }

  if (userData) {
    return (
      <>
        <Toast ref={toast} />

        <div>
          <p className="text-3xl w-10 font-medium line-height-1">
            Create New User
          </p>
          <div className="grid nested-grid">
            <div className="col-12 md:col-8">
              <Divider align="left">
                <div className="inline-flex align-items-center">
                  <span className="p-tag">
                    <i className="pi pi-user mr-2"></i>
                    <b>Personal Information</b>
                  </span>
                </div>
              </Divider>
              <div className="grid">
                <div className="col-12 md:col-6">
                  <div className="flex flex-column gap-2">
                    <label htmlFor="first_name">First Name</label>
                    <InputText
                      id="first_name"
                      value={userData.first_name}
                      onChange={(e) => onInputChange(e, "first_name")}
                    />
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="flex flex-column gap-2">
                    <label htmlFor="last_name">Last Name</label>
                    <InputText
                      id="last_name"
                      value={userData.last_name}
                      onChange={(e) => onInputChange(e, "last_name")}
                    />
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="flex flex-column gap-2">
                    <label htmlFor="email">Email</label>
                    <InputText
                      id="email"
                      value={userData.email}
                      onChange={(e) => onInputChange(e, "email")}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="flex flex-column gap-2">
                    <label htmlFor="address">Address</label>
                    <InputTextarea
                      id="address"
                      value={userData.address}
                      onChange={(e) => onInputChange(e, "address")}
                    />
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="flex flex-column gap-2">
                    <label htmlFor="business_phone">Business Phone</label>
                    <InputText
                      id="business_phone"
                      value={userData.business_phone}
                      onChange={(e) => onInputChange(e, "business_phone")}
                    />
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="flex flex-column gap-2">
                    <label htmlFor="mobile_phone">Mobile Phone</label>
                    <InputText
                      id="mobile_phone"
                      value={userData.mobile_phone}
                      onChange={(e) => onInputChange(e, "mobile_phone")}
                    />
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="flex flex-column gap-2">
                    <label htmlFor="job_title">Job Title</label>
                    <InputText
                      id="job_title"
                      value={userData.job_title}
                      onChange={(e) => onInputChange(e, "job_title")}
                    />
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="flex flex-column gap-2">
                    <label htmlFor="city">City</label>
                    <InputText
                      id="city"
                      value={userData.city}
                      onChange={(e) => onInputChange(e, "city")}
                    />
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="flex flex-column gap-2">
                    <label htmlFor="zip">Zip</label>
                    <InputText
                      id="zip"
                      value={userData.zip}
                      onChange={(e) => onInputChange(e, "zip")}
                    />
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="flex flex-column gap-2">
                    <label htmlFor="signature">Signature</label>
                    <InputText
                      id="signature"
                      value={userData.signature}
                      onChange={(e) => onInputChange(e, "signature")}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 md:col-4">
              <Divider align="left">
                <div className="inline-flex align-items-center">
                  <span className="p-tag">
                    <i className="pi pi-user mr-2"></i>
                    <b>Company Information</b>
                  </span>
                </div>
              </Divider>
              <div className="grid">
                <div className="col-12">
                  <div className="flex flex-column gap-2">
                    <label htmlFor="company">Company</label>
                    <Dropdown
                      value={userData.vendor_id}
                      onChange={(e) => onInputChange(e, "vendor_id")}
                      options={vendors}
                      optionLabel="name"
                      optionValue="_id"
                      placeholder="Select a Company"
                      className="w-full"
                      disabled={isFetchingVendors}
                      panelFooterTemplate={panelFooterTemplate}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col-12">
              <div className="flex justify-content-end gap-2">
                <Button
                  label="Cancel"
                  icon="pi pi-times"
                  outlined
                  onClick={cancelUserCreation}
                />
                <Button
                  label={isPendingUpdate ? "Saving" : "Save"}
                  icon="pi pi-check"
                  onClick={handleUpdateUser}
                  loading={isPendingUpdate}
                  className="right-0"
                />
              </div>
            </div>
          </div>
          {isVisible && (
            <NewVendorDialog
              isVisible={isVisible}
              hideDialog={hideDialog}
              toast={toast}
            />
          )}
        </div>
      </>
    );
  }
}
