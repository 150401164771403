import { Button } from "primereact/button";

export default function Attachments({
  id,
  attachments,
  setAttachments,
  isDisabled,
}) {
  const handleRemoveFile = (event, file) => {
    event.preventDefault();
    const updatedFiles = attachments.filter((f) => f !== file);
    setAttachments(updatedFiles);
  };
  return (
    <div id={id}>
      {attachments.map((file, index) => (
        <div
          className="flex align-items-center justify-content-between mb-2"
          key={index}
        >
          <div className="w-17rem max-w-17rem">
            <a
              href={file.url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm"
            >
              {file.filename ? file.filename : file.name}
            </a>
          </div>
          <Button
            icon="pi pi-times"
            rounded
            text
            severity="danger"
            aria-label="Cancel"
            onClick={(event) => handleRemoveFile(event, file)}
            disabled={isDisabled !== undefined ? isDisabled : false}
          />
        </div>
      ))}
    </div>
  );
}
