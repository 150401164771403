import { Button } from "primereact/button";
import { useContext, useState } from "react";
import UploadPhotosDialog from "./UploadPhotosDialog";
import { Image } from "primereact/image";
import { Carousel } from "primereact/carousel";
import { AppContext } from "../../context/AppProvider";
import Attachments from "../Attachments";

const Photos = ({ dailyReport, setDailyReport, updateDailyReportMutation }) => {
  const responsiveOptions = [
    {
      breakpoint: "1400px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  const [isVisible, setIsVisible] = useState(false);
  const { user } = useContext(AppContext);

  const hideDialog = () => {
    setIsVisible(false);
  };

  const handleRemovePicture = (picture) => {
    let _dailyReport = { ...dailyReport };
    _dailyReport.pictures = _dailyReport.pictures.filter(
      (elem) => elem.name !== picture.name
    );
    setDailyReport(_dailyReport);

    let updatedData = { pictures: _dailyReport["pictures"] };
    updateDailyReportMutation({
      dailyReport: updatedData,
      dailyReportId: dailyReport._id,
    });
  };

  const handleRemoveAttachments = (pictures) => {
    let _dailyReport = { ...dailyReport };
    _dailyReport.pictures = pictures;
    setDailyReport(_dailyReport);

    let updatedData = { pictures: _dailyReport["pictures"] };
    updateDailyReportMutation({
      dailyReport: updatedData,
      dailyReportId: dailyReport._id,
    });
  };

  const pictureTemplate = (picture) => {
    return (
      <div className="border-1 surface-border border-round m-1 text-center py-2 px-3">
        <div className="mb-3">
          <Image src={picture.url} alt={picture.name} width="250" preview />
        </div>
        <div>
          <div className="flex flex-wrap gap-2 justify-content-center">
            <Button
              icon="pi pi-times"
              rounded
              severity="danger"
              onClick={() => handleRemovePicture(picture)}
              text
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="flex align-items-center justify-content-between gap-2">
        <span className="text-xl text-900 font-bold p-3">Photos</span>
      </div>
      <div className="pl-3 pb-3 flex flex-column gap-2">
        <Button
          className="p-4"
          label="Upload"
          icon="pi pi-plus"
          onClick={() => {
            setIsVisible(true);
          }}
          outlined
        />
        {user.is_procore_user && (
          <div className="w-6">
            <Attachments
              id="daily_report_attachments"
              attachments={dailyReport.pictures ? dailyReport.pictures : []}
              setAttachments={handleRemoveAttachments}
            />
          </div>
        )}
        {!user.is_procore_user && (
          <Carousel
            value={dailyReport?.pictures}
            numVisible={3}
            numScroll={3}
            responsiveOptions={responsiveOptions}
            itemTemplate={pictureTemplate}
          />
        )}
      </div>
      <UploadPhotosDialog
        isVisible={isVisible}
        hideDialog={hideDialog}
        dailyReport={dailyReport}
        setDailyReport={setDailyReport}
        updateDailyReportMutation={updateDailyReportMutation}
      />
    </div>
  );
};

export default Photos;
