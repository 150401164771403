import { useMutation } from "@tanstack/react-query";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { copyDailyReport } from "../../api/dailyReportsAPI";
import { queryClient } from "../../api/requestProcessor";
import { useContext, useState } from "react";
import { AppContext } from "../../context/AppProvider";
import { getErrorMessage } from "../../util/helper";
import { Calendar } from "primereact/calendar";

const CopyFromDialog = ({ isVisible, hideDialog, copyToDate }) => {
  const [copyFromDate, setCopyFromDate] = useState(new Date());
  const { toast } = useContext(AppContext);

  const { mutate: copyDailyReportMutation, isPending: isPendingCopy } =
    useMutation({
      mutationFn: copyDailyReport,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["daily-report"] });
        hideDialog();
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Daily Report Copied",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "Error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const onDateChange = (e) => {
    setCopyFromDate((prevCopyFromDate) => {
      const newDate = e.value;
      newDate.setHours(copyToDate.getHours());
      newDate.setSeconds(copyToDate.getSeconds());
      return newDate;
    });
  };

  const handleCopyDailyReport = () => {
    copyDailyReportMutation({
      copyFromDate: copyFromDate,
      copyToDate: copyToDate,
    });
  };

  const dialogFooter = (
    <>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button
        label={isPendingCopy ? "Copying" : "Copy"}
        icon="pi pi-check"
        onClick={handleCopyDailyReport}
        loading={isPendingCopy}
      />
    </>
  );

  return (
    <Dialog
      visible={isVisible}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Copy Daily Report From"
      modal
      className="p-fluid mx-2"
      footer={dialogFooter}
      onHide={hideDialog}
    >
      <div className="field">
        <Calendar value={copyFromDate} onChange={onDateChange} showIcon />
      </div>
    </Dialog>
  );
};

export default CopyFromDialog;
