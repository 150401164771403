import { Accordion, AccordionTab } from "primereact/accordion";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getSubmittal } from "../../api/submittalsAPI";
import SubmittalLoader from "../Loaders/SubmittalLoader";
import SubmittalDetailsBody from "./SubmittalDetailsBody";
import SubmittalDetailsWorkflow from "./SubmittalDetailsWorkflow";
import EditResponseDialog from "./EditResponseDialog";
import { getUserData } from "../../util/storage";
import MobileSubmittalDetailsWorkflow from "./MobileSubmittalDetailsWorkflow";
import { Button } from "primereact/button";
import { AppContext } from "../../context/AppProvider";

export default function SubmittalDetails() {
  const isProcoreUser = getUserData()["is_procore_user"];
  const [newSubmittal, setNewSubmittal] = useState({
    title: "",
    specification_section_id: undefined,
    number: 0,
    revision: 0,
    responsible_contractor_id: undefined,
    received_from_id: undefined,
    submittal_manager_id: undefined,
    status: {},
    received_date: undefined,
    issue_date: undefined,
    distribution_member_ids: [],
    private: false,
    first_name: "",
  });
  const [attachments, setAttachments] = useState([]);
  const [workflow, setWorkflow] = useState([
    { user_ids: [], approver_type: "Submitter", due_date: undefined },
  ]);
  const [selectedApprover, setSelectedApprover] = useState({});
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const params = useParams();
  const navigate = useNavigate();
  const { user } = useContext(AppContext);

  const { data: submittal, isPending: isPendingSubmittal } = useQuery({
    queryKey: ["submittals", params.submittalId],
    queryFn: ({ signal }) =>
      getSubmittal({ signal, submittalId: params.submittalId }),
    staleTime: 5 * 60 * 1000,
  });

  useEffect(() => {
    if (submittal) {
      setNewSubmittal(submittal);
      if (isProcoreUser) {
        const transformedWorkflow = submittal.workflow_data.reduce(
          (accumulator, currentArray, groupIndex) => {
            return accumulator.concat(
              currentArray.map((item) => ({
                ...item,
                workflow_group_number: groupIndex + 1,
              }))
            );
          },
          []
        );
        setWorkflow(transformedWorkflow);
      } else {
        const transformedWorkflow = submittal.workflow_data.reduce(
          (accumulator, currentArray, groupIndex) => {
            return accumulator.concat(
              currentArray.map((item) => ({ ...item, step: groupIndex + 1 }))
            );
          },
          []
        );
        setWorkflow(transformedWorkflow);
      }
      setAttachments(submittal.attachments);
    }
  }, [submittal]);

  const hideDialog = () => {
    setIsDialogVisible(false);
  };

  const navigateEditSubmittal = () => {
    navigate(`/submittals/${params.submittalId}/edit`);
  };

  if (!submittal) {
    return <SubmittalLoader />;
  }

  if (submittal) {
    return (
      <>
        <div className="flex w-full justify-content-between align-items-center">
          <p className="text-3xl w-10 font-medium line-height-1">
            Edit Submittal
          </p>
          {user.role > 1 && (
            <div>
              <Button
                className="px-5"
                label="Edit"
                onClick={() => navigateEditSubmittal()}
              />
            </div>
          )}
        </div>
        <Accordion multiple activeIndex={[0, 1, 2]}>
          <AccordionTab className="hidden sm:block" header="SUBMITTAL WORKFLOW">
            <SubmittalDetailsWorkflow
              workflow={workflow}
              setSelectedApprover={setSelectedApprover}
              setIsDialogVisible={setIsDialogVisible}
              ballInCourtIds={submittal?.ball_in_court.map((user) => {
                return user.id;
              })}
              submittalId={submittal.id}
            />
          </AccordionTab>
          <AccordionTab className="sm:hidden" header="SUBMITTAL WORKFLOW">
            <MobileSubmittalDetailsWorkflow
              workflow={workflow}
              setSelectedApprover={setSelectedApprover}
              setIsDialogVisible={setIsDialogVisible}
              ballInCourtIds={submittal?.ball_in_court.map((user) => {
                return user.id;
              })}
            />
          </AccordionTab>
          <AccordionTab header="GENERAL INFORMATION">
            <SubmittalDetailsBody submittal={newSubmittal} />
          </AccordionTab>
        </Accordion>
        {isDialogVisible && (
          <EditResponseDialog
            isVisible={isDialogVisible}
            hideDialog={hideDialog}
            submittalId={submittal.id}
            workflow={workflow}
            selectedApprover={selectedApprover}
          />
        )}
      </>
    );
  }
}
