import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useContext, useEffect, useState } from "react";
import { queryClient } from "../api/requestProcessor";
import { AppContext } from "../context/AppProvider";
import { getErrorMessage } from "../util/helper";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { getCurrentUser } from "../api/authAPI";
import { getUserSettings, setUserSettings } from "../api/settingsAPI";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { fetchTimeZones } from "../api/timeZonesAPI";

const languages = [
  { label: "English", value: "English" },
  { label: "Turkish", value: "Turkish" },
];

const scanner_times = [
  { label: "06:00 AM", value: 6 },
  { label: "07:00 AM", value: 7 },
  { label: "08:00 AM", value: 8 },
  { label: "09:00 AM", value: 9 },
  { label: "10:00 AM", value: 10 },
];

function UserSettings() {
  const [form, setForm] = useState({ should_create_cover_sheet: false });
  const { toast, setUser } = useContext(AppContext);
  const { data: userSettings, isFetching: isFetchingUserSettings } = useQuery({
    queryKey: ["userSettings"],
    queryFn: getUserSettings,
    staleTime: 5 * 60 * 1000,
  });

  const { data: timezones, isFetching: isFetchingTimezones } = useQuery({
    queryKey: ["timezones"],
    queryFn: fetchTimeZones,
    staleTime: 5 * 60 * 1000,
  });

  const {
    data: currentUser,
    isFetching: isFetchingCurrentUser,
    refetch,
  } = useQuery({
    queryKey: ["currentUser"],
    queryFn: getCurrentUser,
    staleTime: 5 * 60 * 1000,
    enabled: false,
  });

  const { mutate: setUserSettingsMutation, isPending: isPendingUserSettings } =
    useMutation({
      mutationFn: setUserSettings,
      onSuccess: async (response) => {
        queryClient.invalidateQueries({ queryKey: ["userSettings"] });
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: response.message,
          life: 3000,
        });
        const newCurrentUser = await refetch();
        setUser(newCurrentUser.data);
        localStorage.setItem("userData", JSON.stringify(newCurrentUser.data));
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  useEffect(() => {
    if (userSettings) {
      setForm(userSettings);
    }
  }, [userSettings]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleNumberChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleSave = () => {
    setUserSettingsMutation({ userSettings: form });
  };

  return (
    <div>
      <div className="text-3xl font-semibold mb-5">User Settings</div>
      <div className="flex flex-column gap-2 max-w-25rem">
        <div className="field-checkbox">
          <Checkbox
            id="should_create_cover_sheet"
            name="should_create_cover_sheet"
            checked={form.should_create_cover_sheet}
            onChange={handleChange}
          />
          <label htmlFor="is_active">
            Auto-generate submittal cover sheet and merge with submittal
            attachments
          </label>
        </div>
        <div className="flex-auto">
          <label htmlFor="max_open_tasks" className="font-bold block mb-2">
            Max Number of Open Tasks in Daily Briefing
          </label>
          <InputNumber
            inputId="max_open_tasks"
            name="max_open_tasks"
            value={form.max_open_tasks}
            onValueChange={handleNumberChange}
            className="w-full"
          />
        </div>
        <div className="flex-auto">
          <label
            htmlFor="email_scanner_language"
            className="font-bold block mb-2"
          >
            Email Scanner Language
          </label>
          <Dropdown
            id="email_scanner_language"
            name="email_scanner_language"
            value={form.email_scanner_language}
            options={languages}
            onChange={handleChange}
            placeholder="Select a Language"
            className="w-full"
          />
        </div>
        <div className="flex-auto">
          <label
            htmlFor="email_scanner_language"
            className="font-bold block mb-2"
          >
            Email Sent by AI Scanner at
          </label>
          <Dropdown
            id="email_scanner_time"
            name="email_scanner_time"
            value={form.email_scanner_time}
            options={scanner_times}
            onChange={handleChange}
            placeholder="Select a Time"
            className="w-full"
          />
        </div>
        <div className="flex-auto">
          <label htmlFor="timezone" className="font-bold block mb-2">
            Timezone
          </label>
          <Dropdown
            id="timezone"
            name="timezone"
            value={form.timezone}
            options={timezones}
            onChange={handleChange}
            placeholder="Select a Timezone"
            className="w-full"
            filter
          />
        </div>
        <Button
          label={
            isPendingUserSettings
              ? "Saving"
              : isFetchingUserSettings
              ? "Loading Settings"
              : "Save Settings"
          }
          onClick={handleSave}
          loading={isPendingUserSettings || isFetchingUserSettings}
          disabled={isPendingUserSettings || isFetchingUserSettings}
        />
      </div>
    </div>
  );
}

export default UserSettings;
