import { Accordion, AccordionTab } from "primereact/accordion";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppProvider";
import { useQuery } from "@tanstack/react-query";
import { getRfi } from "../api/rfisAPI";
import { useParams } from "react-router-dom";
import ViewRFIRequest from "../components/RFIs/ViewRFIRequest";
import ViewRFIGeneralInformation from "../components/RFIs/ViewRFIGeneralInformation";
import ViewRFIResponses from "../components/RFIs/ViewRFIResponses";

export default function ViewRFIPage() {
  const [rfi, setRfi] = useState({
    subject: "",
    rfi_body: "",
    attachments: [],
    number: 0,
  });
  const [attachments, setAttachments] = useState([]);
  const { user } = useContext(AppContext);
  const params = useParams();

  const isProcoreUser = user.is_procore_user;

  const { data: rfiData, isFetching: isPendingRfi } = useQuery({
    queryKey: ["rfis", params.rfiId],
    queryFn: ({ signal }) => getRfi({ signal, rfiId: params.rfiId }),
    staleTime: 5 * 60 * 1000,
  });

  useEffect(() => {
    if (rfiData) {
      if (isProcoreUser) {
        setRfi({ ...rfiData, rfi_body: rfiData.questions[0].plain_text_body });
        setAttachments(rfiData.questions[0].attachments);
      } else {
        setRfi(rfiData);
        setAttachments(rfiData.attachments);
      }
    }
  }, [rfiData]);

  return (
    <>
      <p className="text-3xl w-10 font-medium line-height-1">{`RFI #${rfi.number}: ${rfi.subject}`}</p>
      <Accordion multiple activeIndex={[0, 1, 2]}>
        <AccordionTab header="REQUEST">
          <ViewRFIRequest
            rfi={rfi}
            attachments={attachments}
            isPending={isPendingRfi}
          />
        </AccordionTab>
        <AccordionTab header="RESPONSES">
          <ViewRFIResponses
            rfi={rfi}
            isProcoreUser={isProcoreUser}
            rfiId={params.rfiId}
            isPending={isPendingRfi}
          />
        </AccordionTab>
        <AccordionTab header="GENERAL INFORMATION">
          <ViewRFIGeneralInformation
            rfi={rfi}
            isProcoreUser={isProcoreUser}
            isPending={isPendingRfi}
          />
        </AccordionTab>
      </Accordion>
    </>
  );
}
