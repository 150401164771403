import React, { useState, useRef } from "react";

import { useQuery } from "@tanstack/react-query";
import { fetchContacts } from "../../api/contactsAPI";
import ContactTable from "./ContactTable";
import CreateContact from "./CreateContact";
import DeleteContact from "./DeleteContact";

export default function Contacts() {
  let emptyContact = {
    email: "",
  };

  const [dialog, setDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [contact, setContact] = useState(emptyContact);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [editMode, setEditMode] = useState(null);

  const { data: contacts, isFetching } = useQuery({
    queryKey: ["contacts"],
    queryFn: ({ signal }) => fetchContacts({ signal }),
    staleTime: 5 * 60 * 1000,
  });

  const dt = useRef(null);

  const openNew = () => {
    setContact(emptyContact);
    setEditMode(false);
    setDialog(true);
  };

  const hideDialog = () => {
    setDialog(false);
  };

  const hideDeleteDialog = () => {
    setDeleteDialog(false);
  };

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const edit = (contact) => {
    setContact({ ...contact });
    setEditMode(true);
    setDialog(true);
  };

  const confirmDelete = (contact) => {
    setContact(contact);
    setDeleteDialog(true);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _contact = { ...contact };

    _contact[`${name}`] = val;

    setContact(_contact);
  };

  return (
    <>
      <ContactTable
        dt={dt}
        selectedContacts={selectedContacts}
        setSelectedContacts={setSelectedContacts}
        contacts={contacts}
        isPending={isFetching}
        edit={edit}
        confirmDelete={confirmDelete}
        exportCSV={exportCSV}
        openNew={openNew}
      />

      {/* TODO: Add MobileDataTable */}

      {dialog && (
        <CreateContact
          dialog={dialog}
          hideDialog={hideDialog}
          contact={contact}
          onInputChange={onInputChange}
          editMode={editMode}
        />
      )}

      {deleteDialog && (
        <DeleteContact
          deleteDialog={deleteDialog}
          hideDeleteDialog={hideDeleteDialog}
          contact={contact}
        />
      )}
    </>
  );
}
