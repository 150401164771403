import { Accordion, AccordionTab } from "primereact/accordion";
import { useContext, useEffect, useState } from "react";
import SubmittalGeneralInformation from "./SubmittalGeneralInformation";
import SubmittalWorkflow from "./NewSubmittalWorkflow";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import {
  createSubmittal,
  createSubmittalCoversheet,
} from "../../api/submittalsAPI";
import { queryClient } from "../../api/requestProcessor";
import { uploadFiles } from "../../api/filesAPI";
import { getUserData } from "../../util/storage";
import MobileSubmittalWorkflow from "./MobileSubmittalWorkflow";
import { AppContext } from "../../context/AppProvider";
import { getErrorMessage } from "../../util/helper";

export default function NewSubmittal() {
  const isProcoreUser = getUserData()["is_procore_user"];
  const { user } = useContext(AppContext);
  const [newSubmittal, setNewSubmittal] = useState({
    title: "",
    specification_section_id: undefined,
    number: 0,
    revision: 0,
    responsible_contractor_id: undefined,
    received_from_id: undefined,
    submittal_manager_id: isProcoreUser ? user.procore_user.id : user.id,
    status: isProcoreUser ? {} : "Open",
    received_date: undefined,
    issue_date: undefined,
    distribution_member_ids: [],
    private: false,
    first_name: "",
  });
  const [files, setFiles] = useState([]);
  const [uploadReset, setUploadReset] = useState(false);
  const [workflow, setWorkflow] = useState([
    {
      user_ids: [],
      approver_type: "Submitter",
      due_date: undefined,
      response: {},
    },
  ]);
  const [label, setLabel] = useState("Save");

  const { toast } = useContext(AppContext);

  const navigate = useNavigate();

  const { mutate: createSubmittalMutation, isPending: isPendingCreation } =
    useMutation({
      mutationFn: createSubmittal,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["submittals"] });
        queryClient.invalidateQueries({ queryKey: ["openItems"] });
        navigate("/submittals");
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Submittal Created",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const {
    mutateAsync: createSubmittalCoversheetMutation,
    isPending: isPendingCoversheet,
  } = useMutation({
    mutationFn: createSubmittalCoversheet,
    onSuccess: () => {
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Coversheet Generated",
        life: 3000,
      });
      setUploadReset(true);
    },
    onError: (error) => {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: getErrorMessage(error),
        life: 3000,
      });
    },
  });

  const { mutateAsync: uploadFilesMutation, isPending: isPendingUpload } =
    useMutation({
      mutationFn: uploadFiles,
      onSuccess: () => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "File Uploaded",
          life: 3000,
        });
        setUploadReset(true);
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  useEffect(() => {
    if (isPendingUpload) {
      setLabel("Uploading Files");
    } else if (isPendingCreation) {
      setLabel("Saving Changes");
    } else {
      setLabel(isProcoreUser ? "Create and Send Emails" : "Create");
    }
  }, [isPendingUpload, isPendingCreation]);

  const cancelSubmittalCreation = () => {
    navigate("/submittals");
  };

  const printErrorMessage = (errorMessage) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: errorMessage,
      life: 3000,
    });
  };

  const checkValidation = () => {
    if (newSubmittal.number === null && newSubmittal.number === undefined) {
      printErrorMessage("Submittal Number area is required!");
      return false;
    } else if (!newSubmittal.title) {
      printErrorMessage("Submittal Title area is required!");
      return false;
    } else if (!newSubmittal.received_from_id) {
      printErrorMessage("Submittal Received From area is required!");
      return false;
    } else if (!newSubmittal.submittal_manager_id) {
      printErrorMessage("Submittal Manager area is required!");
      return false;
    } else if (Object.keys(newSubmittal.status).length === 0) {
      printErrorMessage("Status area is required!");
      return false;
    }
    return true;
  };

  const handleCreateSubmittal = async () => {
    if (checkValidation()) {
      let updatedAttachments = [];
      let isErrorUpdatedAttachment = false;

      if (files.length > 0) {
        const attachmentNames = files.map((file) => file.name);
        const submittalData = { ...newSubmittal };
        let coversheetId;

        try {
          let uploadPromises;
          if (user.settings.should_create_cover_sheet) {
            coversheetId = await createSubmittalCoversheetMutation({
              submittalData: submittalData,
              attachments: attachmentNames,
              workflow: workflow,
            });

            uploadPromises = files.map(async (file) => {
              return await uploadFilesMutation({
                files: [file],
                coversheet_id: coversheetId,
              });
            });
          } else {
            uploadPromises = files.map(async (file) => {
              return await uploadFilesMutation({
                files: [file],
              });
            });
          }

          const responses = await Promise.all(uploadPromises);
          updatedAttachments = [...responses];
        } catch (error) {
          isErrorUpdatedAttachment = true;
        }
      }

      if (!isErrorUpdatedAttachment) {
        createSubmittalMutation({
          submittalData: newSubmittal,
          attachments: updatedAttachments,
          workflow: workflow,
        });
      }
    }
  };

  return (
    <>
      <p className="text-3xl w-10 font-medium line-height-1">
        Create New Submittal
      </p>
      <Accordion multiple activeIndex={[0, 1, 2]}>
        <AccordionTab header="GENERAL INFORMATION">
          <SubmittalGeneralInformation
            newSubmittal={newSubmittal}
            setNewSubmittal={setNewSubmittal}
            setFiles={setFiles}
            uploadReset={uploadReset}
            setUploadReset={setUploadReset}
            editMode={false}
          />
        </AccordionTab>
        <AccordionTab className="hidden sm:block" header="SUBMITTAL WORKFLOW">
          <SubmittalWorkflow
            workflow={workflow}
            setWorkflow={setWorkflow}
            editMode={false}
          />
        </AccordionTab>
        <AccordionTab className="sm:hidden" header="SUBMITTAL WORKFLOW">
          <MobileSubmittalWorkflow
            workflow={workflow}
            setWorkflow={setWorkflow}
            editMode={false}
          />
        </AccordionTab>
      </Accordion>

      <div className="flex justify-content-end gap-2 mt-2">
        <Button
          label="Cancel"
          icon="pi pi-times"
          outlined
          onClick={cancelSubmittalCreation}
        />
        <Button
          label={label}
          icon="pi pi-check"
          onClick={handleCreateSubmittal}
          loading={isPendingCreation || isPendingUpload || isPendingCoversheet}
          disabled={isPendingCreation || isPendingUpload || isPendingCoversheet}
          className="right-0"
        />
      </div>
    </>
  );
}
