import { Dialog } from "primereact/dialog";

const TermsDialog = ({ visible, onHide }) => {
  return (
    <Dialog
      header="Terms of Service"
      visible={visible}
      onHide={onHide}
      style={{ width: "50vw" }}
      maximizable
    >
      <div className="mt-2">
        {/* Replace this with your actual Terms of Service content */}
        <h3>1. Terms</h3>
        <p>
          By accessing our service, you agree to be bound by these terms of
          service...
        </p>

        <h3>2. Use License</h3>
        <p>Permission is granted to temporarily access the materials...</p>

        <h3>3. Disclaimer</h3>
        <p>The materials on our service are provided on an 'as is' basis...</p>
      </div>
    </Dialog>
  );
};

export default TermsDialog;
