import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { AppContext } from "../../context/AppProvider";
import FilterSidebar from "../FilterSidebar";

const cols = [
  { field: "number", header: "Number" },
  { field: "title", header: "Title" },
  { field: "approver_name", header: "Approver" },
  { field: "formatted_assignees", header: "Assignees" },
  { field: "status", header: "Status" },
  { field: "location", header: "Location" },
  { field: "room", header: "Room" },
  { field: "reference", header: "Reference" },
  { field: "notes", header: "Notes" },
];

const exportColumns = cols.map((col) => ({
  title: col.header,
  dataKey: col.field,
}));

const PunchListItemTable = ({
  isFetching,
  localPunchListItems,
  total,
  setQueryParams,
  openCreateDialog,
  openUpdateDialog,
  openDeleteDialog,
  users,
  initialFilters,
  punchListId,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [filterSidebarVisible, setFilterSidebarVisible] = useState(false);
  const [filterQuery, setFilterQuery] = useState({});
  const [lazyState, setLazyState] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: { punch_list_id: punchListId },
  });
  const [numOfSelectedFilters, setNumOfSelectedFilters] = useState(0);
  const dt = useRef(null);
  const navigate = useNavigate();
  const { user } = useContext(AppContext);

  useEffect(() => {
    setQueryParams(mergeAllQueries());
  }, [lazyState, setQueryParams]);

  useEffect(() => {
    console.log(lazyState);
    setLazyState({ ...lazyState, first: 0, page: 0 });
  }, [filterQuery, setQueryParams]);

  const mergeAllQueries = () => {
    const pageQuery = { page: lazyState.page + 1, per_page: lazyState.rows };
    const searchQuery = { search: searchInput };
    return {
      ...pageQuery,
      ...searchQuery,
      ...filterQuery,
      punch_list_id: punchListId,
    };
  };

  const onSearchInputChange = (e) => {
    const val = (e.target && e.target.value) || "";
    setSearchInput(val);
  };

  const handleSearch = (e, isFromButton) => {
    if (e.key === "Enter" || isFromButton) {
      setLazyState({ ...lazyState, page: 0, first: 0 });
    }
  };

  const onResetSearch = (e) => {
    const allQueries = mergeAllQueries();
    allQueries.search = "";
    setQueryParams(allQueries);
    setSearchInput("");
  };

  const onPage = (e) => {
    setLazyState(e);
  };

  const navigateBack = () => {
    navigate("/punch-lists");
  };

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.autoTable(exportColumns, localPunchListItems);
        doc.save("punch_list_items.pdf");
      });
    });
  };

  const header = () => {
    return (
      <div className="flex align-items-center justify-content-between">
        <div className="flex gap-2 align-items-center">
          <Button icon="pi pi-arrow-left" onClick={navigateBack} />
          <Button
            label="New"
            icon="pi pi-plus"
            severity="success"
            onClick={openCreateDialog}
            disabled={user.role < 2}
          />
          <Button
            label="Export CSV"
            icon="pi pi-file"
            onClick={() => exportCSV(false)}
            data-pr-tooltip="CSV"
            outlined
          />
          <Button
            label="Export PDF"
            icon="pi pi-file-pdf"
            onClick={exportPdf}
            data-pr-tooltip="PDF"
            outlined
          />
          <Button
            label={
              numOfSelectedFilters === 0
                ? "Filter"
                : `Filter (${numOfSelectedFilters})`
            }
            icon="pi pi-filter"
            onClick={() => setFilterSidebarVisible(true)}
          />
        </div>

        <div className="flex align-items-center gap-2">
          <span className="p-input-icon-right">
            {searchInput && (
              <i
                className="pi pi-times mr-1 cursor-pointer"
                onClick={onResetSearch}
              />
            )}
            <InputText
              value={searchInput}
              onChange={onSearchInputChange}
              placeholder="Keyword Search"
              className="mr-1"
              onKeyDown={(e) => handleSearch(e, false)}
            />
          </span>
          <Button icon="pi pi-search" onClick={(e) => handleSearch(e, true)} />
        </div>
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex">
        <Button
          icon="pi pi-pencil"
          rounded
          onClick={() => openUpdateDialog(rowData)}
          text
        />
        {user.role > 1 && (
          <Button
            type="button"
            icon="pi pi-times"
            rounded
            onClick={() => openDeleteDialog(rowData)}
            text
            severity="danger"
          />
        )}
      </div>
    );
  };

  const approverTemplate = (rowData) => {
    const approver = users?.find((user) => user.id === rowData.approver_id);
    return <span>{approver?.name}</span>;
  };

  const assigneesTemplate = (rowData) => {
    const assignees = users?.filter((user) =>
      rowData?.assignee_ids.includes(user.id)
    );
    return assignees?.map((assignee) => (
      <>
        <span>{assignee?.name}</span>
        <br />
      </>
    ));
  };

  return (
    <>
      <DataTable
        ref={dt}
        value={localPunchListItems}
        tableStyle={{ minWidth: "50rem" }}
        header={header}
        dataKey="_id"
        emptyMessage="No punch list item found."
        paginator
        className="hidden md:block"
        loading={isFetching}
        lazy
        onPage={onPage}
        first={lazyState.first}
        rows={10}
        totalRecords={total}
      >
        <Column key="number" field="number" header="Number" />
        <Column key="title" field="title" header="Title" />
        <Column
          key="approver_id"
          field="approver_id"
          header="Approver"
          body={approverTemplate}
          exportField="approver_name"
        />
        <Column
          key="assignee_ids"
          field="assignee_ids"
          header="Assignees"
          body={assigneesTemplate}
          exportField="formatted_assignees"
        />
        <Column key="status" field="status" header="Status" />
        <Column
          headerStyle={{ width: "5rem", textAlign: "center" }}
          bodyStyle={{ textAlign: "center", overflow: "visible" }}
          body={actionBodyTemplate}
        />
      </DataTable>
      <FilterSidebar
        isVisible={filterSidebarVisible}
        setIsVisible={setFilterSidebarVisible}
        setFilterQuery={setFilterQuery}
        initialFilters={initialFilters}
        pageType="punch_list_items"
        setNumOfSelectedFilters={setNumOfSelectedFilters}
        additionalData={punchListId}
      />
    </>
  );
};

export default PunchListItemTable;
