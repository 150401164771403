import { fetchPunchListItems } from "../api/punchListsAPI";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import PunchListItemTable from "../components/PunchList/PunchListItemTable";
import PunchListItemMobileTable from "../components/PunchList/PunchListItemMobileTable";
import NewPunchListItemDialog from "../components/PunchList/NewPunchListItemDialog";
import UpdatePunchListItemDialog from "../components/PunchList/UpdatePunchListItemDialog";
import DeletePunchListItemDialog from "../components/PunchList/DeletePunchListItemDialog";
import { useEffect, useState } from "react";
import { fetchEntities } from "../api/entitiesAPI";

const initialFilters = [
  {
    key: "statuses",
    value: "Status",
    selected_options: [],
    options: [],
  },
  {
    key: "assignee_ids",
    value: "Assignee",
    selected_options: [],
    options: [],
  },
  {
    key: "approver_ids",
    value: "Approver",
    selected_options: [],
    options: [],
  },
];

const PunchListItem = () => {
  const { punchListId } = useParams();

  const [queryParams, setQueryParams] = useState({
    page: 1,
    per_page: 10,
    punch_list_id: punchListId,
  });
  const [isVisibleCreate, setIsVisibleCreate] = useState(false);
  const [isVisibleUpdate, setIsVisibleUpdate] = useState(false);
  const [isVisibleDelete, setIsVisibleDelete] = useState(false);
  const [selectedPunchListItem, setSelectedPunchListItem] = useState(null);
  const [localPunchListItems, setLocalPunchListItems] = useState([]);

  const { data: users } = useQuery({
    queryKey: ["entities"],
    queryFn: fetchEntities,
    staleTime: 5 * 60 * 1000,
  });

  const {
    data: punchListItems,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["punch-lists", queryParams],
    queryFn: ({ signal }) =>
      fetchPunchListItems({ signal, queryParams: queryParams }),
    staleTime: 5 * 60 * 1000,
  });

  useEffect(() => {
    if (punchListItems && users) {
      let _punchListItems = punchListItems.punch_list_items.map((elem) => {
        const assignees = users
          ?.filter((user) => elem?.assignee_ids.includes(user.id))
          .map((user) => user.name)
          .join(" ");

        const formatted_assignees = users
          ?.filter((user) => elem?.assignee_ids.includes(user.id))
          .map((user) => user.name)
          .join(",");

        return {
          ...elem,
          approver_name: users.find((user) => user.id === elem.approver_id)
            .name,
          assignees: assignees,
          formatted_assignees: formatted_assignees,
        };
      });
      setLocalPunchListItems(_punchListItems);
    }
  }, [punchListItems, users]);

  const openCreateDialog = () => {
    setIsVisibleCreate(true);
  };

  const hideCreateDialog = () => {
    setIsVisibleCreate(false);
  };

  const openUpdateDialog = (rowData) => {
    setSelectedPunchListItem(rowData);
    setIsVisibleUpdate(true);
  };

  const hideUpdateDialog = () => {
    setIsVisibleUpdate(false);
  };

  const openDeleteDialog = (rowData) => {
    setSelectedPunchListItem(rowData);
    setIsVisibleDelete(true);
  };

  const hideDeleteDialog = () => {
    setIsVisibleDelete(false);
  };

  return (
    <>
      <PunchListItemTable
        isFetching={isFetching}
        localPunchListItems={localPunchListItems}
        total={punchListItems && punchListItems.total}
        setQueryParams={setQueryParams}
        openCreateDialog={openCreateDialog}
        openUpdateDialog={openUpdateDialog}
        openDeleteDialog={openDeleteDialog}
        users={users}
        initialFilters={initialFilters}
        punchListId={punchListId}
      />
      <PunchListItemMobileTable
        localPunchListItems={localPunchListItems}
        openCreateDialog={() => setIsVisibleCreate(true)}
        openUpdateDialog={openUpdateDialog}
        openDeleteDialog={openDeleteDialog}
        setQueryParams={setQueryParams}
        isFetching={isFetching}
        total={punchListItems && punchListItems.total}
        initialFilters={initialFilters}
        punchListId={punchListId}
      />
      {isVisibleCreate && (
        <NewPunchListItemDialog
          isVisible={isVisibleCreate}
          hideDialog={hideCreateDialog}
          punchListId={punchListId}
        />
      )}
      <UpdatePunchListItemDialog
        isVisible={isVisibleUpdate}
        hideDialog={hideUpdateDialog}
        punchListItem={selectedPunchListItem}
        punchListId={punchListId}
      />
      <DeletePunchListItemDialog
        isVisible={isVisibleDelete}
        hideDialog={hideDeleteDialog}
        punchListId={punchListId}
        punchListItemId={selectedPunchListItem?._id}
      />
    </>
  );
};

export default PunchListItem;
