import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useContext, useState } from "react";
import { queryClient } from "../../api/requestProcessor";
import { useMutation } from "@tanstack/react-query";
import { AppContext } from "../../context/AppProvider";
import { uploadFilesToFolder } from "../../api/foldersAPI";
import { getErrorMessage } from "../../util/helper";
import CustomFileUpload from "../FileUpload/FileUpload";

export default function FileDialog({ isVisible, hideDialog, folderId }) {
  const [files, setFiles] = useState([]);
  const [uploadReset, setUploadReset] = useState(false);

  const { toast } = useContext(AppContext);

  const { mutate: uploadFileMutation, isPending: isPendingUpload } =
    useMutation({
      mutationFn: uploadFilesToFolder,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["folders", folderId] });
        hideDialog();
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Files Uploaded",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const onFileChange = (files) => {
    setFiles(files);
  };

  const uploadFile = () => {
    uploadFileMutation({ files: files, folderId: folderId });
  };

  const dialogFooter = (
    <>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button
        label={isPendingUpload ? "Creating" : "Create"}
        icon="pi pi-check"
        onClick={uploadFile}
        loading={isPendingUpload}
      />
    </>
  );

  return (
    <Dialog
      visible={isVisible}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Upload Files"
      modal
      className="p-fluid mx-2"
      footer={dialogFooter}
      onHide={hideDialog}
    >
      <div className="field">
        <div className="mb-3">
          <CustomFileUpload
            accept=""
            onFileChange={onFileChange}
            reset={uploadReset}
            setReset={setUploadReset}
          />
        </div>
      </div>
    </Dialog>
  );
}
