import React, { useState, useRef } from "react";

import { fetchConsultants } from "../../api/consultantsAPI";
import { useQuery } from "@tanstack/react-query";
import CreateConsultant from "./CreateConsultant";
import DeleteConsultant from "./DeleteConsultant";
import ConsultantTable from "./ConsultantTable";

export default function Consultants() {
  let emptyConsultant = {
    role: "",
    vendor_id: "",
    project_manager_ids: [],
    distribution_ids: [],
  };

  const [dialog, setDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [consultant, setConsultant] = useState(emptyConsultant);
  const [selectedConsultants, setSelectedConsultants] = useState([]);
  const [editMode, setEditMode] = useState(null);

  const {
    data: consultants,
    isFetching,
    isError,
    error,
  } = useQuery({
    queryKey: ["consultants"],
    queryFn: ({ signal }) => fetchConsultants({ signal }),
    staleTime: 5 * 60 * 1000,
  });

  const dt = useRef(null);

  const openNew = () => {
    setConsultant(emptyConsultant);
    setEditMode(false);
    setDialog(true);
  };

  const hideDialog = () => {
    setDialog(false);
  };

  const hideDeleteDialog = () => {
    setDeleteDialog(false);
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const edit = (consultant) => {
    setConsultant({ ...consultant });
    setEditMode(true);
    setDialog(true);
  };

  const confirmDelete = (consultant) => {
    setConsultant(consultant);
    setDeleteDialog(true);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _consultant = { ...consultant };

    _consultant[`${name}`] = val;

    setConsultant(_consultant);
  };

  return (
    <>
      <ConsultantTable
        dt={dt}
        selectedConsultants={selectedConsultants}
        setSelectedConsultants={setSelectedConsultants}
        consultants={consultants}
        isPending={isFetching}
        edit={edit}
        confirmDelete={confirmDelete}
        exportCSV={exportCSV}
        openNew={openNew}
      />

      {/* TODO: Add MobileDataTable */}

      <CreateConsultant
        dialog={dialog}
        hideDialog={hideDialog}
        consultant={consultant}
        onInputChange={onInputChange}
        editMode={editMode}
      />

      <DeleteConsultant
        deleteDialog={deleteDialog}
        hideDeleteDialog={hideDeleteDialog}
        consultant={consultant}
      />
    </>
  );
}
