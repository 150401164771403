import axios from "axios";
import { getAuthToken } from "../util/auth";
import API_BASE_URL from "../util/apiConfig";

const authClient = axios.create({
  baseURL: `${API_BASE_URL}/auth`,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

authClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response.data);
  }
);

export async function login({ credentials }) {
  const formData = new FormData();
  formData.append("username", credentials.username);
  formData.append("password", credentials.password);

  const response = await authClient.post("/token", formData);
  return response.data;
}

export async function getCurrentUser({ signal }) {
  const token = getAuthToken();
  const response = await authClient.get(
    "/me",
    { headers: { Authorization: `Bearer ${token}` } },
    signal
  );
  return response.data;
}

export async function sendOtpCode(credentials) {
  const response = await authClient.post("/send-otp", credentials, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
}

export async function verifyOtpCode(credentials) {
  const response = await authClient.post("/verify-otp", credentials, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
}

export async function registerUser(user) {
  const token = getAuthToken();
  const response = await authClient.post("/register", user, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
}

export async function getOnboardInfo({ signal }) {
  const token = getAuthToken();
  const response = await authClient.get("/onboard-info", {
    headers: { Authorization: `Bearer ${token}` },
    signal: signal,
  });
  return response.data;
}

export async function fetchProcoreAuthUrl({ signal }) {
  const token = getAuthToken();
  const response = await authClient.get("/procore", {
    headers: { Authorization: `Bearer ${token}` },
    signal: signal,
  });
  return response.data;
}

export async function addUserToWaitlist(userData) {
  const response = await authClient.post("/waitlist", userData, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
}
