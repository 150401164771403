import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useContext, useState } from "react";
import { fetchVendors } from "../../api/vendorsAPI";
import { queryClient } from "../../api/requestProcessor";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AppContext } from "../../context/AppProvider";
import { updateDailyReport } from "../../api/dailyReportsAPI";
import { getErrorMessage } from "../../util/helper";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { AutoComplete } from "primereact/autocomplete";

const initialCrew = {
  vendor_name: "",
  crew_count: 0,
  crew_hours: 8,
  crew_note: "",
};

export default function NewCrewDialog({
  isVisible,
  hideDialog,
  dailyReport,
  setDailyReport,
}) {
  const [filteredVendors, setFilteredVendors] = useState([]);
  const { toast } = useContext(AppContext);

  const [newCrew, setNewCrew] = useState(initialCrew);

  const { data: vendors, isFetching: isFetchingVendors } = useQuery({
    queryKey: ["vendors"],
    queryFn: fetchVendors,
    staleTime: 5 * 60 * 1000,
  });

  const { mutate: updateDailyReportMutation, isPending: isPendingUpdate } =
    useMutation({
      mutationFn: updateDailyReport,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["daily-report"] });
        hideDialog();
        setNewCrew(initialCrew);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Crew added to the daily report",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "Error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const searchVendors = (event) => {
    setFilteredVendors(
      vendors
        .filter((item) =>
          item.name.toLowerCase().includes(event.query.toLowerCase())
        )
        .map((item) => item.name)
    );
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewCrew((prevNewCrew) => ({
      ...prevNewCrew,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleIntegerChange = (e, name) => {
    let { value } = e;
    if (value == null && name === "crew_count") {
      value = 0;
    }
    if (value == null && name === "crew_hours") {
      value = 8;
    }
    setNewCrew((prevNewCrew) => ({
      ...prevNewCrew,
      [name]: value,
    }));
  };

  const handleUpdateDailyReport = () => {
    let _dailyReport = { ...dailyReport };
    _dailyReport.crew = [..._dailyReport.crew, newCrew];

    setDailyReport(_dailyReport);
    updateDailyReportMutation({
      dailyReport: { crew: _dailyReport.crew },
      dailyReportId: _dailyReport._id,
    });
  };

  const dialogFooter = (
    <>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button
        label={isPendingUpdate ? "Creating" : "Create"}
        icon="pi pi-check"
        onClick={handleUpdateDailyReport}
        loading={isPendingUpdate}
      />
    </>
  );

  return (
    <Dialog
      visible={isVisible}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Crew Details"
      modal
      className="p-fluid mx-2"
      footer={dialogFooter}
      onHide={hideDialog}
    >
      <div className="field">
        <label htmlFor="vendor_name" className="font-bold">
          Crew Vendor
        </label>
        <AutoComplete
          id="vendor_name"
          name="vendor_name"
          suggestions={filteredVendors}
          completeMethod={searchVendors}
          value={newCrew.vendor_name}
          onChange={handleChange}
          required
        />
      </div>
      <div className="field">
        <label htmlFor="crew_count" className="font-bold">
          Crew Count
        </label>
        <InputNumber
          id="crew_count"
          value={newCrew.crew_count}
          onChange={(e) => handleIntegerChange(e, "crew_count")}
          required
        />
      </div>
      <div className="field">
        <label htmlFor="crew_hours" className="font-bold">
          Hours per Day
        </label>
        <InputNumber
          id="crew_hours"
          value={newCrew.crew_hours}
          onChange={(e) => handleIntegerChange(e, "crew_hours")}
          required
        />
      </div>
      <div className="field">
        <label htmlFor="crew_note" className="font-bold">
          Note
        </label>
        <InputTextarea
          id="crew_note"
          name="crew_note"
          value={newCrew.crew_note}
          onChange={handleChange}
          required
        />
      </div>
    </Dialog>
  );
}
