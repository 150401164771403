import React from "react";
import { Card } from "primereact/card";
import { WaitlistTable } from "../components/Admin/Waitlist";

const WaitlistPage = () => {
  return (
    <div className="grid">
      <div className="col-12">
        <Card title="Waitlist Management">
          <WaitlistTable />
        </Card>
      </div>
    </div>
  );
};

export default WaitlistPage;
