import { Dialog } from "primereact/dialog";

const PrivacyDialog = ({ visible, onHide }) => {
  return (
    <Dialog
      header="Privacy Policy"
      visible={visible}
      onHide={onHide}
      style={{ width: "50vw" }}
      maximizable
    >
      <div className="mt-2">
        {/* Replace this with your actual Privacy Policy content */}
        <h3>1. Information Collection</h3>
        <p>We collect information that you provide directly to us...</p>

        <h3>2. Use of Information</h3>
        <p>
          We use the information we collect to operate and improve our
          services...
        </p>

        <h3>3. Information Sharing</h3>
        <p>
          We do not share your personal information with third parties except as
          described in this policy...
        </p>
      </div>
    </Dialog>
  );
};

export default PrivacyDialog;
