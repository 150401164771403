import axios from "axios";
import { getAuthToken } from "../util/auth";
import { getCompanyId, getProjectId, getUserData } from "../util/storage";
import API_BASE_URL from "../util/apiConfig";
import { generateBaseUrl } from "../util/helper";

const token = getAuthToken();

const responsesClient = axios.create({
  baseURL: `${API_BASE_URL}`,
  headers: {
    Authorization: "Bearer " + token,
  },
});

export async function createReply({ replyData, attachments, rfiId }) {
  const isProcoreUser = getUserData()["is_procore_user"];
  const projectId = getProjectId();
  const companyId = getCompanyId();

  const baseUrl = generateBaseUrl(
    isProcoreUser,
    projectId,
    `rfis/${rfiId}/replies`
  );

  if (isProcoreUser) {
    replyData["prostore_file_ids"] = attachments.map((obj) => {
      return obj.id;
    });
  } else {
    replyData["files"] = attachments;
  }

  const response = await responsesClient.post(baseUrl, replyData, {
    params: {
      company_id: companyId,
    },
  });
  return response.data;
}

export async function deleteReply({ replyId, rfiId }) {
  const isProcoreUser = getUserData()["is_procore_user"];
  const projectId = getProjectId();
  const companyId = getCompanyId();

  const baseUrl = generateBaseUrl(
    isProcoreUser,
    projectId,
    `rfis/${rfiId}/replies/${replyId}`
  );

  const response = await responsesClient.delete(baseUrl, {
    params: {
      company_id: companyId,
    },
  });
  return response.data;
}
