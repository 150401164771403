import axios from "axios";
import { getAuthToken } from "../util/auth";
import API_BASE_URL from "../util/apiConfig";
import { getProjectId, getProjects, getCompanyId } from "../util/storage";

const token = getAuthToken();

const dailyReportsClient = axios.create({
  baseURL: `${API_BASE_URL}/daily-reports`,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

dailyReportsClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response.data);
  }
);

export async function fetchDailyReports(signal) {
  const projectId = getProjectId();
  const response = await dailyReportsClient.get("", {
    signal,
    params: {
      project_id: projectId,
    },
  });
  return response.data;
}

export async function fetchDailyReportByDate(signal, date) {
  const projectId = getProjectId();
  const formattedDate = date.toISOString().split("T")[0];
  const response = await dailyReportsClient.get("/from-date", {
    signal,
    params: {
      project_id: projectId,
      date: formattedDate,
    },
  });
  return response.data;
}

export async function updateDailyReport({ dailyReport, dailyReportId }) {
  const response = await dailyReportsClient.patch(
    `/${dailyReportId}`,
    dailyReport
  );
  return response.data;
}

export async function copyDailyReport({ copyFromDate, copyToDate }) {
  const projectId = getProjectId();
  const formattedCopyFromDate = copyFromDate.toISOString().split("T")[0];
  const formattedCopyToDate = copyToDate.toISOString().split("T")[0];
  const response = await dailyReportsClient.post(
    `/copy`,
    {},
    {
      params: {
        project_id: projectId,
        copy_from_date: formattedCopyFromDate,
        copy_to_date: formattedCopyToDate,
      },
    }
  );
  return response.data;
}

export async function generateDailyReportPdf({ dailyReportId }) {
  const companyId = getCompanyId();
  const projectId = getProjectId();
  const projects = getProjects();

  const matchedProject = projects.find(
    (project) => project.id.toString() === projectId.toString()
  );

  const response = await dailyReportsClient.get(
    `/${dailyReportId}/generate-pdf`,
    {
      params: {
        project_id: projectId,
        project_name: matchedProject?.name,
        company_id: companyId,
      },
    }
  );

  return response.data;
}
