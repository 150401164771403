import { DataView } from "primereact/dataview";
import CustomCard from "../MobileDataTable/CustomCard";
import { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { getProperty } from "../../util/property";
import { getUserData } from "../../util/storage";
import { useNavigate } from "react-router-dom";

export default function EntityMobileDataTable({
  items,
  name,
  vendor,
  mobile_phone,
  email_address,
  isPending,
  openDeleteDialog,
}) {
  const isProcoreUser = getUserData().is_procore_user;

  const [filterValue, setFilterValue] = useState("");
  const [filteredItems, setFilteredItems] = useState(items);

  const navigate = useNavigate();

  useEffect(() => {
    filterItems();
  }, [filterValue, items]);

  const itemTemplate = (item) => {
    return (
      <CustomCard
        id={item.id}
        title={item[name]}
        body={item.vendor ? item.vendor["name"] : ""}
        number={item.mobile_phone ? item["mobile_phone"] : ""}
        assignees={item[email_address] ? item[email_address] : ""}
        status=""
        editMode={!isProcoreUser}
        editHandler={navigateDetailedUser}
        deleteHandler={openDeleteDialog}
        item={item}
      />
    );
  };

  const onFilterChange = (e) => {
    const value = (e.target && e.target.value) || "";
    setFilterValue(value);
  };

  const filterItems = () => {
    if (items) {
      const lowerCaseFilterValue = filterValue.toLowerCase();
      const filtered = items.filter((item) => {
        const itemName = name ? item[name]?.toLowerCase() : "";
        const itemVendorName = item[vendor]
          ? item[vendor]["name"]?.toLowerCase()
          : "";
        const itemEmailAdress = item[email_address]
          ? getProperty(item, email_address)?.toLowerCase()
          : "";
        const itemMobileNumber = item[mobile_phone]
          ? getProperty(item, mobile_phone)?.toLowerCase()
          : "";

        return (
          itemName.includes(lowerCaseFilterValue) ||
          itemVendorName.includes(lowerCaseFilterValue) ||
          itemEmailAdress.includes(lowerCaseFilterValue) ||
          itemMobileNumber.includes(lowerCaseFilterValue)
        );
      });
      setFilteredItems(filtered);
    }
  };

  const navigateNewUser = () => {
    navigate("new");
  };

  const navigateDetailedUser = (item) => {
    navigate(`${item.id}`);
  };

  const renderHeader = () => {
    return (
      <div className="flex flex-column gap-2">
        {!isProcoreUser && (
          <Button
            label="Create User"
            icon="pi pi-plus"
            severity="success"
            onClick={navigateNewUser}
            className="mr-2"
          />
        )}
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="w-full"
            value={filterValue}
            onChange={onFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <DataView
      header={header}
      value={filteredItems}
      itemTemplate={itemTemplate}
      paginator
      rows={10}
      className="sm:hidden"
      loading={isPending}
    />
  );
}
