import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import CustomFileUpload from "../FileUpload/FileUpload";
import Attachments from "../Attachments";
import { RFIRequestLoader } from "../Loaders/RFILoader";

export default function RFIRequest({
  rfi,
  setRfi,
  setFiles,
  uploadReset,
  setUploadReset,
  isProcoreUser,
  attachments,
  setAttachments,
  isPending,
}) {
  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _rfi = { ...rfi };

    _rfi[`${name}`] = val;

    setRfi(_rfi);
  };
  const onFileChange = (files) => {
    setFiles(files);
  };

  if (isPending) {
    return <RFIRequestLoader />;
  }

  if (!isPending) {
    return (
      <>
        <div>
          <div className="grid">
            <div className="col-12 md:col-6">
              <div className="flex flex-column gap-2">
                <label htmlFor="subject">Subject</label>
                <InputText
                  id="subject"
                  value={rfi.subject}
                  onChange={(e) => onInputChange(e, "subject")}
                />
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col-12 md:col-6">
              <div className="flex flex-column gap-2">
                <label htmlFor="question">Question</label>
                <InputTextarea
                  id="question"
                  value={rfi.rfi_body}
                  onChange={(e) => onInputChange(e, "rfi_body")}
                  autoResize
                  rows={8}
                  cols={30}
                />
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className="flex flex-column gap-2">
                <label htmlFor="attachments">Attachments</label>
                <CustomFileUpload
                  id="attachments"
                  accept=""
                  onFileChange={onFileChange}
                  reset={uploadReset}
                  setReset={setUploadReset}
                />
              </div>
              {attachments && attachments.length ? (
                <div className="field mt-2">
                  <Attachments
                    id={"rfi-attachments"}
                    attachments={attachments}
                    setAttachments={setAttachments}
                    isDisabled={false}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
