import axios from "axios";
import { getAuthToken } from "../util/auth";
import API_BASE_URL from "../util/apiConfig";
import { getProjectId } from "../util/storage";

const token = getAuthToken();

const teamClient = axios.create({
  baseURL: `${API_BASE_URL}`,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

teamClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response.data);
  }
);

export async function fetchTeamMembers() {
  const projectId = getProjectId();
  const response = await teamClient.get(`/projects/${projectId}/team-members`);
  return response.data;
}

export async function setTeamMembers({ teamData }) {
  const projectId = getProjectId();
  const response = await teamClient.post(
    `/projects/${projectId}/team-members`,
    teamData
  );
  return response.data;
}
