import React, { useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  fetchChangeOrders,
  createChangeOrder,
  updateChangeOrder,
} from "../../api/commitmentsAPI";
import { queryClient } from "../../api/requestProcessor";
import ChangeOrderDialog from "./ChangeOrderDialog";
import DeleteChangeOrderDialog from "./DeleteChangeOrderDialog";
import { getErrorMessage } from "../../util/helper";

const ChangeOrdersTable = ({ commitmentId }) => {
  const [changeOrderDialog, setChangeOrderDialog] = useState(false);
  const [deleteChangeOrderDialog, setDeleteChangeOrderDialog] = useState(false);
  const [editingChangeOrder, setEditingChangeOrder] = useState(null);
  const [files, setFiles] = useState([]);
  const [uploadReset, setUploadReset] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const toast = useRef(null);

  const { data: changeOrders, isLoading } = useQuery({
    queryKey: ["changeOrders", commitmentId],
    queryFn: ({ signal }) =>
      fetchChangeOrders({
        signal,
        queryParams: {},
        commitmentId: commitmentId,
      }),
  });

  const createMutation = useMutation({
    mutationFn: createChangeOrder,
    onSuccess: () => {
      queryClient.invalidateQueries(["changeOrders", commitmentId]);
      hideDialog();
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Change Order Created",
        life: 3000,
      });
    },
    onError: (error) => {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: getErrorMessage(error),
        life: 3000,
      });
    },
  });

  const updateMutation = useMutation({
    mutationFn: updateChangeOrder,
    onSuccess: () => {
      queryClient.invalidateQueries(["changeOrders", commitmentId]);
      hideDialog();
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Change Order Updated",
        life: 3000,
      });
    },
    onError: (error) => {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: getErrorMessage(error),
        life: 3000,
      });
    },
  });

  const openNew = () => {
    setEditingChangeOrder({
      title: "",
      status: "draft",
      executed: false,
      paid: false,
      visibility: [],
    });
    setFiles([]);
    setAttachments([]);
    setChangeOrderDialog(true);
  };

  const editChangeOrder = (changeOrder) => {
    setEditingChangeOrder({ ...changeOrder });
    setAttachments(changeOrder.attachments || []);
    setChangeOrderDialog(true);
  };

  const hideDialog = () => {
    setChangeOrderDialog(false);
    setEditingChangeOrder(null);
    setFiles([]);
    setAttachments([]);
  };

  const deleteChangeOrder = (changeOrder) => {
    setEditingChangeOrder({ ...changeOrder });
    setDeleteChangeOrderDialog(true);
  };

  const hideDeleteDialog = () => {
    setChangeOrderDialog(false);
    setDeleteChangeOrderDialog(false);
  };

  const onSubmit = async (data) => {
    if (editingChangeOrder._id) {
      updateMutation.mutate({
        commitmentId,
        changeOrderId: editingChangeOrder._id,
        changeOrder: data,
      });
    } else {
      createMutation.mutate({
        commitmentId,
        changeOrder: data,
      });
    }
  };

  const dateBodyTemplate = (date) => {
    return date ? new Date(date).toLocaleDateString() : "";
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value || 0);
  };

  const currencyTemplate = (rowData, field) => {
    return <span>{formatCurrency(rowData[field])}</span>;
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex">
        <Button
          type="button"
          icon="pi pi-pencil"
          rounded
          text
          className="mr-2"
          onClick={() => editChangeOrder(rowData)}
        />
        <Button
          type="button"
          icon="pi pi-times"
          rounded
          onClick={() => deleteChangeOrder(rowData)}
          text
          severity="danger"
        />
      </div>
    );
  };

  return (
    <div>
      <Toast ref={toast} />

      <div className="flex align-items-center justify-content-between mb-2">
        <span className="text-xl font-medium">Change Orders</span>
        <Button
          className="w-9rem"
          label="New"
          icon="pi pi-plus"
          onClick={openNew}
        />
      </div>

      <DataTable
        value={changeOrders?.change_orders || []}
        loading={isLoading}
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 25]}
        dataKey="_id"
      >
        <Column field="number" header="Number" />
        <Column field="title" header="Title" />
        <Column field="status" header="Status" />
        <Column
          field="due_date"
          header="Due Date"
          body={(rowData) => dateBodyTemplate(rowData.due_date)}
        />
        <Column
          field="paid_date"
          header="Paid Date"
          body={(rowData) => dateBodyTemplate(rowData.paid_date)}
        />
        <Column
          field="amount"
          header="Amount"
          body={(rowData) => currencyTemplate(rowData, "amount")}
        />
        <Column
          body={actionBodyTemplate}
          exportable={false}
          style={{ width: "4rem" }}
        />
      </DataTable>

      {changeOrderDialog && (
        <ChangeOrderDialog
          visible={changeOrderDialog}
          onHide={hideDialog}
          changeOrder={editingChangeOrder}
          setChangeOrder={setEditingChangeOrder}
          onSubmit={onSubmit}
          isSubmitting={createMutation.isPending || updateMutation.isPending}
          files={files}
          setFiles={setFiles}
          uploadReset={uploadReset}
          setUploadReset={setUploadReset}
          attachments={attachments}
          setAttachments={setAttachments}
          toast={toast}
        />
      )}

      <DeleteChangeOrderDialog
        visible={deleteChangeOrderDialog}
        onHide={hideDeleteDialog}
        changeOrder={editingChangeOrder}
        commitmentId={commitmentId}
        toast={toast}
      />
    </div>
  );
};

export default ChangeOrdersTable;
