import React from "react";
import { getUserData } from "../../util/storage";
import { Button } from "primereact/button";
import { truncateFileName } from "../../util/helper";

export default function SubmittalDetailsBody({ submittal }) {
  const isProcoreUser = getUserData()["is_procore_user"];
  return (
    <>
      <div>
        <div className="grid">
          <div className="col-12 md:col-6">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="title">
                Title
              </label>
              <div id="title" className="flex h-3rem align-items-center">
                {submittal.title}
              </div>
            </div>
          </div>
          <div className="col-12 md:col-6">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="title">
                Attachments
              </label>
              {submittal && submittal.attachments.length > 0 ? (
                <div>
                  {submittal.attachments.map((attachment) => (
                    <Button
                      key={attachment.id}
                      label={truncateFileName(attachment.filename, 14)}
                      severity="secondary"
                      icon="pi pi-external-link"
                      className="p-mr-2 p-mb-2 mt-3"
                      onClick={() => window.open(attachment.url, "_blank")}
                      outlined
                    />
                  ))}
                </div>
              ) : (
                <div>No attachments</div>
              )}
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="specification-section">
                Spec Section
              </label>
              <div
                id="specification-section"
                className="flex h-3rem align-items-center"
              >
                {submittal.specification_section
                  ? `${submittal.specification_section?.description} ${submittal.specification_section?.number}`
                  : ""}
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="grid">
              <div className="col-6">
                <div className="flex flex-column gap-2">
                  <label className="font-semibold" htmlFor="number">
                    Number
                  </label>
                  <div id="number" className="flex h-3rem align-items-center">
                    {submittal.number}
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="flex flex-column gap-2">
                  <label className="font-semibold" htmlFor="revision">
                    Revision
                  </label>
                  <div id="revision" className="flex h-3rem align-items-center">
                    {submittal.revision}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="submittal-type">
                Submittal Type
              </label>
              <div
                id="submittal-type"
                className="flex h-3rem align-items-center"
              >
                --
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="submittal-package">
                Submittal Package
              </label>
              <div
                id="submittal-package"
                className="flex h-3rem align-items-center"
              >
                --
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="responsible-contractor">
                Responsible Contractor
              </label>
              <div
                id="responsible-contractor"
                className="flex h-3rem align-items-center"
              >
                {submittal.responsible_contractor?.name}
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="received-from">
                Received From
              </label>
              <div
                id="received-from"
                className="flex h-3rem align-items-center"
              >
                {submittal.received_from?.name}
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="submittal-manager">
                Submittal Manager
              </label>
              <div
                id="submittal-manager"
                className="flex h-3rem align-items-center"
              >
                {submittal.submittal_manager?.name}
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="status">
                Status
              </label>
              <div id="status" className="flex h-3rem align-items-center">
                {isProcoreUser ? submittal.status.name : submittal.status}
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="submit-by">
                Submit By
              </label>
              <div id="submit-by" className="flex h-3rem align-items-center">
                --
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="received-date">
                Received Date
              </label>
              <div
                id="received-date"
                className="flex h-3rem align-items-center"
              >
                {submittal.received_date}
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="issue-date">
                Issue Date
              </label>
              <div id="issue-date" className="flex h-3rem align-items-center">
                {submittal.issue_date}
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="final-due-date">
                Final Due Date
              </label>
              <div
                id="final-due-date"
                className="flex h-3rem align-items-center"
              >
                --
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="cost-code">
                Cost Code
              </label>
              <div id="cost-code" className="flex h-3rem align-items-center">
                --
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="location">
                Location
              </label>
              <div id="location" className="flex h-3rem align-items-center">
                --
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="linked-drawings">
                Linked Drawings
              </label>
              <div
                id="linked-drawings"
                className="flex h-3rem align-items-center"
              >
                --
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label
                className="font-semibold"
                htmlFor="distribution-member-ids"
              >
                Distribution List
              </label>
              <div id="distribution-member-ids">
                {submittal.distribution_members?.map((user, index) => (
                  <React.Fragment key={user.id}>
                    <span>{user.name}</span>
                    {index !== submittal.distribution_members.length - 1 && (
                      <br />
                    )}{" "}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="ball-in-court">
                Ball In Court
              </label>
              <div
                id="ball-in-court"
                className="flex h-3rem align-items-center"
              >
                {submittal.ball_in_court.map((user, index) => (
                  <React.Fragment key={user.id}>
                    <span>{user.name}</span>
                    {index !== submittal.ball_in_court?.length - 1 && (
                      <br />
                    )}{" "}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="lead-time">
                Lead Time
              </label>
              <div id="lead-time" className="flex h-3rem align-items-center">
                --
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="required-on-site-date">
                Required On-Site Date
              </label>
              <div
                id="required-on-site-date"
                className="flex h-3rem align-items-center"
              >
                --
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="private">
                Private
              </label>
              <div id="private" className="flex h-3rem align-items-center">
                {submittal.private ? "Yes" : "No"}
              </div>
            </div>
          </div>
          <div className="col-12 md:col-6">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="description">
                Description
              </label>
              <div id="description" className="flex h-3rem align-items-center">
                {submittal.description}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
