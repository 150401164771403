import { Button } from "primereact/button";
import { DataView } from "primereact/dataview";
import { InputText } from "primereact/inputtext";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppProvider";

export default function PunchListMobileTable({
  punchLists,
  openCreateDialog,
  openUpdateDialog,
  openDeleteDialog,
  openPunchList,
}) {
  const [filterValue, setFilterValue] = useState("");
  const [filteredItems, setFilteredItems] = useState(punchLists);
  const { user } = useContext(AppContext);

  useEffect(() => {
    filterItems();
  }, [filterValue, punchLists]);

  const onFilterChange = (e) => {
    const value = (e.target && e.target.value) || "";
    setFilterValue(value);
  };

  const filterItems = () => {
    if (punchLists) {
      const lowerCaseFilterValue = filterValue.toLowerCase();
      const filtered = punchLists.filter((item) => {
        return item.title.includes(lowerCaseFilterValue);
      });
      setFilteredItems(filtered);
    }
  };

  const itemTemplate = (item) => {
    return (
      <div className="min-w-full p-2 mb-2 shadow-3 border-round-md">
        <div className="p-2">{item.title}</div>
        <div className="flex justify-content-end">
          <Button
            type="button"
            icon="pi pi-eye"
            rounded
            onClick={() => openPunchList(item)}
            text
          />
          {user.role > 1 && (
            <Button
              type="button"
              icon="pi pi-pencil"
              rounded
              onClick={() => openUpdateDialog(item)}
              text
            />
          )}
          {user.role > 1 && (
            <Button
              type="button"
              icon="pi pi-times"
              rounded
              onClick={() => openDeleteDialog(item)}
              text
              severity="danger"
            />
          )}
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className="flex flex-column gap-2">
        <Button
          label="New"
          icon="pi pi-plus"
          severity="success"
          onClick={openCreateDialog}
          className="mr-2"
          disabled={user.role < 2}
        />

        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="w-full"
            value={filterValue}
            onChange={onFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <DataView
      header={header}
      value={filteredItems}
      itemTemplate={itemTemplate}
      paginator
      rows={10}
      className="sm:hidden"
    />
  );
}
