import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useContext, useEffect, useState } from "react";
import NewPunchListDialog from "./NewPunchListDialog";
import UpdatePunchListDialog from "./UpdatePunchListDialog";
import DeletePunchListDialog from "./DeletePunchListDialog";
import { useNavigate } from "react-router-dom";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { InputText } from "primereact/inputtext";
import PunchListMobileTable from "./PunchListMobileTable";
import { AppContext } from "../../context/AppProvider";

const PunchListTable = ({ punchLists }) => {
  const [isVisibleCreate, setIsVisibleCreate] = useState(false);
  const [isVisibleUpdate, setIsVisibleUpdate] = useState(false);
  const [isVisibleDelete, setIsVisibleDelete] = useState(false);
  const [selectedPunchList, setSelectedPunchList] = useState(null);
  const [filters, setFilters] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const navigate = useNavigate();
  const { user } = useContext(AppContext);

  useEffect(() => {
    initFilters();
  }, []);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      title: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
    setGlobalFilterValue("");
  };

  const clearFilter = () => {
    initFilters();
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const hideCreateDialog = () => {
    setIsVisibleCreate(false);
  };

  const openUpdateDialog = (rowData) => {
    setSelectedPunchList(rowData);
    setIsVisibleUpdate(true);
  };

  const hideUpdateDialog = () => {
    setIsVisibleUpdate(false);
  };

  const openDeleteDialog = (rowData) => {
    setSelectedPunchList(rowData);
    setIsVisibleDelete(true);
  };

  const hideDeleteDialog = () => {
    setIsVisibleDelete(false);
  };

  const handleRowChange = (value) => {
    navigate(`/punch-lists/${value._id}`);
  };

  const header = () => {
    return (
      <div className="flex align-items-center justify-content-between gap-2">
        <Button
          severity="success"
          label="New"
          icon="pi pi-plus"
          onClick={() => {
            setIsVisibleCreate(true);
          }}
          disabled={user.role < 2}
        />
        <div className="flex align-items-center gap-2">
          <Button
            type="button"
            icon="pi pi-filter-slash"
            label="Clear Filters"
            outlined
            onClick={clearFilter}
          />
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex">
        <Button
          type="button"
          icon="pi pi-pencil"
          rounded
          onClick={() => openUpdateDialog(rowData)}
          text
        />
        <Button
          type="button"
          icon="pi pi-times"
          rounded
          onClick={() => openDeleteDialog(rowData)}
          text
          severity="danger"
        />
      </div>
    );
  };

  return (
    <>
      <DataTable
        value={punchLists}
        tableStyle={{ minWidth: "50rem" }}
        header={header}
        selectionMode="single"
        selection={selectedPunchList}
        dataKey="_id"
        onSelectionChange={(e) => handleRowChange(e.value)}
        filters={filters}
        globalFilterFields={["title"]}
        emptyMessage="No punch list found."
        paginator
        rows={8}
        className="hidden md:block"
      >
        <Column
          key="title"
          field="title"
          header="Title"
          filter
          filterField="title"
        />
        {user.role > 1 && (
          <Column
            headerStyle={{ width: "5rem", textAlign: "center" }}
            bodyStyle={{ textAlign: "center", overflow: "visible" }}
            body={actionBodyTemplate}
          />
        )}
      </DataTable>
      <PunchListMobileTable
        className="md:hidden"
        punchLists={punchLists}
        openCreateDialog={() => setIsVisibleCreate(true)}
        openUpdateDialog={openUpdateDialog}
        openDeleteDialog={openDeleteDialog}
        openPunchList={handleRowChange}
      />
      <NewPunchListDialog
        isVisible={isVisibleCreate}
        hideDialog={hideCreateDialog}
      />
      <UpdatePunchListDialog
        isVisible={isVisibleUpdate}
        hideDialog={hideUpdateDialog}
        punchList={selectedPunchList}
      />
      <DeletePunchListDialog
        isVisible={isVisibleDelete}
        hideDialog={hideDeleteDialog}
        punchListId={selectedPunchList?._id}
      />
    </>
  );
};

export default PunchListTable;
