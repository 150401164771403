import { useMutation } from "@tanstack/react-query";
import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { initializeChatbot } from "../../api/chatbotsAPI";
import { queryClient } from "../../api/requestProcessor";
import { useContext } from "react";
import { AppContext } from "../../context/AppProvider";
import { getErrorMessage } from "../../util/helper";

export default function ChatbotGreeting({ chatbot, messages, isPending }) {
  const { toast } = useContext(AppContext);

  const { mutate: initializeChatbotMutation, isPending: isPendingInitialize } =
    useMutation({
      mutationFn: initializeChatbot,
      onSuccess: (response) => {
        queryClient.invalidateQueries({ queryKey: ["chatbot"] });
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const handleChatbotInitialization = (e) => {
    e.preventDefault();
    initializeChatbotMutation();
  };

  if (isPending && !chatbot) {
    return (
      <div className="flex align-items-center justify-content-center h-full flex-column">
        <div className="text-3xl font-semibold text-center mb-2">
          Fetching Chatbot Data. Please Wait.
        </div>
        <div>
          <ProgressBar
            mode="indeterminate"
            style={{ height: "6px" }}
          ></ProgressBar>
        </div>
      </div>
    );
  }

  if (chatbot && !chatbot.is_initialized) {
    return (
      <div className="flex align-items-center justify-content-center h-full flex-column">
        <div className="text-3xl font-semibold text-center mb-2">
          In order to use chatbot utilities, you must first initialize the
          chatbot.
        </div>
        <div>
          <Button
            label="Initialize Chatbot"
            onClick={handleChatbotInitialization}
            raised
            loading={isPendingInitialize}
            disabled={isPendingInitialize}
          />
        </div>
      </div>
    );
  }

  if (chatbot && chatbot.is_initialized && chatbot.status === "Initializing") {
    return (
      <div className="flex align-items-center justify-content-center h-full flex-column">
        <div className="text-3xl font-semibold text-center mb-2">
          Your chatbot is initializing. After initializing, you can use the
          chatbot. Try refreshing the page shortly.
        </div>
      </div>
    );
  }
  if (
    chatbot &&
    chatbot.is_initialized &&
    chatbot.status === "Created" &&
    !messages.length
  ) {
    return (
      <div className="flex align-items-center justify-content-center h-full flex-column">
        <div className="text-3xl font-semibold text-center mb-2">
          Chatbot initialized successfully. How can I help you?
        </div>
      </div>
    );
  }
}
