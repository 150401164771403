import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { getUserData } from "../../util/storage";
import { getErrorMessage, truncateFileName } from "../../util/helper";
import { useContext } from "react";
import { AppContext } from "../../context/AppProvider";
import { setBallInCourt } from "../../api/submittalsAPI";
import { queryClient } from "../../api/requestProcessor";
import { useMutation } from "@tanstack/react-query";

export default function SubmittalDetailsWorkflow({
  workflow,
  setSelectedApprover,
  setIsDialogVisible,
  ballInCourtIds,
  submittalId,
}) {
  const isProcoreUser = getUserData()["is_procore_user"];

  const { user, toast } = useContext(AppContext);

  const { mutate: updateBallInCourt, isPending: isPendingUpdate } = useMutation(
    {
      mutationFn: setBallInCourt,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["submittals"] });
        queryClient.invalidateQueries({
          queryKey: ["submittal", submittalId],
        });
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Ball In Court Updated",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    }
  );

  const editResponse = (rowData) => {
    if (setSelectedApprover) {
      setSelectedApprover(rowData);
    }
    if (setIsDialogVisible) {
      setIsDialogVisible(true);
    }
  };

  const handleBallInCourt = (rowData) => {
    const ballInCourt = workflow
      .filter((item) => item.step === rowData.step)
      .map((item) => item.user);

    updateBallInCourt({
      submittalId: submittalId,
      ballInCourt: ballInCourt,
      step: rowData.step,
    });
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        {!isProcoreUser &&
          rowData.response.status === "Pending" &&
          ((ballInCourtIds &&
            ballInCourtIds.includes(user.id) &&
            user.id === rowData.user.id) ||
            (ballInCourtIds.includes(rowData.user.id) && user.role > 1)) && (
            <div className="flex justify-content-end">
              <Button
                label="Edit Response"
                size="small"
                onClick={() => editResponse(rowData)}
              />
            </div>
          )}
      </>
    );
  };

  const attachmentsTemplate = (rowData) => {
    return rowData.attachments.map((attachment) => (
      <Button
        key={attachment.id}
        label={truncateFileName(attachment.filename)}
        severity="secondary"
        icon="pi pi-external-link"
        className="mb-2"
        onClick={() => window.open(attachment.url, "_blank")}
        outlined
      />
    ));
  };

  const headerTemplate = (data) => {
    return (
      <div className="font-semibold flex align-items-center justify-content-between">
        #{isProcoreUser ? data.workflow_group_number : data.step}
        {!isProcoreUser &&
          user.role > 1 &&
          !ballInCourtIds.includes(data.user.id) && (
            <Button
              label="Set Ball In Court"
              onClick={() => handleBallInCourt(data)}
              size="small"
            />
          )}
      </div>
    );
  };

  return (
    <div className="card">
      <DataTable
        value={workflow}
        rowGroupMode="subheader"
        groupRowsBy={isProcoreUser ? "workflow_group_number" : "step"}
        rowGroupHeaderTemplate={headerTemplate}
        tableStyle={{ minWidth: "50rem" }}
      >
        <Column
          key="user"
          columnKey="user"
          header="Name"
          field="user.name"
          style={{ minWidth: "10rem" }}
        />
        <Column
          key="sent_date"
          columnKey="sent_date"
          header="Sent Date"
          field="sent_date"
        />
        <Column
          key="due_date"
          columnKey="due_date"
          header="Due Date"
          field="due_date"
        />
        <Column
          key="returned_date"
          columnKey="returned_date"
          header="Returned Date"
          field="returned_date"
        />
        <Column
          key="response"
          columnKey="response"
          header="Response"
          field={isProcoreUser ? "response.name" : "response.status"}
        />
        <Column
          key="comments"
          columnKey="comments"
          header="Comments"
          field={"comment"}
        />
        <Column
          key="attachments"
          columnKey="attachments"
          header="Attachments"
          body={attachmentsTemplate}
        />
        <Column
          header="Actions"
          body={actionBodyTemplate}
          exportable={false}
          style={{ minWidth: "3rem" }}
        ></Column>
      </DataTable>
    </div>
  );
}
