import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useContext, useState } from "react";
import { fetchVendors } from "../../api/vendorsAPI";
import { queryClient } from "../../api/requestProcessor";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AppContext } from "../../context/AppProvider";
import { updateDailyReport } from "../../api/dailyReportsAPI";
import { getErrorMessage } from "../../util/helper";
import { InputTextarea } from "primereact/inputtextarea";
import { AutoComplete } from "primereact/autocomplete";

const initialDelivery = {
  delivery_vendor_name: "",
  delivery_vendor_note: "",
};

export default function NewDeliveryDialog({
  isVisible,
  hideDialog,
  dailyReport,
  setDailyReport,
}) {
  const [filteredVendors, setFilteredVendors] = useState([]);
  const { toast } = useContext(AppContext);

  const [newDelivery, setNewDelivery] = useState(initialDelivery);

  const { data: vendors, isFetching: isFetchingVendors } = useQuery({
    queryKey: ["vendors"],
    queryFn: fetchVendors,
    staleTime: 5 * 60 * 1000,
  });

  const { mutate: updateDailyReportMutation, isPending: isPendingUpdate } =
    useMutation({
      mutationFn: updateDailyReport,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["daily-report"] });
        hideDialog();
        setNewDelivery(initialDelivery);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Delivery added to the daily report",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "Error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const searchVendors = (event) => {
    setFilteredVendors(
      vendors
        .filter((item) =>
          item.name.toLowerCase().includes(event.query.toLowerCase())
        )
        .map((item) => item.name)
    );
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewDelivery((prevNewDelivery) => ({
      ...prevNewDelivery,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleUpdateDailyReport = () => {
    let _dailyReport = { ...dailyReport };
    _dailyReport.deliveries = [..._dailyReport.deliveries, newDelivery];

    setDailyReport(_dailyReport);
    updateDailyReportMutation({
      dailyReport: { deliveries: _dailyReport.deliveries },
      dailyReportId: _dailyReport._id,
    });
  };

  const dialogFooter = (
    <>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button
        label={isPendingUpdate ? "Creating" : "Create"}
        icon="pi pi-check"
        onClick={handleUpdateDailyReport}
        loading={isPendingUpdate}
      />
    </>
  );

  return (
    <Dialog
      visible={isVisible}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Delivery Details"
      modal
      className="p-fluid mx-2"
      footer={dialogFooter}
      onHide={hideDialog}
    >
      <div className="field">
        <label htmlFor="delivery_vendor_name" className="font-bold">
          Delivery Vendor
        </label>
        <AutoComplete
          id="delivery_vendor_name"
          name="delivery_vendor_name"
          suggestions={filteredVendors}
          completeMethod={searchVendors}
          value={newDelivery.delivery_vendor_name}
          onChange={handleChange}
          required
        />
      </div>
      <div className="field">
        <label htmlFor="delivery_vendor_note" className="font-bold">
          Note
        </label>
        <InputTextarea
          id="delivery_vendor_note"
          name="delivery_vendor_note"
          value={newDelivery.delivery_vendor_note}
          onChange={handleChange}
          required
        />
      </div>
    </Dialog>
  );
}
