import { Button } from "primereact/button";
import { DataView } from "primereact/dataview";
import { Image } from "primereact/image";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppProvider";
import FilterSidebar from "../FilterSidebar";

export default function PunchListItemMobileTable({
  localPunchListItems,
  openCreateDialog,
  openUpdateDialog,
  openDeleteDialog,
  setQueryParams,
  isFetching,
  total,
  initialFilters,
  punchListId,
}) {
  const [searchInput, setSearchInput] = useState("");
  const [lazyState, setLazyState] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });
  const [filterSidebarVisible, setFilterSidebarVisible] = useState(false);
  const [filterQuery, setFilterQuery] = useState({});
  const [numOfSelectedFilters, setNumOfSelectedFilters] = useState(0);
  const navigate = useNavigate();
  const { user } = useContext(AppContext);

  useEffect(() => {
    setQueryParams(mergeAllQueries());
  }, [lazyState, setQueryParams]);

  useEffect(() => {
    setLazyState({ ...lazyState, first: 0, page: 0 });
  }, [filterQuery, setQueryParams]);

  const mergeAllQueries = () => {
    const pageQuery = { page: lazyState.page + 1, per_page: lazyState.rows };
    const searchQuery = { search: searchInput };
    return {
      ...pageQuery,
      ...searchQuery,
      ...filterQuery,
      punch_list_id: punchListId,
    };
  };

  const navigateBack = () => {
    navigate("/punch-lists");
  };

  const onSearchInputChange = (e) => {
    const val = (e.target && e.target.value) || "";
    setSearchInput(val);
  };

  const handleSearch = (e, isFromButton) => {
    if (e.key === "Enter" || isFromButton) {
      setLazyState({ ...lazyState, page: 0, first: 0 });
    }
  };

  const onResetSearch = (e) => {
    setSearchInput("");
    setLazyState({ ...lazyState, page: 0, first: 0 });
  };

  const onPage = (e) => {
    setLazyState(e);
  };

  const itemTemplate = (item) => {
    return (
      <div className="min-w-full p-2 mb-2 shadow-3 border-round-md">
        <div className="flex p-2 gap-2">
          <div className="flex-0">
            {item.files && item.files.length > 0 && (
              <Image
                src={item?.files[0].url}
                alt={item?.files[0].name}
                className="shadow-2 md:hidden"
                width="100"
                preview
                rounded
              />
            )}
          </div>
          <div className="flex-grow flex-column gap-2">
            <div>
              <span className="font-bold"></span> {item.title}
            </div>
            <div className="mt-2">
              <span className="font-bold">Assignees:</span>{" "}
              {item.formatted_assignees}
            </div>
            <div className="mt-2">
              <Tag
                value={item.status}
                severity={item.status === "Complete" ? "success" : "danger"}
              ></Tag>
            </div>
          </div>
        </div>
        <div className="flex justify-content-end">
          <Button
            type="button"
            icon="pi pi-pencil"
            rounded
            onClick={() => openUpdateDialog(item)}
            text
          />
          {user.role > 1 && (
            <Button
              type="button"
              icon="pi pi-times"
              rounded
              onClick={() => openDeleteDialog(item)}
              text
              severity="danger"
            />
          )}
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className="flex flex-column gap-2">
        <div className="flex gap-2">
          <Button icon="pi pi-arrow-left" size="small" onClick={navigateBack} />
          <Button
            label="New"
            icon="pi pi-plus"
            severity="success"
            onClick={openCreateDialog}
            className="w-full"
            disabled={user.role < 2}
          />
        </div>

        <Button
          label={
            numOfSelectedFilters === 0
              ? "Filter"
              : `Filter (${numOfSelectedFilters})`
          }
          icon="pi pi-filter"
          onClick={() => setFilterSidebarVisible(true)}
        />

        <div className="flex w-full">
          <span className="p-input-icon-right mr-auto">
            {searchInput && (
              <i
                className="pi pi-times mr-1 cursor-pointer w-full"
                onClick={onResetSearch}
              />
            )}
            <InputText
              value={searchInput}
              onChange={onSearchInputChange}
              placeholder="Keyword Search"
              className="mr-1"
              onKeyDown={(e) => handleSearch(e, false)}
            />
          </span>
          <Button icon="pi pi-search" onClick={(e) => handleSearch(e, true)} />
        </div>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <>
      <DataView
        header={header}
        value={localPunchListItems}
        itemTemplate={itemTemplate}
        paginator
        rows={10}
        className="md:hidden"
        loading={isFetching}
        lazy
        onPage={onPage}
        first={lazyState.first}
        totalRecords={total}
      />
      <FilterSidebar
        isVisible={filterSidebarVisible}
        setIsVisible={setFilterSidebarVisible}
        setFilterQuery={setFilterQuery}
        initialFilters={initialFilters}
        pageType="punch_list_items"
        setNumOfSelectedFilters={setNumOfSelectedFilters}
        additionalData={punchListId}
      />
    </>
  );
}
