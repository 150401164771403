import { Button } from "primereact/button";
import { Menubar } from "primereact/menubar";
import React from "react";
import { Link } from "react-router-dom";

function OnboardNavbar() {
  const handleLogout = () => {
    localStorage.clear();
    window.location.reload(true);
  };

  const start = () => {
    return (
      <div className="hidden sm:block header-title mx-4">
        <Link style={{ textDecoration: "none", color: "inherit" }} to="/">
          Nicky AI
        </Link>
      </div>
    );
  };

  const end = (
    <div className="flex align-items-center gap-2">
      <Button
        label="Sign Out"
        icon="pi pi-fw pi-sign-out"
        text
        onClick={handleLogout}
      />
    </div>
  );
  return <Menubar start={start} end={end} />;
}

export default OnboardNavbar;
