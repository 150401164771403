import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useContext } from "react";
import { AppContext } from "../../context/AppProvider";
import { useMutation } from "@tanstack/react-query";
import { deleteChangeOrder } from "../../api/commitmentsAPI";
import { getErrorMessage } from "../../util/helper";
import { queryClient } from "../../api/requestProcessor";

const DeleteChangeOrderDialog = ({
  visible,
  setVisible,
  commitmentId,
  changeOrder,
  onHide,
}) => {
  const { toast } = useContext(AppContext);

  const { mutate: deleteChangeOrderMutation, isPending } = useMutation({
    mutationFn: deleteChangeOrder,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["changeOrders", commitmentId],
      });
      queryClient.invalidateQueries({
        queryKey: ["commitments", commitmentId],
      });
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Change Order Deleted",
        life: 3000,
      });
      onHide();
    },
    onError: (error) => {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: getErrorMessage(error),
        life: 3000,
      });
    },
  });

  const handleDelete = () => {
    if (changeOrder?._id) {
      deleteChangeOrderMutation({
        changeOrderId: changeOrder._id,
        commitmentId,
      });
    }
  };

  const footerContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={onHide}
        className="mr-2"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={handleDelete}
        loading={isPending}
      />
    </div>
  );

  return (
    <Dialog
      visible={visible}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Confirm Delete"
      modal
      footer={footerContent}
      onHide={onHide}
    >
      <div className="flex align-items-center">
        <i
          className="pi pi-exclamation-triangle flex-shrink-0"
          style={{
            fontSize: "2rem",
            color: "var(--red-500)",
            marginRight: "1rem",
          }}
        />
        <span className="flex-grow-1">
          Are you sure you want to delete the change order{" "}
          <b>{changeOrder?.title}</b>?
        </span>
      </div>
    </Dialog>
  );
};

export default DeleteChangeOrderDialog;
