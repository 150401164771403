import React, { useState, useEffect } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export default function ContactTable({
  dt,
  selectedContacts,
  setSelectedContacts,
  contacts,
  isPending,
  edit,
  confirmDelete,
  exportCSV,
  openNew,
}) {
  const [filters, setFilters] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  useEffect(() => {
    initFilters();
  }, []);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      email: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
    setGlobalFilterValue("");
  };

  const clearFilter = () => {
    initFilters();
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <div>
          <Button
            label="New"
            icon="pi pi-plus"
            severity="success"
            onClick={openNew}
            className="mr-2"
          />
          <Button
            label="Export"
            icon="pi pi-upload"
            className="p-button-help"
            onClick={() =>
              exportCSV(selectedContacts.length > 0 ? true : false)
            }
          />
        </div>
        <div>
          <Button
            type="button"
            icon="pi pi-filter-slash"
            label="Clear Filters"
            outlined
            onClick={clearFilter}
            className="mr-2"
          />
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
      </div>
    );
  };

  const header = renderHeader();

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-trash"
          rounded
          text
          severity="danger"
          onClick={() => confirmDelete(rowData)}
        />
      </>
    );
  };

  return (
    <DataTable
      ref={dt}
      selection={selectedContacts}
      onSelectionChange={(e) => setSelectedContacts(e.value)}
      dataKey="email"
      value={contacts}
      paginator
      showGridlines
      rows={10}
      loading={isPending}
      filters={filters}
      globalFilterFields={["email"]}
      header={header}
      emptyMessage="No contact found."
      className="hidden sm:block"
      exportFilename="Nicky AI Contacts"
    >
      <Column
        selectionMode="multiple"
        exportable={false}
        style={{ width: "3rem" }}
      ></Column>
      <Column
        header="Email"
        field="email"
        filter
        filterPlaceholder="Search by email"
        style={{ minWidth: "12rem" }}
      />
      <Column
        body={actionBodyTemplate}
        exportable={false}
        style={{ width: "3rem" }}
      ></Column>
    </DataTable>
  );
}
