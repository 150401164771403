import { useQuery } from "@tanstack/react-query";
import { fetchOpenItems } from "../api/dashboardAPI";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useContext, useEffect, useState } from "react";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import MobileDashboardTable from "../components/MobileDataTable/MobileDashboardTable";
import { AppContext } from "../context/AppProvider";

export default function Dashboard() {
  const [selectedItem, setSelectedItem] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const { selectedProjectId } = useContext(AppContext);

  const navigate = useNavigate();

  const { data: openItems, isFetching } = useQuery({
    queryKey: ["openItems"],
    queryFn: ({ signal }) => fetchOpenItems({ signal }),
    staleTime: 5 * 60 * 1000,
    enabled: !!selectedProjectId,
  });

  useEffect(() => {
    if (openItems && openItems.length === 0) {
      navigate("/tasks");
    }
  }, [openItems, navigate]);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const onRowSelect = (event) => {
    if (event.data.type === "Submittal") {
      navigate(`/submittals/${event.data.id}/view`);
    } else if (event.data.type === "RFI") {
      navigate(`/rfis/${event.data.id}/view`);
    } else {
      navigate("tasks");
    }
  };

  const renderHeader = () => {
    return (
      <div class="flex justify-content-between flex-wrap">
        <div class="flex align-items-center justify-content-center">
          <p className="text-3xl font-medium line-height-1">Open Items</p>
        </div>
        <div class="flex align-items-center justify-content-center">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <>
      <div className="card">
        <DataTable
          value={openItems}
          selectionMode="single"
          selection={selectedItem}
          onSelectionChange={(e) => setSelectedItem(e.value)}
          dataKey="id"
          onRowSelect={onRowSelect}
          metaKeySelection={false}
          tableStyle={{ minWidth: "50rem" }}
          paginator
          rows={10}
          filters={filters}
          globalFilterFields={["type", "title", "status"]}
          header={header}
          loading={isFetching}
          className="hidden sm:block"
        >
          <Column field="type" header="Type"></Column>
          <Column field="title" header="Title"></Column>
          <Column field="status" header="Status"></Column>
          <Column field="due_date" header="Due Date"></Column>
        </DataTable>
        <MobileDashboardTable items={openItems} isPending={isFetching} />
      </div>
    </>
  );
}
