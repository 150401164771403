import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useQuery } from "@tanstack/react-query";
import { fetchInviteCodes } from "../../../api/adminAPI";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";

export const InviteCodesTable = () => {
  const {
    data: inviteCodes,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["invite-codes"],
    queryFn: ({ signal }) => fetchInviteCodes(signal),
  });

  const dateBodyTemplate = (rowData) => {
    const date = new Date(rowData.created_at);
    return date.toLocaleString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
    });
  };

  const codeBodyTemplate = (rowData) => {
    const copyToClipboard = async () => {
      try {
        await navigator.clipboard.writeText(rowData.invite_code);
      } catch (err) {
        console.error("Failed to copy:", err);
      }
    };

    return (
      <div className="flex align-items-center">
        <span className="mr-2">{rowData.invite_code}</span>
        <Button
          icon="pi pi-copy"
          className="p-button-rounded p-button-text"
          onClick={copyToClipboard}
          tooltip="Copy code"
          tooltipOptions={{ position: "top" }}
        />
      </div>
    );
  };

  if (error) {
    return (
      <div className="surface-danger text-white m-2 p-2 border-round">
        Error loading invite codes: {error.message}
      </div>
    );
  }

  return (
    <DataTable
      value={inviteCodes}
      loading={isLoading}
      paginator
      rows={10}
      rowsPerPageOptions={[5, 10, 25, 50]}
      emptyMessage="No invite codes found"
      className="p-datatable-gridlines"
      sortField="created_at"
      sortOrder={-1}
    >
      <Column
        field="email"
        header="Email"
        sortable
        filter
        filterPlaceholder="Search by email"
      />
      <Column
        field="invite_code"
        header="Invite Code"
        body={codeBodyTemplate}
        sortable
      />
      <Column
        field="created_at"
        header="Created At"
        sortable
        body={dateBodyTemplate}
      />
    </DataTable>
  );
};
