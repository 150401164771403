import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import { useMutation } from "@tanstack/react-query";

import { queryClient } from "../../api/requestProcessor";
import { deleteSpec } from "../../api/specsAPI";
import { useContext } from "react";
import { AppContext } from "../../context/AppProvider";
import { getErrorMessage } from "../../util/helper";

export default function DeleteSpec({ deleteDialog, hideDeleteDialog, spec }) {
  const { toast } = useContext(AppContext);

  const { mutate, isPending } = useMutation({
    mutationFn: deleteSpec,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["specs"] });
      hideDeleteDialog();
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Spec Deleted",
        life: 3000,
      });
    },
    onError: (error) => {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: getErrorMessage(error),
        life: 3000,
      });
    },
  });

  const handleDeleteSpec = () => {
    mutate({ specId: spec.id });
  };

  const deleteProductDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={handleDeleteSpec}
        loading={isPending}
      />
    </>
  );

  return (
    <Dialog
      visible={deleteDialog}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Confirm"
      modal
      footer={deleteProductDialogFooter}
      onHide={hideDeleteDialog}
      className="mx-2"
    >
      <div className="confirmation-content">
        <i
          className="pi pi-exclamation-triangle mr-3"
          style={{ fontSize: "2rem" }}
        />
        {spec && (
          <span>
            Are you sure you want to delete <b>{spec.label}</b>?
          </span>
        )}
      </div>
    </Dialog>
  );
}
