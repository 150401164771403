import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { createVendor } from "../../api/vendorsAPI";
import { queryClient } from "../../api/requestProcessor";
import { useMutation } from "@tanstack/react-query";

export default function NewVendorDialog({
  isVisible,
  hideDialog,
  toast,
  onPostCreate,
}) {
  const [newVendor, setNewVendor] = useState({
    name: "",
    abbreviated_name: "",
  });

  const {
    mutate: createVendorMutation,
    isPending: isPendingCreation,
    error: createError,
  } = useMutation({
    mutationFn: createVendor,
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ["companyVendors"] });
      onPostCreate(response.data.id);
      hideDialog();
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Company Created",
        life: 3000,
      });
    },
    onError: () => {
      toast.current.show({
        severity: "Error",
        summary: "Error",
        detail: createError,
        life: 3000,
      });
    },
  });

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _newVendor = { ...newVendor };

    _newVendor[`${name}`] = val;

    setNewVendor(_newVendor);
  };

  const createCompany = () => {
    createVendorMutation({ newVendor: newVendor });
  };

  const dialogFooter = (
    <>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button
        label={isPendingCreation ? "Creating" : "Create"}
        icon="pi pi-check"
        onClick={createCompany}
        loading={isPendingCreation}
      />
    </>
  );

  return (
    <Dialog
      visible={isVisible}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Company Details"
      modal
      className="p-fluid mx-2"
      footer={dialogFooter}
      onHide={hideDialog}
    >
      <div className="field">
        <label htmlFor="company_name" className="font-bold">
          Company Name
        </label>
        <InputText
          id="company_name"
          value={newVendor.name}
          onChange={(e) => onInputChange(e, "name")}
          required
        />
      </div>
      <div className="field">
        <label htmlFor="abbreviated_name" className="font-bold">
          Abbreviated Name
        </label>
        <InputText
          id="abbreviated_name"
          value={newVendor.abbreviated_name}
          onChange={(e) => onInputChange(e, "abbreviated_name")}
          required
        />
      </div>
    </Dialog>
  );
}
