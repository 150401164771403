import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useContext, useState } from "react";
import { queryClient } from "../../api/requestProcessor";
import { useMutation } from "@tanstack/react-query";
import { AppContext } from "../../context/AppProvider";
import { getErrorMessage } from "../../util/helper";
import { renameFileOrFolder } from "../../api/foldersAPI";
import { InputText } from "primereact/inputtext";

export default function RenameDialog({
  isVisible,
  hideDialog,
  fileOrFolder,
  folderId,
}) {
  const [fileOrFolderName, setFileOrFolderName] = useState(fileOrFolder?.name);
  const { toast } = useContext(AppContext);

  const { mutate: renameMutation, isPending: isPendingRename } = useMutation({
    mutationFn: renameFileOrFolder,
    onSuccess: () => {
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Renamed Successfully",
        life: 3000,
      });
      queryClient.invalidateQueries({ queryKey: ["folders", folderId] });
      hideDialog();
    },
    onError: (error) => {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: getErrorMessage(error),
        life: 3000,
      });
    },
  });

  const handleRename = () => {
    const fileOrFolderData = {
      name: fileOrFolderName,
      is_folder: fileOrFolder.file_type ? false : true,
    };

    renameMutation({
      fileOrFolderId: fileOrFolder.id,
      fileOrFolder: fileOrFolderData,
    });
  };

  const dialogFooter = (
    <>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button
        label={isPendingRename ? "Renaming" : "Rename"}
        icon="pi pi-check"
        onClick={handleRename}
        loading={isPendingRename}
      />
    </>
  );

  return (
    <Dialog
      visible={isVisible}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="File/Folder Details"
      modal
      className="p-fluid mx-2"
      footer={dialogFooter}
      onHide={hideDialog}
    >
      <div className="field">
        <label htmlFor="file-or-folder-name" className="font-bold">
          Name
        </label>
        <InputText
          id="file-or-folder-name"
          value={fileOrFolderName}
          onChange={(e) => setFileOrFolderName(e.target.value)}
          required
        />
      </div>
    </Dialog>
  );
}
