import React, { useState } from "react";
import Navbar from "../components/Navbar";
import { Menu } from "primereact/menu";
import Consultants from "../components/Consultant/Consultants";
import TeamMembers from "../components/TeamMembers";
import Specs from "../components/Specs/Specs";
import Contacts from "../components/Contact/Contacts";
import CompanyInfo from "../components/CompanyInfo";
import UserSettings from "../components/UserSettings";

const SettingsPage = () => {
  const [selectedItem, setSelectedItem] = useState("userSettings");

  let items = [
    {
      label: "User Settings",
      icon: "pi pi-building",
      className: selectedItem === "userSettings" ? "surface-200" : "",
      command: () => setSelectedItem("userSettings"),
    },
    {
      label: "Consultants",
      icon: "pi pi-users",
      className: selectedItem === "consultant" ? "surface-200" : "",
      command: () => setSelectedItem("consultant"),
    },
    {
      label: "Team Members",
      icon: "pi pi-book",
      className: selectedItem === "teamMembers" ? "surface-200" : "",
      command: () => setSelectedItem("teamMembers"),
    },
    {
      label: "Specs",
      icon: "pi pi-ellipsis-h",
      className: selectedItem === "spec" ? "surface-200" : "",
      command: () => setSelectedItem("spec"),
    },
    {
      label: "Contacts",
      icon: "pi pi-at",
      className: selectedItem === "contacts" ? "surface-200" : "",
      command: () => setSelectedItem("contacts"),
    },
    {
      label: "Company Info",
      icon: "pi pi-building",
      className: selectedItem === "companyInfo" ? "surface-200" : "",
      command: () => setSelectedItem("companyInfo"),
    },
  ];

  return (
    <>
      <Navbar />
      <div className="layout-sidebar">
        <Menu model={items} className="w-full border-none" />
      </div>
      <div className="layout-right-container">
        {selectedItem === "userSettings" && <UserSettings />}
        {selectedItem === "consultant" && <Consultants />}
        {selectedItem === "teamMembers" && <TeamMembers />}
        {selectedItem === "spec" && <Specs />}
        {selectedItem === "contacts" && <Contacts />}
        {selectedItem === "companyInfo" && <CompanyInfo />}
      </div>
    </>
  );
};

export default SettingsPage;
