import React from "react";
import { fetchEntities } from "../api/entitiesAPI";
import Entities from "../components/Entities/Entities";
import { useQuery } from "@tanstack/react-query";

const EntityPage = () => {
  const {
    data: entities,
    isPending,
    isError,
    error,
  } = useQuery({
    queryKey: ["entities"],
    queryFn: fetchEntities,
  });

  return (
    <div>
      <Entities entities={entities} isPending={isPending} />
    </div>
  );
};

export default EntityPage;
