import React, { useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchWaitlist, registerUserFromWaitlist } from "../../../api/adminAPI";
import { AppContext } from "../../../context/AppProvider";
import { Badge } from "primereact/badge";

export const WaitlistTable = () => {
  const { toast } = useContext(AppContext);
  const queryClient = useQueryClient();

  // Query for fetching waitlist data
  const {
    data: waitlistData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["waitlist"],
    queryFn: ({ signal }) => fetchWaitlist(signal),
  });

  // Mutation for registering user from waitlist
  const registerMutation = useMutation({
    mutationFn: registerUserFromWaitlist,
    onSuccess: () => {
      queryClient.invalidateQueries(["waitlist"]);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "User has been successfully registered",
        life: 3000,
      });
    },
    onError: (error) => {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message || "An error occurred while registering the user",
        life: 3000,
      });
    },
  });

  const confirmRegister = (waitlistItem) => {
    confirmDialog({
      message: `Are you sure you want to register ${waitlistItem.first_name} ${waitlistItem.last_name}?`,
      header: "Confirm Registration",
      icon: "pi pi-exclamation-triangle",
      accept: () => registerMutation.mutate(waitlistItem._id),
    });
  };

  const actionBodyTemplate = (rowData) => {
    if (rowData.is_registered) {
      return <Badge value="Registered" severity="success" />;
    }

    return (
      <Button
        icon="pi pi-user-plus"
        className="p-button-rounded p-button-success"
        onClick={() => confirmRegister(rowData)}
        tooltip="Register User"
        disabled={registerMutation.isLoading}
      />
    );
  };

  const dateBodyTemplate = (rowData) => {
    const date = new Date(rowData.created_at);
    return date.toLocaleString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
    });
  };

  const statusBodyTemplate = (rowData) => {
    const severity = rowData.is_registered ? "success" : "warning";
    const value = rowData.is_registered ? "Registered" : "Pending";

    return <Badge value={value} severity={severity} />;
  };

  if (error) {
    return (
      <div className="surface-danger text-white m-2 p-2 border-round">
        Error loading waitlist: {error.message}
      </div>
    );
  }

  return (
    <>
      <ConfirmDialog />

      <DataTable
        value={waitlistData}
        loading={isLoading}
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 25, 50]}
        emptyMessage="No waitlist entries found"
        className="p-datatable-gridlines"
        sortField="created_at"
        sortOrder={-1}
      >
        <Column
          field="email"
          header="Email"
          sortable
          filter
          filterPlaceholder="Search by email"
        />
        <Column
          field="first_name"
          header="First Name"
          sortable
          filter
          filterPlaceholder="Search by first name"
        />
        <Column
          field="last_name"
          header="Last Name"
          sortable
          filter
          filterPlaceholder="Search by last name"
        />
        <Column
          field="company_name"
          header="Company"
          sortable
          filter
          filterPlaceholder="Search by company"
        />
        <Column
          field="created_at"
          header="Created At"
          sortable
          body={dateBodyTemplate}
        />
        <Column
          field="is_registered"
          header="Status"
          sortable
          body={statusBodyTemplate}
          style={{ width: "10rem" }}
        />
        <Column
          body={actionBodyTemplate}
          exportable={false}
          style={{ width: "8rem" }}
          header="Actions"
        />
      </DataTable>
    </>
  );
};
