import { useQuery } from "@tanstack/react-query";
import React from "react";
import { fetchSchedulerJobs } from "../../../api/schedulerAPI";
import SchedulerTable from "./SchedulerTable";
import { fetchAllUsers } from "../../../api/adminAPI";

function Scheduler() {
  const { data: schedulerJobs, isFetching } = useQuery({
    queryKey: ["schedulerJobs"],
    queryFn: ({ signal }) => fetchSchedulerJobs(signal),
    staleTime: 5 * 60 * 1000,
  });

  const { data: users } = useQuery({
    queryKey: ["admin-users"],
    queryFn: ({ signal }) => fetchAllUsers(signal),
    staleTime: 5 * 60 * 1000,
  });

  return (
    <SchedulerTable
      schedulerJobs={schedulerJobs}
      isLoading={isFetching}
      users={users}
    />
  );
}

export default Scheduler;
