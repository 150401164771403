import { Button } from "primereact/button";
import { useContext, useState } from "react";
import CreateUserDialog from "./CreateUserDialog";
import { useMutation } from "@tanstack/react-query";
import { AppContext } from "../../../context/AppProvider";
import { getErrorMessage } from "../../../util/helper";
import { registerUser } from "../../../api/authAPI";
import { Card } from "primereact/card";
import CreateProjectDialog from "./CreateProjectDialog";
import { createProject } from "../../../api/projectsAPI";
import RoleManagementDialog from "./RoleManagementDialog";
import { authorizeUser } from "../../../api/adminAPI";
import { queryClient } from "../../../api/requestProcessor";

const Onboarding = () => {
  const [displayDialog, setDisplayDialog] = useState([false, false, false]);
  const { toast } = useContext(AppContext);

  const { mutate: registerUserMutation, isPending: isPendingRegister } =
    useMutation({
      mutationFn: registerUser,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["admin-users"] });
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "User Registered",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const { mutate: createProjectUserMutation, isPending: isPendingCreation } =
    useMutation({
      mutationFn: createProject,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["admin-projects"] });
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Project Created",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const { mutateAsync: authorizeUserMutation, isPending: isPendingAuth } =
    useMutation({
      mutationFn: authorizeUser,
      onSuccess: () => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "User Authorized",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const openDialog = (index) => {
    let _displayDialog = [...displayDialog];
    _displayDialog[index] = true;
    setDisplayDialog(_displayDialog);
  };

  const hideDialog = (index) => {
    let _displayDialog = [...displayDialog];
    _displayDialog[index] = false;
    setDisplayDialog(_displayDialog);
  };

  return (
    <div>
      <CreateUserDialog
        visible={displayDialog[0]}
        onHide={() => hideDialog(0)}
        onSave={registerUserMutation}
        isPendingRegister={isPendingRegister}
      />
      <CreateProjectDialog
        visible={displayDialog[1]}
        onHide={() => hideDialog(1)}
        onSave={createProjectUserMutation}
        isLoading={isPendingCreation}
      />
      <RoleManagementDialog
        visible={displayDialog[2]}
        onHide={() => hideDialog(2)}
        onSave={authorizeUserMutation}
        isLoading={isPendingAuth}
      />
      <div className="flex flex-column gap-2">
        <Card title="Step #1: Create a User">
          <Button
            label="Create a User"
            icon="pi pi-plus"
            onClick={() => openDialog(0)}
          />
        </Card>
        <Card title="Step #2: Create a Project">
          <Button
            label="Create a Project"
            icon="pi pi-plus"
            onClick={() => openDialog(1)}
          />
        </Card>
        <Card title="Step #3: Role Management">
          <Button
            label="Manage Users' Roles"
            icon="pi pi-settings"
            onClick={() => openDialog(2)}
          />
        </Card>
      </div>
    </div>
  );
};

export default Onboarding;
