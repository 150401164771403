import React, { useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { useQuery, useMutation } from "@tanstack/react-query";
import { fetchEntities } from "../../api/entitiesAPI";
import { uploadFiles } from "../../api/filesAPI";
import CustomFileUpload from "../FileUpload/FileUpload";
import Attachments from "../Attachments";
import { InputNumber } from "primereact/inputnumber";
import { fetchNextChangeOrderNumber } from "../../api/commitmentsAPI";

const ChangeOrderDialog = ({
  visible,
  onHide,
  changeOrder,
  setChangeOrder,
  onSubmit,
  isSubmitting,
  files,
  setFiles,
  uploadReset,
  setUploadReset,
  attachments,
  setAttachments,
  toast,
}) => {
  const statuses = [
    { label: "Draft", value: "draft" },
    { label: "Pending", value: "pending" },
    { label: "Approved", value: "approved" },
    { label: "Rejected", value: "rejected" },
  ];

  const { data: users } = useQuery({
    queryKey: ["entities"],
    queryFn: fetchEntities,
    staleTime: 5 * 60 * 1000,
  });

  const { data: nextNumber, isFetching: isFetchingNumber } = useQuery({
    queryKey: ["nextChangeOrderNumber"],
    queryFn: fetchNextChangeOrderNumber,
    staleTime: 0,
  });

  useEffect(() => {
    if (nextNumber && !changeOrder._id) {
      setChangeOrder((prev) => ({
        ...prev,
        number: nextNumber.number,
      }));
    }
  }, [nextNumber]);

  const { mutateAsync: uploadFilesMutation, isPending: isPendingUpload } =
    useMutation({
      mutationFn: uploadFiles,
      onSuccess: () => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "File Uploaded",
          life: 3000,
        });
        setUploadReset(true);
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.message,
          life: 3000,
        });
      },
    });

  const handleSubmit = async () => {
    let updatedAttachments = [...attachments];

    if (files.length > 0) {
      const uploadPromises = files.map(async (file) => {
        return await uploadFilesMutation({
          files: [file],
        });
      });

      const responses = await Promise.all(uploadPromises);
      updatedAttachments = [...responses, ...attachments];
    }

    onSubmit({
      ...changeOrder,
      attachments: updatedAttachments,
    });
  };

  return (
    <Dialog
      visible={visible}
      style={{ width: "50rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={changeOrder?._id ? "Edit Change Order" : "New Change Order"}
      modal
      className="p-fluid"
      onHide={onHide}
    >
      <div className="field">
        <label htmlFor="title">Number</label>
        <InputText
          id="number"
          value={changeOrder?.number || ""}
          onChange={(e) =>
            setChangeOrder({ ...changeOrder, number: e.target.value })
          }
        />
      </div>

      <div className="field">
        <label htmlFor="title">Title</label>
        <InputText
          id="title"
          value={changeOrder?.title || ""}
          onChange={(e) =>
            setChangeOrder({ ...changeOrder, title: e.target.value })
          }
          required
        />
      </div>

      <div className="field">
        <label htmlFor="visibility">Received From</label>
        <Dropdown
          value={changeOrder?.received_from_id}
          options={users}
          onChange={(e) =>
            setChangeOrder({ ...changeOrder, received_from_id: e.value })
          }
          optionLabel="name"
          optionValue="id"
          placeholder="Select Received From"
          filter
          filterBy="name"
          emptyFilterMessage="No users found"
        />
      </div>

      <div className="field">
        <label htmlFor="title">Amount</label>
        <div className="p-inputgroup mb-3">
          <span className="p-inputgroup-addon">$</span>
          <InputNumber
            value={changeOrder?.amount}
            onValueChange={(e) =>
              setChangeOrder({
                ...changeOrder,
                amount: e.value,
              })
            }
            mode="decimal"
            minFractionDigits={2}
            placeholder="Change Order Amount"
          />
        </div>
      </div>

      <div className="field">
        <label htmlFor="status">Status</label>
        <Dropdown
          id="status"
          value={changeOrder?.status}
          options={statuses}
          onChange={(e) => setChangeOrder({ ...changeOrder, status: e.value })}
          placeholder="Select Status"
        />
      </div>

      <div className="field">
        <label htmlFor="dueDate">Due Date</label>
        <Calendar
          id="dueDate"
          value={changeOrder?.due_date ? new Date(changeOrder.due_date) : null}
          onChange={(e) =>
            setChangeOrder({
              ...changeOrder,
              due_date: e.value?.toISOString().split("T")[0],
            })
          }
          dateFormat="yy-mm-dd"
          showIcon
        />
      </div>

      <div className="field">
        <label htmlFor="paidDate">Paid Date</label>
        <Calendar
          id="paidDate"
          value={
            changeOrder?.paid_date ? new Date(changeOrder.paid_date) : null
          }
          onChange={(e) =>
            setChangeOrder({
              ...changeOrder,
              paid_date: e.value?.toISOString().split("T")[0],
            })
          }
          dateFormat="yy-mm-dd"
          showIcon
        />
      </div>

      <div className="field">
        <label htmlFor="invoicedDate">Invoiced Date</label>
        <Calendar
          id="invoicedDate"
          value={
            changeOrder?.invoiced_date
              ? new Date(changeOrder.invoiced_date)
              : null
          }
          onChange={(e) =>
            setChangeOrder({
              ...changeOrder,
              invoiced_date: e.value?.toISOString().split("T")[0],
            })
          }
          dateFormat="yy-mm-dd"
          showIcon
        />
      </div>

      <div className="field-checkbox">
        <Checkbox
          inputId="executed"
          checked={changeOrder?.executed || false}
          onChange={(e) =>
            setChangeOrder({ ...changeOrder, executed: e.checked })
          }
        />
        <label htmlFor="executed">Executed</label>
      </div>

      <div className="field-checkbox">
        <Checkbox
          inputId="paid"
          checked={changeOrder?.paid || false}
          onChange={(e) => setChangeOrder({ ...changeOrder, paid: e.checked })}
        />
        <label htmlFor="paid">Paid</label>
      </div>

      <div className="field">
        <label htmlFor="attachments">Attachments</label>
        {attachments && attachments.length > 0 && (
          <Attachments
            id="change-order-attachments"
            attachments={attachments}
            setAttachments={setAttachments}
            isDisabled={false}
          />
        )}
        <CustomFileUpload
          id="attachments"
          accept=""
          onFileChange={setFiles}
          reset={uploadReset}
          setReset={setUploadReset}
        />
      </div>

      <div className="flex justify-content-end">
        <Button
          label="Cancel"
          icon="pi pi-times"
          outlined
          onClick={onHide}
          className="mr-2"
        />
        <Button
          label="Save"
          icon="pi pi-check"
          onClick={handleSubmit}
          loading={isSubmitting || isPendingUpload}
        />
      </div>
    </Dialog>
  );
};

export default ChangeOrderDialog;
