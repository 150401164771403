import React, { useState, useRef } from "react";

import { Toast } from "primereact/toast";

import EntityTable from "./EntitiesTable";
import EntityMobileDataTable from "./EntityMobileTable";
import { getUserData } from "../../util/storage";
import DeleteUser from "./DeleteUser";

export default function Entities({ entities, isPending }) {
  const isProcoreUser = getUserData().is_procore_user;
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [entity, setEntity] = useState();

  const dt = useRef(null);
  const toast = useRef(null);

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const hideDialog = () => {
    setIsDialogVisible(false);
  };

  const openDialog = (rowData) => {
    setEntity(rowData);
    setIsDialogVisible(true);
  };

  return (
    <>
      <Toast className="z-5" ref={toast} />
      <EntityTable
        dt={dt}
        selectedEntities={selectedEntities}
        setSelectedEntities={setSelectedEntities}
        entities={entities}
        isPending={isPending}
        exportCSV={exportCSV}
        openDialog={openDialog}
      />
      <EntityMobileDataTable
        items={entities}
        isPending={isPending}
        name="name"
        vendor="vendor"
        mobile_phone="mobile_phone"
        email_address={isProcoreUser ? "email_address" : "email"}
        openDeleteDialog={openDialog}
      />
      {isDialogVisible && (
        <DeleteUser
          isVisible={isDialogVisible}
          hideDialog={hideDialog}
          user={entity}
          toast={toast}
        />
      )}
    </>
  );
}
