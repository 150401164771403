import axios from "axios";
import { getAuthToken } from "../util/auth";
import API_BASE_URL from "../util/apiConfig";

const token = getAuthToken();

const emailClient = axios.create({
  baseURL: `${API_BASE_URL}`,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

emailClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response.data);
  }
);

export async function fetchGoogleAuthUrl({ signal }) {
  const response = await emailClient.get("/google/login", { signal: signal });
  return response.data;
}

export async function fetchMicrosoftAuthUrl({ signal }) {
  const response = await emailClient.get("/microsoft/login", {
    signal: signal,
  });
  return response.data;
}
