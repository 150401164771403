import React, { useState, useRef } from "react";

import CreateRFI from "./CreateRFI";
import RFITable from "./RFITable";
import DeleteRFI from "./DeleteRFI";
import { useQuery } from "@tanstack/react-query";
import { getRfi } from "../../api/rfisAPI";
import Reply from "./Response";
import MobileDataTable from "../MobileDataTable/MobileDataTable";
import { useNavigate } from "react-router-dom";

export default function RFIs({
  rfis,
  isPending,
  refetch,
  setQueryParams,
  total,
}) {
  let emptyRFI = {
    subject: "",
    assignees: [],
    distribution_list: [],
    received_from: {},
  };

  const initialFilters = [
    {
      key: "assigned_id",
      value: "Assignees",
      selected_options: [],
      options: [],
    },
    {
      key: "ball_in_court_id",
      value: "Ball In Court",
      selected_options: [],
      options: [],
    },
    {
      key: "received_from_login_information_id",
      value: "Received From",
      selected_options: [],
      options: [],
    },
    {
      key: "responsible_contractor_id",
      value: "Responsible Contractor",
      selected_options: [],
      options: [],
    },
    {
      key: "rfi_manager_id",
      value: "RFI Manager",
      selected_options: [],
      options: [],
    },
    { key: "status", value: "Status", selected_options: [], options: [] },
  ];

  const [rfiDialog, setRFIDialog] = useState(false);
  const [replyDialog, setReplyDialog] = useState(false);
  const [deleteRFIDialog, setDeleteRFIDialog] = useState(false);
  const [rfi, setRFI] = useState(emptyRFI);
  const [rfiId, setRfiId] = useState();
  const [selectedRFIs, setSelectedRFIs] = useState([]);
  const [editRFIMode, setEditRFIMode] = useState(false);

  const dt = useRef(null);

  const navigate = useNavigate();

  const { data: RFIData, isPending: isPendingRFI } = useQuery({
    queryKey: ["rfi", rfiId],
    queryFn: ({ signal }) => getRfi({ signal, rfiId: rfiId }),
    enabled: !!rfiId,
    staleTime: 5 * 60 * 1000,
  });

  const openNew = () => {
    navigate(`/rfis/new`);
  };

  const hideDialog = () => {
    setRFIDialog(false);
  };

  const hideReplyDialog = () => {
    setReplyDialog(false);
  };

  const hideDeleteRFIDialog = () => {
    setDeleteRFIDialog(false);
  };

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const editRFI = (rfi) => {
    navigate(`/rfis/${rfi.id}/edit`);
  };

  const createReply = (rfi) => {
    navigate(`/rfis/${rfi.id}/view`);
  };

  const confirmDeleteRFI = (rfi) => {
    setRFI(rfi);
    setDeleteRFIDialog(true);
  };

  const onInputChange = (e, name) => {
    if (name === "questions" && editRFIMode) {
      const val = (e.target && e.target.value) || "";
      let _rfi = { ...rfi };

      _rfi["questions"][0]["plain_text_body"] = val;
      _rfi["questions"][0]["rich_text_body"] = val;

      setRFI(_rfi);
    } else if (name === "accepted" && editRFIMode) {
      const val = e.target && e.target.value;
      let _rfi = { ...rfi };

      _rfi[`${name}`] = val;

      setRFI(_rfi);
    } else {
      const val = (e.target && e.target.value) || "";
      let _rfi = { ...rfi };

      _rfi[`${name}`] = val;

      setRFI(_rfi);
    }
  };

  return (
    <>
      <RFITable
        dt={dt}
        selectedRFIs={selectedRFIs}
        setSelectedRFIs={setSelectedRFIs}
        rfis={rfis}
        isPending={isPending}
        editRFI={editRFI}
        confirmDeleteRFI={confirmDeleteRFI}
        exportCSV={exportCSV}
        openNew={openNew}
        createReply={createReply}
        refetch={refetch}
        setQueryParams={setQueryParams}
        total={total}
        initialFilters={initialFilters}
      />

      <MobileDataTable
        items={rfis}
        createHandler={openNew}
        editHandler={editRFI}
        deleteHandler={confirmDeleteRFI}
        isPending={isPending}
        title="subject"
        number="number"
        status="status"
        date="due_date"
        assignees="assignees"
        editMode={true}
        total={total}
        setQueryParams={setQueryParams}
        createReply={createReply}
        initialFilters={initialFilters}
        pageType="rfi"
      />

      {rfiDialog && (
        <CreateRFI
          rfiDialog={rfiDialog}
          hideDialog={hideDialog}
          rfi={rfi}
          onInputChange={onInputChange}
          editRFIMode={editRFIMode}
          rfiId={rfiId}
          setRFI={setRFI}
        />
      )}

      {replyDialog && (
        <Reply
          replyDialog={replyDialog}
          hideReplyDialog={hideReplyDialog}
          rfiId={rfiId}
        />
      )}

      <DeleteRFI
        deleteRFIDialog={deleteRFIDialog}
        hideDeleteRFIDialog={hideDeleteRFIDialog}
        rfi={rfi}
      />
    </>
  );
}
