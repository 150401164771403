import { Outlet } from "react-router-dom";
import AdminNavbar from "../components/Admin/AdminNavbar";

export default function AdminRoot() {
  return (
    <>
      <AdminNavbar />
      <div className="sm:mx-5 mb-7 md:mb-0 layout-main-container top-0">
        <Outlet />
      </div>
    </>
  );
}
