import Navbar from "../components/Navbar";
import { useEffect, useRef, useState } from "react";
import ChatbotGreeting from "../components/Chatbot/ChatbotGreeting";
import ChatbotMessages from "../components/Chatbot/ChatbotMessages";
import ChatbotInputArea from "../components/Chatbot/ChatbotInputArea";
import { useMutation, useQuery } from "@tanstack/react-query";
import { askChatbot, getChatbot } from "../api/chatbotsAPI";
import { getErrorMessage } from "../util/helper";

export default function ChatbotsPage() {
  const [messages, setMessages] = useState([]);
  const [chatbotInitialized, setChatbotInitialized] = useState(true);
  const [chatHistory, setChatHistory] = useState([]);
  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      const { scrollHeight, clientHeight } = chatContainerRef.current;
      chatContainerRef.current.scrollTop = scrollHeight - clientHeight;
    }
  }, [messages]);

  const { data: chatbot, isFetching: isFetchingChatbot } = useQuery({
    queryKey: ["chatbot"],
    queryFn: getChatbot,
    options: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    },
  });

  const { mutate: askChatbotMutation, isPending: isPendingChatbot } =
    useMutation({
      mutationFn: askChatbot,
      onSuccess: (response) => {
        let _messages = [...messages];
        _messages.pop();
        _messages.push({
          label: "N",
          from: "Nicky AI",
          text: response.result,
          status: "sent",
          sources: response.docs,
          type: response.query_type,
        });
        setMessages(_messages);
        setChatHistory(response.chat_history);
      },
      onError: (error) => {
        let errorMessage;
        if (error?.response?.status !== 403) {
          errorMessage =
            "There was an error processing your request. Please try refreshing the page or start over.";
        } else {
          errorMessage = getErrorMessage(error);
        }
        let _messages = [...messages];
        _messages.pop();
        _messages.push({
          label: "N",
          from: "Nicky AI",
          text: errorMessage,
          status: "sent",
          sources: [],
          type: "document",
        });
        setMessages(_messages);
      },
    });

  const writeMessage = (message) => {
    const _messages = [
      ...messages,
      { label: "Y", from: "You", text: message, status: "sent" },
      { label: "N", from: "Nicky AI", text: "Pending...", status: "pending" },
    ];

    setMessages(_messages);
    askChatbotMutation({
      query: { query: message, history: chatHistory },
    });
  };

  return (
    <div className="flex h-screen max-w-full flex-column overflow-hidden">
      <div
        ref={chatContainerRef}
        className="flex-1 bg-primary-reverse chatbot-main-layout"
      >
        <ChatbotGreeting
          chatbotInitialized={chatbotInitialized}
          messages={messages}
          isPending={isFetchingChatbot}
          chatbot={chatbot}
        />
        <ChatbotMessages
          chatbotInitialized={chatbotInitialized}
          messages={messages}
          chatbot={chatbot}
        />
      </div>
      <div className="flex bg-primary-reverse">
        <ChatbotInputArea
          writeMessage={writeMessage}
          isPending={isPendingChatbot}
          chatbot={chatbot}
        />
      </div>
    </div>
  );
}
