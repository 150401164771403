import { useState, useEffect, useContext } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

import { useMutation } from "@tanstack/react-query";

import { queryClient } from "../../api/requestProcessor";

import { createSpec, updateSpec } from "../../api/specsAPI";
import { AppContext } from "../../context/AppProvider";
import { getErrorMessage } from "../../util/helper";

export default function CreateSpec({
  dialog,
  hideDialog,
  spec,
  onInputChange,
  editMode,
}) {
  const [label, setLabel] = useState("Save");

  const { toast } = useContext(AppContext);

  const { mutate: createSpecMutation, isPending: isPendingCreation } =
    useMutation({
      mutationFn: createSpec,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["specs"] });
        hideDialog();
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Spec Created",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const {
    mutate: updateSpecMutation,
    isPending: isPendingUpdate,
    isError: isErrorUpdating,
    error: updateError,
  } = useMutation({
    mutationFn: updateSpec,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["specs"] });
      hideDialog();
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Spec Updated",
        life: 3000,
      });
    },
    onError: (error) => {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: getErrorMessage(error),
        life: 3000,
      });
    },
  });

  useEffect(() => {
    if (isPendingCreation || isPendingUpdate) {
      setLabel("Saving Changes");
    } else {
      setLabel("Save");
    }
  }, [isPendingCreation, isPendingUpdate]);

  const saveSpec = async () => {
    if (editMode) {
      updateSpecMutation({
        specData: spec,
        specId: spec.id,
      });
    } else createSpecMutation({ specData: spec });
  };

  const specDialogFooter = (
    <>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button
        label={label}
        icon="pi pi-check"
        onClick={saveSpec}
        loading={isPendingCreation || isPendingUpdate}
        disabled={isPendingCreation || isPendingUpdate}
      />
    </>
  );

  return (
    <Dialog
      visible={dialog}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Spec Details"
      modal
      className="p-fluid mx-2"
      footer={specDialogFooter}
      onHide={hideDialog}
    >
      <div className="field">
        <label htmlFor="number" className="font-bold">
          Number
        </label>
        <InputText
          id="number"
          type="number"
          value={spec.number}
          onChange={(e) => onInputChange(e, "number")}
          required
        />
      </div>
      <div className="field">
        <label htmlFor="description" className="font-bold">
          Description
        </label>
        <InputText
          id="description"
          value={spec.description}
          onChange={(e) => onInputChange(e, "description")}
          required
        />
      </div>
    </Dialog>
  );
}
