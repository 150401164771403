import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import NewNoteDialog from "./NewNoteDialog";

const NotesTable = ({
  dailyReport,
  setDailyReport,
  updateDailyReportMutation,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const hideDialog = () => {
    setIsVisible(false);
  };

  const header = () => {
    return (
      <div className="flex align-items-center justify-content-between gap-2">
        <span className="text-xl text-900 font-bold">Notes</span>
        <Button
          className="w-9rem"
          label="Create"
          icon="pi pi-plus"
          onClick={() => {
            setIsVisible(true);
          }}
        />
      </div>
    );
  };

  const cellEditor = (options) => {
    if (options.field === "vendor_id") return textEditor(options);
    return textEditor(options);
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
        onKeyDown={(e) => e.stopPropagation()}
      />
    );
  };

  const onCellEditComplete = (e) => {
    let { newRowData, newValue, field, originalEvent: event, rowIndex } = e;

    let shouldUpdated = dailyReport["notes"][rowIndex][field] !== newValue;

    if (!shouldUpdated) {
      return;
    }

    let _dailyReport = { ...dailyReport };
    _dailyReport["notes"][rowIndex][field] = newValue;
    setDailyReport(_dailyReport);

    let updatedData = { notes: _dailyReport["notes"] };
    updateDailyReportMutation({
      dailyReport: updatedData,
      dailyReportId: dailyReport._id,
    });
  };

  const handleRemoveRow = (rowIndex) => {
    let _dailyReport = { ...dailyReport };
    _dailyReport.notes.splice(rowIndex, 1);
    setDailyReport(_dailyReport);

    let updatedData = { notes: _dailyReport["notes"] };
    updateDailyReportMutation({
      dailyReport: updatedData,
      dailyReportId: dailyReport._id,
    });
  };

  const actionBodyTemplate = (rowData, options) => {
    const { rowIndex } = options;
    return (
      <Button
        type="button"
        icon="pi pi-times"
        rounded
        severity="danger"
        onClick={() => handleRemoveRow(rowIndex)}
        text
      />
    );
  };

  return (
    <>
      <div className="card p-fluid">
        <DataTable value={dailyReport.notes} editMode="cell" header={header}>
          <Column
            key="note"
            field="note"
            header="Note"
            style={{ width: "100%" }}
            editor={(options) => cellEditor(options)}
            onCellEditComplete={onCellEditComplete}
          />
          <Column
            headerStyle={{ width: "5rem", textAlign: "center" }}
            bodyStyle={{ textAlign: "center", overflow: "visible" }}
            body={actionBodyTemplate}
          />
        </DataTable>
      </div>
      <NewNoteDialog
        isVisible={isVisible}
        hideDialog={hideDialog}
        dailyReport={dailyReport}
        setDailyReport={setDailyReport}
      />
    </>
  );
};
export default NotesTable;
