export function getProperty(obj, path) {
  if (path === undefined) {
    return "";
  }

  const parts = path.split(".");
  let result = obj;

  for (const part of parts) {
    if (part.includes("[")) {
      const [propName, indexStr] = part.split("[");
      const index = parseInt(indexStr.replace("]", ""));
      result = result && result[propName] ? result[propName][index] : undefined;
    } else {
      result = result && result[part];
    }

    if (result === undefined || result === null) {
      return "";
    }
  }

  return result === null ? "" : result.toString();
}
