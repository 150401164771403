import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import { useMutation } from "@tanstack/react-query";

import { deleteSubmittal } from "../../api/submittalsAPI";
import { queryClient } from "../../api/requestProcessor";
import { useContext } from "react";
import { AppContext } from "../../context/AppProvider";
import { getErrorMessage } from "../../util/helper";

export default function DeleteSubmittal({
  deleteSubmittalDialog,
  hideDeleteSubmittalDialog,
  submittal,
}) {
  const { toast } = useContext(AppContext);

  const { mutate, isPending } = useMutation({
    mutationFn: deleteSubmittal,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["submittals"] });
      hideDeleteSubmittalDialog();
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Submittal Deleted",
        life: 3000,
      });
    },
    onError: (error) => {
      toast.current.show({
        severity: "Error",
        summary: "Error",
        detail: getErrorMessage(error),
        life: 3000,
      });
    },
  });

  const handleDeleteSubmittal = () => {
    mutate({ submittalId: submittal.id });
  };

  const deleteSubmittalDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteSubmittalDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={handleDeleteSubmittal}
        loading={isPending}
      />
    </>
  );

  return (
    <Dialog
      visible={deleteSubmittalDialog}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Confirm"
      modal
      footer={deleteSubmittalDialogFooter}
      onHide={hideDeleteSubmittalDialog}
    >
      <div className="confirmation-content">
        <i
          className="pi pi-exclamation-triangle mr-3"
          style={{ fontSize: "2rem" }}
        />
        {submittal && (
          <span>
            Are you sure you want to delete{" "}
            <b>
              {submittal.formatted_number} {submittal.title}
            </b>
            ?
          </span>
        )}
      </div>
    </Dialog>
  );
}
