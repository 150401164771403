import { Skeleton } from "primereact/skeleton";

export default function CustomLoader() {
  return (
    <>
      <Skeleton className="mb-2" borderRadius="16px"></Skeleton>
      <Skeleton width="10rem" className="mb-2" borderRadius="16px"></Skeleton>
      <Skeleton width="5rem" borderRadius="16px" className="mb-2"></Skeleton>
      <Skeleton height="2rem" className="mb-2" borderRadius="16px"></Skeleton>
      <Skeleton width="10rem" height="4rem" borderRadius="16px"></Skeleton>
    </>
  );
}
