import { Button } from "primereact/button";
import Answers from "./Answers";
import { useContext, useEffect, useState } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import CustomFileUpload from "../FileUpload/FileUpload";
import { useMutation } from "@tanstack/react-query";
import { createReply } from "../../api/responsesAPI";
import { queryClient } from "../../api/requestProcessor";
import { AppContext } from "../../context/AppProvider";
import { getErrorMessage } from "../../util/helper";
import { uploadFiles } from "../../api/filesAPI";
import { RFIResponseLoader } from "../Loaders/RFILoader";

export default function ViewRFIResponses({
  rfi,
  rfiId,
  isProcoreUser,
  isPending,
}) {
  const [responseMode, setResponseMode] = useState(false);
  const [files, setFiles] = useState([]);
  const [uploadReset, setUploadReset] = useState(false);
  const [responseBody, setResponseBody] = useState("");

  const { user, toast } = useContext(AppContext);

  const { mutate: createReplyMutation, isPending: isPendingCreation } =
    useMutation({
      mutationFn: createReply,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["rfis", rfiId] });
        setResponseBody("");
        setResponseMode(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Reply Created",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const { mutateAsync: uploadFilesMutation, isPending: isPendingUpload } =
    useMutation({
      mutationFn: uploadFiles,
      onSuccess: () => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "File Uploaded",
          life: 3000,
        });
        setUploadReset(true);
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const onFileChange = (files) => {
    setFiles(files);
  };

  const saveReply = async () => {
    let updatedAttachments = [];

    if (files.length > 0) {
      const uploadPromises = files.map(async (file) => {
        return await uploadFilesMutation({
          files: [file],
        });
      });

      const responses = await Promise.all(uploadPromises);
      updatedAttachments = [...responses];
    }

    const replyData = {
      body: responseBody,
    };

    if (isProcoreUser) {
      replyData.question_id = rfi.questions[0].id;
    }

    createReplyMutation({
      replyData: replyData,
      attachments: updatedAttachments,
      rfiId: rfi.id,
    });
  };

  if (isPending) {
    return <RFIResponseLoader />;
  }

  if (
    !responseMode &&
    !isPending &&
    ((isProcoreUser &&
      rfi.questions &&
      rfi.questions[0].answers &&
      rfi.questions[0].answers.length === 0) ||
      (!isProcoreUser && rfi.answers && rfi.answers.length === 0))
  ) {
    return (
      <div className="grid">
        <div className="col-12 md:col-6">
          <div className="font-semibold">
            There Are No Responses to Display Right Now
          </div>
          <div>Once your team creates responses, you can view them here.</div>
        </div>
        <div className="col-12 md:col-6">
          <div className="flex justify-content-end">
            {rfi.assignees.map((assignee) => assignee.id).includes(user.id) && (
              <Button
                icon="pi pi-plus"
                label="Add Response"
                onClick={() => {
                  setResponseMode(true);
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  if (responseMode) {
    return (
      <>
        <div className="grid">
          <div className="col-12 md:col-6">
            <div className="flex flex-column gap-2">
              <label htmlFor="response">Response</label>
              <InputTextarea
                id="response"
                value={responseBody}
                onChange={(e) => {
                  setResponseBody(e.target.value);
                }}
                autoResize
                rows={8}
                cols={30}
              />
            </div>
          </div>
          <div className="col-12 md:col-6">
            <div className="flex flex-column gap-2">
              <label htmlFor="attachments">Attachments</label>
              <CustomFileUpload
                id="attachments"
                accept=""
                onFileChange={onFileChange}
                reset={uploadReset}
                setReset={setUploadReset}
              />
            </div>
          </div>
        </div>
        <div className="flex gap-2 justify-content-end">
          <Button
            label="Cancel"
            icon="pi pi-times"
            outlined
            onClick={() => {
              setResponseMode(false);
            }}
          />
          <Button
            label="Add"
            icon="pi pi-check"
            onClick={saveReply}
            loading={isPendingCreation || isPendingUpload}
            disabled={isPendingCreation || isPendingUpload}
          />
        </div>
      </>
    );
  }

  if (
    !isPending &&
    ((isProcoreUser &&
      rfi.questions &&
      rfi.questions[0].answers &&
      rfi.questions[0].answers.length > 0) ||
      (!isProcoreUser && rfi.answers && rfi.answers.length > 0))
  ) {
    return (
      <>
        <Answers
          replies={isProcoreUser ? rfi.questions[0].answers : rfi.answers}
          rfiId={rfi.id}
        />
        <div className="flex gap-2 justify-content-end mt-2">
          {rfi.assignees.map((assignee) => assignee.id).includes(user.id) && (
            <Button
              icon="pi pi-plus"
              label="Add Response"
              onClick={() => {
                setResponseMode(true);
              }}
            />
          )}
        </div>
      </>
    );
  }
}
