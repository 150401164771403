import { useMutation } from "@tanstack/react-query";
import React, { useContext } from "react";
import { deleteSchedulerJob } from "../../../api/schedulerAPI";
import { queryClient } from "../../../api/requestProcessor";
import { AppContext } from "../../../context/AppProvider";
import { getErrorMessage } from "../../../util/helper";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

function DeleteSchedulerJobDialog({ visible, onHide, schedulerJob }) {
  const { toast } = useContext(AppContext);

  const { mutate, isPending } = useMutation({
    mutationFn: deleteSchedulerJob,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["schedulerJobs"] });
      onHide();
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Scheduler Job Removed",
        life: 3000,
      });
    },
    onError: (error) => {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: getErrorMessage(error),
        life: 3000,
      });
    },
  });

  const handleDelete = () => {
    mutate({ schedulerJobId: schedulerJob._id });
  };

  const dialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" outlined onClick={onHide} />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={handleDelete}
        loading={isPending}
      />
    </>
  );

  return (
    <Dialog
      visible={visible}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Confirm"
      modal
      footer={dialogFooter}
      onHide={onHide}
      className="mx-2"
    >
      <div className="confirmation-content">
        <div className="flex">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {schedulerJob && (
            <span>
              Are you sure you want to remove <b>{`${schedulerJob.job_id}`}</b>{" "}
              from the scheduler?
            </span>
          )}
        </div>
      </div>
    </Dialog>
  );
}

export default DeleteSchedulerJobDialog;
