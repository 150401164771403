import axios from "axios";
import { getAuthToken } from "../util/auth";
import { getProjectId, getUserData } from "../util/storage";
import API_BASE_URL from "../util/apiConfig";

const token = getAuthToken();

const consultantsClient = axios.create({
  baseURL: `${API_BASE_URL}/consultants`,
  headers: {
    Authorization: "Bearer " + token,
  },
});

consultantsClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response.data);
  }
);

export async function fetchConsultants({ signal }) {
  const isProcoreUser = getUserData()["is_procore_user"];
  const projectId = getProjectId();

  if (isProcoreUser) {
    const response = await consultantsClient.get(
      `?project_id=${projectId.toString()}`,
      signal
    );
    return response.data;
  } else {
    const response = await consultantsClient.get(
      `?project_id=${projectId}`,
      signal
    );
    return response.data;
  }
}

export async function createConsultant({ consultantData }) {
  const user = getUserData();
  const isProcoreUser = user["is_procore_user"];
  const projectId = getProjectId();

  consultantData["project_id"] = projectId.toString();

  if (isProcoreUser) {
    const response = await consultantsClient.post("/", consultantData);
    return response.data;
  } else {
    const response = await consultantsClient.post(`/`, consultantData);
    return response.data;
  }
}

export async function updateConsultant({ consultantData }) {
  const user = getUserData();
  const isProcoreUser = user["is_procore_user"];

  if (isProcoreUser) {
    const response = await consultantsClient.patch("/", consultantData);
    return response.data;
  } else {
    const response = await consultantsClient.patch(`/`, consultantData);
    return response.data;
  }
}

export async function deleteConsultant({ consultantId }) {
  const user = getUserData();
  const isProcoreUser = user["is_procore_user"];
  const projectId = getProjectId();

  if (isProcoreUser) {
    const response = await consultantsClient.delete(
      `/${consultantId}?project_id=${projectId}`
    );
    return response.data;
  } else {
    const response = await consultantsClient.delete(
      `/${consultantId}?project_id=${projectId}`
    );
    return response.data;
  }
}
