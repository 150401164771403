import { Accordion, AccordionTab } from "primereact/accordion";
import RFIRequest from "../components/RFIs/RFIRequest";
import { useContext, useEffect, useState } from "react";
import RFIGeneralInformation from "../components/RFIs/RFIGeneralInformation";
import { AppContext } from "../context/AppProvider";
import { useMutation } from "@tanstack/react-query";
import { createRfi } from "../api/rfisAPI";
import { queryClient } from "../api/requestProcessor";
import { getErrorMessage } from "../util/helper";
import { uploadFiles } from "../api/filesAPI";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

export default function NewRFIPage() {
  const [rfi, setRfi] = useState({
    subject: "",
    rfi_body: "",
    attachments: [],
    number: 0,
  });
  const [files, setFiles] = useState([]);
  const [uploadReset, setUploadReset] = useState(false);
  const [label, setLabel] = useState("Save");

  const { toast, user } = useContext(AppContext);
  const navigate = useNavigate();

  const isProcoreUser = user.is_procore_user;

  const { mutate: createRfiMutation, isPending: isPendingCreation } =
    useMutation({
      mutationFn: createRfi,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["rfis"] });
        navigate("/rfis");
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "RFI Created",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const { mutateAsync: uploadFilesMutation, isPending: isPendingUpload } =
    useMutation({
      mutationFn: uploadFiles,
      onSuccess: () => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "File Uploaded",
          life: 3000,
        });
        setUploadReset(true);
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  useEffect(() => {
    if (isPendingUpload) {
      setLabel("Uploading Files");
    } else if (isPendingCreation) {
      setLabel("Saving Changes");
    } else {
      setLabel("Create and Send Emails");
    }
  }, [isPendingUpload, isPendingCreation, isProcoreUser]);

  const cancelRfiCreation = () => {
    navigate("/rfis");
  };

  const printErrorMessage = (errorMessage) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: errorMessage,
      life: 3000,
    });
  };

  const checkValidation = () => {
    if (!rfi.subject) {
      printErrorMessage("RFI Subject area is required!");
      return false;
    } else if (!rfi.rfi_body) {
      printErrorMessage("RFI Body area is required!");
      return false;
    }
    return true;
  };

  const handleCreateRfi = async () => {
    if (checkValidation()) {
      let updatedAttachments = [];

      if (files.length > 0) {
        const uploadPromises = files.map(async (file) => {
          return await uploadFilesMutation({
            files: [file],
          });
        });

        const responses = await Promise.all(uploadPromises);
        updatedAttachments = [...responses];
      }

      const rfiData = { ...rfi };

      createRfiMutation({
        rfiData: rfiData,
        attachments: updatedAttachments,
      });
    }
  };

  return (
    <>
      <p className="text-3xl w-10 font-medium line-height-1">Create New RFI</p>
      <Accordion multiple activeIndex={[0, 1]}>
        <AccordionTab header="REQUEST">
          <RFIRequest
            rfi={rfi}
            setRfi={setRfi}
            setFiles={setFiles}
            uploadReset={uploadReset}
            setUploadReset={setUploadReset}
            isProcoreUser={isProcoreUser}
            isPending={false}
          />
        </AccordionTab>
        <AccordionTab header="REQUEST">
          <RFIGeneralInformation
            rfi={rfi}
            setRfi={setRfi}
            editMode={false}
            isPending={false}
          />
        </AccordionTab>
      </Accordion>

      <div className="flex justify-content-end gap-2 mt-2">
        <Button
          label="Cancel"
          icon="pi pi-times"
          outlined
          onClick={cancelRfiCreation}
        />
        <Button
          label={label}
          icon="pi pi-check"
          onClick={handleCreateRfi}
          loading={isPendingCreation || isPendingUpload}
          disabled={isPendingCreation || isPendingUpload}
          className="right-0"
        />
      </div>
    </>
  );
}
