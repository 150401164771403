import { useMutation } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { addUserToWaitlist } from "../api/authAPI";
import { AppContext } from "../context/AppProvider";
import { getErrorMessage } from "../util/helper";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import TermsDialog from "../components/TermsDialog";
import PrivacyDialog from "../components/PrivacyDialog";

const initialUserData = {
  email: "",
  first_name: "",
  last_name: "",
  company_name: "",
};

const Registration = () => {
  const [userData, setUserData] = useState(initialUserData);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const { toast } = useContext(AppContext);
  const navigate = useNavigate();

  const { mutate: registerMutation, isPending } = useMutation({
    mutationFn: addUserToWaitlist,
    onSuccess: (response) => {
      toast.current.show({
        severity: "success",
        summary: "We've added you to the waitlist!",
        detail: response.message,
        life: 3000,
      });
      setUserData(initialUserData);
      setAgreedToTerms(false);
    },
    onError: (error) => {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: getErrorMessage(error),
        life: 3000,
      });
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!agreedToTerms) {
      toast.current.show({
        severity: "warn",
        summary: "Agreement Required",
        detail: "Please agree to the Terms of Service and Privacy Policy",
        life: 3000,
      });
      return;
    }
    registerMutation(userData);
  };

  const openTerms = (e) => {
    e.preventDefault();
    window.open("https://terms-and-conditions.heynicky.com/", "_blank");
  };

  const openPrivacy = (e) => {
    e.preventDefault();
    window.open("https://privacy-policy.heynicky.com/", "_blank");
  };

  return (
    <div className="centered-content">
      <Card
        className="nicky-card"
        title="Create your account"
        subTitle="Join us today to get started"
      >
        <form onSubmit={handleSubmit}>
          <div className="flex flex-column gap-4">
            <span className="p-float-label">
              <InputText
                id="email"
                name="email"
                type="email"
                value={userData.email}
                onChange={handleChange}
                className="w-full"
                required
              />
              <label htmlFor="email">Email</label>
            </span>

            <span className="p-float-label">
              <InputText
                id="first_name"
                name="first_name"
                type="text"
                value={userData.first_name}
                onChange={handleChange}
                className="w-full"
                required
              />
              <label htmlFor="first_name">First Name</label>
            </span>

            <span className="p-float-label">
              <InputText
                id="last_name"
                name="last_name"
                type="text"
                value={userData.last_name}
                onChange={handleChange}
                className="w-full"
                required
              />
              <label htmlFor="last_name">Last Name</label>
            </span>

            <span className="p-float-label">
              <InputText
                id="company_name"
                name="company_name"
                type="text"
                value={userData.company_name}
                onChange={handleChange}
                className="w-full"
                required
              />
              <label htmlFor="company_name">Company Name</label>
            </span>

            <div className="flex align-items-center">
              <Checkbox
                inputId="terms"
                checked={agreedToTerms}
                onChange={(e) => setAgreedToTerms(e.checked)}
              />
              <label htmlFor="terms" className="ml-2">
                I agree to the{" "}
                <Button
                  label="Terms of Service"
                  link
                  className="p-0 text-primary"
                  onClick={openTerms}
                />{" "}
                and{" "}
                <Button
                  label="Privacy Policy"
                  link
                  className="p-0 text-primary"
                  onClick={openPrivacy}
                />
              </label>
            </div>

            <Button
              className="w-full"
              icon="pi pi-user-plus"
              label={isPending ? "Creating Account..." : "Create Account"}
              type="submit"
              loading={isPending}
            />

            <Button
              label="Already have an account? Sign in"
              link
              onClick={() => navigate("/login")}
            />
          </div>
        </form>
      </Card>

      {/* Keeping dialogs for future use */}
      <TermsDialog visible={showTerms} onHide={() => setShowTerms(false)} />

      <PrivacyDialog
        visible={showPrivacy}
        onHide={() => setShowPrivacy(false)}
      />
    </div>
  );
};

export default Registration;
