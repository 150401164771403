import React, { useState, useEffect } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getUserData } from "../../util/storage";

export default function SpecTable({
  dt,
  selectedSpecs,
  setSelectedSpecs,
  specs,
  isPending,
  edit,
  confirmDelete,
  exportCSV,
  openNew,
}) {
  const isProcoreUser = getUserData()["is_procore_user"];

  const [filters, setFilters] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  useEffect(() => {
    initFilters();
  }, []);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      number: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      description: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      label: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
    setGlobalFilterValue("");
  };

  const clearFilter = () => {
    initFilters();
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <div>
          {!isProcoreUser && (
            <Button
              label="New"
              icon="pi pi-plus"
              severity="success"
              onClick={openNew}
              className="mr-2"
            />
          )}
          <Button
            label="Export"
            icon="pi pi-upload"
            className="p-button-help"
            onClick={() => exportCSV(selectedSpecs.length > 0 ? true : false)}
          />
        </div>
        <div>
          <Button
            type="button"
            icon="pi pi-filter-slash"
            label="Clear Filters"
            outlined
            onClick={clearFilter}
            className="mr-2"
          />
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
      </div>
    );
  };

  const header = renderHeader();

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-pencil"
          rounded
          text
          className="mr-2"
          onClick={() => edit(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          text
          severity="danger"
          onClick={() => confirmDelete(rowData)}
        />
      </>
    );
  };

  return (
    <DataTable
      ref={dt}
      selection={selectedSpecs}
      onSelectionChange={(e) => setSelectedSpecs(e.value)}
      dataKey="_id"
      value={specs}
      paginator
      showGridlines
      rows={10}
      loading={isPending}
      filters={filters}
      globalFilterFields={["description", "number"]}
      header={header}
      emptyMessage="No spec found."
      className="hidden sm:block"
    >
      <Column selectionMode="multiple" exportable={false}></Column>
      <Column
        header="Number"
        field="number"
        filter
        filterPlaceholder="Search by number"
        style={{ minWidth: "12rem" }}
      />
      <Column
        header="Description"
        field="description"
        style={{ minWidth: "12rem" }}
        filter
        filterPlaceholder="Search by description"
      />
      <Column
        header="Label"
        field="label"
        style={{ minWidth: "12rem" }}
        filter
        filterPlaceholder="Search by label"
      />
      {!isProcoreUser && (
        <Column
          body={actionBodyTemplate}
          exportable={false}
          style={{ minWidth: "8rem", maxWidth: "8rem" }}
        ></Column>
      )}
    </DataTable>
  );
}
