import { Button } from "primereact/button";
import { deleteReply } from "../../api/responsesAPI";
import { queryClient } from "../../api/requestProcessor";
import { useMutation } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { getUserData } from "../../util/storage";
import { AppContext } from "../../context/AppProvider";

export default function Answers({ replies, rfiId }) {
  const isProcoreUser = getUserData()["is_procore_user"];

  const initialArray = Array(replies.length).fill(false);
  const [confirmDelete, setConfirmDelete] = useState(initialArray);

  const { user, toast } = useContext(AppContext);

  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: deleteReply,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["rfis", rfiId] });
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Reply Deleted",
        life: 3000,
      });
    },
    onError: () => {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to Delete Reply",
        life: 3000,
      });
    },
  });

  const handleDeleteReply = (event, reply, index) => {
    const _confirmDelete = [...confirmDelete];
    _confirmDelete[index] = true;
    setConfirmDelete(_confirmDelete);
  };

  const confirmDeleteReply = (event, reply, index) => {
    mutate({ replyId: reply.id, rfiId: rfiId });

    const _confirmDelete = [...confirmDelete];
    _confirmDelete[index] = false;
    setConfirmDelete(_confirmDelete);
  };

  const convertFormattedDate = (date) => {
    const formattedDate = new Date(date).toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    return formattedDate;
  };

  return (
    <div id="rfi_answers">
      {replies.map((reply, index) => (
        <div
          key={index}
          className="flex align-items-center justify-content-between border-solid border-1 border-200 border-round-sm p-2 my-1"
        >
          <div className="grid align-items-center w-full">
            <div className="col-12 md:col-3">
              <div className="font-semibold">{reply.created_by}</div>
              <div className="text-sm">
                {convertFormattedDate(reply.answer_date)}
              </div>
            </div>

            <div className="col-12 md:col-5">
              {isProcoreUser ? reply.plain_text_body : reply.body}
            </div>

            <div className="col-12 md:col-4">
              <div className="text-sm font-medium">Attachments</div>
              {reply.attachments.length ? (
                reply.attachments.map((attachment, index) => (
                  <div key={`attachments-${index}`}>
                    <a
                      href={attachment.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm"
                    >
                      {attachment.filename}
                    </a>
                  </div>
                ))
              ) : (
                <div className="text-sm">There is no attachment</div>
              )}
            </div>
          </div>
          {confirmDelete[index] ? (
            <Button
              className="max-w-7rem p-0"
              label="Confirm Delete"
              text
              severity="danger"
              aria-label="Cancel"
              onClick={(event) => confirmDeleteReply(event, reply, index)}
            />
          ) : (
            user.role > 1 && (
              <Button
                icon="pi pi-times"
                rounded
                text
                severity="danger"
                aria-label="Cancel"
                onClick={(event) => handleDeleteReply(event, reply, index)}
              />
            )
          )}
        </div>
      ))}
    </div>
  );
}
