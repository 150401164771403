import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchSubmittals } from "../api/submittalsAPI";
import Submittals from "../components/Submittals/Submittals";

const SubmittalPage = () => {
  const [queryParams, setQueryParams] = useState({
    page: 1,
    per_page: 10,
    search: "",
  });

  const {
    data: submittals,
    isFetching,
    isError,
    error,
    refetch,
  } = useQuery({
    queryKey: ["submittals", queryParams],
    queryFn: ({ signal }) =>
      fetchSubmittals({ signal, queryParams: queryParams }),
    staleTime: 5 * 60 * 1000,
  });

  return (
    <div>
      <Submittals
        submittals={submittals && submittals.submittals}
        isPending={isFetching}
        refetch={refetch}
        setQueryParams={setQueryParams}
        total={submittals && submittals.total}
      />
    </div>
  );
};

export default SubmittalPage;
