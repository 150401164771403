export function getProjectId() {
  const projectId = localStorage.getItem("savedProjectId");

  if (!projectId) {
    return null;
  }

  return projectId;
}

export function getProjects() {
  const projects = JSON.parse(localStorage.getItem("projects"));

  if (!projects) {
    return null;
  }

  const storedExpirationDate = JSON.parse(
    localStorage.getItem("projectsExpiration")
  );
  const expirationDate = new Date(storedExpirationDate);
  const now = new Date();
  const duration = expirationDate.getTime() - now.getTime();

  if (duration < 0) {
    localStorage.removeItem("projects");
    localStorage.removeItem("projectsExpiration");
    return null;
  }

  return projects;
}

export function getUserData() {
  const userData = JSON.parse(localStorage.getItem("userData"));

  if (!userData) {
    return null;
  }

  return userData;
}

export function getCompanyId() {
  const companyId = localStorage.getItem("company_id");

  if (!companyId) {
    return null;
  }

  return companyId;
}
